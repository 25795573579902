import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FileDownload, Mail } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import PictureAvatar from "components/PictureAvatar";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import OnboardingStatus from "components/OnboardingStatus";

import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import CompanyStatusButton from "components/CompanyStatusButton";
import { useAppContextController } from "context/AppContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import ApplicantsFilterCards from "layouts/pages/applicants/components/ApplicantFilterCards";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import StaffingPoolFilterCards from "layouts/pages/employees/components/StaffingPoolFilterCards";
import CustomersApplicantsTableActions from "layouts/pages/jobs/components/JobsApplicantsTableActions";
import moment from "moment";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import { SnackbarProvider } from "notistack";
import { getApplicantCompanyStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
// import CompanySearch from "../CompanySearch";
import TagSearchAndToggle from "components/TagSearchAndToggle";
import { isEmpty } from "lodash";
import NoTagSelectedModal from "layouts/pages/applicants/components/NoTagSelectedModal";
import NoResultsModal from "layouts/pages/applicants/components/NoResultsModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const CustomersApplicantsTable = ({
  type = "Staffing",
  fetchAll = false,
  title = "Customer Applicants",
  filters,
  pageSize = 5,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  customerSlug = null,
  jobSlug = null,
  showEventStatus = false,
  setSelectedId,
  staffingPoolName,
}) => {
  const queryClient = useQueryClient();
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const [openNoTagModal, setOpenNoTagModal] = useState(false);
  const [messageContext, setMessageContext] = useState(null);
  const [openNoResultsModal, setOpenNoResultsModal] = useState(false);
  const { currentApplicant, setCurrentApplicant, currentCustomer, company } =
    useAppContextController();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);

  const defaultFilters = {
    status: type === "Staffing" ? "Employee" : "Applicant",
  };
  if (type !== "Staffing") defaultFilters.applicantStatus = "New";

  const [applicantsFilters, setApplicantsFilters] = useState(defaultFilters);
  const [candidates, setCandidates] = useState({});
  const [searchTagList, setSearchTagList] = useState([]);
  const [useOrTags, setUseOrTags] = useState(true);
  const [recipientText, setRecipientText] = useState("All");

  const filtersFinal = {
    status: type === "Staffing" ? "Employee" : "Applicant",
    ...filters,
    ...applicantsFilters,
  };

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize); // Default pageSize higher than 10. Don't use preferred pageSize

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters.tags;
    setApplicantsFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(true);
  };

  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll, order, orderBy } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    includeVerified: type === "Staffing",
    filters: filtersFinal,
    useOrTags,
    useElemMatch: true,
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["customersapplicants", allOptions], () =>
    type === "Applicants" ? fetchApplicants(allOptions) : fetchStaffingPool(allOptions)
  );

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      // setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, id, applicants?.data]);

  const columns = [
    // {
    //   title: "Avatar",
    //   field: "profileImg",
    //   customCell: (image, field, row) => (
    //     <PictureAvatar
    //       image={row?.profileImg}
    //       firstName={row?.firstName}
    //       lastName={row?.lastName}
    //       userId={row?.userRecordId ? row.userRecordId : row?._id}
    //       size="md"
    //     />
    //   ),
    // },
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, field, row) => {
        if (row.isDnu === "Yes")
          return (
            <Tooltip title="Do not use">
              <BlockIcon fontSize="large" color="error" />
            </Tooltip>
          );
        if (flagged === "Yes")
          return (
            <Tooltip title="Check Employee Notes">
              <FlagIcon fontSize="large" color="error" />
            </Tooltip>
          );
        return "";
      },
    },
    ...(company?.companyType === "Company"
      ? [
        {
          title: "Onboard",
          field: "profileImg",
          customCell: (image, field, row) => <OnboardingStatus applicant={row} size="md" />,
          width: "10%",
        },
      ]
      : []),
    { title: "Type", field: "status" },
    { title: "Applicant Status", field: "applicantStatus" },
    ...(customerSlug && currentCustomer
      ? [
        {
          title: "Customer Status",
          field: "slug",
          customCell: (_id, field, row) => (
            <>
              <CompanyStatusButton
                status={getApplicantCompanyStatus(row, customerSlug)}
                customer={currentCustomer}
                onUpdate={() => refetchApplicants()}
                applicant={row}
              />
            </>
          ),
        },
      ]
      : []),
    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    ...(type === "Staffing" ? [{ title: "Login", field: "loginVerified" }] : []),
    // {
    //   title: "Hire Date",
    //   field: "hireDate",
    //   customCell: (date) => moment(date).format("YYYY-MM-DD"),
    // },
    // { title: "Rep", field: "" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    ...(company?.companyType === "Venue" ? [{ title: "Rank", field: "rank" }] : []),

    { title: "Source", field: "referralSource" },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Applicant Actions",
      field: "_id",
      customCell: (rowId, field, row) => (
        <CustomersApplicantsTableActions
          type={type}
          id={rowId}
          row={row}
          setCurrentApplicant={setCurrentApplicant}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
          parentTable="customers"
        />
      ),
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      type === "Staffing"
        ? `/employees/${applicant._id}${navigateToAction ? `/action/${navigateToAction}?backTo=customers` : ""
        }`
        : `/applicants/${applicant._id}${navigateToAction ? `/action/${navigateToAction}?backTo=customers` : ""
        }`
    );
  };

  const addNew = (e) => {
    if (!currentApplicant) {
      setCurrentApplicant({});
    }
    setNavigateUrl("/applicants/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  useEffect(() => {
    // if (!applicants) return;
    setCandidates(applicants);
  }, [applicants]);

  useEffect(() => {
    if (applicantsFilters?.["licenses.licenseType.value"])
      setRecipientText(`Licenses: ${applicantsFilters?.["licenses.licenseType.value"]}`);
    if (applicantsFilters?.status) {
      if (applicantsFilters?.status?.includes("Applicant")) setRecipientText("All");
      else setRecipientText(applicantsFilters?.status);
    }
  }, [applicantsFilters]);

  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor={type === "Staffing" ? "error" : "warning"}
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-4}
                >
                  <Icon fontSize="large">people</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <TagSearchAndToggle
                filters={applicantsFilters}
                setFilters={setApplicantsFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                useOrTags={useOrTags}
                setUseOrTags={setUseOrTags}
              />
              {showSearchBar && (
                <Searchbar
                  fetch={fetchApplicants}
                  fetchAll={false}
                  placeholder="Search Applicants"
                  columns={columns.slice(1, columns.length - 1)}
                  queryCharacterLimit={3}
                  resultsLimit={15}
                  setFilters={setFilters}
                  setPage={setPage}
                  searchBy={["firstName", "lastName", "email", "altEmail"]}
                  filterBy="_id"
                  scrollLimit={200}
                  sortFunction={sortLastFirst}
                // onRowClick={(item) => {
                //   if (item?._id) setSelectedId(item._id);
                // }}
                />
              )}

              <MDBox>
                <Tooltip title="Bulk Message">
                  <IconButton
                    color="error"
                    onClick={() => {
                      if (
                        applicants?.data?.length === 0 ||
                        applicants?.data?.length === undefined
                      ) {
                        setOpenNoResultsModal(true);
                      } else {
                        if (searchTagList?.length > 0 && applicants?.data?.length > 0)
                          setMessageContext("Applicant");
                        else setMessageContext("Customer");
                        toggleBulkMessageModal(true);
                      }
                    }}
                  >
                    <Mail fontSize="large" />
                  </IconButton>
                </Tooltip>
                {currentCustomer?.slug && (
                  <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                    <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Create a New Employee">
                  <IconButton className={classes.addButton} color="info" onClick={addNew}>
                    <AddCircleRoundedIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            </Grid>
          </Grid>
          {showFiltersList && !isEmpty(searchTagList) && (
            <MDBox ml={3} mb={2}>
              <Grid item xs={6}>
                <FiltersList
                  filters={applicantsFilters}
                  setFilters={setApplicantsFilters}
                  searchTagList={searchTagList}
                  setSearchTagList={setSearchTagList}
                  clearTagsHandler={clearTagsHandler}
                  onlyDisplayTags
                />
              </Grid>
            </MDBox>
          )}
          <MDBox ml={3}>
            <MDBox display="flex" flexWrap="wrap" alignItems="center" rowGap="1rem">
              {type === "Staffing" ? (
                <StaffingPoolFilterCards
                  setPage={setPage}
                  parent="Customers"
                  setFilters={setApplicantsFilters}
                  filters={filtersFinal}
                  useElemMatch
                  doReplace
                />
              ) : (
                <ApplicantsFilterCards
                  parent="Customers"
                  setFilters={setApplicantsFilters}
                  filters={filtersFinal}
                  useElemMatch
                  doReplace
                />
              )}
              {/* <CompanySearch
                                data={candidates}
                                setData={setCandidates}
                                fetchedData={applicants}
                                setPage={setPage}
                                applicantsFilters={applicantsFilters}
                                label={type === "Applicants" ? "Applicants" : "Staff"}
                            /> */}
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={2}>
            <DataTable
              columns={columns}
              data={candidates}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isLoading}
              onRowClick={(row, e) => handleRowClick(row, e)}
              order={order}
              orderBy={orderBy}
              toggleSort={toggleSort}
              fetchAll={fetchAll}
              defaultSort="createdDate:desc"
              idField="_id"
            />
          </MDBox>
          <ApplicantActionModal
            open={genericModalOpen}
            setOpen={setGenericModalOpen}
            modalInfo={modalInfo}
            setToastAlert={setToastAlert}
            currentApplicant={currentApplicant}
          />
        </SnackbarProvider>
      </Card>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={recipientText}
          applicants={applicants?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext={messageContext}
          attachmentContext="Customer"
        />
      )}
      {/* {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          customerSlug={currentCustomer?.slug}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
        />
      )} */}
      {openNoTagModal && <NoTagSelectedModal open={openNoTagModal} setOpen={setOpenNoTagModal} />}
      {openNoResultsModal && (
        <NoResultsModal open={openNoResultsModal} setOpen={setOpenNoResultsModal} />
      )}
    </>
  );
};

// Setting default values for the props
CustomersApplicantsTable.defaultProps = {
  // currentCustomer: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
CustomersApplicantsTable.propTypes = {
  // currentCustomer: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default CustomersApplicantsTable;
