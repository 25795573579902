import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import useSort from "utils/useSort";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import { Grid, IconButton, Stack, TableHead, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useQuery } from "react-query";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import FilterButtons from "components/FilterButtons";
import {
  APPLICANT_COMPANIES_JOBS_OPTIONS,
  APPLICANT_VENUES_JOBS_OPTIONS,
} from "components/FilterButtons/options";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import { isAdmin } from "utils/helpers/roles";
import JobStatusButton from "components/JobStatusButton";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import useSessionAuth from "hooks/useSessionAuth";
import fetchCustomers from "api/companies/fetchCustomers";
import ApplicantStageButton from "components/ApplicantStageButton";
import { useNavigate } from "react-router-dom";
import { Person, PersonOff } from "@mui/icons-material";

const activeFilterIcons = {
  [null]: {
    color: "secondary",
    icon: <Person fontSize="large" />,
    tooltip: "Showing all jobs",
  },
  Active: {
    color: "info",
    icon: <Person fontSize="large" />,
    tooltip: "Showing active jobs",
  },
  Inactive: {
    color: "error",
    icon: <PersonOff fontSize="large" />,
    tooltip: "Showing inactive jobs",
  },
};

const ApplicantJobs = ({ fetchAll = true, title = "Title", filters, setFilters, refresh }) => {
  const [dataFilter, setDataFilter] = useState({ status: "all" });

  const { company, venues, companyType } = useAppContextController();
  const isCompany = company?.companyType === "Company";
  const { currentApplicant, userType } = useAppContextController();
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { order, orderBy };

  const [isFilteringActive, setIsFilteringActive] = useState("Active");

  const allOptions = {
    ...options,
    filters: { ...(isFilteringActive ? { status: isFilteringActive } : {}), ...filters },
    venues,
  };
  const [customerData, setCustomerData] = useState({});
  const { logoutAuth0User } = useSessionAuth();
  const navigate = useNavigate();

  const {
    data: jobs,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["jobs", allOptions],
    () => (isCompany ? fetchCompanyJob(allOptions) : fetchJobs(allOptions)),
    isCompany && {
      select: (data) => {
        if (data?.data == null) return data;
        const newData = data.data.map((jb) => {
          let isJobFull = false;
          if (jb.positions != null) {
            const openPositions = jb.positions.filter((pos) => pos.status === "Open").length;
            isJobFull = openPositions <= 0;
          }

          return {
            ...jb,
            isJobFull,
          };
        });
        return {
          ...data,
          data: newData,
        };
      },
      onSuccess: async (data) => {
        // Assuming that data is an array of interviews
        const customers = {};

        try {
          // Get all company slugs
          let companySlugs = data?.data?.map((comp) => comp.companySlug);
          // Remove duplicates
          companySlugs = [...new Set(companySlugs)];

          const results = await fetchCustomers({
            filters: { slug: companySlugs.join(";") },
            page: 1,
            limit: 0,
          });

          (results?.data ?? []).forEach((cstmr) => {
            customers[cstmr.slug] = cstmr;
          });
          setCustomerData(customers);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      },
    }
  );

  const allJobs = Object.values(jobs?.data || []);

  // const getModifiedDateFromApplVenues = (applVenues, venue) => {
  //     if (!applVenues || !applVenues.length) return null;
  //     const ndx = applVenues.findIndex((item) => item.venueSlug === venue);
  //     if (ndx > -1) return new Date(applVenues[ndx]?.dateModified);
  //     return null;
  // };

  const renderRow = (row) => {
    const rowCompany =
      customerData[row.companySlug] && isCompany ? customerData[row.companySlug] : {};
    const applicantJobStatus = getApplicantJobStatus(currentApplicant, row.jobSlug);
    return (
      <>
        <MDBox
          key={`${row.name}_${row.jobSlug}`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={2} lg={1.5}>
            {isCompany ? (
              <CompanyLogoSquare company={rowCompany} defaultCompany={company} />
            ) : (
              <VenueIcon logoUrl={row.logoUrl} slug={row.jobSlug} name={row.venueName} />
            )}
          </Grid>
          <Grid item xs={12} lg={isCompany ? 4 : 5}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row.title}
                  {row.status === "Inactive" && (
                    <Tooltip title="Inactive">
                      <PersonOff
                        fontSize="small"
                        color="error"
                        sx={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </Tooltip>
                  )}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  {isCompany ? row.companyCity : row.venueCity},{" "}
                  {isCompany ? row.companyState : row.venueState}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={isCompany ? 6.5 : 5.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} desktop={8}>

                <MDTypography variant="body2">
                  {isCompany ? row.companyName : row.venueName}
                </MDTypography>

              </Grid>
              <Grid item xs={12} desktop={4} >
                <Stack direction="row" >
                  {applicantJobStatus !== "None" && applicantJobStatus != null && isCompany ? (
                    <ApplicantStageButton applicant={currentApplicant} job={row} refresh={refresh} />
                  ) : null}
                  <Tooltip title={`View ${row.title ? row.title : "Job"}`}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(isCompany ? `/companyjobs/${row.jobSlug}` : `/jobs/${row.jobSlug}`);
                      }}
                    >
                      <InfoIcon fontSize="large" color="info" />
                    </IconButton>
                  </Tooltip>
                  <JobStatusButton status={applicantJobStatus} job={row} refresh={refresh} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </>
    );
  };

  const topButtonBar = (
    <TableHead
      sx={{
        height: "6rem",
        zIndex: 3,
        position: "sticky",
        top: 0,
        background: "white",
        width: "100%",
      }}
      display="flex"
      width="100%"
    >
      {isAdmin(userType) && (
        <FilterButtons
          filter={dataFilter}
          setFilter={setDataFilter}
          name="status"
          options={isCompany ? APPLICANT_COMPANIES_JOBS_OPTIONS : APPLICANT_VENUES_JOBS_OPTIONS}
          fontSize="0.72rem"
        />
      )}
    </TableHead>
  );

  const header = (
    <Grid container direction="row" justifyContent="flex-end" pr="3rem" pt="1rem">
      <Tooltip title={activeFilterIcons[isFilteringActive].tooltip}>
        <span>
          <IconButton
            color={activeFilterIcons[isFilteringActive].color}
            onClick={() =>
              setIsFilteringActive((value) => {
                if (value == null) return "Active";
                if (value === "Active") return "Inactive";
                return null;
              })
            }
          >
            {activeFilterIcons[isFilteringActive].icon}
          </IconButton>
        </span>
      </Tooltip>
    </Grid>
  );

  const filteredJobs = useMemo(() => {
    if (dataFilter.status !== "all") {
      return allJobs
        .filter(
          (item) => getApplicantJobStatus(currentApplicant, item.jobSlug) === dataFilter.status
        )
        .sort((itemA, itemB) => {
          if (itemA.title > itemB.title) return 1;
          if (itemA.title < itemB.title) return -1;
          return 0;
        });
    }
    return allJobs.sort((itemA, itemB) => {
      if (itemA.companyName > itemB.companyName) return 1;
      if (itemA.companyName < itemB.companyName) return -1;
      return 0;
    });
  }, [dataFilter, allJobs, currentApplicant]);

  return (
    <DataList
      renderRow={renderRow}
      data={filteredJobs}
      icon="work"
      iconColor="warning"
      title="Jobs"
      isLoading={isFetching}
      greybar
      divider
      header={header}
      tableHeight={450}
      height={850}
      scrollY
      computedHeight
      topButtonBar={topButtonBar}
    />
  );
};

export default ApplicantJobs;
