import { useEffect, useState } from "react";
import { useNewApplicantContext } from "context/NewApplicantContext";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import Attachments from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Button, Stack, Box } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { getCommonBaseImageUrl } from "utils/helpers/upload";

const UploadID = () => {
  const { applicant, updateButtons, buttonState, updateApplicantAction, company } =
    useNewApplicantContext();
  const { user } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const [attachmentOpen, setAttachmentOpen] = useState(false);

  const attachmentTitle = (
    <>
      <Stack alignItems="center" justifyContent="flex-start">
        <MDTypography component="label" variant="button" fontWeight="bold">
          You must upload a Drivers License/Government Photo ID, plus one of either Social Security
          Card, Birth Certificate, or US Passport
        </MDTypography>
        <MDBox sx={{ width: "100%" }}>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "info",
              textDecoration: "underline",
              pl: 0,
              textAlign: "left",
            }}
            onClick={() => {
              window.open(
                `${getCommonBaseImageUrl(
                  company
                )}/static/How%20to%20Compress%20Your%20Images%20for%20Upload.pdf`
              );
            }}
          >
            Click here for our image upload guide
          </Button>
        </MDBox>
      </Stack>

      <hr />
    </>
  );

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: false,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  return (
    <>
      <Attachments
        currentApplicant={applicant}
        setOpen={setAttachmentOpen}
        attachmentTitle={attachmentTitle}
      />
      <UploadFileModal
        currentApplicant={applicant}
        open={attachmentOpen}
        setOpen={setAttachmentOpen}
        updateApplicantAction={updateApplicantAction}
        outsideMode={user ? "" : "protected"}
      />
    </>
  );
};

export default UploadID;
