// @mui material components
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import Grid from "@mui/material/Grid";
import postNote from "layouts/pages/applicants/actions/postNote";
import {
  Article,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  CheckCircleRounded,
  Handshake as HandshakeIcon,
  Info as InfoIcon,
  Login as LoginIcon,
  PersonOff as PersonOffIcon,
  Block,
} from "@mui/icons-material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";

import EventsTable from "layouts/pages/events/components/EventsTable";
import JobsTable from "layouts/pages/jobs/components/JobsTable";
import { Button } from "@mui/material";
import { NewApplicantContextProvider } from "context/NewApplicantContext";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import ApplicantActivities from "layouts/pages/applicants/components/ApplicantActivities";
import ApplicantAttachments from "layouts/pages/applicants/components/ApplicantAttachments";
import ApplicantOnboardingEvents from "layouts/pages/applicants/components/ApplicantEvents";
import ApplicantNotes from "layouts/pages/applicants/components/ApplicantNotes";
import ApplicantOnboardingPanel from "layouts/pages/applicants/components/ApplicantOnboardingPanel";
import ApplicantSendMessagePanel from "layouts/pages/applicants/components/ApplicantSendMessagePanel";
import ApplicantVenues from "layouts/pages/applicants/components/ApplicantVenues";
import ApplicantVenuesTable from "layouts/pages/applicants/components/ApplicantVenuesTable";
import ApplicantCustomersTable from "layouts/pages/customers/components/CustomerTable";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import EmployeesFilterCards from "layouts/pages/employees/components/EmployeesFilterCards";
import UserPanel from "layouts/pages/applicants/components/UserPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmployeesTable from "layouts/pages/employees/components/EmployeesTable";
import { SnackbarProvider } from "notistack";
import StaffingPoolFilterCards from "layouts/pages/employees/components/StaffingPoolFilterCards";

import useSessionAuth from "hooks/useSessionAuth";
import ApplicantJobs from "layouts/pages/applicants/components/ApplicantJobs";
import { useRefreshApplicant } from "hooks/useRefreshApplicant";
import ApplicantCompanies from "../applicants/components/ApplicantCompanies";
import ApplicantMainInfoPanel from "../applicants/components/ApplicantMainInfoPanel";
import ApplicantAdditionalInfo from "../applicants/components/ApplicantAdditionalInfo";
import CompanyJobsTable from "../companyjobs/components/CompanyJobsTable";

// import ApplicantProcessStepper from "./components/ApplicantProcessStepper";

function Employees({ mode = "edit" }) {
  const navigate = useNavigate();
  const { company } = useAppContextController();

  const initialCards = useMemo(
    () => [
      // {
      //   color: "info",
      //   icon: <InfoIcon fontSize="large" color="info" />,
      //   title: "All",
      //   tooltip: "Show All Employees",
      //   number: 0,
      //   filterField: "status",
      //   filterValue: "Employee;Inactive;Partner",
      // },
      ...(company?.companyType === "Company"
        ? [
            {
              color: "info",
              icon: <InfoIcon fontSize="large" color="info" />,
              title: "All",
              tooltip: "Show All Employees",
              default: true,
              number: 0,
              filterField: "employmentStatus",
              filterValue: "Active;On Assignment;Inactive;Terminated",
            },
            {
              color: "success",
              icon: <CheckCircleIcon fontSize="large" color="success" />,
              title: "Assignment",
              tooltip: "",
              number: 0,
              filterField: "employmentStatus",
              filterValue: "On Assignment",
            },
            {
              color: "secondary",
              icon: <CheckCircleRounded fontSize="large" color="secondary" />,
              title: "Inactive",
              number: 0,
              tooltip: "Inactive Employees",
              filterField: "employmentStatus",
              filterValue: "Inactive",
            },
            {
              color: "secondary",
              icon: <CheckCircleRounded fontSize="large" color="secondary" />,
              title: "Terminated",
              number: 0,
              tooltip: "Terminated Employees",
              filterField: "employmentStatus",
              filterValue: "Terminated",
            },
            {
              icon: <Block color="error" fontSize="large" />,
              title: "Do Not Use",
              color: "error",
              tooltip: "Do Not Use",
              number: 0,
              filterField: "isDnu",
              filterValue: "Yes",
            },
            // {
            //   color: "warning",
            //   icon: <PersonOffRounded fontSize="large" color="warning" />,
            //   title: "Unavailable",
            //   number: 0,
            //   tooltip: "Unavailable Employees",
            //   filterField: "isAvailable",
            //   filterValue: "No",
            // },
          ]
        : []),
      ...(company?.companyType === "Venue"
        ? [
            {
              color: "success",
              icon: <CheckCircleIcon fontSize="large" color="success" />,
              title: "Active",
              default: true,
              tooltip: "",
              number: 0,
              filterField: "employmentStatus",
              filterValue: "Active",
            },
            {
              color: "primary",
              icon: <HandshakeIcon fontSize="large" color="success" />,
              title: "Partner",
              tooltip: "Partner Employees",
              number: 0,
              filterField: "status",
              filterValue: "Partner",
            },

            {
              color: "info",
              icon: <LoginIcon fontSize="large" color="info" />,
              title: "LoggedIn",
              tooltip: "Users Have Logged In",
              number: 0,
              filterField: "loginVerified",
              filterValue: "Yes",
            },
            {
              color: "info",
              icon: <LoginIcon fontSize="large" color="info" />,
              title: "No Login",
              tooltip: "Users Have Not Logged In",
              number: 0,
              filterField: "loginVerified",
              filterValue: "No",
            },
            {
              color: "warning",
              icon: <PersonOffIcon fontSize="large" color="warning" />,
              title: "Inactive",
              tooltip: "Inactive Employees",
              number: 0,
              filterField: "employmentStatus",
              filterValue: "Inactive",
            },
            {
              color: "error",
              icon: <CancelIcon fontSize="large" color="error" />,
              title: "Terminated",
              tooltip: "Terminated Employees",
              number: 0,
              filterField: "employmentStatus",
              filterValue: "Terminated",
            },
            {
              icon: <Block color="error" fontSize="large" />,
              title: "Do Not Use",
              color: "error",
              tooltip: "Do Not Use",
              number: 0,
              filterField: "isDnu",
              filterValue: "Yes",
            },
          ]
        : [
            // {
            //   color: "warning",
            //   icon: <PersonOffIcon fontSize="large" color="warning" />,
            //   title: "Do Not Use",
            //   tooltip: "DNU Employees",
            //   number: 0,
            //   filterField: "employmentStatus",
            //   filterValue: "DNU",
            // },
            // {
            //   color: "error",
            //   icon: <CancelIcon fontSize="large" color="error" />,
            //   title: "Terminated",
            //   tooltip: "Terminated Employees",
            //   number: 0,
            //   filterField: "employmentStatus",
            //   filterValue: "Terminated",
            // },
          ]),
      {
        icon: <Article fontSize="large" />,
        color: "secondary",
        title: "Licenses",
        tooltip: "Licenses",
        number: 0,
        filterField: "licenses.licenseType.value",
        filterValue: "gamingLicense;securityLicense;tabcLicense",
      },
    ],
    [company?.companyType]
  );

  const { currentApplicant, setCurrentApplicant, currentLoggedUser, userType } =
    useAppContextController();
  const { id, action } = useParams();
  const [searchParams] = useSearchParams();
  const backComponent = searchParams.get("backTo");
  const [selectedId, setSelectedId] = useState(id);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [selectedCardNumber, setSelectedCardNumber] = useState(0);

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    employmentStatus: "Active;On Assignment;Inactive;Terminated",
  });
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [panelSelect, setPanelSelect] = useState("activities");
  const [boarding, setBoarding] = useState(false);
  const [useOrTags, setUseOrTags] = useState(true);
  const { logoutAuth0User } = useSessionAuth();

  const { refreshApplicant } = useRefreshApplicant();

  const baseFiltersClient = useMemo(() => {
    const clientOrgSlugs = currentLoggedUser?.clientOrgs?.map((item) => item.slug) ?? [];
    return { status: "Employee;Partner", "venues.venueSlug": clientOrgSlugs?.join(";") };
  }, [currentLoggedUser?.clientOrgs]);

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };
    const updateNewNote = async () => {
      let res;
      await postNote({ applicantId: currentApplicant?._id, data: newNote })
        .then((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: "Notes Have Been Successfully Updated",
            status: "success",
          });
        })
        .catch((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: `Something went wrong! ${response}`,
            status: "error",
          });
          if (String(response).includes("401") || response?.response?.status === 401) {
            logoutAuth0User();
          }
        });
      if (res?.data?.modifiedCount) {
        await refreshApplicant();
      }

      return res;
    };

    return updateNewNote();
    // const newNotesArr = currentApplicant.attachments ? [...currentApplicant.attachments, newAtt] : [newAtt];
    // await updateApplicantMutation.mutateAsync({
    //     applicantId: currentApplicant._id,
    //     data: {
    //         notes: [...newNotesArr],
    //     }
    // });
  };

  const fetchApplicant = async () => {
    const appl = await fetchApplicants({ filters: { _id: selectedId }, useOr: useOrTags });
    if (appl && mode === "edit") setCurrentApplicant(appl.data[0]);
  };

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  useEffect(() => {
    if (id && action) {
      if (company?.companyType === "Venue") setFilters({ _id: id, status: "Employee;Partner" });
      else setFilters({ _id: id });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }
    if (id && (!currentApplicant || currentApplicant._id !== id)) {
      fetchApplicant();
    }
  }, [id, action, currentApplicant]);

  // causes filter to default to below even if selecting different filter card
  // useEffect(() => {
  //   if (!action && !id) {
  //     const { filterField, filterValue } = initialCards.find((card) => card.default);
  //     setFilters(() => ({
  //       [filterField]: filterValue,
  //       status: "Employee;Inactive",
  //     }));
  //   }
  // }, [action, id, initialCards, company?.companyType]);

  // useEffect(() => {
  //   if (isEmpty(filters) || (!id && filters._id)) {
  //     const defaultStaffingPoolCard = initialCards.find((card) => card.default);
  //     setFilters(() => ({
  //       [defaultStaffingPoolCard.filterField]: defaultStaffingPoolCard.filterValue,
  //       status: "Employee;Inactive;Partner",
  //     }));
  //   }
  // }, [filters, id, initialCards]);

  // useEffect(() => {
  //   if (id && action && !filters._id && !searchParams.get("ref")) {
  //     navigate("/employees");
  //   }
  // }, [filters]);

  function panelSelector(selection) {
    switch (selection) {
      case "activities":
        return <ApplicantActivities />;
      case "companies":
        return <ApplicantCompanies parent="Employee" />;
      case "venues":
        return <ApplicantVenues />;
      case "jobs":
        return <ApplicantJobs refresh={refreshApplicant} />;
      case "note":
        return <ApplicantNotes setOpen={setNoteModalOpen} currentApplicant={currentApplicant} />;
      case "user":
        return <UserPanel currentApplicant={currentApplicant} />;
      case "message":
        return <ApplicantSendMessagePanel />;
      case "orientation":
        return <ApplicantOnboardingEvents fetchAll={false} />;
      case "attachments":
        return <ApplicantAttachments setOpen={setOpen} />;
      case "additionalInfo":
        return <ApplicantAdditionalInfo />;
      default:
        return null;
    }
  }

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} sm={12}>
          {backComponent && (
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back to {backComponent}
            </Button>
          )}
          {!backComponent && company?.companyType === "Venue" && (
            <EmployeesFilterCards
              setFilters={setFilters}
              setPage={setPage}
              filters={filters}
              baseFilters={{ status: "Employee;Partner" }}
            />
          )}
          {!backComponent && (
            <StaffingPoolFilterCards
              // setTableFilter={setFilters}
              filters={filters}
              setFilters={setFilters}
              selectedCardNumber={selectedCardNumber}
              initialCards={initialCards}
              baseFilters={
                userType === "Client" || userType === "Event Admin"
                  ? baseFiltersClient
                  : { status: "Employee;Partner" }
              }
            />
          )}
          <EmployeesTable
            defaultSort="createdDate"
            filters={filters}
            setNavigateUrl={setNavigateUrl}
            setFilters={setFilters}
            showFiltersList
            setSelectedId={setSelectedId}
            page={page}
            setPage={setPage}
            pageSize={5}
            useOrTags={useOrTags}
            setUseOrTags={setUseOrTags}
            staffingPoolCards={initialCards}
            status="Employee;Partner"
          />
        </Grid>
        {["employeeinfo", "employeeall", ""].includes(actionSelected) && (id || mode === "create") && (
          <>
            {/* {currentApplicant &&
                ["New", "ATC", "Screened", "Pre-Hire"].includes(currentApplicant.status) && (
                  <Grid container sx={12} spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <ApplicantProcessStepper applicant={currentApplicant} />
                    </Grid>
                  </Grid>
                )} */}
            <Grid container spacing={1} mt={2}>
              <Grid item xs={12} sm={panelSelect === "" ? 12 : 6}>
                <ApplicantMainInfoPanel
                  setPanelSelect={setPanelSelect}
                  panelSelect={panelSelect}
                  setBoarding={setBoarding}
                  boarding={boarding}
                  setNavigateUrl={setNavigateUrl}
                  parent="Employee"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {panelSelector(panelSelect)}
              </Grid>
              {boarding && (
                <Grid item xs={12} sm={12}>
                  <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    key="top-center"
                  >
                    <NewApplicantContextProvider>
                      <ApplicantOnboardingPanel refetchApplicant={fetchApplicant} />
                    </NewApplicantContextProvider>
                  </SnackbarProvider>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {["employeecustomers", "employeeall"].includes(actionSelected) &&
          company?.companyType === "Company" && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <ApplicantCustomersTable
                  title={`Customers For for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={
                    currentApplicant?.companies
                      ? {
                          slug: currentApplicant.companies
                            .map((comp) => comp.companySlug)
                            .join(";"),
                        }
                      : {}
                  }
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="slug"
                  showVenueStatus
                  currentApplicant={currentApplicant}
                />
              </Grid>
            </>
          )}
        {["employeevenues", "employeeall"].includes(actionSelected) &&
          company?.companyType === "Venue" && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <ApplicantVenuesTable
                  title={`Venues for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={
                    currentApplicant?.venues
                      ? { slug: currentApplicant.venues.map((venue) => venue.venueSlug).join(";") }
                      : {}
                  }
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="slug"
                  showVenueStatus
                  currentApplicant={currentApplicant}
                />
              </Grid>
            </>
          )}
        {["employeejobs", "employeeall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              {company?.companyType === "Venue" ? (
                <JobsTable
                  title={`Jobs for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={
                    currentApplicant?.jobs
                      ? { jobSlug: currentApplicant.jobs.map((job) => job.jobSlug).join(";") }
                      : {}
                  }
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="title"
                  parent="employees"
                  currentApplicant={currentApplicant}
                />
              ) : (
                <CompanyJobsTable
                  title={`Jobs for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={
                    currentApplicant?.jobs
                      ? { jobSlug: currentApplicant.jobs.map((job) => job.jobSlug).join(";") }
                      : {}
                  }
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="title"
                  parent="employees"
                  currentApplicant={currentApplicant}
                />
              )}
            </Grid>
          </>
        )}
        {["employeeOnboardingEvents", "employeeall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <EventsTable
                showFiltersList={false}
                showSearchBar={false}
                fetchAll
                title={`${company?.companyType === "Venue" ? "Events" : "Interviews"} for: ${
                  currentApplicant?.firstName
                } ${currentApplicant?.lastName} `}
                filters={
                  currentApplicant?.events
                    ? { eventUrl: currentApplicant.events.map((event) => event.eventUrl).join(";") }
                    : {}
                }
                defaultSort="eventDate"
                showEventStatus
              />
            </Grid>
          </>
        )}
        <UploadFileModal
          currentApplicant={currentApplicant}
          setCurrentApplicant={setCurrentApplicant}
          open={open}
          setOpen={setOpen}
          refresh={refreshApplicant}
        />
      </Grid>
      <AddANoteModal
        currentApplicant={currentApplicant}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        saveNote={saveNote}
      />
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
Employees.defaultProps = {
  // pageName: "Employees",
};

// Typechecking props for BaseLayout
Employees.propTypes = {
  // pageName: PropTypes.string,
};

export default Employees;
