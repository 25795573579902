import { Box, Button, Card, Container, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { isEmpty } from "lodash";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { i9Schema } from "data/i9";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useAppContextController } from "context/AppContext";
import SignatureModal from "./SignatureModal";

const I9Form = ({ parent }) => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(i9Schema);
  const { company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const [signatureModalOpen, setSignatureModalOpen] = useState(false)
  const [citizenshipStatus1, setCitizenshipStatus1] = useState(applicant?.i9Form?.citizenshipStatus || '');
  const [preparerOrTranslator1, setPreparerOrTranslator1] = useState(applicant?.i9Form?.preparerOrTranslator || '');


  const { submit } = useForm({ resolver });
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    watch,
    reset,
    formState: { errors, isDirty, isValid, isSubmitSuccessful }
  } = useForm({ resolver });

  const onSubmit = async ({ alienRegistrationNumber, preparerOrTranslator, citizenshipStatus, signature, createdDate }) => {
    const parsedData = {
      i9Form: {
        alienRegistrationNumber,
        preparerOrTranslator,
        citizenshipStatus,
        signature,
        createdDate
      },
    };
    updateApplicantAction(applicant._id, parsedData);
    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );

  };

  const externalSubmit = async () => {
    const result = await handleSubmit(onSubmit)();
  };

  const handleCitizenChange = (e) => {
    setCitizenshipStatus1(e.target.value);
    setValue("citizenshipStatus", e.target.value, { shouldDirty: true });
  };
  const handlePreparerOrTranslatorChange = (e) => {
    setPreparerOrTranslator1(e.target.value);
    setValue("preparerOrTranslator", e.target.value, { shouldDirty: true });
  };

  const validateFormForSigning = async (e) => {
    e.preventDefault()
    const isValidForSigning = await trigger();
    if (isValidForSigning && parent !== "onboarding") {
      setSignatureModalOpen(true)
    }
    else if (isValidForSigning && parent === "onboarding") {
      await handleSubmit(onSubmit)()
    }
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);


  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (applicant?.i9Form) {
      reset(applicant?.i9Form, { keepErrors: true });
    }
  }, [applicant]);



  return (
    <>
      <form onSubmit={(e) => { validateFormForSigning(e) }
      } id="current-form">
        <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.firstName}
                variant="outlined"
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.middleInitial}
                variant="outlined"
                label="Middle Initial"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.lastName}
                variant="outlined"
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.maidenName}
                variant="outlined"
                label="Maiden Name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.birthDate ? new Date(`${applicant?.birthDate.split('T')?.[0]} 00:00:000`).toLocaleDateString() : ""}
                variant="outlined"
                label="Date of Birth"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <PatternFormat
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.socialSecurity}
                format="###-##-####"
                allowEmptyFormatting
                mask="_"
                label="Social Security"
                variant="outlined"
                onValueChange={({ value }) =>
                  setValue("socialSecurity", value, { shouldDirty: true })
                }
                customInput={CustomTextField}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <CustomTextField
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.email}
                variant="outlined"
                label="Email Address"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
              <PatternFormat
                disabled
                inputProps={{ tabIndex: -1 }}
                value={applicant?.phone}
                format="+1 (###) ### ####"
                allowEmptyFormatting
                mask="_"
                variant="outlined"
                label="Mobile Number"
                onValueChange={({ value }) => setValue("mobileNumber", value, { shouldDirty: true })}
                customInput={CustomTextField}
              />
            </Grid>
          </Grid>
          <MDTypography variant="h6">Citizenship Status:</MDTypography>

          <RadioGroup
            aria-labelledby={`citizenshipStatus_${applicant?.venueSlug}`}
            name="citizenshipStatus"
            value={citizenshipStatus1}
            onChange={handleCitizenChange}
          >
            <Grid container>
              <Grid item sm={6}>
                <Stack display="flex" direction="row" alignItems="center">
                  <FormControlLabel
                    value="US Citizen"
                    control={<Radio />}
                    sx={{ marginRight: "0" }}
                  />
                  <MDTypography fontWeight="bold" fontSize="0.875rem">
                    1. A citizen of the United States{" "}
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <Stack display="flex" direction="row" alignItems="center">
                  <FormControlLabel
                    value="Non-Citizen"
                    control={<Radio />}
                    sx={{ marginRight: "0" }}
                  />
                  <MDTypography fontWeight="bold" fontSize="0.875rem">
                    2. A non-citizen of the United States{" "}
                  </MDTypography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack display="flex" direction="row" alignItems="center">
                  <FormControlLabel
                    value="Permanent Resident"
                    control={<Radio />}
                    sx={{ marginRight: "0" }}
                  />
                  <MDTypography fontWeight="bold" fontSize="0.875rem">
                    3. A lawful permanent resident: (Alien Registration Number/USCIS Number){" "}
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="alienRegistrationNumber"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label="Alien Registration Number"
                      disabled={
                        !["Authorized Alien", "Permanent Resident"].includes(
                          watch("citizenshipStatus")
                        )
                      }
                      variant="outlined"
                      {...field}
                      onChange={(e) => {
                        setValue(`alienRegistrationNumber`, e.target.value, { shouldDirty: true });
                        trigger(`alienRegistrationNumber`)
                      }}
                    />

                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack display="flex" direction="row" alignItems="center">
                  <FormControlLabel
                    value="Authorized Alien"
                    control={<Radio />}
                    sx={{ marginRight: "0" }}
                  />
                  <MDTypography fontWeight="bold" fontSize="0.875rem">
                    4. An alien authorized to work until expiration date{" "}
                  </MDTypography>
                </Stack>
              </Grid>
            </Grid>
          </RadioGroup>

          <MDTypography variant="h6">
            Preparer and/or Translator Certification (check one):
          </MDTypography>
          <Grid container>
            <Grid item xs={12}>
              <RadioGroup
                aria-labelledby={`preparerOrTranslator_${applicant?.venueSlug}`}
                name="radio-buttons-group"
                value={preparerOrTranslator1}
                onChange={handlePreparerOrTranslatorChange}
              >
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel value="1" control={<Radio />} sx={{ marginRight: "0" }} />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        1. A preparer or translator was not used while filling this form{" "}
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel value="2" control={<Radio />} sx={{ marginRight: "0" }} />
                      <MDTypography fontWeight="bold" fontSize="0.875rem">
                        2. A preparer or translator was used while filling this form{" "}
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              {(applicant?._id && applicant?.i9Form?.signature) &&
                <>
                  <MDTypography fontWeight="bold" fontSize="0.875rem">
                    E-Signature
                  </MDTypography>

                  <Card style={{ width: "45%" }} >
                    <img
                      src={`${IMAGE_SERVER}/applicants/${applicant?._id}/signature/${applicant?.i9Form?.signature}?${Date.now()}`}
                      alt="signature"
                      style={{ width: "100%" }}
                    />
                  </Card>
                  {/* <Box mt={2} style={{ width: "45%" }}>

                    <Button
                      variant="contained"
                      onClick={() => {
                        setSignatureModalOpen(true)
                      }}
                      fullWidth

                    >
                      Change Signature
                    </Button>
                  </Box> */}
                </>

              }
            </Grid>
          </Grid>

        </FlexWrapper>
        <FormErrors errors={errors} />
      </form >
      <SignatureModal
        applicant={applicant}
        setSignatureModalOpen={setSignatureModalOpen}
        signatureModalOpen={signatureModalOpen}
        updateApplicantAction={updateApplicantAction}
        setValue={setValue}
        externalSubmit={externalSubmit}
      />
    </>
  );
};

export default I9Form;
