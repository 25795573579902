import { useEffect, useState, useCallback } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  Grid,
  IconButton,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import GenericModal from "components/GenericModal";
import { PARTNER_MEMBER_ENTITIES } from "utils/constants/entityOptions";
import useDropdowns from "hooks/useDropdowns";
import { getDirtyFields } from "utils/helpers/formHelpers";
import FormField from "layouts/applications/wizard/components/FormField";
import { PatternFormat } from "react-number-format";
import CancelIcon from "@mui/icons-material/Cancel";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import useSessionAuth from "hooks/useSessionAuth";
import { isEmpty } from "lodash";

const { MEMBER_STATUS_ENTITY, T_SHIRTS_SIZE_ENTITY } = PARTNER_MEMBER_ENTITIES;

const MembersModal = ({
  open,
  setOpen,
  currentApplicant,
  handleSubmit,
  Controller,
  control,
  setValue,
  clearErrors,
  setError,
  reset,
  errors,
  getValues,
  setDropdowns,
  dirtyFields,
  currentPartner,
}) => {
  const { currentLoggedUser } = useAppContextController();
  const isEdit = !!currentApplicant?._id;
  const { enqueueSnackbar } = useSnackbar();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const { logoutAuth0User } = useSessionAuth();

  const handleEmailDuplicate = async (value) => {
    try {
      const emailInfo = await fetchApplicants({ filters: { email: value }, fetchAll: true });
      if (!emailInfo || emailInfo?.data[0]?._id === currentApplicant?._id) {
        if (errors?.email?.message === "This email is already in use") clearErrors("email");
      } else {
        setError("email", { type: "custom", message: "This email is already in use" });
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const { dropdowns: memberStatusField } = useDropdowns({ entity: MEMBER_STATUS_ENTITY });
  const { dropdowns: tShirtSizes } = useDropdowns({ entity: T_SHIRTS_SIZE_ENTITY });
  const queryClient = useQueryClient();

  const defaultValues = {
    firstName: "",
    lastName: "",
    status: "Active",
    phone: "",
    email: "",
    tShirtSize: null,
  };

  const createApplicantMutation = useMutation(createApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      enqueueSnackbar("Member has been created!", { variant: "success" });
      setOpen(false);
      queryClient.invalidateQueries(["partnersapplicants"]);
      queryClient.invalidateQueries(["members"]);
    },
  });

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { data }) => {
      queryClient.invalidateQueries(["partnersapplicants"]);
      queryClient.invalidateQueries(["members"]);
      setOpen(false);
      if (data.status === "Deleted") {
        enqueueSnackbar("Member was archived", { variant: "success" });
      } else {
        enqueueSnackbar("Member has been updated!", { variant: "success" });
      }
    },
  });

  const createApplicantHandler = async (values) => {
    // handleEmailDuplicate(values?.email);
    if (!isEmpty(errors)) return;

    const data = { ...values };
    data.status = "Member";
    data.partners = [
      {
        status: values.status ?? "Active",
        partnerSlug: currentPartner.slug,
        agent: `${currentLoggedUser.lastName} ${currentLoggedUser.firstName}`,
        createAgent: currentLoggedUser._id,
        dateModified: new Date().toISOString(),
      },
    ];
    data.createAgent = currentLoggedUser._id;
    data.notes = [];

    await createApplicantMutation.mutateAsync(data);
  };

  const saveChanges = async (values) => {
    // handleEmailDuplicate(data?.email);
    if (!isEmpty(errors)) return;

    if (!currentApplicant?._id) {
      enqueueSnackbar("Something went wrong! No ID", { variant: "error" });
      return;
    }

    const dFields = getDirtyFields(values, dirtyFields)
    const data = { ...dFields };
    data.status = "Member";
    if (dFields.status) {
      const newPartners = (currentApplicant?.partners ?? []).map((par) => {
        if (par.partnerSlug === currentPartner?.slug)
          return {
            ...par,
            status: values.status,
          };
        return par;
      });
      data.partners = newPartners;
    }

    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant?._id,
      data,
    });
  };

  useEffect(() => {
    setDropdowns({
      memberStatusField,
      tShirtSizes,
    });
  }, [memberStatusField, tShirtSizes]);

  useEffect(() => {
    clearErrors();
    if (!isEdit) {
      reset(defaultValues);
    } else {
      let status = "Active";
      if (currentPartner && currentApplicant) {
        const memberPartner = (currentApplicant.partners ?? []).find(
          (par) => par.partnerSlug === currentPartner.slug
        );
        if (memberPartner) status = memberPartner.status;
      }
      reset({
        ...defaultValues,
        ...currentApplicant,
        status,
      });
    }
  }, [isEdit, currentApplicant]);

  const header = (
    <>
      <Box>
        <Typography variant="h5">Member Info</Typography>
      </Box>
      <CloseEditorButton
        onClick={() => {
          setOpen(false);
        }}
      >
        <CancelIcon color="secondary" />
      </CloseEditorButton>
    </>
  );

  const modalBody = (
    <>
      <form onSubmit={handleSubmit(isEdit ? saveChanges : createApplicantHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={memberStatusField || []}
                  defaultValue={null}
                  value={field.value || null}
                  autoSelect
                  disableClearable
                  onChange={(e, v) => {
                    setValue("status", v, { shouldDirty: true });
                  }}
                  sx={{ maxHeight: "44px" }}
                  renderInput={(params) => <FormField {...params} type="text" label="Status" />}
                />
              )}
            />
            {errors?.status && (
              <StyledTypographyError>{errors?.status.message}</StyledTypographyError>
            )}
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => <FormField label="Last Name" {...field} />}
            />
            {errors?.lastName && (
              <StyledTypographyError>{errors?.lastName.message}</StyledTypographyError>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => <FormField {...field} label="First Name" />}
            />
            {errors?.firstName && (
              <StyledTypographyError>{errors?.firstName.message}</StyledTypographyError>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PatternFormat
                  format="(###) ### ####"
                  allowEmptyFormatting
                  mask="_"
                  label="Mobile Phone"
                  {...field}
                  onValueChange={({ value }) => field.onChange(value)}
                  customInput={FormField}
                />
              )}
            />
            {errors?.phone && (
              <StyledTypographyError>{errors?.phone.message}</StyledTypographyError>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormField
                  type="text"
                  label="Email Address"
                  InputLabelProps={{ shrink: getValues("email") }}
                  {...field}
                  onBlur={(e) => {
                    field.onBlur(e.target.value);
                    // handleEmailDuplicate(e.target.value);
                  }}
                />
              )}
            />
            {errors?.email && (
              <StyledTypographyError>{errors?.email.message}</StyledTypographyError>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="tShirtSize"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={tShirtSizes || []}
                  value={field.value || null}
                  onChange={(e, v) => {
                    setValue("tShirtSize", v, { shouldDirty: true });
                  }}
                  sx={{ maxHeight: "44px" }}
                  renderInput={(params) => (
                    <FormField {...params} type="text" label="T-shirt Size" />
                  )}
                />
              )}
            />
            {errors?.tShirtSize && (
              <StyledTypographyError>{errors?.tShirtSize.message}</StyledTypographyError>
            )}
          </Grid>
        </Grid>
        <Box textAlign="end" mt={5}>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SaveIcon />}
            style={{ color: "white" }}
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "700px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  right: "0%",
  m: 1,
  p: 0,
}));
const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

export default MembersModal;
