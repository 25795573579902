import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAppContextController } from "context/AppContext";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";

// NewProduct page components
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import partners from "assets/partners.json";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import useDropdowns from "hooks/useDropdowns";
import TagList from "components/TagList";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { PatternFormat } from "react-number-format";
import { APPLICANT_EMPLOYEE_ENTITIES } from "utils/constants/entityOptions";
import { isAdmin } from "utils/helpers/roles";

// npm packages
import {
  Button,
  Stack,
  styled,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isEmpty, isEqual } from "lodash";
import { useSnackbar } from "notistack";

import {
  applicantsSchema,
  COMPANY_APPLICANT_DEFAULT_VALUES,
  VENUE_APPLICANT_DEFAULT_VALUES,
} from "data/applicantEmployeeInfo";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import Skills from "components/Skills";
import useSessionAuth from "hooks/useSessionAuth";
import checkHelmSyncAllowed from "api/helm/checkHelmSyncAllowed";
import moment from "moment";
import { getDirtyFields } from "utils/helpers/formHelpers";
import syncDataWithHelm from "api/helm/syncDataWithHelm";
import HelmSyncButton from "layouts/pages/applicants/components/ApplicantMainInfoPanel/components/HelmSyncButton";
import { Error, Warning } from "@mui/icons-material";
import VenueSelectionModal from "layouts/pages/applicants/components/ApplicantMainInfoPanel/components/VenueSelectionModal";
import { APPLICANT_VENUE_STATUS_ENUM, JOB_STATUS_ENUM } from "utils/constants/applicant";
import { useInvalidateCheckForHelmAllowed } from "hooks/useInvalidateCheckForHelmAllowed";
import JobSelectionModal from "layouts/pages/applicants/components/ApplicantMainInfoPanel/components/JobSelectionModal";
import updateApplicantJobStatus from "layouts/pages/applicants/actions/updateApplicantJobStatus";
import updateApplicantVenue from "layouts/pages/applicants/actions/updateApplicantVenue";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import { useRefreshApplicant } from "hooks/useRefreshApplicant";
import ApplicantStageChangeModal from "../ApplicantStageChangeModal";
import ApplicantLicenses from "../ApplicantLicenses";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const {
  APPLICANT_STATUS_ENTITY,
  EMPLOYEE_STATUS_ENTITY,
  APPLICANT_SOURCE_ENTITY,
  RANK_ENTITY,
  PRIMARY_POSITION_ENTITY,
  EMPLOYMENT_STATUS_ENTITY,
  APPLICANT_STAGE_ENTITY,
  LICENSE_STATUS_ENTITY,
  STATES_ENTITY,
  COMPANY_EMPLOYMENT_STATUS_ENTITY,
  PAY_FREQUENCY,
} = APPLICANT_EMPLOYEE_ENTITIES;

export const AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR = 10;
const ApplicantForm = ({
  parent = "Applicant",
  setFilters,
  filters,
  useOrTags,
  setUseOrTags,
  panelSelect = "",
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("wideDesktop"));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { currentApplicant, setCurrentApplicant, currentLoggedUser, userType, company } =
    useAppContextController();
  // states
  const [confirmModalApplicant, setConfirmModalApplicant] = useState(initialValueModal);
  const [currentLicense, setCurrentLicense] = useState("securityLicense");
  const [dropdowns, setDropdowns] = useState();
  const [applicantStageModalOpen, setApplicantStageModalOpen] = useState(false);
  const [isOpenVenueSelectionModal, setIsOpenVenueSelectionModal] = useState(false);
  const [isOpenJobSelectionModal, setIsOpenJobSelectionModal] = useState(false);
  const isEdit = !!currentApplicant?._id;
  const { dropdowns: payFrequencyValues } = useDropdowns({ entity: PAY_FREQUENCY });
  const isCompany = company?.companyType === "Company";
  const isVenue = company?.companyType === "Venue";
  const { invalidateCheckForHelmAllowed } = useInvalidateCheckForHelmAllowed();

  const resolver = useYupValidationResolver(
    applicantsSchema(
      isCompany,
      dropdowns,
      currentApplicant?._id,
      currentApplicant?.status ?? parent,
      isEdit,
      payFrequencyValues
    )
  );
  const partnerList = partners?.map((item) => item.partnerName);

  const { dropdowns: applicantStatusField } = useDropdowns({ entity: APPLICANT_STATUS_ENTITY });
  const { dropdowns: employeeStatusField } = useDropdowns({ entity: EMPLOYEE_STATUS_ENTITY });
  const { dropdowns: referralSources } = useDropdowns({ entity: APPLICANT_SOURCE_ENTITY });
  const { dropdowns: ranks } = useDropdowns({ entity: RANK_ENTITY });
  const { dropdowns: primaryPositions } = useDropdowns({ entity: PRIMARY_POSITION_ENTITY });
  const { dropdowns: employmentStatusesField } = useDropdowns({ entity: EMPLOYMENT_STATUS_ENTITY });
  const { dropdowns: applicantStagesField } = useDropdowns({ entity: APPLICANT_STAGE_ENTITY });
  const { dropdowns: licenseStatuses } = useDropdowns({ entity: LICENSE_STATUS_ENTITY });
  const { dropdowns: stateCodes } = useDropdowns({ entity: STATES_ENTITY });
  const { dropdowns: companyEmploymentStatus } = useDropdowns({
    entity: COMPANY_EMPLOYMENT_STATUS_ENTITY,
    companyType: "Company",
  });

  const defaultValues = {
    birthDate: null,
    hireDate: null,
    defaultValue: null,
    payFrequency: isCompany ? "W" : "D",
  };

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    reset,
    setValue,
    setError,
    control,
    watch,
    Control,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({
    mode: "onBlur",
    defaultValues: isEdit ? currentApplicant : defaultValues,
    resolver,
  });

  const [flagged, locked, isAvailable, isDnu] = watch([
    "recordFlagged",
    "recordLocked",
    "isAvailable",
    "isDnu",
  ]);

  const isEmployee = !currentApplicant
    ? parent === "Employee"
    : currentApplicant?.status === "Employee";
  const createApplicantMutation = useMutation(createApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      setCurrentApplicant({ ...currentApplicant, ...data, _id: _.insertedId });
      navigate(isEmployee ? `/employees/${_.insertedId}` : `/applicants/${_.insertedId}`);
      enqueueSnackbar("Applicant has been created!", { variant: "success" });
      await queryClient.invalidateQueries(isEmployee ? "employee_table" : "applicants");
    },
  });

  const syncWithHelm = useMutation(syncDataWithHelm, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries("checkForHelmAllowed");
      setCurrentApplicant({ ...currentApplicant, ...res?.data });
      enqueueSnackbar("Synced With Helm successfully", { variant: "success" });
    },
    onError: (err) => enqueueSnackbar(err?.message ?? `Something went wrong`, { variant: "error" }),
  });

  const {
    data: checkForHelmAllowed,
    isLoading: isCheckForHelmAllowedLoading,
    isSuccess: isCheckForHelmAllowedSuccess,
  } = useQuery(
    ["checkForHelmAllowed", currentApplicant?._id],
    () => checkHelmSyncAllowed(currentApplicant?._id),
    { enabled: !!currentApplicant?._id }
  );

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentApplicant({ ...currentApplicant, ...data });
      reset({ ...currentApplicant, ...data });
      if (isEqual(data.status, "Deleted")) navigate(isEmployee ? "/employees" : "/applicants");
      enqueueSnackbar(
        `Applicant has been ${isEqual(data.status, "Deleted") ? "archived" : "updated"}!`,
        { variant: "success" }
      );
      if (!isEqual(data.status, "Deleted")) {
        await invalidateCheckForHelmAllowed(
          currentApplicant?.status === "Employee",
          currentApplicant?._id
        );
      }
      await queryClient.invalidateQueries(isEmployee ? "employee_table" : "applicants");
      if (dirtyFields?.status || dirtyFields?.applicantStatus) {
        setApplicantStageModalOpen(true);
      }
    },
  });
  const closeModalHandler = () => setConfirmModalApplicant(initialValueModal);

  const createApplicantHandler = async (values) => {
    const data = values;
    data.jobs = [];
    data.events = [];
    data.venues = [];
    data.createAgent = currentLoggedUser._id;

    await createApplicantMutation.mutateAsync(data);
  };

  const deleteApplicantHandler = async () => {
    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant?._id,
      data: { status: "Deleted", modifiedDate: new Date(), deleteAgent: currentLoggedUser._id },
    });
    setFilters({});
    navigate(isEmployee ? "/employees" : "/applicants");
  };

  const { logoutAuth0User } = useSessionAuth();

  const resetSelectedLicense = () => {
    if (!currentApplicant) return;
    const { securityLicense, gamingLicense, tabcLicense } = currentApplicant;

    if (securityLicense) setCurrentLicense("securityLicense");
    else if (gamingLicense) setCurrentLicense("gamingLicense");
    else if (tabcLicense) setCurrentLicense("tabcLicense");
    else setCurrentLicense("securityLicense");
  };

  const handleEmailDuplicate = async (value) => {
    try {
      const emailInfo = await fetchApplicants({ filters: { email: value }, fetchAll: true });
      if (
        !emailInfo ||
        getValues("status") === "Partner" ||
        emailInfo?.data[0]?._id === currentApplicant?._id
      ) {
        if (errors?.email?.message === "This email is already in use") clearErrors("email");
      } else {
        setError("email", { type: "custom", message: "This email is already in use" });
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const saveChanges = async (values, _, sendOnboardingMessage) => {
    if (!currentApplicant?._id) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      return;
    }

    handleEmailDuplicate(values?.email);
    if (!isEmpty(errors)) return;

    const updatedValues = values;
    delete updatedValues?._id;

    // we shouldn't be sending ALL fields and certainly not this one in the update
    delete updatedValues?.createdDate;

    // if the user has changed birthDate, then strip off the time before saving it b/c it causes problems
    if (updatedValues?.birthDate)
      updatedValues.birthDate = moment(updatedValues.birthDate).format("YYYY-MM-DD");

    if (!isCompany) {
      switch (currentLicense) {
        case "securityLicense":
          updatedValues.gamingLicense = "";
          updatedValues.gamingLicenseExpDate = "";
          updatedValues.tabcLicense = "";
          updatedValues.tabcLicenseExpDate = "";
          break;
        case "gamingLicense":
          updatedValues.securityLicense = "";
          updatedValues.securityLicenseExpDate = "";
          updatedValues.tabcLicense = "";
          updatedValues.tabcLicenseExpDate = "";
          break;
        case "tabcLicense":
          updatedValues.securityLicense = "";
          updatedValues.securityLicenseExpDate = "";
          updatedValues.gamingLicense = "";
          updatedValues.gamingLicenseExpDate = "";
          break;
        default:
      }
    }

    const modifiedFields = getDirtyFields(updatedValues, dirtyFields);

    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant?._id,
      data: modifiedFields,
      sendOnboardingMessage,
    });
  };

  const getApplicantValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentApplicant?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    if (!currentApplicant) return;
    reset(getApplicantValues());
    resetSelectedLicense();
    enqueueSnackbar("Changes has been abandoned", { variant: "success" });
  };

  const onCancel = () => {
    setConfirmModalApplicant({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
      description: "Please Confirm to abandon Changes",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          cancelChanges();
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };

  const cancelCreateHandler = () => {
    setConfirmModalApplicant({
      isOpen: true,
      title: "You are leaving create mode",
      bodyText: "Please Confirm to abandon new Applicant.",
      response: false,
      description: "Please Confirm to abandon new Applicant.",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          setConfirmModalApplicant(initialValueModal);
          navigate(isEmployee ? "/employees" : `/applicants`);
        },
        isShown: true,
      },
    });
  };

  const onDelete = () => {
    setConfirmModalApplicant({
      isOpen: true,
      title: `Delete ${currentApplicant?.status ?? parent}`,
      bodyText: `Please confirm you want to DELETE this ${currentApplicant?.status ?? parent}!`,
      response: false,
      description: `Please confirm you want to DELETE this ${currentApplicant?.status ?? parent}!`,
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          deleteApplicantHandler();
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };

  const canSendOnboardingMessage =
    // Sterling should only send onboarding messages when changing a job applicant status
    isVenue &&
    // Only send onboarding message if the onboarding hasn't been done yet
    !currentApplicant?.acknowledged &&
    // Only send onboarding message when changing status to Screened
    watch("applicantStatus") === "Screened" &&
    dirtyFields?.applicantStatus;

  const onSubmitFunc = (...params) => {
    if (!canSendOnboardingMessage) {
      if (isEdit) return saveChanges(...params);
      return createApplicantHandler(...params);
    }

    return setConfirmModalApplicant({
      isOpen: true,
      title: "Applicant stage changed",
      bodyText:
        "The applicant stage has been changed to Screened. Do you want to send an onboarding invitation to the applicant?",
      response: false,
      description:
        "The applicant stage has been changed to Screened. Do you want to send an onboarding invitation to the applicant?",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      negativeAltBtn: {
        label: "Don't Send",
        fn: async () => {
          if (isEdit) saveChanges(...params);
          else createApplicantHandler(...params);
          closeModalHandler();
        },
        isShown: true,
      },
      positiveBtn: {
        label: "Send",
        fn: async () => {
          if (isEdit) saveChanges(...params, true);
          else createApplicantHandler(...params);
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };
  const displayFlagged = () => {
    if (isCompany) return flagged === "Yes" ? "Flagged" : "Unflagged";
    return locked === "Yes" ? "Locked" : "UnLocked";
  };

  const handleLicenseChange = (licenses) => {
    setValue("licenses", licenses, { shouldDirty: true });
  };

  useEffect(() => {
    clearErrors();
    if (!isEdit)
      reset(
        isCompany
          ? COMPANY_APPLICANT_DEFAULT_VALUES(!isEmployee)
          : VENUE_APPLICANT_DEFAULT_VALUES(!isEmployee)
      );
    else {
      reset(currentApplicant);
      resetSelectedLicense();
    }
  }, [watch, isEdit, currentApplicant, isCompany, isEmployee]);
  useEffect(() => {
    setDropdowns({
      applicantStatusField,
      employeeStatusField,
      referralSources,
      ranks,
      primaryPositions,
      employmentStatusesField,
      applicantStagesField,
      licenseStatuses,
      stateCodes,
      companyEmploymentStatus,
    });
  }, [
    applicantStatusField,
    employeeStatusField,
    referralSources,
    ranks,
    primaryPositions,
    employmentStatusesField,
    licenseStatuses,
    stateCodes,
    companyEmploymentStatus,
    applicantStagesField,
  ]);

  const { refreshApplicant } = useRefreshApplicant();

  const changeJobStatusMutation = useMutation(updateApplicantJobStatus, {
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Job status updated successfully", { variant: "success" });
      await refreshApplicant();
    },
    onError: (error) => {
      enqueueSnackbar("Error in updating Job status. You have to change it manually", {
        variant: "error",
      });
    },
    mutationKey: "jobStatusMutation",
  });

  const changeVenueStatusMutation = useMutation(updateApplicantVenue, {
    onSuccess: async (_, { data }) => {
      if (currentApplicant._id) {
        setCurrentApplicant({ ...currentApplicant, venues: _?.venues?.length ? _.venues : [] });
      }
      enqueueSnackbar("Venue status updated successfully", { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar("Error in updating Venue status. You have to change it manually", {
        variant: "error",
      });
    },
    mutationKey: "venueStatusMutation",
  });

  const manualSyncHelm = async (slug, isManual, jobSlug) => {
    await syncWithHelm.mutateAsync(
      { id: currentApplicant?._id, slug, isManual },
      {
        onSuccess: async (_, data) => {
          if (isCompany) {
            const jobStatus = getApplicantJobStatus(currentApplicant, jobSlug);
            if (jobStatus === JOB_STATUS_ENUM.PENDING)
              await changeJobStatusMutation.mutateAsync({
                applicantId: currentApplicant._id,
                data: {
                  status: JOB_STATUS_ENUM.ON_ASSIGNMENT,
                  jobSlug,
                },
              });
          }
          if (isVenue) {
            const newVenue = {
              status: APPLICANT_VENUE_STATUS_ENUM.STAFFING_POOL,
              venueSlug: slug,
              agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
              dateModified: new Date().toISOString(),
            };
            await changeVenueStatusMutation.mutateAsync({
              applicantId: currentApplicant._id,
              data: {
                venue: newVenue,
                createAgent: currentLoggedUser?._id,
                userId: currentLoggedUser?._id,
                agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
              },
            });
          }
          await updateApplicantMutation.mutateAsync(
            {
              applicantId: currentApplicant?._id,
              data: { status: "Employee" },
            },
            {
              onSuccess: async () => {
                setApplicantStageModalOpen(true);
                await refreshApplicant();
              },
            }
          );
        },
      }
    );
  };
  const manualHelmSyncForApplicants = () => {
    if (isVenue) {
      const pendingVenues = currentApplicant?.venues?.filter(
        (venue) =>
          venue.status === APPLICANT_VENUE_STATUS_ENUM.PENDING ||
          venue.status === APPLICANT_VENUE_STATUS_ENUM.STAFFING_POOL
      );
      if (pendingVenues?.length > 0) {
        setIsOpenVenueSelectionModal(true);
        return;
      }
      enqueueSnackbar("There must be one pending or staffing pool venue against applicant", {
        variant: "error",
      });
      return;
    }
    // for company/sterling
    const pendingJobs = currentApplicant?.jobs?.filter(
      (job) =>
        job.status === JOB_STATUS_ENUM.PENDING || job.status === JOB_STATUS_ENUM.ON_ASSIGNMENT
    );
    if (pendingJobs?.length > 0) {
      setIsOpenJobSelectionModal(true);
      return;
    }
    enqueueSnackbar("There must be one pending job or on assignment against applicant", {
      variant: "error",
    });
  };

  const syncHelmHandler = async () => {
    // helm will be inactive only when helmActive is false if key does not exist it will be active
    if (checkForHelmAllowed?.data?.helmActive === false) {
      enqueueSnackbar(`Helm sync is not available at the moment.`, { variant: "error" });
      return;
    }
    if (!checkForHelmAllowed?.data?.isValidManual) {
      enqueueSnackbar(
        `Helm sync is not allowed. Following are missing Requirements. \n${checkForHelmAllowed?.data?.missingRequirementsManual?.join(
          ", "
        )}`,
        { variant: "error" }
      );
      return;
    }
    manualHelmSyncForApplicants();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitFunc)}>
        <Stack
          direction={isSmallScreen ? "column" : "row"}
          alignItems="center"
          p="1.5rem 0.5rem 0.5rem 2.5rem"
        >
          <Stack direction="row" container spacing={1} alignItems="center">
            <Stack direction="row" container alignItems="center">
              {currentApplicant && isEmployee && !currentApplicant?.employeeID ? (
                <Tooltip title="Missing Employee ID">
                  <Warning fontSize="medium" color="error" />
                </Tooltip>
              ) : (
                <>
                  {isEmployee &&
                    checkForHelmAllowed?.data?.isOnHelm &&
                    !checkForHelmAllowed?.data?.isValid && (
                      <Tooltip
                        title={`Helm already synced but Following are missing Requirements. \n${checkForHelmAllowed?.data?.missingRequirementsManual?.join(
                          ", "
                        )}`}
                      >
                        <Error fontSize="medium" color="error" />
                      </Tooltip>
                    )}
                </>
              )}
              <MDTypography color="blue" fontWeight="bold" variant="body2">{`${currentApplicant?.status ?? parent
                } Information`}</MDTypography>
            </Stack>
            <Stack>
              <HelmSyncButton
                helmData={
                  isEmployee
                    ? checkForHelmAllowed?.data
                    : {
                      isValid: checkForHelmAllowed?.data?.isValidManual,
                      message: checkForHelmAllowed?.data?.messageManual,
                      missingRequirements: checkForHelmAllowed?.data?.missingRequirementsManual,
                      isOnHelm: checkForHelmAllowed?.data?.isOnHelm,
                      helmActive: checkForHelmAllowed?.data?.helmActive,
                    }
                }
                employeeID={currentApplicant?.employeeID}
                isLoading={
                  syncWithHelm.isLoading || !checkForHelmAllowed || isCheckForHelmAllowedLoading
                }
                syncHelmHandler={syncHelmHandler}
              />
            </Stack>

            <Stack>
              <Stack container direction="row" alignItems="center">
                <Stack>
                  <Controller
                    name={isCompany ? "recordFlagged" : "recordLocked"}
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value === "Yes"}
                        onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        inputProps={{ "aria-label": "" }}
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <MDTypography variant="body2">{displayFlagged()}</MDTypography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              {isCompany && (
                <Stack container direction="row" alignItems="center">
                  <Stack>
                    <Controller
                      name="isAvailable"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          inputProps={{ "aria-label": "" }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <MDTypography variant="body2">
                      {isAvailable === "Yes" ? "Available" : "Unavailable"}
                    </MDTypography>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack>
              <Stack container direction="row" alignItems="center">
                <Stack>
                  <Controller
                    name="isDnu"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value === "Yes"}
                        onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        inputProps={{ "aria-label": "" }}
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <MDTypography variant="body2">Do Not Use</MDTypography>
                </Stack>
              </Stack>
            </Stack>
            <Stack flexGrow={1} />
            {!isEmpty(errors) && (
              <Stack>
                <Typography color="error" fontSize="0.8rem">
                  Please fix errors to proceed
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" container spacing={1} alignItems="center">
            {isEdit && isEmpty(dirtyFields) && isAdmin(userType) ? (
              <Button
                variant="text"
                startIcon={<DeleteIcon />}
                onClick={onDelete}
                style={{ color: "red" }}
                disabled={isSubmitting}
                sx={{ padding: "0.5rem 1rem" }}
              >
                {`Delete ${currentApplicant?.status ?? parent}`}
              </Button>
            ) : (
              <MDBox display="flex" alignItems="center">
                {(!isEdit || (isEdit && isDirty)) && (
                  <Button
                    variant="text"
                    startIcon={<CancelIcon />}
                    onClick={isEdit ? onCancel : cancelCreateHandler}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Cancel
                  </Button>
                )}
                {!isEdit && (
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Create
                  </Button>
                )}
                {isEdit && isDirty && isAdmin(userType) && (
                  <Button
                    type="submit"
                    variant="text"
                    startIcon={<SaveIcon />}
                    style={{ color: "#4CAF50" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    Save
                  </Button>
                )}
              </MDBox>
            )}
          </Stack>
        </Stack>

        <StyledContainer px={5}>
          <Grid container spacing={3}>
            <Grid item sm={panelSelect === "" ? 6 : 12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="First Name"
                        type="text"
                        InputLabelProps={{ shrink: getValues("firstName") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.firstName && (
                    <StyledTypographyError> {errors?.firstName.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Last Name"
                        InputLabelProps={{ shrink: getValues("lastName") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.lastName && (
                    <StyledTypographyError>{errors?.lastName.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item sm={6}>
                  <Controller
                    name="employmentStatus"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={
                          (isCompany ? companyEmploymentStatus : employmentStatusesField) || []
                        }
                        disabled={isCompany}
                        autoSelect
                        freeSolo
                        name="employmentStatus"
                        value={field.value}
                        onChange={(e, v) => field.onChange(v)}
                        onBlur={() => trigger("employmentStatus")}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            type="text"
                            label="Employment Status"
                            InputLabelProps={{ shrink: getValues("employmentStatus") }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.employmentStatus && (
                    <StyledTypographyError>
                      {errors?.employmentStatus.message}
                    </StyledTypographyError>
                  )}
                </Grid>
                {!isCompany ? (
                  <Grid item sm={6}>
                    <Controller
                      name="rank"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          disabled={getValues("status") !== "Employee"}
                          options={ranks || []}
                          name="rank"
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(`${e.target.innerText}`);
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Rank"
                              InputLabelProps={{ shrink: getValues().rank }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={6}>
                    <MDBox>
                      <Controller
                        name="socialSecurity"
                        control={control}
                        render={({ field }) => (
                          <PatternFormat
                            inputProps={{ tabIndex: -1 }}
                            format="###-##-####"
                            allowEmptyFormatting
                            mask="_"
                            label="Social Security No."
                            value={field.value || ""}
                            onValueChange={({ value }) =>
                              setValue("socialSecurity", value, { shouldDirty: true })
                            }
                            customInput={FormField}
                          />
                        )}
                      />
                    </MDBox>
                    {errors?.socialSecurity && (
                      <StyledTypographyError>
                        {errors?.socialSecurity?.message}
                      </StyledTypographyError>
                    )}
                  </Grid>
                )}
                <Grid item sm={6}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <StyledAutoCompleteStatus
                        options={(isEmployee ? employeeStatusField : applicantStatusField) || []}
                        autoSelect
                        freeSolo
                        name="status"
                        value={field.value || null}
                        color="info"
                        disabled={!checkForHelmAllowed?.data?.helmActive === false && !isEmployee}
                        onChange={(e, v) => {
                          if (v === "Partner") setValue("partnerSlug", "");
                          else setValue("referralSource", currentApplicant?.referralSource);
                          return field.onChange(v);
                        }}
                        onBlur={() => trigger(["rank", "primaryPosition", "status", "employeeID"])}
                        renderInput={(params) => (
                          <FormField
                            label="Type"
                            {...params}
                            type="text"
                            InputLabelProps={{ shrink: getValues("status") }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.status && (
                    <StyledTypographyError>{errors?.status.message}</StyledTypographyError>
                  )}
                </Grid>
                {!isEmployee && (
                  <Grid item sm={6}>
                    <Controller
                      name="applicantStatus"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={applicantStagesField || []}
                          autoSelect
                          freeSolo
                          disabled={isCompany}
                          name="applicantStatus"
                          value={field.value}
                          onChange={(e, v) => field.onChange(v)}
                          onBlur={() => trigger("applicantStatus")}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Applicant Status"
                              InputLabelProps={{ shrink: getValues("applicantStatus") }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.applicantStatus && (
                      <StyledTypographyError>
                        {errors?.applicantStatus.message}
                      </StyledTypographyError>
                    )}
                  </Grid>
                )}
                {getValues("status") !== "Partner" ? (
                  <Grid item sm={6}>
                    <Controller
                      name="referralSource"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={referralSources || []}
                          freeSolo
                          autoSelect
                          name="referralSource"
                          value={field.value}
                          onChange={(e, v) => field.onChange(v)}
                          onBlur={() => trigger("referralSource")}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Applicant Source"
                              InputLabelProps={{ shrink: getValues().referralSource }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.referralSource && (
                      <StyledTypographyError>
                        {errors?.referralSource.message}
                      </StyledTypographyError>
                    )}
                  </Grid>
                ) : (
                  <Grid item sm={6}>
                    <Controller
                      name="partnerSlug"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={partnerList || []}
                          freeSolo
                          autoSelect
                          name="partnerSlug"
                          value={field.value}
                          onChange={(e, v) => {
                            const part = partners?.find((item) => item.partnerName === v);
                            setValue("employeeID", part?.employeeId);
                            field.onChange(v);
                          }}
                          onBlur={() => trigger("partnerSlug")}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Partner"
                              InputLabelProps={{ shrink: !!field.value }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.partnerSlug && (
                      <StyledTypographyError>{errors?.partnerSlug.message}</StyledTypographyError>
                    )}
                  </Grid>
                )}
                {/* <Grid item sm={12}>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="User"
                        type="text"
                        InputLabelProps={{ shrink: getValues("userId") }}
                        {...field}
                      />
                    )}
                  />
                </Grid> */}
                <Grid item sm={6}>
                  <Controller
                    name="employeeID"
                    control={control}
                    // disabled={getValues("status") === "Partner"}
                    disabled={!checkForHelmAllowed?.data?.helmActive === false}
                    render={({ field }) => (
                      <FormField
                        label="Employee ID"
                        type="text"
                        value={field.value || ""}
                        onChange={(e, v) => field.onChange(v)}
                        InputLabelProps={{ shrink: !!field.value }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.employeeID && (
                    <StyledTypographyError>{errors?.employeeID.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item sm={6}>
                  <Controller
                    name="referringPerson"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="Referred By"
                        type="text"
                        value={field.value || ""}
                        onChange={(e, v) => field.onChange(v)}
                        {...field}
                      />
                    )}
                  />
                  {errors?.referringPerson && (
                    <StyledTypographyError>{errors?.referringPerson.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item sm={12}>
                  <Controller
                    name="address1"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Address"
                        InputLabelProps={{ shrink: getValues("address1") }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="City"
                        InputLabelProps={{ shrink: getValues("city") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.city && (
                    <StyledTypographyError>{errors?.city.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={stateCodes || []}
                        autoSelect
                        freeSolo
                        value={field.value}
                        onChange={(e, v) => {
                          field.onChange(v?.toUpperCase());
                        }}
                        onBlur={() => trigger("state")}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            type="text"
                            label="State"
                            InputLabelProps={{ shrink: getValues("state") }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.state && (
                    <StyledTypographyError>{errors?.state.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="zip"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="Zip Code"
                        type="text"
                        InputLabelProps={{ shrink: getValues("zip") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.zip && (
                    <StyledTypographyError>{errors?.zip.message}</StyledTypographyError>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={panelSelect === "" ? 6 : 12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Birthdate"
                          value={!field.value ? null : field.value}
                          onChange={(date) => {
                            field.onChange(new Date(date));
                          }}
                          renderInput={(params) => <FormField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>

                {isCompany && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="hireDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Hire Date"
                            value={!field.value ? null : field.value}
                            onChange={(date) => {
                              field.onChange(new Date(date));
                            }}
                            renderInput={(params) => <FormField {...params} />}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                )}

                {!isCompany && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="primaryPosition"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          disabled={getValues("status") !== "Employee"}
                          options={primaryPositions || []}
                          name="primaryPosition"
                          autoSelect
                          freeSolo
                          value={field.value}
                          onChange={(e, v) => field.onChange(v)}
                          onBlur={() => trigger("primaryPosition")}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Primary Position"
                              InputLabelProps={{ shrink: getValues("primaryPosition") }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.primaryPosition && (
                      <StyledTypographyError>
                        {errors?.primaryPosition.message}
                      </StyledTypographyError>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="email"
                    disabled={parent === "Employee" && watch("employmentStatus") !== "Inactive"}
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Email Address"
                        InputLabelProps={{ shrink: getValues("email") }}
                        {...field}
                        onBlur={(e) => {
                          field.onBlur(e.target.value);
                          handleEmailDuplicate(e.target.value);
                        }}
                      />
                    )}
                  />
                  {errors?.email && (
                    <StyledTypographyError>{errors?.email.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="altEmail"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Alternate Email Address"
                        InputLabelProps={{ shrink: getValues("altEmail") }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.altEmail && (
                    <StyledTypographyError>{errors?.altEmail.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        label="Mobile Phone"
                        {...field}
                        onValueChange={({ value }) => field.onChange(value)}
                        customInput={FormField}
                      />
                    )}
                  />
                  {errors?.phone && (
                    <StyledTypographyError>{errors?.phone.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="altPhone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        label="Alternate Phone"
                        {...field}
                        onValueChange={({ value }) => field.onChange(value)}
                        customInput={FormField}
                      />
                    )}
                  />
                  {errors?.altPhone && (
                    <StyledTypographyError>{errors?.altPhone.message}</StyledTypographyError>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="payFrequency"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        options={payFrequencyValues || []}
                        name="payFrequency"
                        autoSelect
                        getOptionLabel={(option) => option.label || option}
                        value={
                          field.value
                            ? payFrequencyValues?.find((option) => option.value === field.value)
                            : null
                        }
                        onChange={(e, v) => {
                          setValue("payFrequency", v.value, {
                            shouldDirty: true,
                          });
                        }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            type="text"
                            label="Pay Frequency"
                            showError
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.payFrequency && (
                    <StyledTypographyError>{errors?.payFrequency.message}</StyledTypographyError>
                  )}
                </Grid>

                {!isCompany && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="hireDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Hire Date"
                            value={!field.value ? null : field.value}
                            onChange={(date) => {
                              field.onChange(new Date(date));
                            }}
                            renderInput={(params) => <FormField {...params} />}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                )}

                <Grid item width="100%" mt={-1.5}>
                  {/* {!isCompany && ( */}
                  <ApplicantLicenses
                    licenses={watch("licenses") || []}
                    watch={watch}
                    setValue={setValue}
                    onLicenseChange={handleLicenseChange}
                  />
                  {/* // )} */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <MDBox mt={3}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TagList
                  {...field}
                  value={getValues().tags || []}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  currentComponent={currentApplicant}
                  label="Skills from Master List"
                />
              )}
            />
            {errors?.invalidTag && (
              <StyledTypographyError>{errors?.invalidTag?.message}</StyledTypographyError>
            )}
          </MDBox>
          <MDBox mt={3}>
            <Controller
              name="otherSkills"
              control={control}
              render={({ field }) => (
                <Skills
                  {...field}
                  value={getValues().otherSkills || []}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  currentComponent={currentApplicant}
                />
              )}
            />
            {errors?.invalidSkill && (
              <StyledTypographyError>{errors?.invalidSkill?.message}</StyledTypographyError>
            )}
          </MDBox>
        </StyledContainer>
      </form>
      {company?.companyType === "Venue" ? (
        <ApplicantStageChangeModal
          open={applicantStageModalOpen}
          setOpen={setApplicantStageModalOpen}
          applicantId={currentApplicant?._id}
        />
      ) : null}
      <ConfirmModal
        title={confirmModalApplicant.title}
        description={confirmModalApplicant.description}
        positiveBtn={confirmModalApplicant.positiveBtn}
        negativeBtn={confirmModalApplicant.negativeBtn}
        negativeAltBtn={confirmModalApplicant.negativeAltBtn}
        isOpen={confirmModalApplicant.isOpen}
        closeFn={closeModalHandler}
      />
      {isOpenVenueSelectionModal && (
        <VenueSelectionModal
          alreadyOnHelm={checkForHelmAllowed?.data?.isOnHelm}
          open={isOpenVenueSelectionModal}
          setOpen={setIsOpenVenueSelectionModal}
          onSubmitAction={manualSyncHelm}
        />
      )}
      {isOpenJobSelectionModal && (
        <JobSelectionModal
          alreadyOnHelm={checkForHelmAllowed?.data?.isOnHelm}
          open={isOpenJobSelectionModal}
          setOpen={setIsOpenJobSelectionModal}
          onSubmitAction={manualSyncHelm}
        />
      )}
    </>
  );
};
export default ApplicantForm;

const StyledAutoCompleteStatus = styled(Autocomplete)(({ theme }) => ({
  fontWeight: 700,
  color: "#1A73E8",
  width: "100%",
  "& input": {
    fontWeight: 700,
    color: "#1A73E8",
    width: "100%",
  },
}));

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

const StyledContainer = styled(MDBox)(() => ({
  height: 670,
  overflowY: "auto",
}));
