import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, IconButton, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import PictureAvatar from "components/PictureAvatar";
import fetchCustomers from "api/companies/fetchCustomers";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import TagListSearch from "components/TagListSearch";
import TagListToggle from "components/TagListToggle";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import { useAppContextController } from "context/AppContext";
import useTableFunctions from "hooks/useTableFunctions";
import CustomerTableActions from "layouts/pages/customers/components/CustomerTableActions";
import useCompanyStore from "stores/useCompanyStore";
import { isEqual } from "lodash";
import { splitName } from "utils/helpers/splitName";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomersTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = false,
  refreshCompanies,
  showSearchBar = false,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  useOrTags,
  setUseOrTags,
}) => {
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const { company } = useAppContextController();

  const isCompany = company?.companyType === "Company";

  const navigate = useNavigate();
  const classes = useStyle();

  const { action } = useParams();
  const { page, setPage, limit, setLimit, order, orderBy, toggleSort, pageLimitConfirmationModal } =
    useTableFunctions({
      defaultLimit: 5,
    });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentCompany });
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    filters: { ...filters, primaryCompany: false },
    useOrTags,
  };
  const [toastAlert, setToastAlert] = useState(false);
  const {
    data: companies,
    isLoading,
    refetch,
  } = useQuery(["customers_data", allOptions], () => fetchCustomers(allOptions), {
    refetchInterval: 300000,
  });
  const [searchTagList, setSearchTagList] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [tagsToggle, setTagsToggle] = useState("or");

  // useEffect(() => {
  //   if(!companies?.count) return
  //   setCurrentCompany(companies.data[0])
  // }, [companies])

  useEffect(() => {
    if ((action && filters) || filters) setPage(1);
  }, [action, filters]);

  useEffect(() => {
    refetch();
  }, [refreshCompanies]);

  const columns = [
    {
      title: "Logo",
      field: "name",
      customCell: (name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    {
      title: "Company ID",
      field: "slug",
      customCell: (slug) => <div>{slug?.toUpperCase()}</div>,
    },
    { title: "Company Name", field: "name" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    {
      title: "Phone",
      field: "phone",
      customCell: (phone, field, row) => <div>{phone || row?.contact?.phone}</div>,
    },
    { title: "Client ID", field: "clientId" },
    {
      title: "Acct Mgr",
      field: "accountManager",
      customCell: (accountManager, field, row) => {
        if (!accountManager) return null;
        const { first, last } = splitName(accountManager);

        return (
          <PictureAvatar
            image={null}
            firstName={first}
            lastName={last}
            // userId={usr?._id?.toString()}
            size="md"
          />
        );
      },
    },

    {
      title: "Last Modified In",
      field: "modifiedDate",
      customCell: (date) =>
        date ? <span>{moment(date).format("ddd YYYY-MM-DD hh:mm A")}</span> : "",
    },
    {
      title: "Contact Email",
      field: "contactEmail",
      customCell: (email) => <div style={{ paddingLeft: "2rem" }}>{email}</div>,
    },
    {
      title: "Company Actions",
      field: "_id",
      customCell: (slug, field, row, yotro) => {
        return (
          <CustomerTableActions
            slug={row.slug}
            row={row}
            setCurrentCompany={setCurrentCompany}
            setModalInfo={setModalInfo}
            setNavigateUrl={navigate}
            setGenericModalOpen={setGenericModalOpen}
          />
        );
      },
    },
  ];
  const columnsForSearch = [
    {
      title: "Logo",
      field: "name",
      width: 40,
      customCell: (name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    { title: "Company Slug", field: "slug", width: 100 },
    { title: "Customer Name", field: "name", width: 150 },
    { title: "Status", field: "status", width: 75 },
    { title: "Client ID", field: "clientId" },
    {
      title: "City/State",
      field: "city",
      width: 100,
      customCell: (city, row, field) =>
        city ? (
          <span>
            {city} {row?.state}
          </span>
        ) : (
          ""
        ),
    },
  ];

  const handleRowClick = (comp, e) => {
    e.stopPropagation();
    setCurrentCompany({ ...comp });
    navigate(`/customers/${comp.slug}`);
  };

  const addNew = (e) => {
    setCurrentCompany({});
    navigate("/customers/create");
    e.stopPropagation();
  };

  const handleClearTags = () => {
    const tempFilters = filters;
    delete tempFilters?.tags;
    setFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(false);
  };

  const handleAllTags = () => setUseOrTags(false);
  const handleAnyTags = () => setUseOrTags(true);

  const { slug } = useParams();

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <AccountCircleIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {company?.name} Customers
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center" marginLeft={2}>
          {searchTagList?.length > 0 && (
            <TagListToggle
              tagsToggle={tagsToggle}
              setTagsToggle={setTagsToggle}
              onAny={handleAnyTags}
              onAll={handleAllTags}
              onClear={handleClearTags}
            />
          )}
          {!isSmallScreen && (
            <TagListSearch
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          {showSearchBar && (
            <Searchbar
              fetch={fetchCustomers}
              fetchAll
              placeholder="Search Customers"
              columns={columnsForSearch}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name", "city", "slug"]}
              filterBy="slug"
              onRowClick={(comp, e) => {
                setCurrentCompany(comp);
                navigate(`/customers/${comp?.slug}/action/customerinfo`);
                e.stopPropagation();
              }}
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox ml={3} flexWrap="wrap">
        {showFiltersList && (
          <Grid item xs={6}>
            <FiltersList
              filters={filters}
              setFilters={setFilters}
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              clearTagsHadler={handleClearTags}
            />
          </Grid>
        )}
      </MDBox>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={companies}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          defaultSort="name"
        />
      </MDBox>
      {/* <CompanyActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
CustomersTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Companies",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
CustomersTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default CustomersTable;
