import { useState } from "react";
import { Stack, Button } from "@mui/material";
import { Cancel, Download, Save, Upload } from "@mui/icons-material";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import MDTypography from "components/MDTypography";

const MembersPanelActions = ({ onSave }) => {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleCloseModal = () => setConfirmModalOpen(false);
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          variant="text"
          type="button"
          onClick={() => setConfirmModalOpen(true)}
          startIcon={<Upload />}
          color="primary"
          sx={{ color: "primary", fontSize: 14, padding: "0" }}
        >
          Import
        </Button>
        <MDTypography sx={{ color: "lightgray" }}>|</MDTypography>
        <Button
          variant="text"
          startIcon={<Download />}
          type="button"
          onClick={onSave}
          color="primary"
          sx={{ color: "primary", fontSize: 14, lineHeight: 1.5, padding: "0" }}
        >
          Export
        </Button>
      </Stack>
    </>
  );
};

export default MembersPanelActions;
