import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useAppContextController } from "context/AppContext";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import useSort from "utils/useSort";

import FiltersList from "components/FiltersList";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import fetchCustomers from "api/companies/fetchCustomers";
import useSessionAuth from "hooks/useSessionAuth";
import moment from "moment";
import DateRangePopover from "components/DateRangePopover";
import { Close, DateRange } from "@mui/icons-material";
import DateRangeSelector from "components/DateRangeSelector";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import JobsTableActions from "../JobsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const JobsTable = ({
  jobSlug,
  fetchAll,
  setNavigateUrl,
  filters,
  setFilters,
  setOpen,
  setJobPreview,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  refetchJobs,
  title = "Stadium People Jobs",
  parent = "Jobs",
  currentApplicant,
}) => {
  const { navigate } = useNavigate();
  const classes = useStyle();
  const { company, currentJob, setCurrentJob, venues } = useAppContextController();
  const [customerData, setCustomerData] = useState({});
  const isCompany = company?.companyType === "Company";

  const [dateRangeFilter, setDateRangeFilter] = useState(null);

  const { logoutAuth0User } = useSessionAuth();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters: { ...filters }, venues };

  if (dateRangeFilter) {
    allOptions.startDate = dateRangeFilter.startDate.toISOString();
    allOptions.endDate = dateRangeFilter.endDate.toISOString();
  }

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery(["jobs", allOptions], () => fetchJobs(allOptions), {
    onSuccess: async (data) => {
      if (!isCompany) return;
      const customers = {};
      try {
        // Get all company slugs
        let companySlugs = data?.data?.map((comp) => comp.companySlug);
        // Remove duplicates
        companySlugs = [...new Set(companySlugs)];

        const results = await fetchCustomers({
          filters: { slug: companySlugs.join(";") },
          page: 1,
          limit: 0,
        });

        // Do something with all fetched customer data

        // Assuming that 'results' is an array of objects and each object has a 'slug' property
        (results?.data ?? []).forEach((cstmr) => {
          customers[cstmr.slug] = cstmr;
        });
        setCustomerData(customers);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [refetchJobs]);

  useEffect(() => {
    if (!isLoading && jobs?.data?.length && jobSlug) {
      const ndx = jobs.data.findIndex((item) => item.jobSlug === jobSlug);
      if (ndx > -1) {
        setCurrentJob(jobs.data[ndx]);
      }
    }
  }, [isLoading, jobSlug, jobs?.data]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => {
          if (isCompany) {
            const rowCompany = customerData[row.companySlug] ?? {};
            return <CompanyLogoSquare company={rowCompany} defaultCompany={company} />;
          }
          return <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />;
        },
      },
      ...(!isCompany
        ? [
          {
            title: "Venue",
            field: "venueSlug",
            customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
          },
        ]
        : []),
      { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Date Created",
        field: "createdDate",
        customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
      },
      // {
      //   title: "Start Date",
      //   field: "startDate",
      //   customCell: (date) => moment(date).format("MM/DD/YYYY"),
      // },
      { title: "Status", field: "status" },
      {
        title: "Job Actions",
        field: "jobSlug",
        onClick: (e) => e.stopPropagation(),
        customCell: (thisJobSlug, field, row) => {
          // if (parent === "jobs")
          return (
            <JobsTableActions
              jobSlug={thisJobSlug}
              setNavigateUrl={navigate}
              row={row}
              setActionSelected={setActionSelected}
              setOpen={setOpen}
              setJobPreview={setJobPreview}
              parent={parent}
              currentApplicant={currentApplicant}
              isCompany={isCompany}
            />
          );
        },
      },
    ],
    [
      isCompany,
      customerData,
      company,
      navigate,
      setActionSelected,
      setOpen,
      setJobPreview,
      parent,
      currentApplicant,
    ]
  );

  const handleRowClick = (job, e) => {
    e.stopPropagation();
    setCurrentJob(job);
    setNavigateUrl(`/jobs/${job.jobSlug}`);
  };

  const addNew = () => {
    if (!currentJob) {
      setCurrentJob({});
    }
    setNavigateUrl("/jobs/create");
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="warning"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">work</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <DateRangeSelector
            setDateRangeFilter={setDateRangeFilter}
            dateRangeFilter={dateRangeFilter}
          />
          {showSearchBar && (
            <Searchbar
              fetch={fetchJobs}
              fetchAll={false}
              placeholder="Search Job/Venue"
              columns={columns.slice(1, columns.length - 1)}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["title", "venueName", "venueSlug"]}
              filterBy="venueSlug"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={jobs}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading}
          defaultSort="jobSlug"
          idField="jobSlug"
        />
      </MDBox>
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
JobsTable.defaultProps = {
  fetchAll: true,
  setNavigateUrl: () => { },
  title: "Jobs Table",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
JobsTable.propTypes = {
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default JobsTable;
