import { useState, useEffect, useRef } from "react";
import fetchCounts from "api/counts/fetchCounts";
import { API_URL } from "config";
import createApiParams from "utils/createApiParams";
import _ from "lodash";
import { useQuery } from "react-query";
import useSessionAuth from "./useSessionAuth";
import useIsMountedReferenced from "./useIsMountedReferenced";

const useBadgeValues = ({
  initialCards,
  filters,
  entity,
  refreshBadges = false,
  selectedIndex,
  useElemMatch,
  extraParams,
}) => {
  const isMountedRef = useIsMountedReferenced();
  const lastCardsRef = useRef(initialCards);
  const { logoutAuth0User } = useSessionAuth();

  const getBadgeValueUrl = ({ cd, ent }) => {
    let filts = null;
    if (cd.filters) {
      filts = cd.filters;
    } else if (cd.filterField) {
      filts = { [cd.filterField]: cd.filterValue };
    } else {
      filts = {};
    }

    const { filterQuery } = createApiParams({
      fetchAll: true,
      filters: { ...filters, ...filts },
    });
    return `${API_URL}/outside-backend/${ent}${filterQuery}&countOnly=true${
      useElemMatch ? "&useElemMatch=true" : ""
    }${extraParams ?? ""}`;
  };

  const urls = initialCards?.map((card) => getBadgeValueUrl({ cd: card, ent: entity }));

  const { data: cards, refetch } = useQuery(
    ["useBadgeValues", entity, urls],
    () => fetchCounts({ entity, urlList: urls, size: urls.length }),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchInterval: 60000,
      select: (data) => {
        if (data) {
          if (isMountedRef.current) {
            const badgeNumbers = data.map((count) => count || 0);
            const newCards = initialCards.map((item, index) => ({
              ...item,
              number: badgeNumbers[index],
            }));
            return newCards;
          }
        }
        return initialCards;
      },
      onError: (err) => {
        console.error("Error fetching badge values:", err);
        if (String(err).includes("401") || err?.response?.status === 401) {
          logoutAuth0User();
        }
      },
      enabled: !!urls && !!urls.length,
    }
  );

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      refetch();
    } else {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshBadges]);

  if (cards) lastCardsRef.current = cards;

  return { cards: lastCardsRef.current };
};

export default useBadgeValues;
