import { Warning } from "@mui/icons-material";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";

const Flags = ({ flagged, row }) => (
  <Stack direction="row" container>
    {row.isDnu === "Yes" && (
      <Tooltip title="Do not use">
        <BlockIcon fontSize="large" color="error" />
      </Tooltip>
    )}
    {!row.employeeID && (
      <Tooltip title="Missing Employee ID">
        <Warning fontSize="large" color="error" />
      </Tooltip>
    )}
    {flagged === "Yes" && (
      <Tooltip title="Check Employee Notes">
        <FlagIcon fontSize="large" color="error" />
      </Tooltip>
    )}
  </Stack>
);

export default Flags;
