import * as yup from "yup";

export const i9Schema = yup.object().shape({
  preparerOrTranslator: yup.string().required("Please choose Certification"),
  citizenshipStatus: yup.string().required("Please choose Citizenship status"),
  alienRegistrationNumber: yup
    .string()
    .default("")
    .when("citizenshipStatus", {
      is: (val) => val === "Permanent Resident" || val === "Authorized Alien",
      then: yup.string().required("Alien Registration Number is required"),
      otherwise: yup.string().notRequired(),
    }),
});
