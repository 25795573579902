import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import SaveIcon from "@mui/icons-material/Save";
import { Button, TextareaAutosize } from "@mui/material";

const AddAComment = ({ setValue, comments, currentIssue, currentLoggedUser, mode }) => {

    const [editorValue, setEditorValue] = useState('')
    const [editorEmptyError, setEditorEmptyError] = useState(false);

    useEffect(() => {
        setEditorValue("")
    }, [currentIssue])


    const handleSubmit = (e) => {
        if (editorValue === "" || editorValue === undefined || editorValue === "<p><br></p>") {
            setEditorEmptyError(true)
        }
        else {
            setEditorEmptyError(false)
            e.preventDefault()
            const newComment = {
                userId: currentLoggedUser._id,
                date: new Date().toISOString(),
                commentText: editorValue
            }
            if (mode === "create") {
                const newCommentArr = comments ? [...comments] : [];
                newCommentArr.push(newComment);
                setValue("comments", newCommentArr, { shouldDirty: true })
                setEditorValue('')
            }
            else {
                const newCommentArr = comments ? [...comments] : [];
                newCommentArr.push(newComment);
                setValue("comments", newCommentArr, { shouldDirty: true })
                setEditorValue('')

            }
        }

    }

    return (
        <>
            <MDBox>

                <MDBox>

                    <MDBox my={2} sx={{ height: "15rem" }}>
                        <MDEditor
                            placeholder="Add a comment..."
                            value={editorValue || ""}
                            style={{ width: "100%", fontSize: "1.3rem", height: "12rem" }}
                            onChange={(e, v) => {
                                setEditorValue(e);
                            }}
                        />
                        {editorEmptyError &&
                            <MDTypography sx={{ fontSize: "0.75rem", }} color="error">
                                Comment can&apos;t be empty
                            </MDTypography>
                        }
                    </MDBox>
                    <MDBox display="flex" alignItems="center" justifyContent="right">

                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={<SaveIcon />}
                            style={{ color: "white" }}
                            sx={{ padding: "0.5rem 1rem" }}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Send Comment
                        </Button>
                    </MDBox>
                </MDBox>

            </MDBox>
        </>
    )
}

export default AddAComment