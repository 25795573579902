import { useNewApplicantContext } from "context/NewApplicantContext";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import useYupValidationResolver from "hooks/useYupValidationResolver";
import { applicantSchema } from "data/applicant";
import { Button, Stack, Switch, Icon, Box, Tooltip } from "@mui/material";

import MDTypography from "components/MDTypography";
import GenericModal from "components/GenericModal";


const Subscriptions = () => {
    const {
        applicant,
        updateApplicantAction,
        updateButtons,
        buttonState,
        updateCurrentFormState,
        currentFormState,
    } = useNewApplicantContext();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

    const resolver = useYupValidationResolver(applicantSchema);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        reset,
        watch,
        trigger,
        formState: { errors, isDirty, isSubmitSuccessful, isValid },
    } = useForm({ resolver });

    const header = (
        <Box  >
            <MDTypography variant="h4">
                Turn Off All Notifications
            </MDTypography>
        </Box>
    );

    const modalBody = (
        <Box >
            <MDTypography variant="body2">
                Turning off all notifications will also terminate your Account. This action is permanent. Do you wish to proceed?
            </MDTypography>
        </Box>
    );

    const modalButtons = (
        <>
            <Button
                variant="contained"
                style={{ color: "white" }}
                color="primary"
                onClick={() => {
                    setConfirmationModalOpen(false)
                }}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                color="secondary"

                style={{ color: "white" }}
                onClick={() => {
                    setValue("emailNotifications", "no", { shouldDirty: true })
                    setValue("smsNotifications", "no", { shouldDirty: true })
                    setConfirmationModalOpen(false)
                }}
            >
                Continue
            </Button>
        </>
    );

    useEffect(() => {
        updateCurrentFormState({
            ...currentFormState,
            isDirty,
        });

        updateButtons({
            ...buttonState,
            submit: {
                show: true,
                disabled: !isDirty,
            },
        });
    }, [isDirty]);

    useEffect(() => {
        updateButtons({
            ...buttonState,
            previous: {
                show: true,
                disabled: false,
            },
            next: {
                show: true,
                disabled: true,
            },
            submit: {
                show: true,
                disabled: false,
            },
        });
    }, []);

    useEffect(() => {
        if (isValid) {
            updateButtons({
                ...buttonState,
                next: {
                    show: true,
                    disabled: false,
                },
            });
        }
    }, [isValid]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            updateButtons({
                ...buttonState,
                next: {
                    show: true,
                    disabled: false,
                },
                submit: {
                    show: true,
                    disabled: false,
                },
            });
        }
    }, [isSubmitSuccessful]);





    return (
        <>
            <Box p={5}>
                <Stack

                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                >
                    <MDTypography variant="h6">Email Notifications  &nbsp;</MDTypography>
                    <MDTypography variant="body2" color="info" >{applicant?.email}</MDTypography>
                </Stack>
                <Tooltip title="Coming soon..." placement="left">
                    <Stack

                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                    >
                        <Controller
                            name="emailNotifications"
                            control={control}
                            render={({ field }) => (
                                <Switch
                                    disabled
                                    checked={field.value === "Yes"}
                                    onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                            )}
                        />
                        <MDTypography variant="body2" color="info">{watch("emailNotifications") || "No"}</MDTypography>
                    </Stack>
                </Tooltip>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                >
                    <MDTypography variant="h6">SMS Notifications &nbsp;</MDTypography>
                    <MDTypography variant="body2" color="info">{applicant?.phone}</MDTypography>
                </Stack>
                <Tooltip title="Coming soon..." placement="left">
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                    >
                        <Controller
                            name="smsNotifications"
                            control={control}
                            render={({ field }) => (
                                <Switch
                                    disabled
                                    checked={field.value === "Yes"}
                                    onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                            )}
                        />
                        <MDTypography variant="body2" color="info">{watch("smsNotifications") || "No"}</MDTypography>
                    </Stack>
                </Tooltip>

                <Tooltip title="Coming soon..." placement="left">
                    <Box>
                        <Box>

                            <MDTypography variant="h6">Notification Preferences  &nbsp;</MDTypography>
                            <MDTypography variant="caption" ><Icon>info</Icon> Turning off all notifications will also Terminate your Account</MDTypography>
                        </Box>
                        <Button
                            disabled
                            variant="contained"
                            onClick={() => {
                                setConfirmationModalOpen(true)
                            }}>
                            Turn Off All Notifications
                        </Button>
                    </Box>
                </Tooltip >
                <GenericModal
                    open={confirmationModalOpen}
                    setOpen={setConfirmationModalOpen}
                    header={header}
                    body={modalBody}
                    buttons={modalButtons}
                    width="50%"

                />
            </Box >
        </>
    )
}

export default Subscriptions