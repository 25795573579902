import { useEffect, useMemo, useState } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import fetchCompanyJob from "api/companies/fetchCompanyJob";
import fetchCustomers from "api/companies/fetchCustomers";

import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useSort from "utils/useSort";

import moment from "moment";
// import CompanyJobIcon from "components/CompanyJobIcon";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import FiltersList from "components/FiltersList";
import Searchbar from "components/Searchbar";
import CompanyJobsTableActions from "layouts/pages/companyjobs/components/CompanyJobsTableActions";
import useSessionAuth from "hooks/useSessionAuth";
import PictureAvatar from "components/PictureAvatar";
import { splitName } from "utils/helpers/splitName";
import { Close, DateRange } from "@mui/icons-material";
import DateRangePopover from "components/DateRangePopover";
import DateRangeSelector from "components/DateRangeSelector";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import PositionCount from "../PositionCount";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CompanyJobsTable = ({
  jobSlug,
  fetchAll,
  filters,
  setFilters,
  setOpen,
  setJobPreview,
  showFiltersList = true,
  showSearchBar = true,
  title = "Jobs",
  parent = "Jobs",
  refetchJobs,
  currentApplicant,
}) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const { currentJob, setCurrentJob, venues, company, setCompany, setCompanyType } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const [dateRangeFilter, setDateRangeFilter] = useState(null);

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const { order, orderBy, toggleSort } = useSort();
  const [customerData, setCustomerData] = useState({});

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, includeExtraCounts: true, filters: { ...filters }, venues };
  const { logoutAuth0User } = useSessionAuth();

  if (dateRangeFilter) {
    allOptions.startDate = dateRangeFilter.startDate.toISOString();
    allOptions.endDate = dateRangeFilter.endDate.toISOString();
  }

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery(["company_jobs_table", allOptions], () => fetchCompanyJob(allOptions), {
    onSuccess: async (data) => {
      // Assuming that data is an array of interviews
      const customers = {};

      try {
        // Get all company slugs
        let companySlugs = data?.data?.map((comp) => comp.companySlug);
        // Remove duplicates
        companySlugs = [...new Set(companySlugs)];

        const results = await fetchCustomers({
          filters: { slug: companySlugs.join(";") },
          page: 1,
          limit: 0,
        });

        // Do something with all fetched customer data

        // Assuming that 'results' is an array of objects and each object has a 'slug' property
        (results?.data ?? []).forEach((cstmr) => {
          customers[cstmr.slug] = cstmr;
        });
        setCustomerData(customers);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
  });

  const columns = useMemo(
    () => [
      {
        title: "Logo",
        field: "companySlug",
        customCell: (slug, field, row) => {
          const cust = customerData[slug] ? customerData[slug] : {};
          return (
            <>
              <CompanyLogoSquare company={cust} defaultCompany={company} />
            </>
          );
        },
      },
      { title: "Status", field: "status" },
      {
        title: "Customer",
        field: "companyName",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Approving Manager",
        field: "approvingManager",
        customCell: (approvingManager, field, row) => {
          if (!approvingManager) return null;
          const { first, last } = splitName(approvingManager);

          return (
            <PictureAvatar
              image={null}
              firstName={first}
              lastName={last}
              // userId={usr?._id?.toString()}
              size="md"
            />
          );
        },
      },
      {
        title: "Recruiter",
        field: "recruiter",
        customCell: (recruiter, field, row) => {
          if (!recruiter) return null;
          const { first, last } = splitName(recruiter);

          return (
            <PictureAvatar
              image={null}
              firstName={first}
              lastName={last}
              // userId={usr?._id?.toString()}
              size="md"
            />
          );
        },
      },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Qty",
        field: "positionQuantity",
        customCell: (field) => {
          const result = field !== undefined && field !== null ? field : 1;
          return <span>{result}</span>;
        },
      },
      {
        title: "Date Created",
        field: "createdDate",
        customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
      },
      {
        title: "Job Actions",
        field: "jobSlug",
        onClick: (e) => e.stopPropagation(),
        customCell: (thisJobSlug, field, row, job) => {
          return (
            <CompanyJobsTableActions
              jobSlug={thisJobSlug}
              row={row}
              setOpen={setOpen}
              setJobPreview={setJobPreview}
              parent={parent}
              setPage={setPage}
              currentApplicant={currentApplicant}
            />
          );
        },
      },
    ],
    [customerData, company, setOpen, setJobPreview, parent, currentApplicant]
  );

  const searchColumns = useMemo(
    () => [
      {
        width: 50,
        title: "Logo",
        field: "companySlug",
        customCell: (slug, field, row) => {
          const cust = customerData[slug] ? customerData[slug] : {};
          return (
            <>
              <CompanyLogoSquare company={cust} defaultCompany={company} />;
            </>
          );
        },
      },
      { title: "Status", field: "status", width: 50 },
      {
        width: 150,
        title: "Customer",
        field: "companySlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Url", field: "jobSlug", width: 200 },
      { title: "Title", field: "title", width: 150 },
      {
        width: 40,
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
    ],
    []
  );
  const handleRowClick = (job, e) => {
    e.stopPropagation();
    setCurrentJob(job);
    navigate(`/companyjobs/${job.jobSlug}`);
  };

  const addNew = () => {
    if (currentJob?._id) setCurrentJob({});
    navigate("/companyjobs/create");
  };

  useEffect(() => {
    refetch();
  }, [refetchJobs]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="warning"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">work</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <PositionCount data={jobs?.data} />
          <DateRangeSelector
            setDateRangeFilter={setDateRangeFilter}
            dateRangeFilter={dateRangeFilter}
          />
          {showSearchBar && (
            <Searchbar
              fetch={fetchCompanyJob}
              fetchAll={false}
              placeholder="Search Customer Jobs"
              columns={searchColumns}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["title", "name", "companySlug", "jobSlug"]}
              filterBy="jobSlug"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={jobs}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading}
          defaultSort="jobSlug"
          idField="jobSlug"
        />
      </MDBox>
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
CompanyJobsTable.defaultProps = {
  fetchAll: true,
  title: "Jobs Table",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
CompanyJobsTable.propTypes = {
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default CompanyJobsTable;
