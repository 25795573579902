import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CallMerge, DoDisturbAlt, FileDownload, Lock, LockOpen, Mail } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Box, Button, Card, Grid, Icon, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import TagSearchAndToggle from "components/TagSearchAndToggle";
import VenueIcon from "components/VenueIcon";
import VenueStatusButton from "components/VenueStatusButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import fetchCustomers from "api/companies/fetchCustomers";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import fetchEmployeesAdvanced from "layouts/pages/employees/actions/fetchEmployeesAdvanced";
import fetchEmployeesOnly from "layouts/pages/employees/actions/fetchEmployeesOnly";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import NoTagSelectedModal from "layouts/pages/applicants/components/NoTagSelectedModal";
import NoResultsModal from "layouts/pages/applicants/components/NoResultsModal";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import { isEmpty } from "lodash";
import moment from "moment";
import { SnackbarProvider, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import useSearchbarStore from "stores/useSearchbarStore";
import AdvancedSearchModal from "layouts/pages/applicants/components/AdvancedSearchModal";
// maybe change to employee?
import DuplicateApplicantModal from "layouts/pages/applicants/components/DuplicateApplicantModal";
import applicantEnableMerge from "api/applicants/applicantEnableMerge";
import Flags from "layouts/pages/employees/components/EmployeesTable/Flags";
import useSessionAuth from "hooks/useSessionAuth";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import EmployeesTableActions from "../EmployeesTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const EmployeesTable = ({
  fetchAll = false,
  title = "Title",
  filters,
  pageSize = 50,
  page,
  setPage,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setSelectedId,
  queryName = "employees",
  venueId,
  parent = "employees",
  useOrTags,
  setUseOrTags,
  status = "Employee",
  staffingPoolCards = [],
}) => {
  const queryClient = useQueryClient();
  const { searchResults, setSearchResults } = useSearchbarStore((state) => ({
    setSearchResults: state.setSearchResults,
    searchResults: state.searchResults,
  }));
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const {
    currentApplicant,
    setCurrentApplicant,
    currentVenue,
    venues,
    setVenues,
    userType,
    company,
    setCompany,
    setCompanyType,
    currentLoggedUser,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const [selectedCardNumber, setSelectedCardNumber] = useState(0);
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [openNoTagModal, setOpenNoTagModal] = useState(false);
  const [messageContext, setMessageContext] = useState("Applicant");
  const [openNoResultsModal, setOpenNoResultsModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
  const [searchTagList, setSearchTagList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  // Pagination state
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(pageSize);
  const { order, orderBy, toggleSort } = useSort("createdDate", "asc");
  const [selectedTags, setSelectedTags] = useState("");
  const [mergeApplicantData, setMergeApplicantData] = useState([]);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  const isCompany = company?.companyType === "Company";
  const options = useMemo(
    () => (fetchAll ? { fetchAll } : { page, limit, order, orderBy }),
    [fetchAll, limit, order, orderBy, page]
  );
  const optionsForBulk = fetchAll ? { fetchAll } : { page, limit: null, order, orderBy };

  const enableMergeMutation = useMutation(applicantEnableMerge);
  const { enqueueSnackbar } = useSnackbar();
  const [searchRefetchKey, setSearchRefetchKey] = useState(0);

  const refetchSearch = () => setSearchRefetchKey(Math.random());

  // test this

  // let allOptions;
  // if (filters.filt === "All") {
  //   allOptions = {
  //     ...options,
  //     filters: { status: "Employee;Inactive", ...filters },
  //     useOrTags,
  //   };
  // } else if (filters.loginVerified) {
  //   allOptions = {
  //     ...options,
  //     filters: {
  //       status: "Employee;Inactive",
  //       ...filters,
  //       loginVerified: filters.loginVerified,
  //     },
  //     useOrTags,
  //   };
  // } else if (filters?.status) {
  //   allOptions = {
  //     ...options,
  //     filters: {
  //       ...filters,
  //       status: filters?.status,
  //     },
  //     useOrTags,
  //   };
  // } else if ("isAvailable" in filters) {
  //   allOptions = {
  //     ...options,
  //     filters: {
  //       ...filters,
  //       isAvailable: filters?.isAvailable,
  //     },
  //     useOrTags,
  //   };
  // }

  const allOptions = useMemo(
    () => ({
      ...options,
      filters: { status, ...filters, ...selectedTags },
      useOrTags,
    }),
    [filters, options, selectedTags, status, useOrTags]
  );

  const { logoutAuth0User } = useSessionAuth();

  const allOptionsForBulk = {
    ...optionsForBulk,
    filters: { status, ...filters, ...selectedTags },
    useOrTags,
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchEmployees,
  } = useQuery(["employee_table", queryName, allOptions, searchQuery], () => {
    const allOptionsCopy = { ...allOptions, filters: { ...allOptions.filters } };
    // If we're filtering by ID, overwrite status filter so it can find the user regardless of status
    if (allOptionsCopy?.filters?._id != null && allOptionsCopy?.filters?.status != null) {
      allOptionsCopy.filters.status = "Employee;Partner";
    }

    if (searchQuery) {
      return fetchEmployeesAdvanced({ ...allOptionsCopy, searchQuery });
    }
    if (filters) {
      if (queryName === "staffingpool") return fetchStaffingPool(allOptionsCopy);
    }
    return fetchEmployees(allOptionsCopy);
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    refetch: refetchCompanies,
  } = useQuery(
    ["employee_table_companies", { filters: { primaryCompany: false }, page: 1, limit: 0 }],
    () => fetchCustomers({ filters: { primaryCompany: false }, page: 1, limit: 0 }),
    {
      enabled: company?.companyType !== "Venue",
      select: (data) => data?.data ?? [],
    }
  );

  const {
    data: applicantsForBulk,
    isLoading: isLoadingForBulk,
    refetch: refetchEmployeeForBulk,
  } = useQuery(
    ["employeesForBulkMessaging", queryName, allOptionsForBulk, searchQuery],
    () => {
      if (searchQuery) {
        return fetchEmployeesAdvanced({ ...allOptionsForBulk, searchQuery });
      }
      if (filters) {
        if (queryName === "staffingpool") return fetchStaffingPool(allOptionsForBulk);
      }
      return fetchEmployees(allOptionsForBulk);
    },
    {
      enabled: isBulkMessageModalOpen,
    }
  );

  const formatSearchResults = (searchResult) => {
    const formatted = searchResult?.map((v, index) => {
      if (v.status === "Deleted") return v;
      if (v.doNotMerge === "Yes") return { ...v, mergeStatus: "ignore" };
      const result = searchResult.find((val, idx) => {
        return (
          val.employeeID === v.employeeID &&
          v._id !== val._id &&
          val.doNotMerge !== "Yes" &&
          val.status !== "Deleted"
        );
      });
      if (result) {
        return { ...v, mergeStatus: "merge" };
      }
      return v;
    });
    return formatted;
  };

  const openMergeModal = useCallback(
    (row) => {
      const updatedRow = { ...row };
      const duplicateApplicants = searchResults.filter(
        (v) =>
          row.employeeID === v.employeeID &&
          v._id !== row._id &&
          v.mergeStatus !== "ignore" &&
          v.status !== "Deleted"
      );
      updatedRow.mergeStatus = "primary";
      setMergeApplicantData([updatedRow, ...duplicateApplicants]);
      setDuplicateModalOpen(true);
    },
    [searchResults]
  );

  const removeIgnoredFlag = (e, row) => {
    e.stopPropagation();
    const params = {
      id: row._id,
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };
    enableMergeMutation.mutate(params, {
      onSuccess: (result) => {
        if (result && result.data.success) {
          enqueueSnackbar(`Ignore flag removed successfully`, { variant: "success" });
          refetchSearch();
        } else {
          enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
      },
      onError: () => {
        enqueueSnackbar(`Something went wrong`, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    if (company?.companyType === "Venue" && (!venues || Object.keys(venues).length))
      refetchVenues();
  }, [venues, company?.companyType]);

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      //      setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, applicants?.data?.length]);

  const columns = useMemo(
    () => [
      ...(company?.companyType === "Company"
        ? [
          {
            title: "Flag",
            field: "recordFlagged",
            width: 15,
            customCell: (flagged, field, row) => <Flags flagged={flagged} row={row} />,
          },
        ]
        : [
          {
            title: "Avatar",
            field: "profileImg",
            width: 15,
            customCell: (image, field, row) => (
              <PictureAvatar
                image={row?.profileImg}
                firstName={row?.firstName}
                lastName={row?.lastName}
                userId={row?.userRecordId ? row.userRecordId : null}
                applicantId={row?.userRecordId ? null : row?._id}
                size="md"
              />
            ),
          },
          {
            title: "Flag",
            field: "recordFlagged",
            width: 15,
            customCell: (flagged, field, row) => {
              return <Flags flagged={flagged} row={row} />;
            },
          },
          {
            title: "Locked",
            field: "recordLocked",
            width: 15,
            customCell: (recordLocked) => (
              <Box pl={0.75}>
                {recordLocked === "Yes" ? (
                  <Lock fontSize="large" color="error" />
                ) : (
                  <LockOpen fontSize="large" color="success" />
                )}
              </Box>
            ),
          },
        ]),
      // {
      //   title: "Merge",
      //   field: "mergeStatus",
      //   width: "10%",
      //   customCell: (value, row) => {
      //     if (value === "merge") {
      //       return (
      //         <IconButton
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             openMergeModal(row);
      //           }}
      //         >
      //           <CallMerge fontSize="medium" color="info" />
      //         </IconButton>
      //       );
      //     }
      //     return "";
      //   },
      // },
      { title: "Type", field: "status" },
      { title: "Employment Status", field: "employmentStatus" },
      ...(venueSlug && currentVenue
        ? [
          {
            title: "Venue Status",
            field: "slug",
            customCell: (_id, field, row) => {
              return (
                <VenueStatusButton
                  status={getApplicantVenueStatus(row, venueSlug)}
                  venue={venues?.[venueSlug] || {}}
                  onUpdate={() => queryClient.invalidateQueries(["applicants", allOptions])}
                  applicant={row}
                />
              );
            },
          },
        ]
        : []),

      {
        title: "Last Name",
        field: "lastName",
        customCell: (name, field, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (name, field, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      ...(filters?.status !== "Partner"
        ? [
          // {
          //   title: "Birthdate",
          //   field: "birthDate",
          //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
          // },
          // {
          //   title: "Hire Date",
          //   field: "hireDate",
          //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
          // },
          ...(company?.companyType === "Venue"
            ? [
              { title: "Login", field: "loginVerified" },
              {
                title: "Venue",
                field: "venues",
                customCell: (vens, field, row) => {
                  if (!vens || !vens.length) return "";
                  const { venueSlug: vslug } = vens?.[0];
                  if (!venues) return "";
                  const venue = venues[vslug];
                  return (
                    <>
                      <VenueIcon
                        logoUrl={venue?.logoUrl}
                        slug={vslug}
                        name={venue?.name}
                        imageWidth="50"
                        imageHeight="50"
                      />
                    </>
                  );
                },
              },
              { title: "Rank", field: "rank" },
            ]
            : [
              {
                title: "Customer",
                field: "companies",
                customCell: (comps, field, row) => {
                  if (!comps || !comps.length) return "";
                  let foundComp = comps?.find((c) => c.status === "On Assignment");
                  if (!foundComp) foundComp = comps?.[comps.length - 1];
                  const cslug = foundComp?.companySlug;
                  if (!cslug) return "";

                  if (company?.companyType === "Company") {
                    const existingCompany = (companies ?? []).find(
                      (comp) => comp.slug === cslug
                    );
                    return (
                      <CompanyLogoSquare company={existingCompany} defaultCompany={company} />
                    );
                  }
                  return (
                    <span>
                      {cslug}
                      {/* <VenueIcon
                    logoUrl={venue?.logoUrl}
                    slug={vslug}
                    name={venue?.name}
                    imageWidth="50"
                    imageHeight="50"
                  /> */}
                    </span>
                  );
                },
              },
            ]),
          { title: "Phone", field: "phone" },
          {
            title: "Source",
            field: "referralSource",
            customCell: (source, field, row) => source || row?.systemSource || "",
          },
          {
            title: "Apply Date",
            field: "createdDate",
            customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
          },
        ]
        : [
          { title: "Partner", field: "partnerSlug" },
          { title: "Helm ID", field: "employeeID" },
        ]),
      {
        title: "Applicant Actions",
        field: "_id",
        customCell: (rowId, field, row) => (
          <EmployeesTableActions
            id={row?._id}
            row={row}
            setCurrentApplicant={setCurrentApplicant}
            setModalInfo={setModalInfo}
            setPage={setPage}
            setNavigateUrl={setNavigateUrl}
            setGenericModalOpen={setGenericModalOpen}
            company={company}
          />
        ),
      },
    ],
    [
      allOptions,
      companies,
      company,
      currentVenue,
      filters?.status,
      queryClient,
      setCurrentApplicant,
      setNavigateUrl,
      setPage,
      venueSlug,
      venues,
    ]
  );

  const searchColumns = [
    ...(company?.companyType === "Venue"
      ? [
        {
          width: 50,
          title: "Avatar",
          field: "profileImg",
          customCell: (image, row) => {
            return (
              <PictureAvatar
                image={row?.profileImg}
                firstName={row?.firstName}
                lastName={row?.lastName}
                userId={row?.userRecordId ? row.userRecordId : null}
                applicantId={row?.userRecordId ? null : row?._id}
                size="md"
              />
            );
          },
        },
      ]
      : []),
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, row) => <Flags flagged={flagged} row={row} />,
    },
    {
      title: "Merge",
      field: "mergeStatus",
      width: 50,
      customCell: (value, row) => {
        if (value === "merge") {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                openMergeModal(row);
              }}
            >
              <CallMerge fontSize="medium" color="info" />
            </IconButton>
          );
        }
        if (value === "ignore") {
          return (
            <IconButton
              onClick={(e) => {
                removeIgnoredFlag(e, row);
              }}
            >
              <DoDisturbAlt fontSize="medium" color="error" />
            </IconButton>
          );
        }
        return "";
      },
    },
    ...(company?.companyType === "Venue"
      ? [
        {
          title: "Locked",
          field: "recordLocked",
          width: 50,
          customCell: (recordLocked) => (
            <Box pl={1.25}>
              {recordLocked === "Yes" ? (
                <Lock fontSize="medium" color="error" />
              ) : (
                <LockOpen fontSize="medium" color="success" />
              )}
            </Box>
          ),
        },
      ]
      : []),
    { width: 120, title: "Type", field: "status" },
    ...(venueSlug && currentVenue
      ? [
        {
          title: "Venue Status",
          field: "slug",
          customCell: (_id, row) => {
            return (
              <VenueStatusButton
                status={getApplicantVenueStatus(row, venueSlug)}
                venue={venues?.[venueSlug] || {}}
                onUpdate={() => queryClient.invalidateQueries(["applicants", allOptions])}
                applicant={row}
              />
            );
          },
        },
      ]
      : []),

    {
      width: 140,
      title: "Last Name",
      field: "lastName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      width: 140,
      title: "First Name",
      field: "firstName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    ...(filters?.status !== "Partner" && company?.companyType === "Venue"
      ? [
        // {
        //   title: "Birthdate",
        //   field: "birthDate",
        //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
        // },
        // {
        //   title: "Hire Date",
        //   field: "hireDate",
        //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
        // },
        { width: 40, title: "Login", field: "loginVerified" },
        {
          title: "Venue",
          width: 50,
          field: "venues",
          customCell: (vens, row) => {
            if (!vens || !vens.length) return "";
            const { venueSlug: vslug } = vens?.[0];
            if (!venues) return "";
            const venue = venues[vslug];
            return (
              <>
                <VenueIcon
                  logoUrl={venue?.logoUrl}
                  slug={vslug}
                  name={venue?.name}
                  imageWidth="50"
                  imageHeight="50"
                />
              </>
            );
          },
        },
        { width: 20, title: "Rank", field: "rank" },
      ]
      : []),
    {
      title: "SSN-4",
      field: "socialSecurity",
      width: 50,
      customCell: (ssn) => {
        if (!ssn) return "";
        const numString = ssn.toString();
        const last4Digits = numString.slice(-4);
        return last4Digits;
      },
    },
    { title: "Helm ID", field: "employeeID", width: 50 },
    { width: 160, title: "Phone", field: "phone" },
    // {
    //   width: 80,
    //   title: "Source",
    //   field: "referralSource",
    //   customCell: (source, row) => source || row?.systemSource || "",
    // },
    {
      width: 120,
      title: "Apply Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      `/employees/${applicant._id}${navigateToAction ? `/action/${navigateToAction}` : ""}`
    );
  };

  const addNew = (e) => {
    if (!isEmpty(currentApplicant)) setCurrentApplicant({});
    setNavigateUrl("/employees/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters.tags;
    setFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(false);
  };

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  // useEffect(() => {
  //   if (JSON.stringify(filters) !== "{}" && searchTagList.length > 0) {
  //     setSearchTagList([]);
  //   }
  // }, [filters]);
  // useEffect(() => {
  //   if (searchTagList.length > 0 && JSON.stringify(filters) !== "{}") {
  //     setFilters({});
  //   }
  // }, [searchTagList]);

  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="error"
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-4}
                >
                  <Icon fontSize="large">person-add</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <TagSearchAndToggle
                filters={filters}
                setFilters={setFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                useOrTags={useOrTags}
                setUseOrTags={setUseOrTags}
              />
              {showSearchBar && (
                <Searchbar
                  fetch={fetchEmployeesOnly}
                  fetchAll={false}
                  placeholder="Search Employees"
                  columns={searchColumns}
                  queryCharacterLimit={3}
                  resultsLimit={15}
                  setFilters={setFilters}
                  filters={filters}
                  setPage={setPage}
                  searchBy={["firstName", "lastName", "email", "altEmail", "socialSecurity"]}
                  changedSearchByList={["firstName", "lastName", "email", "altEmail", "employeeID"]}
                  changeSearchByAtInputChar={5}
                  filterBy="_id"
                  scrollLimit={200}
                  sortFunction={sortLastFirst}
                  onRowClick={(item) => {
                    if (item?._id) setSelectedId(item._id);
                    setFilters({
                      // ...filters,
                      status: "Employee",
                      _id: item._id,
                    });
                    setNavigateUrl(`/employees/${item._id}/action/employeeinfo`);
                  }}
                  returnAfterClick={false}
                  formatData={formatSearchResults}
                  refetchDependency={searchRefetchKey}
                />
              )}
              {isCompany && (
                <MDBox sx={{ width: "25rem" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ color: "white" }}
                    onClick={() => setAdvancedSearchModalOpen(true)}
                  >
                    Advanced Search
                  </Button>
                </MDBox>
              )}
              <Tooltip title="Bulk Message">
                <IconButton
                  color="error"
                  onClick={() => {
                    if (searchTagList?.length > 0 && applicants?.data?.length > 0) {
                      setMessageContext("Applicant");
                      toggleBulkMessageModal(true);
                    } else if (searchTagList?.length === 0) {
                      setOpenNoTagModal(true);
                    } else if (
                      applicants?.data?.length === 0 ||
                      applicants?.data?.length === undefined
                    ) {
                      setOpenNoResultsModal(true);
                    }
                  }}
                >
                  <Mail fontSize="large" />
                </IconButton>
              </Tooltip>
              <MDBox>
                {["Master", "Admin"].includes(userType) && (
                  <>
                    <FlexWrapper flexWrap="nowrap">
                      {parent !== "employees" && (
                        <Tooltip title="Bulk Message">
                          <IconButton
                            color="error"
                            onClick={() => {
                              setMessageContext("Venue");
                              toggleBulkMessageModal(true);
                            }}
                          >
                            <Mail fontSize="large" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {filters?.["venues.venueSlug"] && (
                        <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                          <FileDownload />
                        </IconButton>
                      )}
                      <IconButton className={classes.addButton} color="info" onClick={addNew}>
                        <AddCircleRoundedIcon />
                      </IconButton>
                    </FlexWrapper>
                  </>
                )}
              </MDBox>
            </Grid>
          </Grid>
          {/* <MDBox ml={3}>
            <StaffingPoolFilterCards
              // setTableFilter={setFilters}
              filters={filters}
              setFilters={setFilters}
              selectedCardNumber={selectedCardNumber}
              initialCards={staffingPoolCards}
            />
          </MDBox> */}
          <MDBox ml={3} flexWrap="wrap">
            {showFiltersList && (
              <Grid item xs={6}>
                <FiltersList
                  filters={filters}
                  setFilters={setFilters}
                  searchTagList={searchTagList}
                  setSearchTagList={setSearchTagList}
                  clearTagsHandler={clearTagsHandler}
                />
              </Grid>
            )}
          </MDBox>
          <MDBox pt={2} pb={2}>
            <DataTable
              columns={columns}
              data={applicants || { data: null, count: 0 }}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isLoading}
              onRowClick={(row, e) => handleRowClick(row, e)}
              order={order}
              orderBy={orderBy}
              toggleSort={toggleSort}
              fetchAll={fetchAll}
              defaultSort="lastName"
              idField="_id"
            />
          </MDBox>
          <ApplicantActionModal
            open={genericModalOpen}
            setOpen={setGenericModalOpen}
            modalInfo={modalInfo}
            setToastAlert={setToastAlert}
            currentApplicant={currentApplicant}
            messageContext={messageContext}
            venueId={venueId}
          />
        </SnackbarProvider>
        {duplicateModalOpen && (
          <DuplicateApplicantModal
            open={duplicateModalOpen}
            setOpen={setDuplicateModalOpen}
            mergeData={mergeApplicantData}
            parentComponent="Employee"
            onSubmit={refetchSearch}
          />
        )}
        <AdvancedSearchModal
          advancedSearchModalOpen={advancedSearchModalOpen}
          setAdvancedSearchModalOpen={setAdvancedSearchModalOpen}
          searchQuery={searchQuery}
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          useOrTags={useOrTags}
          setUseOrTags={setUseOrTags}
          filters={filters}
          setPage={setPage}
          showFiltersList
          parent="Employees"
        />
      </Card>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={filters?.status?.search(";") !== -1 ? "All" : filters?.status}
          applicants={applicantsForBulk?.data?.map((item) => ({ ...item, id: item?._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext={messageContext}
          isLoading={isLoadingForBulk}
        />
      )}
      {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          venueSlug={filters?.["venues.venueSlug"]}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
          filters={allOptions.filters}
          useOrTags
        />
      )}
      {openNoTagModal && <NoTagSelectedModal open={openNoTagModal} setOpen={setOpenNoTagModal} />}
      {openNoResultsModal && (
        <NoResultsModal open={openNoResultsModal} setOpen={setOpenNoResultsModal} />
      )}
      {pageLimitConfirmationModal}
    </>
  );
};

// Setting default values for the props
EmployeesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Employees",
  filters: null,
  showFiltersList: true,
  staffingPoolCards: [],
};

// Typechecking props
EmployeesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
  staffingPoolCards: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element || PropTypes.string,
      title: PropTypes.string.isRequired,
      number: PropTypes.number,
    })
  ),
};

export default EmployeesTable;
