import { DateRange } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useCallback, useState } from "react";

import enrollUserToEvent from "api/events/enrollUserToEvent";
import leaveRosterMessage from "api/events/leaveRosterMessage";
import colors from "assets/theme/base/colors";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";

import useSessionAuth from "hooks/useSessionAuth";
import EventDetail from "../EventDetail";
import RemoveFromEventModal from "../RemoveFromEventModal";

const EventConflict = ({ event, refetch }) => {
  const { currentLoggedUser } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [isRemoveModalOpen, toggleRemoveModal] = useState(false);
  const { logoutAuth0User } = useSessionAuth();

  const [isHidden, toggleHidden] = useState(false);

  const handleAction = useCallback(async () => {
    const body = {
      requestType: "Not Roster",
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };
    try {
      const response = await enrollUserToEvent({
        eventUrl: event.eventUrl,
        applicantId: currentLoggedUser.applicantId,
        body,
      });
      if (response.data.success) {
        const {
          data: { message = "", status = "", type: responseType = "" },
        } = response;
        refetch();
        if (message) {
          enqueueSnackbar(message, { variant: status.toLowerCase() });
        }
      }
    } catch (err) {
      console.error(err);
      if (String(err).includes("401") || err?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, [currentLoggedUser, event]);

  const handleUnder48Hours = useCallback(
    async (msg) => {
      const body = {
        message: msg,
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
      };
      try {
        const response = await leaveRosterMessage({
          eventUrl: event.eventUrl,
          applicantId: currentLoggedUser.applicantId,
          body,
        });
        if (response.data.success) {
          const {
            data: { message = "", status = "", type: responseType = "" },
          } = response;
          refetch();
          if (message) {
            enqueueSnackbar(message, { variant: status.toLowerCase() });
          }
        }
      } catch (err) {
        console.error(err);
        if (String(err).includes("401") || err?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
    [currentLoggedUser, event]
  );

  return (
    <>
      <FlexWrapper
        flexDirection="column"
        alignItems="flex-start"
        display={!isEmpty(event) && !isHidden ? "flex" : "none"}
      >
        <Box sx={{ backgroundColor: colors.error.main, padding: "10px", borderRadius: "10px" }}>
          <Typography fontWeight="bold" fontSize="1.25rem" sx={{ color: "white !important" }}>
            Conflicting Event Warning!
          </Typography>
          <Typography fontSize="1rem" sx={{ maxWidth: "500px", color: "white !important" }}>
            You have an event already scheduled below. If you wish to remove this event and register
            for this new event, you must remove the one below.
          </Typography>
        </Box>
        <EventDetail {...event} />
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              rowGap: "10px",
            },
          })}
        >
          <Button
            variant="contained"
            endIcon={<DateRange />}
            onClick={() => toggleRemoveModal(true)}
            sx={{
              maxWidth: "200px",
              backgroundColor: `${colors.error.main} !important`,
            }}
          >
            REMOVE THIS EVENT
          </Button>
          <Button variant="text" onClick={() => toggleHidden(true)}>
            KEEP ME SCHEDULED FOR THIS EVENT
          </Button>
        </Box>
      </FlexWrapper>
      <RemoveFromEventModal
        open={isRemoveModalOpen}
        setOpen={toggleRemoveModal}
        onClick={(allowed, msg) => {
          if (allowed) handleAction();
          else handleUnder48Hours(msg);
          toggleRemoveModal(false);
        }}
      />
    </>
  );
};

export default EventConflict;
