import { Button, Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GroupIcon from "@mui/icons-material/Group";
import PollIcon from "@mui/icons-material/Poll";
import WorkIcon from "@mui/icons-material/Work";
import EventIcon from "@mui/icons-material/Event";
import StadiumIcon from "@mui/icons-material/Stadium";
import InfoIcon from "@mui/icons-material/Info";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import ActiveNumbersSquare from "./ActiveNumbersSquare";

const useStyle = makeStyles({
  box: {
    marginTop: 35,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const ActiveNumbers = () => {
  const classes = useStyle();

  const hardcodedData = [
    {
      color: "#E91E63",
      icon: <GroupIcon fontSize="small" color="white" />,
      title: "New Applicants",
      tooltip: "New Applicants",
      number: 75,
    },
    {
      color: "#F44335",
      icon: <GroupIcon fontSize="small" color="white" />,
      title: "Working Employees",
      tooltip: "Working Employees",
      number: 342,
    },
    {
      color: "#FB8C00",
      icon: <WorkIcon fontSize="small" color="white" />,
      title: "Active Jobs",
      tooltip: "Active Jobs",
      number: 18,
    },
    {
      color: "#4CAF50",
      icon: <EventIcon fontSize="small" color="white" />,
      title: "Active Events",
      tooltip: "Active Events",
      number: 21,
    },

    {
      color: "#1A73E8",
      icon: <StadiumIcon fontSize="small" color="white" />,
      title: "Active Venues",
      tooltip: "Active Venues",
      number: 14,
    },
    {
      color: "#344767",
      icon: <InfoIcon fontSize="small" color="white" />,
      title: "Active User",
      tooltip: "Active User",
      number: 6,
    },
  ];

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="dark"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <PollIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Active Numbers
              <MDTypography variant="body2">
                Last Update: {moment(new Date()).format("ddd MM/DD/YYYY hh:mm a")}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <Button
        variant="text"
        startIcon={<BorderColorIcon />}
        style={{ textTransform: "none", justifyContent: "end", display: "flex" }}
        onClick={() => console.log("Edit headers clicked")}
      >
        Edit Headers
      </Button>
      <MDBox p={2}>
        <Grid container spacing={2} pb={2} sx={{ width: "100%", justifyContent: "space-between" }}>
          {hardcodedData.map((square) => {
            return (
              <Grid item key={`item_${square?.title}`}>
                <ActiveNumbersSquare data={square} />
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    </Card>
  );
};

export default ActiveNumbers;
