import {
  Check,
  Close,
  Event,
  HourglassTop,
  Stadium,
  Close as IconClose,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";

import moment from "moment";
import enrollUserToEvent from "api/events/enrollUserToEvent";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useAppContextController } from "context/AppContext";
import { APPLICANT_EVENT_STATUS_ENUM } from "utils/constants/applicant";
import CustomNotification from "components/CustomNotification";
import enrollPartnerToEvent from "api/events/enrollPartnerToEvent";
import EventStatusIcon from "./EventStatusIcon";

const statusColor = {
  "Not Roster": "dark",
  Roster: "success",
  Waitlist: "info",
  Request: "warning",
};

const ICON_TYPE_ENUM = {
  SIMPLE: "simple",
  DISTINCT: "distinct",
};

const EventStatusButton = ({
  status = "Not Roster",
  isInvalid = false,
  event,
  viewOnly = false,
  onUpdate,
  applicant,
  iconType = ICON_TYPE_ENUM.SIMPLE,
  isPartner,
}) => {
  const {
    currentApplicant: currentApplicantLocal,
    setCurrentApplicant: setCurrentApplicantLocal,
    currentPartner: currentPartnerLocal,
    setCurrentPartner: setCurrentPartnerLocal,
    currentLoggedUser,
    userType,
  } = useAppContextController();

  const currentApplicant = isPartner ? currentPartnerLocal : currentApplicantLocal;
  const setCurrentApplicant = isPartner ? setCurrentPartnerLocal : setCurrentApplicantLocal;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const activeApplicant = applicant || currentApplicant;

  function SnackbarCloseButton({ snackbarKey }) {
    return (
      <IconButton
        onClick={() => closeSnackbar(snackbarKey)}
        style={{ backgroundColor: "green", position: "absolute" }}
      >
        <IconClose />
      </IconButton>
    );
  }

  const OverTimeEventLines = ({ arr }) => {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="overtime events table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell>Date/Time</TableCell>
              <TableCell>Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arr.map((evt) => (
              <TableRow key={evt.eventName}>
                <TableCell>{evt.eventName}</TableCell>
                <TableCell>{evt.venue}</TableCell>
                <TableCell>{evt.eventDate}</TableCell>
                <TableCell>{evt.duration} hours</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  function padString(str, minLength) {
    return (str + " ".repeat(minLength)).slice(0, Math.max(str.length, minLength));
  }
  function createOverTimeEventTextTable(arr) {
    if (!arr || arr.length === 0) return "No events to display";

    const maxStringLengthArray = [5, 5, 5, 5];
    for (let index = 0; index < arr.length; index += 1) {
      const evt = arr[index];
      maxStringLengthArray[0] = Math.max(evt?.eventName?.length ?? 0, maxStringLengthArray[0]);
      maxStringLengthArray[1] = Math.max(evt?.venue?.length ?? 0, maxStringLengthArray[1]);
      maxStringLengthArray[2] = Math.max(evt?.eventDate?.length ?? 0, maxStringLengthArray[2]);
      maxStringLengthArray[3] = Math.max((evt?.duration?.length ?? 0) + 6, maxStringLengthArray[3]);
    }

    const headerRow = `| ${padString("Event", maxStringLengthArray[0])} | ${padString(
      "Venue",
      maxStringLengthArray[1]
    )} | ${padString("Date/Time", maxStringLengthArray[2])} | ${padString(
      "Duration",
      maxStringLengthArray[3]
    )} |`;

    const separatorRow = `|${"-".repeat(maxStringLengthArray[0] + 2)}|${"-".repeat(
      maxStringLengthArray[1] + 2
    )}|${"-".repeat(maxStringLengthArray[2] + 2)}|${"-".repeat(maxStringLengthArray[3] + 2)}|`;

    const eventRows = arr.map(
      (evt) =>
        `| ${padString(evt.eventName, maxStringLengthArray[0])} | ${padString(
          evt.venue,
          maxStringLengthArray[1]
        )} | ${padString(evt.eventDate, maxStringLengthArray[2])} | ${padString(
          `${evt.duration} hours`,
          maxStringLengthArray[3]
        )} |`
    );

    return [headerRow, separatorRow, ...eventRows].join("\n");
  }
  function createOverTimeWarningMessage(events, totalHours) {
    const warningMessage = `Total hours: ${totalHours}\r\nHere are the other events that lead to overtime:\n\n${createOverTimeEventTextTable(
      events
    )}`;

    return warningMessage;
  }

  const { mutate: enrollUserMutation } = useMutation(isPartner ? enrollPartnerToEvent : enrollUserToEvent, {
    onSuccess: async ({ data: response }, variables) => {
      if (response.success) {
        const { message = "", status: messageStatus = "" } = response;
        if (["Roster", "Waitlist"].includes(response.type) && response.overlappingEvent) {
          const ol = response.overlappingEvent;
          const noteMessage = (
            <>
              {message}
              <br />
              <br />
              HOWEVER, the following event is conflicting:
              <br />
              <br />
              Event: {ol.eventName}
              <br />
              Venue: {ol.venue}
              <br />
              Date/Time: {ol.eventDate}
            </>
          );
          const copyMessage = `
           ${message}
          
           HOWEVER, the following event is conflicting:
          
           Event: ${ol.eventName}
        
           Venue: ${ol.venueName}
         
           Date/Time: ${ol.eventDate}
           `;
          enqueueSnackbar("", {
            variant: "warning",
            autoHideDuration: 15000,
            persist: true,
            action: (key) => (
              <CustomNotification message={noteMessage} key={key} copyMessage={copyMessage} />
            ),
          });
        } else if (["Not Roster"].includes(response.type) && response?.overTimeEventList?.length) {
          const ol = response.overlappingEvent;
          const noteMessage = (
            <>
              {message}
              <br />
              <br />
              Total hours: {response.totalHours}
              <br />
              Here are the other events that lead to overtime:
              <br />
              <br />
              <OverTimeEventLines arr={response.overTimeEventList} />
            </>
          );
          const copyMessage = [
            message,
            createOverTimeWarningMessage(response.overTimeEventList, response.totalHours),
          ].join("\n");
          enqueueSnackbar("", {
            variant: "warning",
            autoHideDuration: 15000,
            persist: true,
            action: (key) => (
              <CustomNotification
                message={noteMessage}
                key={key}
                copyMessage={copyMessage}
                extraButtons={
                  <Button
                    variant="contained"
                    sx={{ marginLeft: '1rem'}}
                    onClick={() => {
                      const body = {
                        ...(variables?.body ?? {}),
                        overtimeApproved: true,
                      };
                      enrollUserMutation(
                        {
                          ...variables,
                          body,
                        },
                        {
                          onSuccess: () => {
                            onUpdate?.();
                          },
                        }
                      );
                      closeSnackbar(key);
                    }}
                  >
                    Approve Overtime
                  </Button>
                }
              />
            ),
          });
        } else {
          enqueueSnackbar(message, {
            variant: messageStatus.toLowerCase(),
            autoHideDuration: 5000,
          });
        }
        if (response?.removalResult && response?.removalResult.length > 0) {
          const eventBlock = response?.removalResult.map((res) => {
            const ol = res.otherWaitlist;
            return (
              <>
                <br />
                <br />
                Event: {ol.eventName}
                <br />
                Venue: {ol.venueName}
                <br />
                Date/Time: {moment(ol.eventDate).format("dddd MMM Do hh:mm a")}
              </>
            );
          });
          const noteMessage = (
            <>
              {message}
              <br />
              <br />
              NOTE, the following waitlisted events have been removed:
              {eventBlock}
            </>
          );

          const copyMessageSection = response?.removalResult.map((res) => {
            const ol = res.otherWaitlist;
            return `
            Event: ${ol.eventName}
            Venue: ${ol.venueName}
            Date/Time: ${moment(ol.eventDate).format("dddd MMM Do hh:mm a")}
            `;
          });
          const copyMessage = `
          ${message}
         
          NOTE, the following waitlisted events have been removed:
         
          ${copyMessageSection.join("\n\n")}
          `;
          enqueueSnackbar("", {
            variant: "info",
            autoHideDuration: 15000,
            persist: true,
            action: (key) => (
              <CustomNotification message={noteMessage} key={key} copyMessage={copyMessage} />
            ),
          });
        }
      }
    },
    mutationKey: "eventStatusMutation",
  });

  const handleUpdate = useCallback(
    (newStatus) => {
      const body = {
        requestType: newStatus,
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
      };
      enrollUserMutation(
        {
          eventUrl: event?.eventUrl,
          applicantId: activeApplicant._id,
          body,
        },
        {
          onSuccess: () => {
            onUpdate?.();
          },
        }
      );
    },
    [activeApplicant, currentLoggedUser, enrollUserMutation, event, onUpdate]
  );

  const defaultIconProps = (iconStatus) => ({
    fontSize: "medium",
    color: statusColor[iconStatus],
  });

  const options = [
    {
      text: APPLICANT_EVENT_STATUS_ENUM.ROSTER,
      icon:
        iconType === ICON_TYPE_ENUM.SIMPLE ? (
          <Stadium {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.ROSTER)} />
        ) : (
          <Check {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.ROSTER)} />
        ),
      handleClick: () => handleUpdate(APPLICANT_EVENT_STATUS_ENUM.ROSTER),
      disabled: APPLICANT_EVENT_STATUS_ENUM.ROSTER === status || isInvalid,
    },
    {
      text: APPLICANT_EVENT_STATUS_ENUM.REQUEST,
      icon:
        iconType === ICON_TYPE_ENUM.SIMPLE ? (
          <Stadium {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.REQUEST)} />
        ) : (
          <Check {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.REQUEST)} />
        ),
      handleClick: () => handleUpdate(APPLICANT_EVENT_STATUS_ENUM.REQUEST),
      disabled: APPLICANT_EVENT_STATUS_ENUM.REQUEST === status || isInvalid,
    },
    {
      text: APPLICANT_EVENT_STATUS_ENUM.WAITLIST,
      icon:
        iconType === ICON_TYPE_ENUM.SIMPLE ? (
          <Stadium fontSize="medium" color={statusColor[APPLICANT_EVENT_STATUS_ENUM.WAITLIST]} />
        ) : (
          <HourglassTop
            {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.WAITLIST)}
            color="secondary"
          />
        ),
      handleClick: () => handleUpdate(APPLICANT_EVENT_STATUS_ENUM.WAITLIST),
      disabled: APPLICANT_EVENT_STATUS_ENUM.WAITLIST === status || isInvalid,
    },
    {
      text: APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER,
      icon:
        iconType === ICON_TYPE_ENUM.SIMPLE ? (
          <Stadium {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER)} />
        ) : (
          <Close {...defaultIconProps(APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER)} color="error" />
        ),
      handleClick: () => handleUpdate(APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER),
      disabled: APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER === status,
    },
  ];

  const name = isPartner
    ? activeApplicant?.name
    : `${activeApplicant?.firstName} ${activeApplicant?.lastName}`;

  return (
    <>
      <Tooltip
        title={`${name} is ${
          status === APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER ? `not on the Roster.` : `on ${status}`
        }`}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (!viewOnly) {
              setAnchorEl(e.currentTarget);
            }
          }}
          sx={{ ...(viewOnly && { cursor: "default" }) }}
        >
          {iconType === ICON_TYPE_ENUM.SIMPLE ? (
            <Event fontSize="large" color={statusColor[status]} />
          ) : (
            <EventStatusIcon status={status} />
          )}
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
    </>
  );
};

export default EventStatusButton;
