import * as yup from "yup";

export const taskSchema = yup.object().shape({
    taskName: yup.string().required("Task Name is required").nullable(),
    assignedByName: yup.object().required("Assigned by is required").nullable(),
    status: yup.string().required("Status by is required").nullable(),
    taskType: yup.string().required("Task type is required").nullable(),
    taskDescription: yup.string().required("Task type is required").nullable(),
    createdDate: yup.date().required("Created date is required").nullable(),
    dueDate: yup.date().nullable(),
});