import { useEffect } from "react";
import {
  AccountCircle,
  AttachFile,
  Business,
  DateRange,
  Description,
  Email,
  FolderShared as FolderSharedIcon,
  Stadium,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Card, Grid, IconButton, Stack, styled, Tooltip } from "@mui/material";
import moment from "moment";
import WorkIcon from "@mui/icons-material/Work";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import { useAppContextController } from "context/AppContext";
import ApplicantForm from "./ApplicantForm";

const userPanelAllowedStatus = ["Employee"];

const ApplicantMainInfoPanel = ({
  setPanelSelect,
  panelSelect,
  boarding,
  setBoarding,
  filters,
  parent = "Applicant",
  setFilters,
  useOrTags,
  setUseOrTags,
  defaultPanel,
}) => {
  const { currentApplicant, company } = useAppContextController();
  const isEdit = !!currentApplicant?._id;
  const isCompany = company?.companyType === "Company";
  const isUserPanelVisible = userPanelAllowedStatus.includes(currentApplicant?.status);

  const handleClick = (selection) => {
    setPanelSelect(selection);
    setBoarding(false);
  };

  const handleBoarding = () => {
    setBoarding(true);
    setPanelSelect("activities");
  };

  const applicantTabs = [
    {
      title: "Activities",
      icon: <TimelineIcon />,
      hasShadow: panelSelect === "activities" && !boarding ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handleClick("activities"),
      hidden: false,
    },
    {
      title: "Venues",
      icon: <Stadium />,
      hasShadow: panelSelect === "venues" ? "emd" : "",
      color: "info",
      ariaLabel: "stadium",
      fn: () => handleClick("venues"),
      hidden: isCompany,
    },
    {
      title: "Companies",
      icon: <Business />,
      hasShadow: panelSelect === "companies" ? "emd" : "",
      color: "info",
      ariaLabel: "business",
      fn: () => handleClick("companies"),
      hidden: !isCompany,
    },
    {
      title: "Jobs",
      icon: <WorkIcon />,
      hasShadow: panelSelect === "jobs" ? "emd" : "",
      color: "warning",
      ariaLabel: "work",
      fn: () => handleClick("jobs"),
    },
    {
      title: isCompany ? "Interview" : "Events",
      icon: <DateRange />,
      hasShadow: panelSelect === "orientation" ? "emd" : "",
      color: "success",
      ariaLabel: "date",
      fn: () => handleClick("orientation"),
    },
    {
      title: "Onboarding",
      icon: <FolderSharedIcon />,
      hasShadow: boarding ? "emd" : "",
      color: "error",
      ariaLabel: "folderShared",
      fn: handleBoarding,
    },
    {
      title: "Notes",
      icon: <Description />,
      hasShadow: panelSelect === "note" ? "emd" : "",
      color: "",
      ariaLabel: "description",
      fn: () => handleClick("note"),
    },
    {
      title: "User Account",
      icon: <AccountCircle color="info" />,
      hasShadow: panelSelect === "user" ? "emd" : "",
      color: "",
      ariaLabel: "userPanel",
      fn: () => handleClick("user"),
      hidden: !isUserPanelVisible,
    },
    {
      title: "Messages",
      icon: <Email />,
      hasShadow: panelSelect === "message" ? "emd" : "",
      color: "warning",
      ariaLabel: "Email",
      fn: () => handleClick("message"),
    },
    {
      title: "Attachments",
      icon: <AttachFile />,
      hasShadow: panelSelect === "attachments" ? "emd" : "",
      color: "dark",
      ariaLabel: "attachments",
      fn: () => handleClick("attachments"),
    },
    {
      title: "Additional Info",
      icon: <NoteAddIcon />,
      hasShadow: panelSelect === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "additionalInfo",
      fn: () => handleClick("additionalInfo"),
      hidden: !isCompany,
    },
  ];

  useEffect(() => {
    if (!isEdit) {
      setPanelSelect("");
    } else {
      setPanelSelect(defaultPanel ?? "activities");
    }
  }, [isEdit, setPanelSelect]);

  return (
    <>
      <StyledCard key={`card_${currentApplicant?._id}`}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} desktop={5.5}>
            <MDBox display="flex" mx={2} mt={3} alignItems="center">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="white"
                color="white"
                borderRadius="xl"
                ml={1}
              >
                <PictureAvatar
                  image={currentApplicant?.profileImg}
                  firstName={currentApplicant?.firstName}
                  lastName={currentApplicant?.lastName}
                  userId={currentApplicant?.userRecordId}
                  size="md"
                />
              </MDBox>
              <MDBox>
                <MDBox>
                  <MDTypography variant="h5" color="dark" sx={{ mr: 1 }}>
                    {isEdit ? (
                      `${currentApplicant?.firstName} ${currentApplicant?.lastName}`
                    ) : (
                      <>Create {parent}</>
                    )}
                  </MDTypography>
                  {isEdit && (
                    <MDTypography variant="p" fontSize={14}>
                      Last Update:
                      {moment(currentApplicant?.modifiedDate).format("ddd MM/DD/YYYY hh:mm a")}
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
          {isEdit && (
            <Grid item xs={12} desktop={6.5}>
              <StyledStack>
                {applicantTabs
                  .filter((tab) => !tab?.hidden)
                  .map((tab, i) => {
                    const index = i;
                    return (
                      <Tooltip title={tab?.title} key={`${tab?.title}_index`}>
                        <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow}>
                          <IconButton color={tab.color} aria-label={tab.ariaLabel} onClick={tab.fn}>
                            {tab.icon}
                          </IconButton>
                        </MDBox>
                      </Tooltip>
                    );
                  })}
              </StyledStack>
            </Grid>
          )}
        </Grid>
        <ApplicantForm
          parent={parent}
          setFilters={setFilters}
          filters={filters}
          useOrTags={useOrTags}
          setUseOrTags={setUseOrTags}
          panelSelect={panelSelect}
        />
      </StyledCard>
    </>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  gap: 5,
  justifyContent: "flex-end",
  marginRight: 20,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  overflow: "visible",
  height: 850,
}));

export default ApplicantMainInfoPanel;
