import axios from "axios";

import { baseAxios } from "config";

const fetchSingleEvent = async ({ eventId }) => {
  try {
    if (!eventId) return null;
    const res = await baseAxios.get(`/events/${eventId}`);

    return res?.data?.event;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchSingleEvent;
