// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import ChatIcon from '@mui/icons-material/Chat';

const TasksTableActions = ({ id, row, setCurrentTask, setTaskModalOpen, setTaskMode }) => {
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    setCurrentTask(row);
    switch (action) {
      case "tasksinfo":
        // navigate(`/tasks/${id}/action/${action}`);
        setTaskMode("edit");
        setTaskModalOpen(true);
        break;
      // case "tasksall":
      //   navigate(`/tasks/${id}/action/${action}`);
      //   break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("tasksinfo", e)}
        tooltip="Task Info"
      />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("tasksall", e)}
        tooltip="All Task Panels"
      /> */}
    </div>
  );
};

export default TasksTableActions;

TasksTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
TasksTableActions.propTypes = {
  id: PropTypes.string,
};
