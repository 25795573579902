import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Box, Card, Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import VenueStatusButton from "components/VenueStatusButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";
import VenuesTableActions from "layouts/pages/venues/components/VenuesTableActions";
import fetchUserVenues from "layouts/pages/venues/actions/fetchUserVenues";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import fetchVenuesByZipCode from "layouts/pages/venues/actions/fetchVenuesByZipcode";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import useSessionAuth from "hooks/useSessionAuth";
import usePreferredPageSize from "hooks/usePreferredPageSize";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const VenuesTable = ({
  slug,
  fetchAll = true,
  setNavigateUrl,
  title = "Venues Table",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  showVenueStatus = false,
  currentApplicant = null,
  setOpen,
  setVenuePreview,
  refreshVenues,
}) => {
  const queryClient = useQueryClient();
  const classes = useStyle();
  const navigate = useNavigate();
  const {
    venues: venueCache,
    setVenues,
    setCurrentVenue,
    currentVenue,
    userType,
    currentLoggedUser,
    company,
  } = useAppContextController();

  const { action } = useParams();
  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  // const [prevPage, setPrevPage] = useState(1)
  const { order, orderBy, toggleSort } = useSort();
  const [userApplicant, setUserApplicant] = useState(null);
  const [userVenues, setUserVenues] = useState(null);
  const [refetchApplicant, setRefetchApplicant] = useState(false);
  const [lockedVenues, setLockedVenues] = useState([]);

  let imageUrl;
  if (company) imageUrl = company.imageUrl;
  const options = fetchAll ? { fetchAll, imageUrl } : { page, limit, order, orderBy };
  const allOptions =
    userType !== "User"
      ? { ...options, filters, setVenues, imageUrlBase: company?.imageUrl }
      : {
        ...options,
        filters: { slug: filters?.slug || userVenues },
        imageUrlBase: company?.imageUrl,
      };

  if (userType === "Client" || userType === "Event Admin") {
    const clientOrgSlugs = currentLoggedUser?.clientOrgs?.map((item) => item.slug);
    allOptions.filters = { slug: clientOrgSlugs?.join(";") };
  }

  const {
    data: venues,
    isLoading,
    refetch: refetchVenues,
  } = useQuery(
    ["myvenues", allOptions],
    () => (userType === "User" ? fetchUserVenues(allOptions) : fetchVenues(allOptions)),
    {
      // The select function will receive the data returned by the query function
      select: (data) => {
        if (userType === "User" && lockedVenues?.length) {
          const returnObj = {
            ...data,
            data: data.data?.filter(
              (item) => !lockedVenues.includes(item.slug) && item.status === "Active"
            ),
          };
          return returnObj;
        }
        const returnObj2 = {
          ...data,
          data: data.data?.filter((item) =>
            ["Active", "Inactive", "Prospect"].includes(item.status)
          ),
        };
        return returnObj2; // data.data.filter((item) => !lockedVenues.includes(item.slug));
      },
    }
  );

  const setPreviewOpen = (venue, e) => {
    e.stopPropagation();
    setVenuePreview(venue);
    setOpen(true);
  };


  useEffect(() => {
    // alert('firing!')
    refetchVenues();
  }, [refreshVenues]);

  const { logoutAuth0User } = useSessionAuth();

  const refetch = () => {
    setRefetchApplicant((prev) => !prev);
  };
  useEffect(() => {
    const fetchUserApplicant = async (id) => {
      const app = await fetchSingleApplicant({ applicantId: id });
      if (app?.data) {
        setUserApplicant(app.data);
        const locked = app?.data?.venues
          ?.filter((item) => item.venueSlug && item.status === "Locked")
          .map((item) => item.venueSlug);
        setLockedVenues(locked);
      }
    };
    if (userType === "User" && currentLoggedUser) {
      try {
        fetchUserApplicant(currentLoggedUser.applicantId);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [currentLoggedUser?.applicantId, userType, refetchApplicant]);

  useEffect(() => {
    if (userApplicant?.venues?.length) {
      const vens = userApplicant.venues
        .filter((item) => item.status !== "Locked")
        .map((item) => item.venueSlug)
        .join(";");
      setUserVenues(vens);
    }
  }, [userApplicant]);

  const localVenues = venueCache ? Object.entries(venueCache).map((item) => item[1]) : [];
  useEffect(() => {
    if (!isLoading && localVenues?.length && slug) {
      const ndx = localVenues.findIndex((item) => item.slug === slug);
      if (ndx > -1) {
        setCurrentVenue(localVenues[ndx]);
      }
    }
  }, [isLoading, slug]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const columns = [
    {
      title: "Logo",
      field: "logoUrl",
      imageWidth: "50",
      imageHeight: "50",
      customCell: (logoUrl, field, row) => (
        <VenueIcon
          logoUrl={logoUrl}
          slug={row.slug}
          name={row.name}
          imageWidth="50"
          imageHeight="50"
          goToEnabled={false}
          setOpenVenueModal={() => {
            setVenuePreview(row);
            setOpen(true);
          }}
        />
      ),
    },
    ...(showVenueStatus && currentApplicant
      ? [
        {
          title: "Venue",
          field: "slug",
          customCell: (vslug, field, row) => (
            <VenueStatusButton
              status={getApplicantVenueStatus(currentApplicant, vslug)}
              venue={row}
              onUpdate={() => refetchVenues()}
            />
          ),
        },
      ]
      : []),
    ...(userType === "User" && userApplicant
      ? [
        {
          title: "Status",
          field: "slug",
          customCell: (vslug, field, row) => (
            <>
              <VenueStatusButton
                status={getApplicantVenueStatus(userApplicant, vslug)}
                venue={row}
                viewOnly
              />
            </>
          ),
        },
      ]
      : []),
    {
      title: "Venue ID",
      field: "slug",
      customCell: (thisSlug) => <span>{thisSlug?.toUpperCase()}</span>,
    },
    { title: "Venue Name", field: "name" },
    // { title: "Venue URL", field: "venueUrl" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
    {
      title: "Actions",
      noSort: true,
      field: "slug",
      customCell: (thisSlug, field, row) => (
        <VenuesTableActions
          slug={thisSlug}
          row={row}
          setNavigateUrl={navigate}
          setFilters={setFilters}
          setPreviewOpen={setPreviewOpen}
        />
      ),
    },
  ];

  const userColumns = [
    {
      title: "Status",
      field: "slug",
      customCell: (vslug) => {
        return (
          <ApplicantVenueStatus
            id={userApplicant?._id}
            venue={vslug}
            slugField="venueSlug"
            row={userApplicant}
            // invalidQuery={["venues", allOptions]}
            blockUpdate
            mode="Check"
            setRequery={refetch}
          />
        );
      },
      width: "15%",
    },
    {
      title: "Logo",
      field: "logoUrl",
      imageWidth: "50",
      imageHeight: "50",
      customCell: (logoUrl, row) =>
        row ? (
          <VenueIcon
            logoUrl={logoUrl}
            slug={row?.slug}
            name={row?.name}
            imageWidth="50"
            imageHeight="50"
          />
        ) : null,
      width: "15%",
    },
    {
      title: "Venue Name",
      field: "name",
      customCell: (name) => `${name.length > 20 ? name.substring(0, 20) : name}...`,
      width: "40%",
    },
    {
      title: "City/State",
      field: "city",
      customCell: (city, row) => `${city}, ${row?.state}`,
      width: "30%",
    },
  ];

  const handleRowClick = (venue, e) => {
    setCurrentVenue(venue);
    setNavigateUrl(`/venues/${venue.slug}`);
    e.stopPropagation();
  };

  const addNew = (e) => {
    if (!currentVenue) {
      setCurrentVenue({});
    }
    navigate("/venues/create");
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <FlexWrapper mt={0} mb={0} rowGap="20px" flexDirection={{ xs: "column", sm: "row" }}>
            <Box
              display="flex"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  alignSelf: "flex-start",
                },
              })}
            >
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="info"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">stadium</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {title}
              </MDTypography>
            </Box>
            <MDBox ml={3}>
              {userType !== "User" &&
                userType !== "Client" &&
                userType !== "Event Admin" &&
                showFiltersList && (
                  <Grid item>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
            </MDBox>
          </FlexWrapper>
        </Grid>
        <Grid
          item
          display="flex"
          alignItems="center"
          sx={{ maxWidth: "600px" }}
          xs={12}
          sm={8}
          lg={5}
        >
          <FlexWrapper
            flexWrap="nowrap"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ mx: "15px", rowGap: "10px" }}
          >
            {showSearchBar && userType !== "Client" && userType !== "Event Admin" && (
              <Searchbar
                fetch={userType === "User" ? fetchUserVenues : fetchVenues}
                fetchAll={false}
                placeholder="Search Venue"
                columns={userColumns}
                queryCharacterLimit={2}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                onRowClick={(item) => {
                  if (userType !== "User") navigate(`/venues/${item.slug}/action/venueinfo`);
                }}
                searchBy={["slug", "name", "city"]}
                filterBy="slug"
                idField="slug"
                filterData={
                  userType === "User"
                    ? (item) => {
                      return item.status === "Active" && !lockedVenues.includes(item.slug);
                    }
                    : undefined
                }
              />
            )}
            {userType === "User" && showSearchBar && (
              <Searchbar
                fetch={fetchVenuesByZipCode}
                fetchAll={false}
                placeholder="My Zipcode"
                columns={userColumns}
                queryCharacterLimit={5}
                resultsLimit={12}
                scrollLimit={50}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["zip"]}
                useRestParams
                filterBy="slug"
                autoCenter={false}
                filterData={(item) => {
                  return item.status === "Active" && !lockedVenues.includes(item.slug);
                }}
              />
            )}
          </FlexWrapper>
          {userType !== "User" && userType !== "Client" && userType !== "Event Admin" && (
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={(e) => addNew(e)}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          )}
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={venues}
          selected={currentVenue}
          onRowClick={
            userType === "Client" || userType === "Event Admin"
              ? setPreviewOpen
              : (row, e) => (userType !== "User" ? handleRowClick(row, e) : () => { })
          }
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
          defaultSort="name"
          greybar
          divider
        />
      </MDBox>
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
VenuesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: true,
  setNavigateUrl: () => { },
  title: "Venues",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
VenuesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default VenuesTable;
