import { useMemo } from "react";
import { AccountCircle } from "@mui/icons-material";
import { Box, Card, CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import { useAppContextController } from "context/AppContext";
import PanelSelectorBox from "layouts/pages/profile/components/PanelSelectorBox";
import UserPanelForm from "layouts/pages/applicants/components/UserPanel/UserPanelForm";
import fetchUserByEmail from "layouts/pages/users/actions/fetchUserByEmail";
import moment from "moment";
import { useQuery } from "react-query";
import UserPanelCreate from "../UserPanelCreate";

const UserPanel = ({
  currentApplicant,
  profilePanel,
  userIdentityVerification,
  identityVerificationColor,
  toggleConfirmationModal,
  handleUserIdentityUpdate,
}) => {
  const { company, currentApplicant: currentApplicant2 } = useAppContextController();
  const isCompany = company?.companyType === "Company";

  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useQuery(
    ["getUserDetails", currentApplicant?.email || currentApplicant?.emailAddress],
    () => fetchUserByEmail(currentApplicant?.email || currentApplicant?.emailAddress),
    { enabled: !!currentApplicant?.email || !!currentApplicant?.emailAddress }
  );

  const defaultValues = useMemo(() => {
    if (user) {
      return isCompany
        ? {
            status: user?.status || "",
            createdDate: user?.createdDate || null,
            startDate: user?.startDate || null,
            endDate: user?.endDate || null,
            emailAddress: user?.emailAddress || "",
            loginVerified: user?.loginVerified || "no",
            emailVerified: user?.applicant.emailVerified || "no", // todo
          }
        : {
            status: user?.status || "",
            employeeType: user?.employeeType || "Event Staff",
            createdDate: user?.createdDate || currentApplicant.createdDate || null,
            startDate: user?.startDate || null,
            endDate: user?.endDate || null,
            emailAddress: user?.emailAddress || "",
            loginVerified: user?.loginVerified || "no",
            emailVerified: user?.applicant.emailVerified || "no", // todo
          };
    }
    return {};
  }, [user]);

  return (
    <>
      {!profilePanel ? (
        <Card sx={{ maxHeight: 850, minHeight: 850 }}>
          <Box>
            <Grid container spacing={0} alignItems="center">
              <Grid item sm={12} xl={5.5}>
                <MDBox display="flex" mx={2} mt={3} alignItems="center">
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="white"
                    color="white"
                    borderRadius="xl"
                    ml={1}
                  >
                    <PictureAvatar
                      image={currentApplicant?.profileImg}
                      firstName={currentApplicant?.firstName}
                      lastName={currentApplicant?.lastName}
                      userId={currentApplicant?.userRecordId}
                      size="md"
                    />
                  </MDBox>
                  <MDBox>
                    <MDBox>
                      <MDTypography variant="h5" color="dark" sx={{ mr: 1 }}>
                        {currentApplicant?.firstName} {currentApplicant?.lastName}
                      </MDTypography>
                      {user && (
                        <MDTypography variant="p" fontSize={14}>
                          Last Login Date:
                          {moment(currentApplicant?.lastLoginDate).format("ddd MM/DD/YYYY hh:mm a")}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            {user ? (
              <>
                {isUserLoading ? (
                  <Box display="flex" justifyContent="center" mt={3}>
                    <CircularProgress color="info" />
                  </Box>
                ) : (
                  <UserPanelForm
                    currentApplicant={currentApplicant}
                    defaultValues={defaultValues}
                    user={user}
                    title="User Account Information"
                    hasDeleteButton
                  />
                )}
              </>
            ) : (
              <UserPanelCreate currentApplicant={currentApplicant} />
            )}
          </Box>
        </Card>
      ) : (
        <PanelSelectorBox
          title="User Account"
          icon={<AccountCircle color="white" />}
          iconColor="info"
          cardVariant="outlined"
        >
          {isUserLoading ? (
            <Box display="flex" justifyContent="center" mt={3}>
              <CircularProgress color="info" />
            </Box>
          ) : (
            <UserPanelForm
              currentApplicant={currentApplicant}
              defaultValues={defaultValues}
              user={user}
              title="User Login"
              showVerifiedFlags
              userIdentityVerification={userIdentityVerification}
              identityVerificationColor={identityVerificationColor}
              toggleConfirmationModal={toggleConfirmationModal}
              handleUserIdentityUpdate={handleUserIdentityUpdate}
            />
          )}
        </PanelSelectorBox>
      )}
    </>
  );
};

export default UserPanel;
