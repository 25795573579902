import { useQueryClient } from "react-query";
import { Box, Button, Grid, Stack } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import CancelIcon from "@mui/icons-material/Cancel";
import { Search } from "@mui/icons-material";
import CustomTextField from "components/Form/CustomTextField";
import TagList from "components/TagList";
import { jobStatusSearchAllSchema } from "data/jobStatus";
import {
  JOB_SEARCH_ALL_MAX_DISTANCE,
  JOB_SEARCH_ALL_MAX_RESULTS,
  JOB_SEARCH_ALL_MIN_DISTANCE,
  JOB_SEARCH_ALL_MIN_RESULTS,
} from "utils/constants/job";
import { milesToMeters } from "utils/helpers/conversion";

const ApplicantsSearchAllModal = ({ open, setOpen, currentJob, onSubmit }) => {
  const resolver = useYupValidationResolver(jobStatusSearchAllSchema);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    numberOfCandidates: 10,
    geoRadius: 20,
    jobSkills: currentJob?.tags ?? [],
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    setError,
    formState,
  } = useForm({ mode: "onBlur", resolver, defaultValues });
  const { errors, isSubmitting, dirtyFields, isDirty } = formState;
  const modalTitle = (
    <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1 }}>
      Search Applicants for {currentJob?.title}
    </MDTypography>
  );

  const searchHandler = async (values) => {
    const { numberOfCandidates, geoRadius, jobSkills } = values;
    const radiusInMeters = milesToMeters(geoRadius);
    onSubmit(numberOfCandidates, jobSkills, radiusInMeters);
  };

  const modalBody = (
    <form onSubmit={handleSubmit(searchHandler)}>
      <Button
        sx={{
          position: "absolute",
          top: "0%",
          right: "0%",
          m: 1,
          p: 0,
        }}
        onClick={() => {
          reset(defaultValues);
          setOpen(false);
        }}
      >
        <CancelIcon color="secondary" />
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="numberOfCandidates"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                onChange={(e) => setValue("numberOfCandidates", e.target.value)}
                label="Number of Candidates"
                type="number"
                {...field}
                InputLabelProps={{ shrink: true }}
                showError
                errorMessage={error?.message}
                inputProps={{ min: JOB_SEARCH_ALL_MIN_RESULTS, max: JOB_SEARCH_ALL_MAX_RESULTS }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="geoRadius"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                onChange={(e) => setValue("geoRadius", e.target.value)}
                label="Radius (miles)"
                type="number"
                {...field}
                InputLabelProps={{ shrink: true }}
                showError
                errorMessage={error?.message}
                inputProps={{ min: JOB_SEARCH_ALL_MIN_DISTANCE, max: JOB_SEARCH_ALL_MAX_DISTANCE }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box>
            <Controller
              name="jobSkills"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TagList
                  {...field}
                  label="Job Skills"
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  currentComponent={currentJob}
                  errorMessage={error?.message}
                />
              )}
            />
            {errors?.invalidTag && (
              <MDTypography color="error">{errors?.invalidTag?.message}</MDTypography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} mt={10} textAlign="end">
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button variant="text" color="secondary" onClick={() => reset(defaultValues)}>
              Reset
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
              sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              startIcon={<Search />}
              style={{ color: "white" }}
              sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
            >
              Search
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={modalTitle}
        sx={(theme) => ({
          width: "35% !important",
          maxWidth: "50% !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default ApplicantsSearchAllModal;
