import { Box, Icon } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useParams } from "react-router-dom";

import logo from "assets/images/SP-App-Store-Icon.png";
import CustomCard from "components/CustomCard";
import { useAppContextController } from "context/AppContext";
import EventForm from "./EventForm";

const StyledImageBox = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  objectFit: "contain",
}));

const iconCreateContainerStyle = {
  backgroundColor: "success",
  color: "white",
};

const iconEditContainerStyle = {
  backgroundColor: "transparent",
  color: "transparent",
};

const cardStlyle = {
  width: "100%",
  overflow: "visible !important",
  mt: "50px",
};

const EventInfoPanel = ({ setEventPreview, setOpen, setPreviewContent, getVenueField }) => {
  const { currentEvent: event, venues } = useAppContextController();
  const { createVenueSlug } = useParams();
  const [eventLogo, setEventLogo] = useState("");
  const [copyEventName, setCopyEventName] = useState("");
  const isEdit = !!event?._id;


  const createEventSuffix = () => {
    let suffix = "";
    if (copyEventName) suffix = ` from ${copyEventName}`;
    if (createVenueSlug)
      suffix = ` at ${getVenueField(createVenueSlug, "name") ? getVenueField(createVenueSlug, "name") : ""
        }`;
    return suffix;
  };

  return (
    <CustomCard
      // icon={isEdit
      //   ? <StyledImageBox component="img" alt="Venue Logo" src={event?.logoUrl ? eventLogo : logo} />
      //   : <Icon fontSize="large">event-seat</Icon>
      // }
      cardTitle={
        isEdit ? `Event Information: ${event?.eventName}` : `Create Event ${createEventSuffix()}`
      }
      // iconContainerSx={isEdit ? iconEditContainerStyle : iconCreateContainerStyle}
      cardSx={cardStlyle}
    // cardActions={}
    >
      <Box mt={-5}>
        <EventForm
          getVenueField={getVenueField}
          setEventPreview={setEventPreview}
          setPreviewContent={setPreviewContent}
          setOpen={setOpen}
          setEventLogo={setEventLogo}
          setCopyEventName={setCopyEventName}
        />
      </Box>
    </CustomCard>
  );
};

export default EventInfoPanel;
