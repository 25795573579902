import {
  JOB_SEARCH_ALL_MAX_DISTANCE,
  JOB_SEARCH_ALL_MAX_RESULTS,
  JOB_SEARCH_ALL_MIN_DISTANCE,
  JOB_SEARCH_ALL_MIN_RESULTS,
} from "utils/constants/job";
import * as yup from "yup";

export const jobStatusSearchAllSchema = yup.object().shape({
  numberOfCandidates: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .required("Number of candidates is required")
    .min(
      JOB_SEARCH_ALL_MIN_RESULTS,
      `Number of candidates must be at least ${JOB_SEARCH_ALL_MIN_RESULTS}`
    )
    .max(
      JOB_SEARCH_ALL_MAX_RESULTS,
      `Number of candidates must be ${JOB_SEARCH_ALL_MAX_RESULTS} or less`
    )
    .typeError("Number of candidates must be a number"),
  geoRadius: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .required("Radius is required")
    .min(JOB_SEARCH_ALL_MIN_DISTANCE, `Radius must be at least ${JOB_SEARCH_ALL_MIN_DISTANCE}`)
    .max(JOB_SEARCH_ALL_MAX_DISTANCE, `Radius must be ${JOB_SEARCH_ALL_MAX_DISTANCE} or less`)
    .typeError("Radius must be a number"),
  jobSkills: yup.array().min(1, "Must select at least one skill").required("Required"),
});
