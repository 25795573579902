import { useAuth0 } from "@auth0/auth0-react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import fetchEmailTemplates from "context/actions/fetchEmailTemplates";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import updateUserById from "layouts/pages/users/actions/updateUserById";
import { isEmployeeUser } from "utils/helpers/roles";
import storeToken from "api/auth/storeToken";
import useSessionAuth from "hooks/useSessionAuth";

// The Soft UI Dashboard PRO Material main context
export const AppContext = createContext();

// Material Dashboard 2 PRO React context provider
function AppContextProvider({ children }) {
  const { isLoading, email, user, getAccessTokenSilently } = useAuth0();
  const { handleStoreToken, logoutAuth0User } = useSessionAuth();

  const initialState = {
    currentVenue: null,
    currentJob: null,
    currentApplicant: null,
    currentEvent: null,
    currentCampaign: null,
    currentPartner: null,
    currentActivity: null,
    currentIssue: null,
    currentLoggedUser: null,
    currentUser: null,
    currentTask: null,
    currentReport: null,
    currentCompany: null,
    // TODO:  set this to 'U' after login is working
    backendEnv: "P",
    userType: "User",
    venues: null,
    unreadNotifications: [],
    emailTemplates: [],
    company: null,
    companyType: "Venue", // to test "Company", change this to "Company", otherwise "Venue"
    imageUrl: null,
    isMobile: /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent),
    isCurrentFormDirty: false,
    isPanelChangesOpen: false,
    changePanel: () => null,
    employeeType: "",
  };

  const [state, setState] = useState(initialState);
  const {
    currentVenue,
    currentJob,
    currentApplicant,
    currentEvent,
    currentCampaign,
    currentPartner,
    currentLoggedUser,
    currentActivity,
    currentIssue,
    currentUser,
    currentTask,
    currentReport,
    currentCompany,
    backendEnv,
    userType,
    venues,
    unreadNotifications,
    emailTemplates,
    company,
    companyType,
    imageUrl,
    isMobile,
    isCurrentFormDirty,
    isPanelChangesOpen,
    changePanel,
    employeeType,
  } = state;

  const setStateVar = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const setVenues = (value) => setStateVar("venues", venues ? { ...venues, ...value } : value);
  const setUnreadNotifications = (value) => setStateVar("unreadNotifications", [...value]);

  const setCurrentVenue = (value) => setStateVar("currentVenue", value);
  const setCurrentJob = (value) => setStateVar("currentJob", value);
  const setCurrentApplicant = (value) => setStateVar("currentApplicant", value);

  const setIsMobile = (value) => setStateVar("isMobile", value);
  const setCurrentEvent = (value) => setStateVar("currentEvent", value);
  const setCurrentCampaign = (value) => setStateVar("currentEvent", value);

  const setCurrentUser = (value) => setStateVar("currentUser", value);
  const setCurrentTask = (value) => setStateVar("currentTask", value);
  const setCurrentReport = (value) => setStateVar("currentReport", value);
  const setCurrentCompany = (value) => setStateVar("currentCompany", value);
  const setCurrentLoggedUser = (value) => setStateVar("currentLoggedUser", value);
  const setCurrentActivity = (value) => setStateVar("currentActivity", value);
  const setCurrentIssue = (value) => setStateVar("currentIssue", value);
  const setCurrentPartner = (value) => setStateVar("currentPartner", value);

  const setBackendEnv = (value) => setStateVar("backendEnv", value);
  const setUserType = (value) => setStateVar("userType", value);
  const setEmailTemplates = (value) => setStateVar("emailTemplates", value);

  const setCompanyType = (value) => setStateVar("companyType", value);
  const setCompany = (value) => setStateVar("company", value);
  const setImageUrl = (value) => setStateVar("imageUrl", value);

  const setCurrentFormDirty = (value) => setStateVar("isCurrentFormDirty", value);
  const setPanelChangesOpen = (value) => setStateVar("isPanelChangesOpen", value);
  const setChangePanel = (value) => setStateVar("changePanel", value);
  
  const setEmployeeType = (value) => setStateVar("employeeType", value);

  const getEmailTemplates = () => {
    const getTemplates = async () => {
      const res = await fetchEmailTemplates();
      if (res?.data?.data) setEmailTemplates(res.data.data);
    };

    try {
      getTemplates();
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const getUserFromEmail = async (em) => {
    const tokenStored = await handleStoreToken();
    if (tokenStored) {
      try {
        const users = await fetchUsers({
          fetchAll: true,
          filters: { emailAddress: em.toLowerCase() },
        });
        if (users && users.data?.length) return users.data[0];
        return null;
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }

    return null;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getUser = async (em) => {
      const dbUser = await getUserFromEmail(em);
      // if this user's gmail image hasn't been set yet, let's do it now
      if (
        user?.picture &&
        user?.email.indexOf("@gmail.com") > -1 &&
        (!dbUser?.profileImg || user.picture !== dbUser?.profileImg)
      ) {
        try {
          await updateUserById({ id: dbUser._id, data: { profileImg: user.picture } });
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }

        dbUser.profileImg = user.picture;
      }

      if (dbUser) {
        if (dbUser.userType === "User" && dbUser.employeeType === "Event Admin") setUserType("Event Admin");
        else setUserType(dbUser.userType);
        setEmployeeType(dbUser.employeeType);
        setCurrentLoggedUser(dbUser);
      }
    };
    if (!isLoading && user?.email) {
      getUser(user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  // Custom hook for checking login and redirecting to the sign-in page if not logged in
  const IsLoggedIn = ({ currUser }) => {
    const [loggedIn, setLoggedIn] = useState(undefined);
    useEffect(() => {
      if (currUser) setLoggedIn(true);
    }, [currUser]);
    return { loggedIn };
  };

  const fetchApplicant = useCallback(
    async (applicantId) => {
      try {
        let response = {};
        if (applicantId) {
          response = await fetchSingleApplicant({ applicantId });
        } else if (currentLoggedUser?.applicantId) {
          response = await fetchSingleApplicant({ applicantId: currentLoggedUser?.applicantId });
        }
        if (response?.status === 200) {
          setCurrentApplicant(response.data);
        } else {
          setCurrentApplicant(null);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
    [currentLoggedUser]
  );

  useEffect(() => {
    (async () => {
      if (isEmployeeUser(userType) && isEmpty(currentApplicant)) {
        fetchApplicant();
      }
    })();
  }, [currentLoggedUser, userType, currentApplicant, fetchApplicant]);

  return (
    <AppContext.Provider
      value={{
        IsLoggedIn,
        venues,
        setVenues,
        currentVenue,
        setCurrentVenue,
        currentJob,
        setCurrentJob,
        currentApplicant,
        setCurrentApplicant,
        currentEvent,
        setCurrentEvent,
        currentCampaign,
        setCurrentCampaign,
        currentLoggedUser,
        currentActivity,
        setCurrentActivity,
        currentIssue,
        setCurrentIssue,
        currentPartner,
        setCurrentPartner,
        setCurrentLoggedUser,
        currentUser,
        setCurrentUser,
        currentTask,
        setCurrentTask,
        currentReport,
        setCurrentReport,
        currentCompany,
        setCurrentCompany,
        setStateVar,
        setState,
        state,
        backendEnv,
        setBackendEnv,
        userType,
        setUserType,
        unreadNotifications,
        setUnreadNotifications,
        emailTemplates,
        setEmailTemplates,
        getEmailTemplates,
        user,
        company,
        setCompany,
        companyType,
        setCompanyType,
        imageUrl,
        setImageUrl,
        isMobile,
        fetchApplicant,
        isCurrentFormDirty,
        setCurrentFormDirty,
        isPanelChangesOpen,
        setPanelChangesOpen,
        changePanel,
        setChangePanel,
        employeeType,
        setEmployeeType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

// Material Dashboard 2 PRO React custom hook for using context
function useAppContextController() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContextController should be used inside the AppContextProvider.");
  }

  return context;
}

// Typechecking props for the AppContextProvider
AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContextProvider, useAppContextController };
