import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import {
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Stack,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import useStyles from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments/styles";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { baseAxios } from "config";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useSnackbar } from "notistack";
import { getCommonBaseImageUrl } from "utils/helpers/upload";

const Attachments = ({ currentApplicant, setOpen, attachmentTitle, noButton = false }) => {
  const {
    company,
    currentApplicant: existingApplicant,
    setCurrentApplicant,
    user,
    currentLoggedUser,
  } = useAppContextController();
  const { setApplicant } = useNewApplicantContext();
  const isUser = currentLoggedUser?.userType === "User";
  const IMAGE_SERVER = company?.imageUrl;
  const styles = useStyles();
  const [files, setFiles] = useState([]);
  const [deletePolicyErr, setDeletePolicyErr] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const size = isMobile ? 70 : 100;

  const queryClient = useQueryClient();
  const uploadUrl = `/upload/${currentApplicant?.slug}/venues/banner`;

  const saveUpdate = async (values) => {
    // if (!currentApplicant._id) {
    //   setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
    //   return;
    // }
    // await updateVenueMutation.mutateAsync({
    //   venueId: currentApplicant._id,
    //   data: values,
    // });
  };

  const renameFileMutation = useMutation(
    async ({ rename, original }) => {
      const url = new URL(
        `/outside-protected/upload/rename/${currentApplicant?.slug}/venues/other`
      );
      url.searchParams.append("rename", rename);
      url.searchParams.append("original", original);
      await baseAxios.patch(url.toString());
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: () => {
        setToastAlert({ isShow: true, message: "Successfully renamed", status: "success" });
      },
    }
  );

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");
      if (existingApplicant) setCurrentApplicant({ ...currentApplicant, ...data });
      else if (currentApplicant) setApplicant({ ...currentApplicant, ...data });
      setFiles(data?.attachments);
      enqueueSnackbar("Attachments updated!", { variant: "success" });
    },
  });

  const addNew = (e) => {
    setOpen(true);
  };

  const deleteFileHandler = async (e, idx) => {
    if (existingApplicant || currentApplicant) {
      // delete from DB
      const tempAttachments = [...files];
      const updatedAttachments = tempAttachments.filter((_, i) => i !== idx);

      await updateApplicantMutation.mutateAsync({
        applicantId: existingApplicant?._id || currentApplicant?._id,
        data: {
          attachments: updatedAttachments,
        },
        outsideMode: user ? "" : "protected",
      });
    }
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];

    // get the image extension
    const imgExt = newFiles[idx].name?.substring(newFiles[idx].name.lastIndexOf("."));

    // check if edited
    const removeExt = newFiles[idx].name?.substring(0, newFiles[idx].name.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;

    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].name;

    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const confirmFileNameChanges = async (e, idx) => {
    await renameFileMutation.mutateAsync({
      original: files[idx].name,
      rename: files[idx].editedName,
    });

    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], name: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);

    await updateApplicant.mutateAsync({
      applicantId: currentApplicant?._id,
      data: { attachments: newFiles.map((img) => img.name) },
    });
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );

  useEffect(() => {
    if (currentApplicant?.attachments?.length) {
      setFiles(
        currentApplicant?.attachments.map((file) => {
          const { docType, filename, name, title, type, uploadDate } = file;
          return { docType, name: filename || name, title, type };
        })
      );
    } else {
      setFiles([]);
    }
  }, [currentApplicant?.attachments]);

  return (
    <MDBox className={styles.otherSection}>
      <Grid container>
        <Grid item lg={10} xs={12} display="flex" alignItems="center">
          {attachmentTitle}
        </Grid>
        {!noButton && (
          <Grid item lg={2} xs={12} display="flex" justifyContent="flex-start">
            {!isMobile ? (
              <IconButton className={styles.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon fontSize="medium" />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{ borderRadius: "5px", mb: 1 }}
                onClick={addNew}
              >
                Upload File
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <MDBox className={styles.section_images_wapper}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={isMobile ? "center" : "flex-start"}
        >
          {files.map((file, idx) => {
            return (
              <Grid item className={styles.section_image_container} key={file.name}>
                <Stack container="true" justifyContent="space-evenly" alignItems="center" mb={3}>
                  {!isUser && currentLoggedUser && (
                    <MDBox
                      style={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                        backgroundColor: "white",
                        padding: 1,
                        borderRadius: "25px",
                      }}
                    >
                      <IconButton
                        color="info"
                        // className={styles.maps_section_image_delete}
                        onClick={(e) => deleteFileHandler(e, idx)}
                      >
                        <Icon fontSize="small">cancel</Icon>
                      </IconButton>
                    </MDBox>
                  )}

                  <MDBox
                    onClick={() => {
                      if (IMAGE_SERVER)
                        window.open(
                          `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.name}`
                        );
                    }}
                  >
                    {IMAGE_SERVER &&
                      ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg", "webp"].includes(
                        file.docType.toLowerCase()
                      ) && (
                        <img
                          src={`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${file.name}`}
                          alt="previews"
                          // className={styles.section_image}
                          height={size}
                        />
                      )}
                    {!!company?.imageUrl &&
                      !!company?.uploadUrl &&
                      file.docType.toLowerCase() === "pdf" && (
                        <img
                          src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                          alt="PDF Preview"
                          width={size}
                          height={size}
                        />
                      )}
                    {!!company?.imageUrl &&
                      !!company?.uploadUrl &&
                      file.docType.toLowerCase() === "docx" && (
                        <img
                          src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                          alt="preview"
                          width={size}
                          height={size}
                        />
                      )}
                  </MDBox>

                  <Box>
                    <MDTypography variant="h6" textAlign="center">
                      {files[idx].type}
                    </MDTypography>
                    <TextField
                      disabled={isUser}
                      defaultValue={file.name?.substring(0, file.name.lastIndexOf("."))}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={files[idx].editedName?.substring(0, file.editedName.lastIndexOf("."))}
                      onInput={(e) => editFileNameHandler(e, idx)}
                    />

                    {file.isEdited && (
                      <>
                        <Tooltip title="Confirm" placement="bottom">
                          <CheckIcon
                            color="success"
                            onClick={(e) => confirmFileNameChanges(e, idx)}
                            fontSize="large"
                            sx={{
                              "&": { transition: "0.1s" },
                              "&:hover": { background: "#D3D3D3" },
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Cancel" placement="bottom">
                          <CloseIcon
                            color="error"
                            onClick={(e) => cancelFileNameChanges(e, idx)}
                            fontSize="large"
                            sx={{
                              "&": { transition: "0.1s" },
                              "&:hover": { background: "#D3D3D3" },
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Attachments;
