import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";

// @mui material components
import {
  Box,
  Grid,
  Card,
  Autocomplete,
  Icon,
  Switch,
  Button,
  Stack,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import ConfirmDialog from "components/ConfirmDialog";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";

// NewProduct page components
import {
  Timeline,
  FolderCopy,
  AttachFile,
  TrendingUp,
  Settings,
  Description,
  Assessment,
} from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DataList from "components/DataList";
import { makeStyles } from "@mui/styles";
import FormField from "layouts/applications/wizard/components/FormField";
import { NumericFormat } from "react-number-format";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import generateSlug from "utils/generateSlug";
import { getDirtyFields } from "utils/helpers/formHelpers";
import CopyJobURLModal from "layouts/pages/jobs/components/CopyJobURLModal";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import createJob from "layouts/pages/jobs/actions/createJob";
import NotificationRecipientsModal from "layouts/pages/jobs/components/NotificationRecipientsModal";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import DataListContainer from "components/DataListContainer";
import generateEmail from "utils/generateEmail";
import { customPanelCard, cardStyles } from "layouts/pages/venues/styles";
import useSessionAuth from "hooks/useSessionAuth";
import fetchSingleJob from "../../actions/fetchSingleJob";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  previewButton: {
    padding: 0,
  },
  editor: {
    height: 115,
  },
  error: {
    fontSize: "0.75rem",
  },
});

function JobsInformation({
  mode = "edit",
  open,
  setOpen,
  setJobPreview,
  deleteJob,
  setRefetchJobs,
  setToastAlert,
  selectedPanel = "",
  onPanelSelect = () => null,
}) {
  const {
    currentJob,
    setCurrentJob,
    currentLoggedUser,
    venues,
    userType,
    company,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    isMobile,
  } = useAppContextController();
  const navigate = useNavigate();
  const [disableSlug, setDisableSlug] = useState(true);
  const [jobVenue, setJobVenue] = useState(null);
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [CopyJobURLModalOpen, setCopyJobURLModalOpen] = useState(false);
  const [notificationRecipientsModalOpen, setNotificationRecipientsModalOpen] = useState(false);
  const [copyJobName, setCopyJobName] = useState("");
  const [searchParams] = useSearchParams();
  const copyJobSlug = searchParams.get("copyJobSlug");
  const { logoutAuth0User } = useSessionAuth();

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const resetDeleteConfirmModal = () => {
    setDeleteConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const defaultValues = {
    jobSlug: "",
    jobEmail: "",
    title: "",
    venueName: null,
    venueSlug: "",
    status: "Active",
    venueThumb: "Yes",
    googleMap: "Yes",
    hideThisJob: "No",
    websitePosting: "No",
    payRate: 0,
    hidePayRate: "No",
    billRate: 0,
    hideBillRate: "No",
    description: "",
    bannerFileName: "",
    jobId: "",
    postToIndeed: "Yes",
    jobLogo: "No",
    notificationRecipients: [
      {
        applicantId: "",
        email: company?.companyEmail,
        firstName: "Jobs",
        fullName: "Jobs",
        lastName: "",
        userId: "",
      },
    ],
  };

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => onPanelSelect(panel));
    } else {
      onPanelSelect(panel);
    }
  };

  const panelOptions = [
    {
      title: "Activities",
      icon: <Timeline />,
      hasShadow: selectedPanel === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handlePanelChange("activities"),
    },
    {
      title: "Job Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "content" ? "emd" : "",
      color: "primary",
      ariaLabel: "job content",
      fn: () => handlePanelChange("content"),
    },
    {
      title: "Job Attachments",
      icon: <AttachFile />,
      hasShadow: selectedPanel === "attachments" ? "emd" : "",
      color: "",
      ariaLabel: "job attachments",
      fn: () => handlePanelChange("attachments"),
    },
    // R.Geyer -- commenting this out for now.  Will flesh it out later.
    // {
    //   title: "Job Status",
    //   icon: <TrendingUp />,
    //   hasShadow: selectedPanel === "status" ? "emd" : "",
    //   color: "success",
    //   ariaLabel: "job status",
    //   fn: () => handlePanelChange("status"),
    // },
    {
      title: "Job Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "job settings",
      fn: () => handlePanelChange("settings"),
    },
    {
      title: "Job Notes",
      icon: <Description />,
      hasShadow: selectedPanel === "notes" ? "emd" : "",
      color: "",
      ariaLabel: "job notes",
      fn: () => handlePanelChange("notes"),
    },
    {
      title: "Job Report Data",
      icon: <Assessment />,
      hasShadow: selectedPanel === "reportdata" ? "emd" : "",
      color: "info",
      ariaLabel: "job report data",
      fn: () => handlePanelChange("reportdata"),
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({ mode: "onBlur", defaultValues: mode === "edit" ? currentJob : defaultValues });

  const venueNamesList = venues
    ? Object.keys(venues).map((key) => ({ label: venues[key].name, id: venues[key].slug }))
    : [];
  const venueName = { ...register("venueName", { required: "Venue is required" }) };
  const venueSlug = register("venueSlug");

  const jobSlug = {
    ...register("jobSlug", {
      required: "Job URL is required",
      // eslint-disable-next-line
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      validate: {
        // eslint-disable-next-line
        // lowerCase: (value) => value && /^[a-z0-9][a-z0-9]+$/.test(value) || "Only lowercase letters are allowed",
        // // eslint-disable-next-line
        // startNumber: (value) => value && /^[a-zA-Z][a-zA-Z0-9.,$;]+$/.test(value) || "the slug can't start with a number",
        repeated: async (value) => {
          if (mode === "create") {
            const unique = await fetchJobs({ filters: { jobSlug: value }, fetchAll: true });
            return !unique?.data[0]?._id || "Job URL already exists";
          }
          return null;
        },
      },
    }),
  };
  const jobEmail = {
    ...register("jobEmail", {
      required: "Job URL is required",
    }),
  };

  const title = {
    ...register("title", {
      required: "Name is required",
      // eslint-disable-next-line
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      validate: {
        // eslint-disable-next-line
        // eslint-disable-next-line
        // startNumber: (value) => value && /^[a-zA-Z][a-zA-Z0-9.,$;]+$/.test(value) || "the Job Title can't start with a number",
        repeated: (value) => /^[a-zA-Z\s]+$/.test(value) || "Invalid Job Name",
      },
    }),
  };

  const payRate = register("payRate");
  const jobId = register("jobId");

  const postToIndeed = register("postToIndeed");
  const jobLogo = register("jobLogo");
  const venueThumb = register("venueThumb");
  const googleMap = register("googleMap");
  const hideThisJob = register("hideThisJob");
  const description = register("description");
  const notificationRecipients = register("notificationRecipients");

  const queryClient = useQueryClient();

  const updateJobMutation = useMutation(updateJob, {
    onError: (err) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong!  ${err.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("jobs");
      if (data.status === "Deleted") {
        setCurrentJob(null);
        navigate(`/jobs`);
        setToastAlert({ isShow: true, message: "Job was archived", status: "success" });
      } else {
        setCurrentJob({ ...currentJob, ...data });
        setToastAlert({ isShow: true, message: "Job has been updated!", status: "success" });
      }
    },
  });

  const createJobMutation = useMutation(createJob, {
    onError: (err) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong!  ${err.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("jobs");
      setToastAlert({ isShow: true, message: "Job has been created!", status: "success" });
      setCurrentJob({ ...currentJob, ...data, _id: _.insertedId, [_.id.name]: _.id[_.id.name] });
      navigate(`/jobs/${data?.jobSlug}`);
    },
  });

  const classes = useStyle();

  const openModal = () => {
    setJobPreview(currentJob);
    setOpen(!open);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getJobValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentJob?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    if (mode === "edit") {
      reset(getJobValues());
      setEditorValue(currentJob.description);
      if (venues) {
        Object.keys(venues).forEach((key) => {
          if (venues[key].name === getJobValues().venueSlug) {
            setJobVenue(venues[key]);
          }
        });
      }
      setJobVenue();
    } else {
      const data = {};
      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentJob(data);
      reset({ ...defaultValues });
      setJobVenue(null);
      setEditorValue(defaultValues.description);
      navigate("/jobs");
    }

    setIsModified(false);
    setToastAlert({ isShow: true, message: "Changes have been abandoned", status: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentJob._id) {
      setToastAlert({
        isShow: true,
        message: "Cannot Update Job -- No ID Present!",
        status: "error",
      });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    if (modifiedFields?.payRate) {
      modifiedFields.payRate = parseFloat(modifiedFields.payRate);
    }
    await updateJobMutation.mutateAsync({
      _id: currentJob._id,
      data: { ...modifiedFields },
    });
    setRefetchJobs((prev) => !prev);
  };

  const createJobHandler = async (values) => {
    const newJobValues = { ...values };
    if (copyJobSlug) newJobValues.copyJobSlug = copyJobSlug;
    await createJobMutation.mutateAsync({
      data: {
        ...newJobValues,
        payRate: parseFloat(values.payRate),
        createAgent: currentLoggedUser._id,
      },
    });
    setRefetchJobs((prev) => !prev);
  };

  const onCancel = () => {
    setIsModified(false);
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confrim to abandon Changes",
      response: false,
    });
  };

  const addNotificationRecipients = () => {
    setNotificationRecipientsModalOpen(true);
  };

  const onJobNameChange = (v) => {
    if (venues) {
      Object.keys(venues).forEach((key) => {
        if ((venues[key].name ?? "").trim() === (getValues("venueName") ?? "").trim()) {
          setJobVenue(venues[key]);
          setValue("venueSlug", venues[key].slug);
        }
      });
    }
  };
  const onDelete = () => {
    setDeleteConfirmModal({
      isOpen: true,
      title: "Delete Job",
      bodyText: "Please confirm you want to DELETE this Job!",
      response: false,
    });
  };

  const copyJob = () => {
    navigate(`/jobs/create?copyJobSlug=${currentJob?.jobSlug}`);
  };

  const fetchCopyJob = async () => {
    try {
      const hasJobSlug = await fetchSingleJob(copyJobSlug);
      const oldJob = hasJobSlug?.data?.job;
      if (oldJob) {
        setCopyJobName(oldJob?.title);
        oldJob.title = "";
        oldJob.jobSlug = "";
        oldJob.jobAndApplicantPermissions = [];

        delete oldJob?._id;
        delete oldJob?.jobId;

        reset(oldJob);
        setCurrentJob(oldJob);
        setEditorValue(oldJob.description);
        setIsModified(false);
        if (!oldJob?.attachments) oldJob.attachments = [];
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  useEffect(() => {
    if (!copyJobSlug) return;
    fetchCopyJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyJobSlug]);

  const removeRecipient = (idx) => {
    const temporaryRecipients = getValues()?.notificationRecipients;
    temporaryRecipients.splice(idx, 1);
    setValue("notificationRecipients", temporaryRecipients, { shouldDirty: true });
    if (mode !== "create") setReminderToSaveChangesModalOpen(true);
  };
  const notificationRecipientsRenderRow = (row, idx) => {
    return (
      <>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          onClick={() => {
            setNotificationRecipientsModalOpen(true);
          }}
        >
          <Grid container>
            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>{row.fullName}</MDTypography>
            </Grid>

            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>E-Mail: {row.email}</MDTypography>
            </Grid>

            <Grid item sm={1}>
              <MDBox textAlign="right">
                <Icon
                  color="error"
                  fontSize="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeRecipient(idx);
                  }}
                >
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    if (currentJob) {
      reset(currentJob, { keepDirty: false, keepDirtyValues: false });
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentJob?.[key] || "");
      });
      if (venues) {
        Object.keys(venues).forEach((key) => {
          if (venues[key].slug === currentJob.venueSlug) {
            setJobVenue(venues[key]);
          }
        });
      }
      setEditorValue(currentJob.description);
      setIsModified(false);
    }
  }, [currentJob]);

  useEffect(() => {
    if (mode === "edit") {
      if (currentJob) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentJob[key] || "");
        });
        if (venues) {
          Object.keys(venues).forEach((key) => {
            if (venues[key].slug === currentJob.venueSlug) {
              setJobVenue(venues[key]);
            }
          });
        }
        setEditorValue(currentJob.description);
        setIsModified(false);
      }

      const subscription = watch((value) => {
        if (currentJob) {
          setIsModified(JSON.stringify(getJobValues()) !== JSON.stringify(value));
        }
      });

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
    return null;
  }, [watch, currentJob, confirmModal.response]);
  // Executes on modal response!
  useEffect(() => {
    if (confirmModal.response) {
      resetConfirmModal();
      cancelChanges();
    }
  }, [confirmModal.response]);

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};
      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentJob(data);
      reset({ ...defaultValues });
      setJobVenue(null);
      setEditorValue(defaultValues.description);
      //      setSlugDisable(false)
    } else {
      //      setSlugDisable(true)
    }
  }, [mode]);

  useEffect(() => {
    const del = async () => {
      await updateJobMutation.mutateAsync({
        _id: currentJob._id,
        data: { status: "Deleted", deleteAgent: currentLoggedUser._id },
      });
    };
    if (deleteConfirmModal.response) {
      resetDeleteConfirmModal();
      del();
    }
  }, [deleteConfirmModal.response]);

  // generates the slug
  useMemo(() => {
    if (mode === "create") {
      if (getValues()?.title?.length && getValues()?.venueSlug?.length) {
        (async () => {
          const { slug } = await generateSlug({
            venueSlug: getValues()?.venueSlug,
            title: getValues().title,
            slugField: "jobSlug",
            fetchFunction: fetchJobs,
          });
          setDisableSlug(false);
          const mail = await generateEmail({
            slug,
            venueSlug: company?.slug,
          });
          setValue("jobSlug", slug);
          setValue("jobEmail", mail);
        })();
      } else if (!getValues()?.title?.length || !getValues()?.venueSlug?.length) {
        setValue("jobSlug", "");
        setValue("jobEmail", "");
        setDisableSlug(true);
      }
    } else if (mode === "edit") {
      setDisableSlug(true);
    }
  }, [getValues().title, getValues().venueSlug]);

  const icon = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="4rem"
      height="4rem"
      variant="gradient"
      bgColor={mode === "create" ? "warning" : "white"}
      color="white"
      borderRadius="xl"
    >
      {mode === "create" ? (
        <Icon fontSize="large">work</Icon>
      ) : (
        <img
          src={
            venues?.[currentJob?.venueSlug]?.logoUrl ||
            `${company?.imageUrl}/stadiumpeople/SP-App-Store-Icon.png`
          }
          width="50"
          height="50"
          className={classes.logoImage}
          alt="Venue Logo"
        />
      )}
    </MDBox>
  );

  const cardActions = (
    <Box mr={-3}>
      {mode === "edit" && !isModified && userType === "Master" && (
        <Button
          variant="text"
          endIcon={<DeleteIcon />}
          onClick={onDelete}
          style={{ color: "red", fontSize: 14 }}
          disabled={isSubmitting}
          sx={{ padding: "0.5rem 1rem" }}
        >
          Delete Job
        </Button>
      )}
      {(isModified || mode === "create") && (
        <>
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            onClick={onCancel}
            disabled={isSubmitting}
            style={{ color: "#F44335", fontSize: 14 }}
          >
            Discard
          </Button>
          <Button
            type="submit"
            variant="text"
            endIcon={<SaveIcon />}
            style={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </>
      )}
      {mode === "edit" && !isModified && (
        <Button
          variant="text"
          startIcon={<ContentCopyIcon />}
          onClick={copyJob}
          disabled={isSubmitting}
          sx={{ fontSize: 14 }}
        >
          Copy Job
        </Button>
      )}
    </Box>
  );

  return (
    currentJob && (
      <form onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createJobHandler)}>
        <CustomCard
          key={currentJob?._id}
          icon={icon}
          cardTitle={
            mode === "create"
              ? `Create Job ${copyJobSlug ? `from Duplicate ${copyJobName}` : ""}`
              : `Edit Job: ${currentJob?.title}`
          }
          iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "1.5rem" }}
          cardSx={{ width: "100%", overflow: "visible !important", p: 0 }}
          currentData={currentJob}
          cardActions={cardActions}
          isStacked
          {...customPanelCard(isMobile, "transparent")}
        >
          <Box sx={{ ...cardStyles.container, height: 615 }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={1}>
              {panelOptions.map((tab) => (
                <Tooltip title={tab?.title} key={tab?.title}>
                  <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow}>
                    <IconButton
                      color={tab.color}
                      aria-label={tab.ariaLabel}
                      onClick={tab.fn}
                      disabled={mode !== "edit"} // || (tab.title === "Sales" && watchStatus !== "Prospect")}
                    >
                      {tab.icon}
                    </IconButton>
                  </MDBox>
                </Tooltip>
              ))}
            </Stack>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                  Job Information
                </MDTypography>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      color="success"
                      checked={field.value === "Active"}
                      onChange={(e) => field.onChange(e.target.checked ? "Active" : "Inactive")}
                    />
                  )}
                />
                <MDTypography
                  component="label"
                  variant="h5"
                  fontWeight="bold"
                  color={getValues("status") === "Active" ? "success" : "error"}
                >
                  {getValues("status") === "Active" ? "Active" : "Inactive"}
                </MDTypography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField
                  disabled={mode === "create" && !getValues()?.venueSlug?.length}
                  inputProps={
                    mode === "create" && !getValues()?.venueSlug?.length ? { tabIndex: -1 } : {}
                  }
                  key={`jobtitle_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                  type="text"
                  label="Job Name"
                  {...title}
                />
                {errors?.title && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.title.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  disabled
                  inputProps={{ tabIndex: -1 }}
                  key={`jobId_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                  type="text"
                  label="Job Id"
                  {...jobId}
                />
              </Grid>
              <Grid item xs={12} sm={4} alignItems="center">
                <FormField
                  type="text"
                  disabled
                  inputProps={{ tabIndex: -1 }}
                  label="City"
                  value={jobVenue?.city || ""}
                  InputLabelProps={{ shrink: jobVenue?.city }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  disabled
                  inputProps={{ tabIndex: -1 }}
                  label="State"
                  value={jobVenue?.state || ""}
                  InputLabelProps={{ shrink: jobVenue?.state }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type="text"
                  disabled
                  inputProps={{ tabIndex: -1 }}
                  label="Zip"
                  value={jobVenue?.venueZip || ""}
                  InputLabelProps={{ shrink: jobVenue?.venueZip }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  {...venueName}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={venueNamesList}
                      disabled={mode === "edit"}
                      inputProps={mode === "edit" ? { tabIndex: -1 } : {}}
                      tabIndex="-1"
                      name="venueSlug"
                      value={field.value || ""}
                      onChange={(e, v) => {
                        field.onChange(e.target.textContent);
                        onJobNameChange(v.label);
                      }}
                      renderInput={(params) => (
                        <FormField
                          key={`venueSlug_${currentJob?.venueSlug}`}
                          {...params}
                          type="text"
                          label="Venue Name"
                        />
                      )}
                    />
                  )}
                />
                {errors?.venueName && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.venueName.message}
                  </MDTypography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={11.5}>
                    <FormField
                      disabled
                      inputProps={{ tabIndex: -1 }}
                      key={`jobEmail_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                      type="text"
                      label="Job Email"
                      InputLabelProps={{ shrink: getValues().jobEmail }}
                      {...jobEmail}
                    />
                    {errors?.jobSlug && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.jobEmail?.message}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={0.5}>
                    <Tooltip title="Copy Url">
                      <IconButton
                        color="info"
                        onClick={() => {
                          navigator.clipboard.writeText(getValues().jobEmail);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} alignItems="center">
                <FormField
                  disabled
                  inputProps={{ tabIndex: -1 }}
                  key={`venueHelmID_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                  type="text"
                  label=" Venue HelmID"
                  value={jobVenue?.venueHelmID || ""}
                  InputLabelProps={{ shrink: jobVenue?.venueHelmID }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <FormField
                      disabled
                      inputProps={{ tabIndex: -1 }}
                      key={`jobSlug_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                      type="text"
                      label="Job URL"
                      InputLabelProps={{ shrink: getValues().jobSlug }}
                      {...jobSlug}
                    />
                    {errors?.jobSlug && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.jobSlug.message}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Copy Url">
                      <IconButton
                        color="info"
                        onClick={() => {
                          navigator.clipboard.writeText(getValues().jobSlug);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  {...payRate}
                  key={`payRate_${currentJob?.venueSlug}_${currentJob?.jobId}`}
                  control={control}
                  render={({ field }) => (
                    <NumericFormat
                      InputProps={{
                        startAdornment: "$",
                        inputProps: {
                          inputMode: "numeric",
                        },
                      }}
                      label="Pay Rate"
                      decimalScale={2}
                      {...field}
                      customInput={FormField}
                    />
                  )}
                />
                {errors?.payRate && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.payRate.message}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* {JSON.stringify(data)} */}

          <ConfirmDialog state={deleteConfirmModal} setState={setDeleteConfirmModal} />
          <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
          <CopyJobURLModal
            jobSlug={currentJob?.jobSlug}
            open={CopyJobURLModalOpen}
            setOpen={setCopyJobURLModalOpen}
          />
          <NotificationRecipientsModal
            title="Add Attendees"
            open={notificationRecipientsModalOpen}
            setOpen={setNotificationRecipientsModalOpen}
            NotificationRecipientsArray={getValues().notificationRecipients || []}
            setValue={setValue}
            isCreate={mode === "create"}
            setReminderToSaveChangesModalOpen={setReminderToSaveChangesModalOpen}
          />
          {mode === "edit" && (
            <ReminderToSaveChangesModal
              open={reminderToSaveChangesModalOpen}
              setOpen={setReminderToSaveChangesModalOpen}
            />
          )}
        </CustomCard>
      </form>
    )
  );
}

export default JobsInformation;

// // Setting default values for the props of BaseLayout
// JobsInformation.defaultProps = {
//   pageName: "Jobs",
// };

// // Typechecking props for BaseLayout
// JobsInformation.propTypes = {
//   pageName: PropTypes.string,
// };
