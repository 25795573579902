import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/applications/wizard/components/FormField";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Icon, Button, Switch } from "@mui/material";
import PropTypes from "prop-types";
import Notes from "./Notes";

const useStyle = makeStyles({
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
});

const AddANote = ({
  showType = true,
  noteTypes,
  saveNote,
  saveChanges,
  setOpen,
  applicant = null,
  currentEvent = null,
  allowEmpty = true,
  defaultValue = `<p></p>`,
  onCloseAction,
  onExtraAction,
  extraActionLabel = "",
  showCurrentNotes,
}) => {
  const classes = useStyle();
  const [editorValue, setEditorValue] = useState(defaultValue);
  const [type, setType] = useState("General");

  return (
    <>
      {showType && (
        <MDBox p={3} pb={0}>
          <Grid container spacing={1}>
            <Grid item lg={8}>
              <Autocomplete
                value={type}
                options={noteTypes || []}
                onChange={(e, v) => {
                  setType(v);
                }}
                renderInput={(params) => <FormField {...params} type="text" />}
              />
            </Grid>

            <Grid item lg={4} />
          </Grid>
        </MDBox>
      )}

      <MDBox p={3} mb={3}>
        <MDTypography variant="body2" color="dark">
          NOTE
        </MDTypography>
        <MDEditor
          className={classes.editor}
          value={editorValue}
          onChange={(e) => setEditorValue(e)}
        />
      </MDBox>
      <MDBox p={3} mt={7}>
        <Grid container spacing={3}>
          {onExtraAction ? (
            <>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setOpen(false);
                    onExtraAction();
                  }}
                >
                  <MDTypography variant="body2" color="white">
                    {extraActionLabel}
                  </MDTypography>
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item sm={6} />
          )}

          <Grid item sm={3}>
            <Button
              color="error"
              variant="contained"
              fullWidth
              onClick={() => {
                setOpen(false);
                if (onCloseAction) onCloseAction();
              }}
            >
              <MDTypography variant="body2" color="white">
                Cancel
              </MDTypography>
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button
              variant="contained"
              fullWidth
              disabled={
                !allowEmpty &&
                (editorValue.replace(/(<([^>]+)>)/gi, "") === "" || editorValue === defaultValue)
              }
              onClick={async () => {
                const noteRec = {
                  text: editorValue,
                  type,
                };
                await saveNote(noteRec);
                setOpen(false);
                if (onCloseAction) onCloseAction();
              }}
            >
              <MDTypography variant="body2" color="white">
                Save
              </MDTypography>
            </Button>
          </Grid>
        </Grid>
      </MDBox>
      {((!!showCurrentNotes && !!applicant) || (!!applicant?.notes && !!currentEvent)) && (
        <MDBox>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Notes
                saveChanges={saveChanges}
                notes={
                  currentEvent
                    ? applicant.notes?.filter((item) => item.eventUrl === currentEvent?.eventUrl)
                    : applicant.notes ?? []
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  );
};

AddANote.defaultProps = {
  noteTypes: [],
};

AddANote.propTypes = {
  noteTypes: PropTypes.array,
};

export default AddANote;
