import { RequestPage } from "@mui/icons-material";
import { CommonIconProps } from "./applicant";

export const VENUE_STATE_TAX_FORM_STEPS = {
  OK: {
    id: 3,
    label: "State Tax Form - OK",
    applicantObject: "oklahomaStateTaxForm",
    icon: <RequestPage {...CommonIconProps} />,
  },
  AL: {
    id: 4,
    label: "State Tax Form - AL",
    applicantObject: "alabamaStateTaxForm",
    icon: <RequestPage {...CommonIconProps} />,
  },
  GA: {
    id: 1,
    label: "State Tax Form - GA",
    applicantObject: "georgiaStateTaxForm",
    icon: <RequestPage {...CommonIconProps} />,
  },
  MI: {
    id: 2,
    label: "State Tax Form - MI",
    applicantObject: "michiganStateTaxForm",
    icon: <RequestPage {...CommonIconProps} />,
  },
  SC: {
    id: 3,
    label: "State Tax Form - SC",
    applicantObject: "southCarolinaStateTaxForm",
    icon: <RequestPage {...CommonIconProps} />,
  },
  CA: {
    id: 3,
    label: "State Tax Form - CA",
    applicantObject: "californiaStateTaxForm",
    icon: <RequestPage {...CommonIconProps} />,
  },
};

export const VENUE_DEFAULT_SETTINGS = {
  allowAdminChanges: "Yes",
  makeVenueInactive: "No",
  ShowVenueOnWebsite: "No",
  notifyWhenAdminUsersMakeChanges: "No",
  notifyWhenNewJobsAreAddes: "No",
  notifyWhenEventsAreAdded: "Yes",
  notifyWhenApplicantsAreLocked: "Yes",
  dashboardLastName: "Yes",
  dashboardFirstName: "Yes",
  dashboardPhone1: "Yes",
  dashboardPhone2: "No",
  dashboardEmailAddress: "Yes",
  dashboardHelmEID: "Yes",
  dashboardHireDate: "Yes",
  dashboardBirthDate: "Yes",
  dashboardLastDateWorked: "Yes",
  dashboardRank: "Yes",
  dashboardLastUpdate: "Yes",
  dashboardRepName: "Yes",
  dashboardNotes: "Yes",
  dashboardResults: "No",
  dashboardOtherVenues: "No",
  fein: "",
  groupId: null,
  wcState: null,
  wcCode: null,
  payFrequency: null,
};