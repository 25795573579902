import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AttachFile from "@mui/icons-material/AttachFile";
import { Button, Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import updateApplicant from "../../actions/updateApplicant";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  section_image_delete: {
    position: "absolute!important",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },
});

const ApplicantAttachments = ({ setOpen, refresh }) => {
  const classes = useStyle();
  const { currentApplicant, setCurrent, company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar(`Something went wrong!`, { variant: "error" }),
    onSuccess: async () => {
      enqueueSnackbar(`Attachment has been deleted!`, { variant: "success" });
      if (refresh) await refresh();
    },
  });

  useEffect(() => {
    if (currentApplicant?.attachments?.length) {
      setFiles(
        currentApplicant?.attachments.map((file) => {
          const { docType, filename, name, title, type, uploadDate } = file;
          return { docType, filename, name, title, type, uploadDate };
        })
      );
    } else {
      setFiles([]);
    }
  }, [currentApplicant]);

  const addNew = (e) => {
    setOpen(true);
  };

  const removeAttachmentHandler = async (attachmentID) => {
    const newAttachmentsArr = files.filter((file, index) => index !== attachmentID);
    setFiles(newAttachmentsArr);

    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant?._id,
      data: { attachments: newAttachmentsArr },
    });
  };

  const renderRow = (row, index) => {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2} overflow="auto" mr={2}>
            <MDBox
              component="img"
              sx={{
                height: 90,
                display: "block",
                overflow: "hidden",
                borderRadius: 5,
                padding: 1,
              }}
              src={
                ((row?.docType === "jpeg" ||
                  row?.docType === "jpg" ||
                  row?.docType === "png" ||
                  row?.docType === "webp") &&
                  IMAGE_SERVER &&
                  `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${row?.type}/${
                    row?.filename || row?.name
                  }`) ||
                (!!company?.imageUrl &&
                  !!company?.uploadUrl &&
                  row?.docType === "pdf" &&
                  `${getCommonBaseImageUrl(company)}/static/pdf-icon.png`) ||
                (!!company?.imageUrl &&
                  !!company?.uploadUrl &&
                  row?.docType === "docx" &&
                  `${getCommonBaseImageUrl(company)}/static/word-icon.png`)
              }
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="h6">
                  {row?.type}
                  <Button
                    onClick={() =>
                      window.open(
                        `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${row?.type}/${
                          row?.filename || row?.name
                        }`
                      )
                    }
                  >
                    view
                  </Button>
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="body2">
                  {moment(row?.uploadDate).format("dddd MMM Do HH:mm")}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FlexWrapper
                  flexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <MDTypography variant="body2" marginRight={4}>
                    {row?.filename || row?.name}
                  </MDTypography>
                  <MDTypography variant="body2">{row?.title}</MDTypography>
                </FlexWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={2}>
            <MDBox>
              <IconButton
                color="info"
                className={classes.maps_section_image_delete}
                onClick={() => removeAttachmentHandler(index)}
              >
                <Icon fontSize="large">cancel</Icon>
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
      </>
    );
  };

  const header = (
    <>
      <IconButton className={classes.addButton} color="info" onClick={addNew}>
        <AddCircleRoundedIcon />
      </IconButton>
    </>
  );
  return (
    <>
      <DataList
        renderRow={renderRow}
        data={files}
        onRowClick={() => {}}
        icon={<AttachFile />}
        iconColor="error"
        title="Attachments"
        isLoading={false}
        greybar
        divider
        header={header}
        tableHeight={600}
        height={850}
        scrollY
        computedHeight
      />
    </>
  );
};

export default ApplicantAttachments;
