import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { SnackbarProvider } from "notistack";
import { Autocomplete, Box, Grid, Icon } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import { isEmpty } from "lodash";
import SendMessage from "components/SendMessage";

const MessagingPanel = ({
  selectedContact,
  currentData,
  parent,
  isVenue = false,
  messageContext,
  attachmentContext,
}) => {
  let activityKeyValue = null;
  if (parent === "Venue") {
    activityKeyValue = currentData?._id;
  } else if (parent === "Company") {
    activityKeyValue = currentData?._id;
  }

  const [contact, setContact] = useState();
  const [contactsOptions, setContactsOptions] = useState([]);
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [system, setSystem] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [width, setWidth] = useState(200);

  useEffect(() => {
    if (currentData?.contacts) {
      const options = currentData?.contacts?.map((item) => {
        return { ...item, label: `${item.firstName} ${item.lastName}` || item.contact };
      });
      setContactsOptions(options);
    } else {
      setContactsOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData?.contacts]);

  useEffect(() => {
    if (selectedContact) {
      setContact(contactsOptions[selectedContact]);
    } else if (!isEmpty(contactsOptions)) {
      setContact(contactsOptions[0]);
    } else setContact();
  }, [selectedContact, contactsOptions]);

  useEffect(() => {
    if (!contact?.phone) setText(false);
    if (!contact?.email) setEmail(false);
    else setEmail(true);
    if (contact?.phone && !contact?.email) setText(true);
    else setText(false);
    if (contact) setWidth(200 + contact?.label?.length * 9);
  }, [contact]);

  return (
    <SnackbarProvider
      maxSnack={3}
      open={toastAlert}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => setToastAlert(false)}
      key="top-center"
    >
      <Card
        key={`card_sendMessage_${currentData?._id}`}
        sx={{ overflow: "visible!important", borderRadius: 0, height: 850 }}
      >
        <MDBox display="flex" key={`box_sendMessage_${currentData?._id}`} mb={2}>
          <Box>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-3}
            >
              <Icon fontSize="large">email</Icon>
            </MDBox>
          </Box>

          <MDBox sx={{ mt: 2, mx: 2, width: "100%" }}>
            <Grid container alignItems="center">
              <Grid item xs={2.8} mr={1}>
                <MDTypography variant="h5" color="dark">
                  Send Message to
                </MDTypography>
              </Grid>
              <Grid item xs={4.5} justifyContent="flex-start" ml={-1}>
                <Autocomplete
                  options={contactsOptions}
                  contact="contact"
                  autoSelect
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  getOptionDisabled={(option) => !option.email && !option.phone}
                  value={contact || { label: "" }}
                  onChange={(e, v) => {
                    setContact(v);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      type="text"
                      label=""
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: "1.25rem",
                          paddingLeft: 5,
                          backgroundColor: "#F0F2F5",
                          width,
                        },
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {email && (
              <MDTypography variant="body2" color="info" fontWeight="bold">
                {`E-mail: ${contact?.email ? contact?.email : "N/A"}`}
              </MDTypography>
            )}
            {text && (
              <MDTypography variant="body2" color="info" fontWeight="bold">
                {`Phone: ${contact?.phone ? contact?.phone : "N/A"}`}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>

        <SendMessage
          recipient={contact}
          setToastAlert={setToastAlert}
          text={text}
          setText={setText}
          email={email}
          messageContext={messageContext}
          setEmail={setEmail}
          system={system}
          setSystem={setSystem}
          isButtonDisabled={text === false && email === false}
          isPanel
          dataStatus={currentData?.status}
          venueId={parent === "Venue" ? activityKeyValue : null}
          companyId={parent === "Company" ? activityKeyValue : null}
          attachmentContext={attachmentContext}
        />
      </Card>
    </SnackbarProvider>
  );
};

export default MessagingPanel;
