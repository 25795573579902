import { Description, Email, Info, MoreHoriz, NoteAdd, PhoneAndroid } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation } from "react-query";
import exportNotes from "api/applicants/exportNotes";
import { useAppContextController } from "context/AppContext";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useNavigate } from "react-router-dom";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import postNote from "layouts/pages/applicants/actions/postNote";
import touchJob from "layouts/pages/applicants/actions/touchJob";

const JobStatusMoreButton = ({ applicant, toastAlert, setToastAlert, onTouchJob }) => {
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);

  const { mutateAsync: exportMutation, isLoading } = useMutation(exportNotes);
  const { company, currentLoggedUser, currentJob } = useAppContextController();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [notes, setNotes] = useState([]);

  // -------
  //  Notes
  // -------
  const handlePressExport = () =>
    exportMutation(
      { applicant: applicant._id },
      {
        onSuccess: (data) => {
          window.open(`${company?.imageUrl}${data.data.url}`, "_blank");
          setNotesModalOpen(false);
        },
      }
    );

  const { mutateAsync: fetchSingleApplicantMutation, isLoading: isLoadingNotes } =
    useMutation(fetchSingleApplicant);

  useEffect(() => {
    if (isNotesModalOpen && applicant && applicant._id) {
      fetchSingleApplicantMutation({ applicantId: applicant._id }).then((res) => {
        setNotes(
          (res?.data?.notes ?? [])
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((note) => {
              const { type, date, text, firstName, lastName } = note;
              return { type, date, text, firstName, lastName };
            })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant?._id, isNotesModalOpen]);

  const renderText = isLoadingNotes ? (
    <Box display="flex" justifyContent="center" mt={3}>
      <CircularProgress color="info" />
    </Box>
  ) : (
    notes.map((note) => {
      return (
        <>
          <Grid container>
            <Grid item sm={3}>
              <MDTypography fontWeight="bold">
                {note.firstName} {note.lastName}
              </MDTypography>
            </Grid>
            <Grid item sm={4}>
              {moment(note.date).format("ddd MM/DD/yyyy hh:mm A")}
            </Grid>
            <Grid item sm={4}>
              {note.type}
            </Grid>
          </Grid>
          {parse(note.text || "")}
          <hr />
        </>
      );
    })
  );

  const modalBody = (
    <Box mb={3} style={{ height: "21rem", overflow: "auto" }}>
      {renderText}
    </Box>
  );

  const okButton = (
    <Button variant="contained" style={{ color: "white" }} onClick={() => setNotesModalOpen(false)}>
      Ok
    </Button>
  );

  const modalTitle = (
    <MDTypography
      variant="h5"
      color="dark"
      sx={{ mt: 2, mb: 1, ml: 2 }}
      // key={`note_title_${modalInfo.data.id}`}
    >
      Notes for {applicant?.firstName} {applicant?.lastName}
    </MDTypography>
  );

  const pdfButton = (
    <LoadingButton
      variant="contained"
      style={{ color: "white" }}
      onClick={handlePressExport}
      loading={isLoading}
    >
      pdf download
    </LoadingButton>
  );
  // --------------
  // --------------

  const options = [
    {
      text: `View ${applicant?.firstName} ${applicant?.lastName}`,
      icon: <Info fontSize="medium" color="info" />,
      handleClick: () => {
        if (applicant?.status === "Employee") {
          navigate(`/employees/${applicant._id}/action/employeeinfo`);
        } else {
          navigate(`/applicants/${applicant._id}/action/applicantinfo`);
        }
      },
      disabled: false,
    },
    {
      text: `Send a message`,
      icon: <Email fontSize="medium" color="violet" />,
      handleClick: () => {
        setIsSendMessageModalOpen(true);
      },
      disabled: false,
    },
    {
      text: `Notes`,
      icon: <Description fontSize="medium" color="info" />,
      handleClick: () => {
        setNotesModalOpen(true);
      },
      disabled: false,
    },
    {
      text: `Add note`,
      icon: <NoteAdd fontSize="medium" color="info" />,
      handleClick: () => {
        setAddNoteModalOpen(true);
      },
      disabled: false,
    },
  ];

  const touchJobAction = async () => {
    if (!currentJob || !applicant) return;
    try {
      await touchJob({ applicantId: applicant._id, jobSlug: currentJob.jobSlug });
      onTouchJob?.(applicant._id);
    } catch (e) {
      //
    }
  };

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };
    const updateNewNote = async () => {
      let res;

      try {
        res = await postNote({ applicantId: applicant?._id, data: newNote });
        enqueueSnackbar("Notes Have Been Successfully Updated", { variant: "success" });
        await touchJobAction();
      } catch (e) {
        res = e;
        enqueueSnackbar(`Something went wrong! ${e}`, { variant: "error" });
      }

      return res;
    };

    return updateNewNote();
  };

  return (
    <>
      <Tooltip title="More actions">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          sx={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <MoreHoriz fontSize="large" color="dark" />
        </IconButton>
      </Tooltip>
      <CustomMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} options={options} />
      {isSendMessageModalOpen && (
        <ApplicantActionModal
          open={isSendMessageModalOpen}
          setOpen={setIsSendMessageModalOpen}
          modalInfo={{ type: "applicantmail", data: applicant }}
          setToastAlert={setToastAlert}
          currentApplicant={applicant}
          messageContext="Applicant"
          attachmentContext="Applicant"
          onSuccess={touchJobAction}
        />
      )}
      {isNotesModalOpen && (
        <GenericModal
          open={isNotesModalOpen}
          setOpen={setNotesModalOpen}
          body={modalBody}
          header={modalTitle}
          buttons={okButton}
          pdfButton={pdfButton}
        />
      )}
      {addNoteModalOpen && (
        <AddANoteModal
          currentApplicant={applicant}
          open={addNoteModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setAddNoteModalOpen}
          saveNote={saveNote}
          extraActionLabel="Change Status Only"
        />
      )}
    </>
  );
};

export default JobStatusMoreButton;
