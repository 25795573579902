import { useAppContextController } from "context/AppContext";
import { useEventContext } from "context/EventContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Card, Grid, Icon, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import PropTypes from "prop-types";

import EventDate from "components/EventDate";
import EventNumbers from "components/EventNumbers";
// import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import fetchEvents from "layouts/pages/clientevents/actions/fetchClientEvents";
import fetchSingleEvent from "layouts/pages/clientevents/actions/fetchSingleEvent";
import ApplicantEventStatus from "layouts/pages/clientevents/components/ApplicantEventStatus";
import ApplicantVenueStatus from "layouts/pages/clientevents/components/ApplicantVenueStatus";
import CompanyLogoSquare from "components/CompanyLogoSquare";
// import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
// import fetchCustomers from "api/companies/fetchCustomers";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ListIcon from "@mui/icons-material/List";
import FiltersList from "components/FiltersList";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import useSort from "utils/useSort";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FileDownload } from "@mui/icons-material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PayrollModal from "components/PayrollModal";
import TimeInOutModal from "components/TimeInOutModal";
import EventCalendar from "layouts/pages/clientevents/components/EventCalendar";
import ClientEventsTableActions from "layouts/pages/clientevents/components/ClientEventsTableActions";
import VenueClientEventsTableActions from "layouts/pages/clientevents/components/VenueClientEventsTableActions";
import { isEmpty } from "lodash";
// import { APPLICANT_VENUE_STATUS_ENUM } from "utils/constants/applicant";
import { filterApplicantEvents, getReportDateTime, getPositionEndTime } from "utils/helpers/events";
import { isEmployeeUser } from "utils/helpers/roles";
import usePreferredPageSize from "hooks/usePreferredPageSize";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const ClientEventsTable = ({
  // eventUrl,
  navigateToAction,
  title = "Events",
  filters,
  setFilters,
  fetchAll,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  showEventStatus = false,
  setOpen,
  setEventPreview,
  setInterviewPreview,
  defaultSort,
  setOpenEventPreview,
  parent = "Events",
  isMobile,
}) => {
  const [isMobileWidth, setIsMobileWidth] = useState(false);
  const { setRefetchEventsTable, setEvents, setEventsTableFilter } = useEventContext();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileWidth(true);
      } else {
        setIsMobileWidth(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const classes = useStyle();
  const {
    venues,
    setVenues,
    currentEvent,
    setCurrentEvent,
    currentLoggedUser,
    currentApplicant,
    //    setCurrentApplicant,
    userType,
    company,
    setCompany,
    setCompanyType,
    currentCompany,
    setCurrentCompany,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const navigate = useNavigate();
  const [calendarListView, setCalendarListView] = useState("List");

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [isTimeModalOpen, toggleTimeModal] = useState(false);
  const [isPayrollModalOpen, togglePayrollModal] = useState(false);
  const { order, orderBy, toggleSort } = useSort();
  const isCompany = company?.companyType === "Company";

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  // const myVenues = currentLoggedUser?.venues?.length ? currentLoggedUser.venues.join(";") : "";

  const clientOrgSlugs = currentLoggedUser?.clientOrgs?.map((item) => item.slug);
  const allOptions = {
    ...options,
    filters: { ...filters, venueSlug: filters.venueSlug ?? clientOrgSlugs?.join(";") },
    venues,
  };

  const {
    data: events,
    isLoading,
    refetch: refetchEvents,
  } = useQuery(
    ["events", allOptions],
    () => {
      return fetchEvents(allOptions);
    },
    {
      onSuccess: ({ data }) => {
        if (data) {
          setEvents?.(data);
          setEventsTableFilter?.(allOptions);
        }
      },
    }
  );

  const { action, eventUrl } = useParams();

  const sortByEventDate = (a, b) => {
    if (new Date(a.eventDate) > new Date(b.eventDate)) return 1;
    return -1;
  };

  const setFiltersWithEventUrl = useCallback(
    (eurl) => {
      setFilters((prev) => ({ ...prev, eventUrl: eurl }));
    },
    [setFilters]
  );

  const getAndSetFullEvent = useCallback(async (eid) => {
    const ev = await fetchSingleEvent({ eventId: eid });
    if (ev) setCurrentEvent(ev);
  }, []);

  useEffect(() => {
    if (!isLoading && events?.data?.length && eventUrl) {
      const ndx = events.data.findIndex((item) => item.eventUrl === eventUrl);
      if (ndx > -1) {
        getAndSetFullEvent(events.data[ndx]._id);
      }
    }
    if (action && eventUrl) {
      setFiltersWithEventUrl(eventUrl);
    }
  }, [action, eventUrl, events?.data, getAndSetFullEvent, isLoading, setFiltersWithEventUrl]);

  useEffect(() => {
    setPage(1);
    // causes interviews to misbehave, uncomment if needed
    // if (!filters?.timeFrame && !filters?.eventUrl && setFilters)
    //   setFilters((prev) => ({
    //     ...prev,
    //     eventType: prev.eventType || "Event",

    //   }));
  }, [filters, action]);

  function showSmallDate(date) {
    const opts = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      seconds: "omit",
      hourCycle: "h12", // or 'h23' for 24-hour format
    };
    return date.toLocaleString("en-US", opts).replace(",", ""); // remove the comma separator
  }

  const columns = useMemo(
    () => [
      ...(company?.companyType === "Venue"
        ? [
          {
            title: "Logo",
            field: "logoUrl",
            customCell: (logoUrl, field, row) => (
              <VenueIcon
                logoUrl={logoUrl}
                slug={row?.venueSlug}
                name={row?.venueName}
                goToEnabled={userType !== "User"}
              />
            ),
          },
        ]
        : [
          {
            title: "Logo",
            field: "name",
            customCell: (name, field, row) => (
              <CompanyLogoSquare company={row} defaultCompany={company} />
            ),
          },
          {
            title: "Company",
            field: "companySlug",
            customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
          },
        ]),
      ...(showEventStatus && currentApplicant
        ? [
          {
            title: "Event",
            field: "eventUrl",
            customCell: (vslug, field, row) => (
              <ApplicantVenueStatus
                mode="event"
                id={row?._id}
                venue={vslug}
                slugField="eventUrl"
                row={row}
                invalidQuery={["events", allOptions]}
              />
            ),
          },
        ]
        : []),

      ...(userType === "User" && currentApplicant
        ? [
          {
            title: "Event",
            field: "rosterStatus",
            customCell: (eurl, field, row) => (
              <ApplicantEventStatus
                id={row?._id}
                currentEvent={row}
                row={row}
                invalidQuery={["events", allOptions]}
                showTooltip={false}
                refetchEvents={refetchEvents}
              />
            ),
          },
        ]
        : []),
      ...(company?.companyType === "Venue" && (userType !== "User" || (!isMobile && !isMobileWidth))
        ? [
          {
            title: "Venue",
            field: "venueSlug",
            customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
          },
        ]
        : []),
      // { title: "Event Url", field: "eventUrl" },
      { title: "Event", field: "eventName" },
      {
        title: "Event Date",
        field: "eventDate",
        customCell: (date, field, row) =>
          isMobileWidth || isMobile ? (
            <span>{showSmallDate(new Date(date))}</span>
          ) : (
            <EventDate date={date} reportTimeTBD={row?.reportTimeTBD} showTime={false} />
          ),
      },
      {
        title: "Report Time",
        field: "eventDate",
        customCell: (value, field, row) =>
          value && !row?.reportTimeTBD
            ? getReportDateTime(
              row?.applicants,
              value,
              currentLoggedUser?.applicantId,
              userType,
              "time"
            )
            : row?.reportTimeTBD,
      },
      ...(userType !== "User" || (!isMobile && !isMobileWidth)
        ? [
          {
            title: "Est End Time",
            field: "eventEndTime",
            customCell: (value, field, row) =>
              value && !row?.reportTimeTBD
                ? getPositionEndTime(
                  row?.applicants,
                  value,
                  currentLoggedUser?.applicantId,
                  userType,
                  "time"
                )
                : row?.reportTimeTBD,
          },
          {
            title: "City",
            field: "venueCity",
          },
          {
            title: "State",
            field: "venueState",
          },
        ]
        : []),
      ...(userType !== "User" && company?.companyType === "Venue"
        ? [
          // { title: "Status", field: "status" },
          {
            title: "Numbers",
            field: "positionsRequested",
            customCell: (requested, field, row) => {
              return (
                <EventNumbers
                  eventUrl={row?.eventUrl}
                  requested={requested}
                  onRoster={row?.numberOnRoster || 0}
                  onWaitlist={row?.numberOnWaitlist || 0}
                  onRequest={row?.numberOnRequest || 0}
                  onPremise={row?.numberOnPremise || 0}
                  fontSize="medium"
                />
              );
            },
          },
        ]
        : []),
      {
        title: company?.companyType === "Venue" ? "Event Actions" : "Interview Actions",
        field: "eventUrl",
        customCell: (url, field, row) => (
          <ClientEventsTableActions
            setNavigateUrl={navigate}
            eventUrl={url}
            row={row}
            setFilters={setFilters}
            setActionSelected={setActionSelected}
            setOpen={setOpen}
            setEventPreview={setEventPreview}
            setInterviewPreview={setInterviewPreview}
            setCurrentEvent={setCurrentEvent}
            getAndSetFullEvent={getAndSetFullEvent}
            openTimeModal={() => {
              setCurrentEvent(row);
              toggleTimeModal(true);
            }}
          />
        ),
      },
    ],
    [currentEvent, userType, currentApplicant]
  );

  const customerColumns = useMemo(() => [
    {
      title: "Logo",
      field: "name",
      customCell: (name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    {
      title: "Company ID",
      field: "slug",
      customCell: (slug) => <div>{slug?.toUpperCase()}</div>,
    },
    { title: "Company Name", field: "name" },
    { title: "City", field: "city" },
    { title: "State", field: "state" },
  ]);

  const venueColumns = useMemo(
    () => [
      {
        title: "Logo",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => (
          <VenueIcon logoUrl={logoUrl} slug={row?.slug} name={row?.name} />
        ),
      },
      {
        title: "Venue",
        field: "slug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      {
        title: "Venue Name",
        field: "name",
      },
      {
        title: "City",
        field: "city",
      },
      {
        title: "State",
        field: "state",
      },
      { title: "Status", field: "status" },
    ],
    []
  );

  // const columnsVenueSearch = [
  //   {
  //     title: "Venue ID",
  //     field: "slug",
  //     customCell: (thisSlug) => <span>{thisSlug?.toUpperCase()}</span>,
  //   },
  //   { title: "Venue Name", field: "name" },
  //   { title: "Venue URL", field: "venueUrl" },
  //   { title: "City", field: "city" },
  //   { title: "State", field: "state" },
  // ];

  const addNew = (e) => {
    if (!isEmpty(currentEvent)) {
      setCurrentEvent({});
    }
    if (!isEmpty(currentCompany)) {
      setCurrentCompany(null);
    }
    const url = new URL(window.location).pathname;
    const { pathname } = url;
    if (isCompany) {
      navigate("/events/create");
    } else {
      const nodes = url.split("/");
      if (nodes.length === 4 && nodes[1] === "events" && nodes[2] === "venue") {
        navigate(`/events/create/${nodes[3]}`);
      } else if (nodes.length === 3 && nodes[1] === "events" && nodes[2] && nodes[2] !== "create") {
        const parts = nodes[2].split("-");
        navigate(`/events/create/${parts[0]}`);
      } else navigate("/events/create");
      e.stopPropagation();
    }
  };

  const onRowClick = (row, e) => {
    e.stopPropagation();
    // setCurrentEvent(row);
    getAndSetFullEvent(row._id);
    const basePath = new URL(window.location).pathname;
    const nodes = basePath.split("/");
    if (nodes?.length >= 4 && nodes[2] === "venue") {
      navigate(`/events/venue/${row?.venueSlug}/${row?.eventUrl}`);
    } else
      navigate(`/events/${row?.eventUrl}${navigateToAction ? `/action/${navigateToAction}` : ""}`);
  };

  const handleViewClick = () =>
    setCalendarListView((prev) => (prev === "List" ? "Calendar" : "List"));

  const filteredData = useMemo(() => {
    const eventsData = events?.data ? events : { data: [], count: 0 };
    if (isEmployeeUser(userType)) {
      eventsData.data = filterApplicantEvents(currentApplicant, eventsData?.data);
    }
    return eventsData;
  }, [events, userType, currentApplicant]);

  useEffect(() => {
    if (refetchEvents) setRefetchEventsTable?.(refetchEvents);
  }, [refetchEvents]);

  return (
    <Card className={classes.box}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        // alignItems={{ xs: "center", md: "flex-end" }}
        flexDirection={{ xs: "column", sTablet: "row" }}
        py={1}
      >
        <Grid item xs={12} sTablet={6}>
          <MDBox display="flex" flexWrap="wrap">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="success"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">event-seat</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title} - {calendarListView} View{" "}
              {events?.data?.length
                ? `- ${events.data.length} ${company?.companyType === "Venue" ? "Event" : "Interview"
                }${events.data?.length > 1 ? "s" : ""}`
                : ""}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && userType !== "User" && (
                <Grid item xs={12}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} sTablet={6} alignItems="center">
          <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
            {showSearchBar && (
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  {userType !== "User" && company?.companyType === "Venue" && (
                    <Grid item sm={12}>
                      <Searchbar
                        fetch={fetchEvents}
                        fetchAll={false}
                        placeholder={`Search ${company?.companyType === "Company" ? "Interview" : "Event"
                          }`}
                        columns={columns.slice(1, columns.length - 1)}
                        queryCharacterLimit={4}
                        resultsLimit={25}
                        setFilters={setFilters}
                        setPage={setPage}
                        searchBy={["eventUrl", "eventName"]}
                        filterBy="eventUrl"
                        idField="_id"
                        scrollLimit={200}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item sm={showSearchBar ? 4 : 10} alignItems="flex-end" flexWrap="nowrap">
              <Grid container onClick={handleViewClick} justifyContent="center" flexWrap="nowrap">
                <Grid item>
                  {calendarListView === "Calendar" ? (
                    <ListIcon mt={1} color="primary" fontSize="large" />
                  ) : (
                    <DateRangeIcon color="success" fontSize="large" />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip title="Click to Toggle Calendar/List View" justifyContent="right">
                    <MDTypography
                      variant="h5"
                      color="dark"
                      sx={{ ml: 1, mt: 1 }}
                      whiteSpace="nowrap"
                    >
                      {`Show ${calendarListView === "Calendar" ? "List" : "Calendar"} View`}
                    </MDTypography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {userType !== "User" && (
              <MDBox>
                {company?.companyType === "Venue" && (
                  <Tooltip title="Event Payroll Export">
                    <IconButton color="info" onClick={() => togglePayrollModal(true)}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )}
                {userType !== "Client" && userType !== "Event Admin" && (
                  <Tooltip
                    title={`Create New ${company?.companyType === "Venue" ? "Event" : "Interview"}`}
                  >
                    <IconButton className={classes.addButton} color="info" onClick={addNew}>
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        {calendarListView === "Calendar" ? (
          <Grid container alignItems="center">
            <Grid item xs={12} style={{ maxHeight: 780, overflow: "scroll" }}>
              <EventCalendar
                events={events}
                isLoading={isLoading}
                sortByEventDate={sortByEventDate}
                calendarListView={calendarListView}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <DataTable
              columns={columns}
              data={filteredData}
              onRowClick={(row, e) => {
                e.stopPropagation();
                setEventPreview(row);
                setOpen(true);
              }}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              order={orderBy === "eventDate" && filters?.timeFrame === "Past" ? "desc" : order}
              orderBy={orderBy}
              toggleSort={toggleSort}
              defaultSort={defaultSort}
              fetchAll={fetchAll}
              isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
              greybar
              divider
              idField="_id"
            />
          </>
        )}
      </MDBox>
      {isTimeModalOpen && (
        <TimeInOutModal
          isOpen={isTimeModalOpen}
          toggleOpen={toggleTimeModal}
          event={currentEvent}
        />
      )}
      {isPayrollModalOpen && (
        <PayrollModal isOpen={isPayrollModalOpen} toggleOpen={togglePayrollModal} />
      )}
      {pageLimitConfirmationModal}
    </Card>
  );
};
ClientEventsTable.defaultProps = {
  title: "Events",
  showFiltersList: true,
};

// Typechecking props
ClientEventsTable.propTypes = {
  title: PropTypes.string,
  showFiltersList: PropTypes.bool,
};

export default ClientEventsTable;
