import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import useYupValidationResolver from "hooks/useYupValidationResolver";
// @mui material components
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Alert, Snackbar } from "@mui/material";
import { useForm } from "react-hook-form";
import deleteIssue from "layouts/pages/issues/actions/deleteIssue";
import fetchIssueById from "layouts/pages/issues/actions/fetchIssueById";
import IssuesFilterCards, { initialCards } from "layouts/pages/issues/components/IssuesFilterCards";
import IssuesTable from "layouts/pages/issues/components/IssuesTable";
import useDropdowns from "hooks/useDropdowns";
import useSessionAuth from "hooks/useSessionAuth";
import IssuesInformation from "./components/IssuesInformation";
import IssuesAttachments from "./components/IssuesAttachments";
import IssuesComments from "./components/IssueComments";
import issueSchema from "./issueSchema";

function Issues({ mode = "edit" }) {
  const navigate = useNavigate();

  const { dropdowns: issuesCategories } = useDropdowns({ entity: "issuesCategories" });
  const { dropdowns: issuesTypes } = useDropdowns({ entity: "issuesTypes" });
  const { dropdowns: issuesStatuses } = useDropdowns({ entity: "issuesStatuses" });


  const { currentIssue, setCurrentIssue, currentLoggedUser, userType, company } =
    useAppContextController();

  const isEdit = !!currentIssue?._id;
  const defaultValues = {
    issueTitle: "",
    category: "",
    modifiedDate: null,
    type: "",
    status: "closed",
    comments: [],
    issueDescription: `<p></p>`
  };
  const resolver = useYupValidationResolver(issueSchema(issuesCategories, issuesTypes, issuesStatuses));
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    formState: { isDirty, dirtyFields, errors, isSubmitting },
  } = useForm({
    mode: "onBlur", defaultValues: isEdit ? currentIssue : defaultValues, resolver
  });

  const { issueId, action } = useParams();
  const [selectedId, setSelectedId] = useState(issueId);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [filters, setFilters] = useState({});
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [panelSelect, setPanelSelect] = useState("comments");
  const queryClient = useQueryClient();
  const { logoutAuth0User } = useSessionAuth();

  function panelSelector(selection) {
    switch (selection) {
      case "comments":
        return <IssuesComments setValue={setValue} getValues={getValues} control={control} dirtyFields={dirtyFields} reset={reset} handleSubmit={handleSubmit} />;
      case "attachments":
        return <IssuesAttachments />;

      default:
        return null;
    }
  }

  const deleteIssueHandler = async (deleteIssueId, issueTitle) => {
    try {
      const res = await deleteIssue(deleteIssueId);

      if (res?.status === 200) {
        await queryClient.invalidateQueries("issues");

        setToastAlert({
          isShow: true,
          message: `Issue ${issueTitle} has been deleted`,
          status: "success",
        });
        return { success: true };
      }

      setToastAlert({
        isShow: true,
        message: `Something went wrong -- ${res?.data?.message}`,
        status: "error",
      });
      return { success: false, message: res?.data?.message };
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
      return { success: false, message: String(error) };
    }
  };

  useEffect(() => {
    setSelectedId(issueId);
  }, [issueId]);


  useEffect(() => {
    const getAndSetIssue = async () => {
      const issues = await fetchIssueById(selectedId);
      if (issues && issues?.data?.length) setCurrentIssue(issues?.data[0]);
    };
    if (issueId && !filters._id && action) {
      setFilters({ _id: issueId });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }

    if (selectedId && currentIssue?._id !== selectedId) {
      try {
        getAndSetIssue();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [selectedId, action, currentIssue?._id, issueId, actionSelected]);


  useEffect(() => {
    reset(currentIssue);
  }, [currentIssue]);

  // Default Filter Card
  useEffect(() => {
    if (isEmpty(filters) || (!issueId && filters._id)) {
      const { filterField, filterValue } = initialCards.find((card) => card.default);
      if (filterField && filterValue) {
        setFilters(() => ({ [filterField]: filterValue }));
      }
    }
  }, [issueId, filters]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={12} xl={12} xxl={12}>
          <IssuesFilterCards
            setFilters={setFilters}
          // setPage={setPage}
          />
          <IssuesTable
            id={issueId}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Issues"
          />
        </Grid>
        {["issuesinfo", "issuesall", ""].includes(actionSelected) &&
          (issueId || mode === "create") && (
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={12} sm={6}>
                  <IssuesInformation
                    watch={watch}
                    setValue={setValue}
                    reset={reset}
                    clearErrors={clearErrors}
                    mode={mode}
                    deleteIssue={deleteIssueHandler}
                    setPanelSelect={setPanelSelect}
                    panelSelect={panelSelect}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    control={control}
                    dirtyFields={dirtyFields}
                  />
                </Grid>
                <Grid item xs={12} sm={panelSelect === "" ? 12 : 6}>
                  {panelSelector(panelSelect)}
                </Grid>

              </Grid>

            </Grid>
          )}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Issues.defaultProps = {
  mode: "edit",
};

// Typechecking props
Issues.propTypes = {
  mode: PropTypes.string,
};
export default Issues;
