import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FileDownload, Mail, PublishedWithChanges } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import VenueStatusButton from "components/VenueStatusButton";
import { useAppContextController } from "context/AppContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import ApplicantsFilterCards from "layouts/pages/applicants/components/ApplicantFilterCards";
import JobsApplicantsTableActions from "layouts/pages/jobs/components/JobsApplicantsTableActions";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
// import { applicantFields } from "components/StaffingPoolExportModal/ApplicantExportFields";
import StaffingPoolFilterCards from "layouts/pages/employees/components/StaffingPoolFilterCards";
import TagSearchAndToggle from "components/TagSearchAndToggle";
import { isEmpty } from "lodash";
import { BulkApplicantStageChangeModal } from "layouts/pages/events/components/BulkApplicantStageChangeModal";
import NoApplicantsModal from "layouts/pages/applicants/components/NoApplicantsModal";
import OnboardingStatus from "components/OnboardingStatus";
import usePreferredPageSize from "hooks/usePreferredPageSize";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const JobsApplicantsTable = ({
  type = "Staffing",
  fetchAll = false,
  title = "Job Applicants",
  filters,
  pageSize = 5,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  jobSlug = null,
  showEventStatus = false,
  setSelectedId,
  staffingPoolName,
}) => {
  const queryClient = useQueryClient();
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const { currentApplicant, setCurrentVenue, setCurrentApplicant, currentVenue, company, venues, currentJob } =
    useAppContextController();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [venueId, setVenueId] = useState(null);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isBulkApplicantStageChangeModalOpen, toggleBulkApplicantStageChangeModal] =
    useState(false);
  const [openNoApplicantsModal, toggleNoApplicantsModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [searchTagList, setSearchTagList] = useState([]);
  const [useOrTags, setUseOrTags] = useState(true);

  const defaultFilters = {
    status: type === "Staffing" ? "Employee" : "Applicant",
  };
  if (type !== "Staffing") defaultFilters.applicantStatus = "New";

  const [applicantsFilters, setApplicantsFilters] = useState(defaultFilters);
  const [recipientText, setRecipientText] = useState("All");

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(pageSize);

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters.tags;
    setApplicantsFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(true);
  };

  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const additionalOptions = {
    includeVerified: true,
    filters: {
      status: type === "Staffing" ? "Employee" : "Applicant",
      ...filters,
      ...applicantsFilters,
    },
    useOrTags,
    useElemMatch: true,
  };
  const allOptions = {
    ...options,
    ...additionalOptions,
  };
  const fetchAllOptions = {
    fetchAll: true,
    ...additionalOptions,
  };

  const { data: allApplicants } = useQuery(["jobsapplicants", fetchAllOptions], () =>
    fetchApplicants(fetchAllOptions)
  );

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["jobsapplicants", allOptions], () => fetchApplicants(allOptions));

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      // setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);
  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, id, applicants?.data]);

  useEffect(() => {
    if (venueSlug && venues) {
      setVenueId(venues?.[venueSlug]?._id);
    }
    if (venueSlug && (!currentVenue || currentVenue?.slug !== venueSlug)) {
      const venue = venues?.[venueSlug];
      if (venue) setCurrentVenue(venue);
    }
  }, [venueSlug, venues]);

  const columns = [
    ...(company?.companyType === "Company"
      ? []
      : [
        {
          title: "Avatar",
          field: "profileImg",
          customCell: (image, field, row) => (
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.firstName}
              lastName={row?.lastName}
              userId={row?.userRecordId ? row.userRecordId : row?._id}
              size="md"
            />
          ),
        },
      ]),
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, field, row) => {
        if (row.isDnu === "Yes")
          return (
            <Tooltip title="Do not use">
              <BlockIcon fontSize="large" color="error" />
            </Tooltip>
          );
        if (flagged === "Yes")
          return (
            <Tooltip title="Check Employee Notes">
              <FlagIcon fontSize="large" color="error" />
            </Tooltip>
          );
        return "";
      },
    },
    {
      title: "Onboard",
      field: "profileImg",
      customCell: (image, field, row) => <OnboardingStatus applicant={row} size="md" />,
    },
    { title: "Type", field: "status" },
    { title: "Applicant Status", field: "applicantStatus" },
    ...(venueSlug && currentVenue
      ? [
        {
          title: "Venue Status",
          field: "slug",
          customCell: (_id, field, row) => (
            <>
              <VenueStatusButton
                status={getApplicantVenueStatus(row, venueSlug)}
                venue={currentVenue}
                onUpdate={() => refetchApplicants()}
                applicant={row}
              />
            </>
          ),
        },
      ]
      : []),
    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    ...(type === "Staffing" ? [{ title: "Login", field: "loginVerified" }] : []),
    // {
    //   title: "Hire Date",
    //   field: "hireDate",
    //   customCell: (date) => moment(date).format("YYYY-MM-DD"),
    // },
    // { title: "Rep", field: "" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Rank", field: "rank" },
    { title: "Source", field: "referralSource" },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Applicant Actions",
      field: "_id",
      customCell: (rowId, field, row) => (
        <JobsApplicantsTableActions
          id={rowId}
          row={row}
          type={type}
          setCurrentApplicant={setCurrentApplicant}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
          parentTable="jobs"
        />
      ),
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      type === "Staffing"
        ? `/employees/${applicant._id}/action/employeeinfo?backTo=jobs`
        : `/applicants/${applicant._id}/action/applicantinfo?backTo=jobs`
    );
  };

  const addNew = (e) => {
    if (!currentApplicant) {
      setCurrentApplicant({});
    }
    setNavigateUrl("/applicants/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  useEffect(() => {
    if (applicantsFilters?.["licenses.licenseType.value"])
      setRecipientText(`Licenses: ${applicantsFilters?.["licenses.licenseType.value"]}`);
    if (applicantsFilters?.status) {
      if (applicantsFilters?.status?.includes("Applicant")) setRecipientText("All");
      else setRecipientText(applicantsFilters?.status);
    }
  }, [applicantsFilters]);

  const handleBulkStageChangeModal = () => {
    if (allApplicants?.data?.length > 0) {
      toggleBulkApplicantStageChangeModal(true);
    } else {
      toggleNoApplicantsModal(true);
    }
  };

  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor={type === "Staffing" ? "error" : "warning"}
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-4}
                >
                  <Icon fontSize="large">people</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <TagSearchAndToggle
                filters={applicantsFilters}
                setFilters={setApplicantsFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                useOrTags={useOrTags}
                setUseOrTags={setUseOrTags}
              />
              {showSearchBar && (
                <Searchbar
                  fetch={fetchApplicants}
                  fetchAll={false}
                  placeholder="Search Applicants"
                  columns={columns.slice(1, columns.length - 1)}
                  queryCharacterLimit={3}
                  resultsLimit={15}
                  setFilters={setFilters}
                  setPage={setPage}
                  searchBy={["firstName", "lastName", "email", "altEmail"]}
                  filterBy="_id"
                  scrollLimit={200}
                  sortFunction={sortLastFirst}
                // onRowClick={(item) => {
                //   if (item?._id) setSelectedId(item._id);
                // }}
                />
              )}
              <MDBox>
                <Tooltip title="Bulk Stage Change">
                  <IconButton color="success" onClick={() => handleBulkStageChangeModal()}>
                    <PublishedWithChanges fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Bulk Message">
                  <IconButton color="error" onClick={() => toggleBulkMessageModal(true)}>
                    <Mail fontSize="large" />
                  </IconButton>
                </Tooltip>
                {currentVenue?.slug && (
                  <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                    <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Create a New Employee">
                  <IconButton className={classes.addButton} color="info" onClick={addNew}>
                    <AddCircleRoundedIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            </Grid>
          </Grid>
          {showFiltersList && !isEmpty(searchTagList) && (
            <MDBox ml={3} mb={2}>
              <Grid item xs={6}>
                <FiltersList
                  filters={applicantsFilters}
                  setFilters={setApplicantsFilters}
                  searchTagList={searchTagList}
                  setSearchTagList={setSearchTagList}
                  clearTagsHandler={clearTagsHandler}
                  onlyDisplayTags
                />
              </Grid>
            </MDBox>
          )}
          <MDBox ml={3}>
            {type === "Staffing" ? (
              <StaffingPoolFilterCards
                setPage={setPage}
                parent="Venues"
                setFilters={setApplicantsFilters}
                filters={{ ...filters, ...applicantsFilters }}
                doReplace
                useElemMatch
                baseFilters={{ status: "Employee", ...filters }}
              />
            ) : (
              <ApplicantsFilterCards
                parent="Jobs"
                setFilters={setApplicantsFilters}
                filters={{ ...filters, ...applicantsFilters }}
                doReplace
                useElemMatch
                baseFilters={{ status: "Applicant", ...filters }}
              />
            )}
          </MDBox>
          <MDBox pt={2} pb={2}>
            <DataTable
              columns={columns}
              data={applicants || { data: null, count: 0 }}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isLoading}
              onRowClick={(row, e) => handleRowClick(row, e)}
              order={order}
              orderBy={orderBy}
              toggleSort={toggleSort}
              fetchAll={fetchAll}
              defaultSort="createdDate:desc"
              idField="_id"
            // returnAfterClick
            />
          </MDBox>
          <ApplicantActionModal
            open={genericModalOpen}
            setOpen={setGenericModalOpen}
            modalInfo={modalInfo}
            setToastAlert={setToastAlert}
            currentApplicant={currentApplicant}
            messageContext="Venue"
            venueId={venueId}
          />
        </SnackbarProvider>
      </Card>
      {isBulkApplicantStageChangeModalOpen && (
        <BulkApplicantStageChangeModal
          applicants={allApplicants?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkApplicantStageChangeModalOpen}
          toggleModal={toggleBulkApplicantStageChangeModal}
          isLoading={false}
          status={allOptions.filters.status}
          jobSlug={jobSlug}
        />
      )}
      {openNoApplicantsModal && (
        <NoApplicantsModal open={openNoApplicantsModal} setOpen={toggleNoApplicantsModal} />
      )}
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={recipientText}
          applicants={allApplicants?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          attachmentContext="Job"
          messageContext="Venue"
          venueId={venueId}
          venueSlug={currentVenue?.slug}
        />
      )}
      {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          jobSlug={currentJob?.jobSlug}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
          filters={additionalOptions.filters}
          useElemMatch
          useOrTags
        />
      )}
      {pageLimitConfirmationModal}
    </>
  );
};

// Setting default values for the props
JobsApplicantsTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
JobsApplicantsTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default JobsApplicantsTable;
