import { useMutation, QueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import updateUser from "layouts/pages/users/actions/updateUser";
import TableAction from "components/TableAction";

const ApplicantVenuesTableActions = ({ slug, row, setOpen, setVenuePreview }) => {
  const { currentLoggedUser, setCurrentLoggedUser } = useAppContextController();

  const navigate = useNavigate();
  const queryClient = new QueryClient();

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("myVenues");
      localStorage.setItem("myVenues", data.venues);
      setCurrentLoggedUser(data);
    },
  });

  const isFavorite = (venueSlug) =>
    currentLoggedUser?.venues &&
    currentLoggedUser?.venues?.length &&
    currentLoggedUser.venues.includes(venueSlug);

  const setFavoritesAndRequery = (venueSlug, e) => {
    e.stopPropagation();

    let faves = currentLoggedUser?.venues ? [...currentLoggedUser.venues] : [];
    faves = isFavorite(venueSlug)
      ? faves.filter((item) => item !== venueSlug)
      : Array.from(new Set([...faves, venueSlug]));

    const loggedUser = { ...currentLoggedUser };
    loggedUser.venues = faves;

    updateUserMutation.mutate({ userId: loggedUser.userId, data: loggedUser });
  };

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    navigate(`/venues/${slug}/action/${action}`);
  };

  const setPreviewOpen = (venue, e) => {
    e.stopPropagation();
    setVenuePreview(venue);
    setOpen(true);
  };
  return (
    <span key={`table_actions_span_${slug}`}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("venueinfo", e)}
        tooltip="Venue Info"
      />
      {/* &nbsp;&nbsp;
      <TableAction
        color="info"
        icon={<PreviewIcon />}
        clickAction={(e) => setPreviewOpen(row, e)}
        tooltip="Venue Preview"
      /> */}
      {/* &nbsp;&nbsp; */}
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("venueall", e)}
        tooltip="All Venue Information"
      /> */}
    </span>
  );
};
export default ApplicantVenuesTableActions;

ApplicantVenuesTableActions.defaultProps = {
  slug: "",
};

// Typechecking props for the TableAction
ApplicantVenuesTableActions.propTypes = {
  slug: PropTypes.string,
};
