import * as yup from "yup";

const issueSchema = (issuesCategories, issuesTypes, issuesStatuses) => {
    return yup.object().shape({
        issueTitle: yup
            .string()
            .required("Isssue Title is Required"),
        category: yup
            .string()
            .required("Issue Category is Required")
            .test({
                name: "validate-category",
                test: (value, ctx) => {
                    if (!issuesCategories?.includes(value))
                        return ctx.createError({ message: "Insert a valid category" })
                    return true;
                }
            }),
        type: yup
            .string()
            .required("Issue Type is Required")
            .test({
                name: "validate-type",
                test: (value, ctx) => {
                    if (!issuesTypes?.includes(value))
                        return ctx.createError({ message: "Insert a valid type" })
                    return true;
                }
            }),
        status: yup
            .string()
            .required("Issue Status is Required")
            .test({
                name: "validate-status",
                test: (value, ctx) => {
                    if (!issuesStatuses?.includes(value))
                        return ctx.createError({ message: "Insert a valid status" })
                    return true;
                }
            }),
        issueDescription: yup
            .string()
            .test({
                name: "validate-description",
                test: (value, ctx) => {
                    const finalValue = value !== "" && value !== "<p><br></p>";
                    if (!finalValue)
                        return ctx.createError({ message: "Description is required" })
                    return true;
                },
            })
            .typeError("Invalid Phone Input"),
    });
};

export default issueSchema;