import { baseAxios } from "config";

const axios = require("axios");

export default async function fetchUserByEmail(email, outsideMode = "") {
  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;
    const res = await baseAxios.get(
      `/${outsideModeQuery}users/email/${encodeURIComponent(email)}?filter=status:Active`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return null;
    console.log("error", err.message);
    return { success: false, message: err.message };
  }
}
