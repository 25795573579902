import { Box, styled, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";

import EventDate from "components/EventDate";
import VenueIcon from "components/VenueIcon";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useEventContext } from "context/EventContext";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import usePayrollExportStore from "stores/usePayrollExportStore";
import { useAppContextController } from "context/AppContext";

const Table = styled(DataGrid)(() => ({
  "& .MuiDataGrid-renderingZone": {
    minHeight: "75px !important",
    height: "auto",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "unset !important",
    minHeight: "75px !important",
    height: "auto",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-row": {
    minHeight: "75px !important",
    height: "auto",
  },
}));

const EventsDataGrid = () => {
  const { eventsTableFilter } = useEventContext();
  const { venues } = useAppContextController();
  const { setEventIds, toDate, fromDate } = usePayrollExportStore((state) => ({
    toDate: state.toDate,
    fromDate: state.fromDate,
    setEventIds: state.setEventIds,
  }));

  const payload = {
    startDate: moment(fromDate).startOf("day").toISOString(),
    endDate: moment(toDate).endOf("day").toISOString(),
    filters: { eventType: "Event" },
    fetchAll: true,
    ...eventsTableFilter,
  };

  delete payload.filters.timeFrame;

  const {
    data: eventsData,
    isLoading,
    refetch: refetchEvents,
  } = useQuery(
    ["payrollEvents", payload],
    () => {
      return fetchEvents({ ...payload, venues });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo(
    () => [
      {
        headerName: "Venue",
        field: "logoUrl",
        width: 250,
        renderCell: ({ value, row }) => (
          <FlexWrapper flexWrap="nowrap" justifyContent="start" sx={{ gap: "10px" }}>
            <VenueIcon logoUrl={value} slug={row?.venueSlug} name={row?.venueName} />
            <Typography
              sx={{
                fontSize: "16px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {row?.venueSlug?.toUpperCase()}
            </Typography>
          </FlexWrapper>
        ),
      },
      {
        headerName: "Event",
        field: "eventName",
        width: 300,
      },
      {
        headerName: "Event Date",
        field: "eventDate",
        width: 290,
        renderCell: ({ value, row }) => (
          <EventDate date={value} reportTimeTBD={row?.reportTimeTBD} />
        ),
      },
      {
        headerName: "Location",
        field: "venueCity",
        align: "center",
        width: 150,
        renderCell: ({ value, row }) => (
          <span>
            {row.venueState}, {value}
          </span>
        ),
      },
      {
        headerName: "# Requested",
        field: "positionsRequested",
        width: 150,
      },
    ],
    []
  );

  const parsedRows = useMemo(
    () =>
      eventsData?.data?.length
        ? eventsData.data.map((item) => ({
            ...item,
            id: item._id,
          }))
        : [],
    [eventsData]
  );

  return (
    <Box
      sx={{
        height: "400px",
        width: "100%",
      }}
    >
      <Table
        rows={parsedRows}
        columns={columns}
        checkboxSelection
        onSelectionModelChange={(val) => setEventIds(val)}
        disableSelectionOnClick
        getRowHeight={() => "auto"}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default EventsDataGrid;
