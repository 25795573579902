import Box from "@mui/material/Box";
import noteTypes from "assets/noteTypes.json";
import AddANote from "components/AddANote";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SendMessage from "components/SendMessage";
import { useState } from "react";

const ApplicantActionModal = ({
  modalInfo,
  open,
  setOpen,
  setToastAlert,
  currentApplicant,
  messageContext,
  attachmentContext,
  venueId,
  onSuccess,
}) => {
  const [filters, setFilters] = useState({});
  const options = { fetchAll: true };
  const allOptions = { ...options, filters };
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(!!modalInfo?.data?.email);
  const [system, setSystem] = useState(false);
  // const { data: applicant, isLoading } = useQuery(["applicants", allOptions], () =>
  //     fetchApplicants(allOptions)
  // );

  let modalBody;
  let header;
  let buttons;

  if (["applicantmail", "employeemail"].includes(modalInfo?.type)) {
    header = (
      <Box display="flex" key={`box_sendMessage_${modalInfo?.data?.id}`}>
        <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
          <MDTypography variant="h5" color="dark">
            Send Message to {modalInfo?.data?.lastName} {modalInfo?.data?.firstName}
          </MDTypography>
          {email && (
            <MDTypography variant="body2" color="info" fontWeight="bold">
              {`E-mail: ${modalInfo?.data?.email}`}
            </MDTypography>
          )}
          {text && (
            <MDTypography variant="body2" color="info" fontWeight="bold">
              {`Phone: ${modalInfo?.data?.phone}`}
            </MDTypography>
          )}
        </MDBox>
      </Box>
    );
    modalBody = (
      <Box>
        <SendMessage
          recipient={modalInfo?.data}
          setToastAlert={setToastAlert}
          setOpen={setOpen}
          text={text}
          setText={setText}
          email={email}
          setEmail={setEmail}
          system={system}
          setSystem={setSystem}
          applicant={currentApplicant}
          messageContext={messageContext}
          venueId={venueId}
          attachmentContext={attachmentContext}
          onComplete={onSuccess}
        />
      </Box>
    );
  } else if (modalInfo?.type === "usercomments") {
    header = (
      <MDTypography
        variant="h5"
        color="dark"
        sx={{ mt: 2, mb: 1, ml: 2 }}
        key={`text_addNote_${modalInfo?.data?.id}`}
      >
        Add a Note
      </MDTypography>
    );
    modalBody = <AddANote noteTypes={noteTypes} />;
  }

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "700px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default ApplicantActionModal;
