import { Grid, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import moment from "moment";
import JobsStatusRowActions from "layouts/pages/companyjobs/components/CompanyJobsStatus/JobStatusRow/JobsStatusRowActions";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import { VerifiedUser } from "@mui/icons-material";
import OnboardingStatus from "components/OnboardingStatus";
import CustomWidthTooltip from "layouts/pages/applicants/components/CustomWidthTooltip";
import InterviewRowActions from "../../CompanyJobInterviews/InterviewRow/InterviewRowActions";
import JobsStatusRankStars from "./JobsStatusRankStars";

const JobsStatusRow = ({
  row,
  refresh,
  isInterview,
  currentCustomer,
  onAddToJob,
  onChangeStatus,
  onOpenResumeModal,
  onTouchJob,
  isJobFull,
  isSearchingAll,
}) => {
  return (
    <>
      <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
        <Grid item xs={12} sm={1}>
          <OnboardingStatus applicant={row} size="md" useAltIcons />
        </Grid>
        <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
          <CustomWidthTooltip
            title={`${row?.email} \n ${row?.phone} ${
              row?.jobAgent ? ` \n Added by: ${row.jobAgent}` : ""
            }`}
          >
            <MDTypography variant="body2">
              {[row.firstName, row.lastName].join(" ")}
              {!!row?.jobAgent && (
                <VerifiedUser
                  fontSize="small"
                  color="info"
                  sx={{
                    verticalAlign: "middle",
                    marginLeft: "3px",
                  }}
                />
              )}
            </MDTypography>
          </CustomWidthTooltip>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Tooltip title={`Score: ${row.rank ? (row.rank * 100).toFixed(2) : 0}`}>
            <Grid>
              <JobsStatusRankStars rank={row.rank ?? 0} />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Tooltip
            slotProps={{ tooltip: { style: { whiteSpace: "pre-line" } } }}
            title={
              isInterview
                ? "Interview Date"
                : `Last Activity${row.jobModifiedAgent && row.jobModifiedAgentName ? ` by ${row.jobModifiedAgentName}` : ' Date'}${
                    row.jobApplyDate
                      ? `\nApply date: ${moment(row.jobApplyDate).format("MM/DD/YYYY")}`
                      : ""
                  }`
            }
          >
            <MDTypography variant="body2">
              {(!isInterview && row.jobModifiedDate) || !!row.interview?.eventDate
                ? moment(isInterview ? row.interview?.eventDate : row.jobModifiedDate).format(
                    "MM/DD/YYYY"
                  )
                : ""}
            </MDTypography>
          </Tooltip>
        </Grid>
        <Grid item flexBasis="auto">
          {isInterview ? (
            <InterviewRowActions
              currentApplicant={row}
              refresh={refresh}
              isJobStatus
              currentCustomer={currentCustomer}
              isJobFull={isJobFull}
            />
          ) : (
            <JobsStatusRowActions
              applicant={row}
              refresh={refresh}
              currentCustomer={currentCustomer}
              onAddToJob={onAddToJob}
              onChangeStatus={onChangeStatus}
              onOpenResumeModal={onOpenResumeModal}
              isJobFull={isJobFull}
              isSearchingAll={isSearchingAll}
              onTouchJob={onTouchJob}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default JobsStatusRow;
