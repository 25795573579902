import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import ContactsPanel from "components/ContactsPanel";



const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const PartnerContacts = ({ setSelectedPanel, setSelectedContact }) => {
  const { currentPartner, setCurrentPartner, currentLoggedUser, company } = useAppContextController();

  const [contacts, setContacts] = useState([]);
  const [tempContacts, setTempContacts] = useState(contacts);
  const [primaryInfo, setPrimaryInfo] = useState();
  const [currentContact, setCurrentContact] = useState(emptyValues);

  const { mutateAsync: updatePartnerMutation } = useMutation(updatePartner);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdatePartner = async (values, method = "update", onSuccess) => {
    const createAgent = currentLoggedUser?._id;
    const data = values?.contact
      ? { ...values, ...values?.contact, createAgent }
      : { ...values, createAgent };
    if (!currentPartner?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updatePartnerMutation(
        { id: currentPartner?._id, data },
        {
          onSuccess: async (res) => {
            setCurrentPartner({ ...currentPartner, ...data });
            onSuccess(method, data?.contacts);
            enqueueSnackbar("Partner contacts has been updated.", { variant: "success" });
            await queryClient.invalidateQueries("partners");
          },
        }
      );
    }
  };

  // on company change or current company update
  useEffect(() => {
    setPrimaryInfo();
    if (currentPartner?.contacts) {
      setContacts(currentPartner?.contacts);
      setTempContacts(currentPartner?.contacts);
      if (currentContact && currentContact?.primaryContact === "Yes") {
        const temp = currentPartner?.contacts?.find((item) => item?.primaryContact === "Yes");
        setCurrentContact(temp);
      }
    } else {
      setContacts([]);
      setTempContacts([]);
    }
  }, [currentPartner]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await fetchSingleVenue({
  //       venueId: currentVenue?._id,
  //     });

  //     if (!isEqual(currentVenue?.contacts, res?.contacts)) {
  //       const newData = imageUrlFixer(res, currentVenue, company?.imageUrl);
  //       setCurrentVenue(newData);
  //     }
  //   };

  //   fetchData();
  // }, [currentVenue?.contacts]);

  return (
    <ContactsPanel
      name={currentPartner?.name}
      sourceComponent="Venue"
      onUpdate={handleUpdatePartner}
      setSelectedPanel={setSelectedPanel}
      setSelectedContact={setSelectedContact}
      contacts={contacts}
      tempContacts={tempContacts}
      setTempContacts={setTempContacts}
      primaryInfo={primaryInfo}
      setPrimaryInfo={setPrimaryInfo}
      currentContact={currentContact}
      setCurrentContact={setCurrentContact}
      currentData={currentPartner}
    />
  );
};

export default PartnerContacts;
