import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import parse from "html-react-parser";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Box, Table, TableCell, TableRow } from "@mui/material";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import { isObject } from "formik";
import useSessionAuth from "hooks/useSessionAuth";
import fetchActivityById from "../../actions/fetchActivityById";


function ActivitiesDetails({ activity, displayExtraDetails = false }) {

  const { setCurrentActivity, currentActivity, company } = useAppContextController();
  const [activityApplicant, setActivityApplicant] = useState(null);
  const [activityJob, setActivityJob] = useState(null);
  const [activityVenue, setActivityVenue] = useState(null);
  const [activityTemplate, setActivityTemplate] = useState(null);
  const [activityEvent, setActivityEvent] = useState(null);

  const [userName, setUserName] = useState(null);
  const { logoutAuth0User } = useSessionAuth();
  const { id: activityId } = useParams();
  useEffect(() => {
    if (activity) setCurrentActivity(activity)
    else if (activityId) {
      try {
        const getActivity = async (id) => {
          const fetchedActivity = await fetchActivityById(id);
          if (fetchedActivity) {
            setCurrentActivity(fetchedActivity);
          }
        };
        if (activityId) {
          getActivity(activityId);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [activityId]);



  useEffect(() => {
    if (currentActivity?.integration === "Indeed") {
      setUserName("Indeed");
    } else if (
      (currentActivity?.userId === null || currentActivity?.userId === undefined) &&
      currentActivity?.integration !== "Indeed"
    ) {
      setUserName("Internet");
    } else if (currentActivity?.detail?.agent) {
      setUserName(currentActivity?.detail?.agent);
    } else {
      setUserName(`${currentActivity?.userFirstName} ${currentActivity?.userLastName}`);
    }
  }, [currentActivity?._id]);


  useEffect(() => {
    if (currentActivity?.applicantId) {
      fetchApplicants({ filters: { _id: currentActivity?.applicantId } }).then((applicant) =>
        setActivityApplicant(applicant.data[0])
      );
    } else {
      setActivityApplicant(null);
    }
    if (currentActivity?.jobId) {
      fetchJobs({ filters: { _id: currentActivity?.jobId } }).then((job) =>
        setActivityJob(job.data[0])
      );
    } else {
      setActivityJob(null);
    }
    if (currentActivity?.venueId) {
      fetchVenues({
        filters: { _id: currentActivity?.venueId },
        imageUrlBase: company?.imageUrl,
      }).then((venue) => setActivityVenue(venue.data[0]));
    } else {
      setActivityVenue(null);
    }
    if (currentActivity?.templateId) {
      // to be modified when having a fetchTemplate
      setActivityTemplate(`${currentActivity.userFirstName} ${currentActivity.userLastName}`);
    } else {
      setActivityTemplate(null);
    }
    if (currentActivity?.eventId) {
      if (currentActivity.action !== "Delete Event")
        fetchEvents({ filters: { _id: currentActivity?.eventId } }).then((event) =>
          setActivityEvent(event.data[0])
        );
      else setActivityEvent({ ...currentActivity.detail });
    } else {
      setActivityEvent(null);
    }
  }, [currentActivity?._id]);


  const array = [];
  const recursiveDetails = (details, level) => {
    Object.entries(details).forEach((values) => {
      if (isObject(values[1]) && Object.entries(values[1])) {
        array.push(
          <TableRow>
            <TableCell sx={{ backgroundColor: "#FAFAFA" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                {values[0]}
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }} />
            </TableCell>
          </TableRow>
        );
        recursiveDetails(values[1], level + 1);
      } else {
        array.push(
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
                pl={2 * level}
              >
                {values[0]}
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {values[0] === "messageBody" ? parse(values[1]) : values[1]}
              </Box>
            </TableCell>
          </TableRow>
        );
      }
    });
    return array;
  };

  return (
    <Card key={`card_${currentActivity?._id}`}>
      <MDBox display="flex" justifyContent="space-between" px={3}>
        <MDBox display="flex" pt={2}>
          <MDBox>
            <MDTypography variant="h5">
              Activity Details
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={2} sx={{ maxHeight: currentActivity && 615, height: 615, overflowX: currentActivity && "scroll" }}>
        <Table>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Activity Date
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {moment(currentActivity?.activityDate).format("ddd, MM/DD/YYYY hh:mm:ss A")}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                Description
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                {currentActivity?.description}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ backgroundColor: "#dee2e6" }}>
              <MDTypography
                sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                color="dark"
              >
                User
              </MDTypography>
            </TableCell>
            <TableCell>
              <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>{userName}</Box>
            </TableCell>
          </TableRow>

          {activityApplicant && (
            <TableRow>
              <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                <MDTypography
                  sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                  color="dark"
                >
                  Applicant
                </MDTypography>
              </TableCell>
              <TableCell>
                <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                  {activityApplicant?.firstName} {activityApplicant?.lastName}
                </Box>
              </TableCell>
            </TableRow>
          )}
          {activityVenue && (
            <TableRow>
              <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                <MDTypography
                  sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                  color="dark"
                >
                  Venue
                </MDTypography>
              </TableCell>
              <TableCell>
                <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                  {activityApplicant?.firstName}
                </Box>
              </TableCell>
            </TableRow>
          )}
          {activityJob && (
            <TableRow>
              <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                <MDTypography
                  sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                  color="dark"
                >
                  Job
                </MDTypography>
              </TableCell>
              <TableCell>
                <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                  {activityApplicant?.firstName}
                </Box>
              </TableCell>
            </TableRow>
          )}
          {activityEvent && (
            <TableRow>
              <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                <MDTypography
                  sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                  color="dark"
                >
                  Event
                </MDTypography>
              </TableCell>
              <TableCell>
                <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                  {activityEvent?.eventName}
                </Box>
              </TableCell>
            </TableRow>
          )}
          {currentActivity?.details && (
            <>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                  <MDTypography
                    sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                    color="dark"
                  >
                    Details
                  </MDTypography>
                </TableCell>
                <TableCell sx={{ backgroundColor: "#dee2e6" }} />
              </TableRow>
              {recursiveDetails(currentActivity?.details, 0)}
            </>
          )}
          {currentActivity?.detail && (
            <>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                  <MDTypography
                    sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                    color="dark"
                  >
                    Details
                  </MDTypography>
                </TableCell>
                <TableCell sx={{ backgroundColor: "#dee2e6" }} />
              </TableRow>
              {recursiveDetails(currentActivity?.detail, 0)}
            </>
          )}
          {displayExtraDetails && (
            <>
              {currentActivity?.device && (
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                    <MDTypography
                      sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                      color="dark"
                    >
                      Device
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                      {activityApplicant?.device}
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {currentActivity?.ipAddress && (
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                    <MDTypography
                      sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                      color="dark"
                    >
                      IP Address
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                      {activityApplicant?.ipAddress}
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {currentActivity?.coordinates && (
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#dee2e6" }}>
                    <MDTypography
                      sx={{ whiteSpace: "nowrap", fontSize: "0.9rem", fontWeight: "medium" }}
                      color="dark"
                    >
                      Coordinates
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ wordBreak: "break-all", fontSize: "0.9rem" }}>
                      {activityApplicant?.coordinates}
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </Table>
      </MDBox>
    </Card>
  );
}
export default ActivitiesDetails;
