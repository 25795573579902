import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { FileDownload, Mail } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import { useAppContextController } from "context/AppContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";

import moment from "moment";
import { SnackbarProvider } from "notistack";
import useSort from "utils/useSort";
import InterviewApplicantsTableActions from "./InterviewApplicantsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const InterviewApplicants = ({
  currentEvent,
  fetchAll,
  pageSize = 20,
  filters,
  showSearchBar = true,
  title = "Job Applicants",
  showFiltersList = true,
  setFilters,
  staffingPoolName,
}) => {
  const queryClient = useQueryClient();
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const { currentApplicant, setCurrentApplicant, currentVenue, company } =
    useAppContextController();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [applicantsFilters, setApplicantsFilters] = useState({});

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize); // Default pageSize higher than 10. Don't use preferred pageSize

  const { order, orderBy, toggleSort } = useSort();
  const applicantsIds = currentEvent?.applicants?.map((applicant) => applicant.id).join(";");

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    includeVerified: true,
    filters: {
      _id: applicantsIds,
      ...filters,
      ...applicantsFilters,
    },
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["interviewapplicants", allOptions], () => fetchApplicants(allOptions), {
    enabled: !!applicantsIds,
  });

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      // setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, id, applicants?.data]);

  const columns = [
    ...(company?.companyType === "Company"
      ? []
      : [
        {
          title: "Avatar",
          field: "profileImg",
          customCell: (image, field, row) => (
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.firstName}
              lastName={row?.lastName}
              userId={row?.userRecordId ? row.userRecordId : row?._id}
              size="md"
            />
          ),
        },
      ]),
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, field, row) => {
        if (row.isDnu === "Yes")
          return (
            <Tooltip title="Do not use">
              <BlockIcon fontSize="large" color="error" />
            </Tooltip>
          );
        if (flagged === "Yes")
          return (
            <Tooltip title="Check Employee Notes">
              <FlagIcon fontSize="large" color="error" />
            </Tooltip>
          );
        return "";
      },
    },
    { title: "Type", field: "status" },
    { title: "Applicant Status", field: "applicantStatus" },
    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },

    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Rank", field: "rank" },
    { title: "Source", field: "referralSource" },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Applicant Actions",
      field: "_id",
      customCell: (rowId, field, row) => (
        <InterviewApplicantsTableActions
          id={rowId}
          row={row}
          setCurrentApplicant={setCurrentApplicant}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];
  const searchColumns = [
    ...(company?.companyType === "Venue"
      ? [
        {
          title: "Avatar",
          field: "profileImg",
          customCell: (image, field, row) => (
            <PictureAvatar
              image={row?.profileImg}
              firstName={row?.firstName}
              lastName={row?.lastName}
              userId={row?.userRecordId ? row.userRecordId : row?._id}
              size="md"
            />
          ),
        },
      ]
      : []),
    { title: "Type", field: "status" },
    { title: "Applicant Status", field: "applicantStatus" },
    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },

    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Rank", field: "rank" },
    { title: "Source", field: "referralSource" },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      applicant?.status === "Employee"
        ? `/employees/${applicant._id}/action/employeeinfo?backTo=interviews`
        : `/applicants/${applicant._id}/action/applicantinfo?backTo=interviews`
    );
  };

  const addNew = (e) => {
    if (!currentApplicant) {
      setCurrentApplicant({});
    }
    setNavigateUrl("/applicants/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };
  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="warning"
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-4}
                >
                  <Icon fontSize="large">people</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
                <MDBox ml={3}>
                  {showFiltersList && (
                    <Grid item xs={6}>
                      <FiltersList filters={applicantsFilters} setFilters={setApplicantsFilters} />
                    </Grid>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              {showSearchBar && (
                <Searchbar
                  fetch={fetchApplicants}
                  fetchAll={false}
                  placeholder="Search Applicants"
                  columns={searchColumns}
                  queryCharacterLimit={3}
                  resultsLimit={15}
                  setFilters={setFilters}
                  setPage={setPage}
                  searchBy={["firstName", "lastName", "email", "altEmail"]}
                  filterBy="_id"
                  scrollLimit={200}
                  sortFunction={sortLastFirst}
                // onRowClick={(item) => {
                //   if (item?._id) setSelectedId(item._id);
                // }}
                />
              )}
              <MDBox>
                <Tooltip title="Bulk Message">
                  <IconButton color="error" onClick={() => toggleBulkMessageModal(true)}>
                    <Mail fontSize="large" />
                  </IconButton>
                </Tooltip>
                {currentVenue?.slug && (
                  <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                    <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Create a New Employee">
                  <IconButton className={classes.addButton} color="info" onClick={addNew}>
                    <AddCircleRoundedIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            </Grid>
          </Grid>
          {/* <MDBox ml={3}>
            <ApplicantsFilterCards
              parent="CompanyJobs"
              setFilters={setApplicantsFilters}
              filters={{ ...filters, ...applicantsFilters }}
            />
          </MDBox> */}
          <MDBox pt={2} pb={2}>
            <DataTable
              columns={columns}
              data={applicants}
              page={page}
              limit={limit}
              setPage={setPage}
              setLimit={setLimit}
              isLoading={isLoading}
              onRowClick={(row, e) => handleRowClick(row, e)}
              order={order}
              orderBy={orderBy}
              toggleSort={toggleSort}
              fetchAll={fetchAll}
              defaultSort="createdDate:desc"
              idField="_id"
            />
          </MDBox>
          <ApplicantActionModal
            open={genericModalOpen}
            setOpen={setGenericModalOpen}
            modalInfo={modalInfo}
            setToastAlert={setToastAlert}
            currentApplicant={currentApplicant}
          />
        </SnackbarProvider>
      </Card>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText="All"
          applicants={
            applicants?.data
              ?.filter((item) => item?.email || item?.phone)
              .map((item) => ({ ...item, id: item._id })) || []
          }
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext="Applicant"
        />
      )}
      {/* {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          venueSlug={currentVenue?.slug}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
          filters={allOptions.filters}
        />
      )} */}
    </>
  );
};

// Setting default values for the props
InterviewApplicants.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
InterviewApplicants.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default InterviewApplicants;
