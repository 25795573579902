import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";

const CompanyJobsApplicantsTableActions = ({
  id,
  row,
  type,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setModalInfo,
}) => {
  const { company } = useAppContextController();
  const navigate = useNavigate();
  const route = type === "Staffing" ? "employees" : "applicants";
  const actionroute = type === "Staffing" ? "employee" : "applicant";
  const onClickHandler = (action, e) => {
    if (action === "applicantmail") {
      setCurrentApplicant(row);
      setModalInfo({ type: action, data: row });
      setGenericModalOpen(true);
    } else {
      e.stopPropagation();
      navigate(`/${route}/${id}/action/${action}`);
    }
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler(`${actionroute}info?backTo=companyjobs`, e)}
        tooltip={type === "Staffing" ? "Employee Info" : "Applicant Info"}
      />
      {company?.companyType === "Venue" ? (
        <TableAction
          color="info"
          icon="stadium"
          clickAction={(e) => onClickHandler("applicantvenues", e)}
          tooltip="Applicant Venues"
        />
      ) : (
        <TableAction
          color="info"
          icon="business"
          clickAction={(e) => onClickHandler("applicantcustomers", e)}
          tooltip="Applicant Customers"
        />
      )}
      <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickHandler("applicantjobs", e)}
        tooltip="Applicant Jobs"
      />
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler("applicantevents", e)}
        tooltip="Applicant Event"
      />
      <TableAction
        color="error"
        icon="mail"
        clickAction={(e) => onClickHandler("applicantmail", e)}
        tooltip="Applicant Mail"
      />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("applicantall", e)}
        tooltip="All Applicant Information"
      /> */}
    </span>
  );
};

export default CompanyJobsApplicantsTableActions;

CompanyJobsApplicantsTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => { },
};

// Typechecking props for the TableAction
CompanyJobsApplicantsTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
