import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, IconButton } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import SmartPhoneColumn from "components/SmartPhoneColumn";
import UsersTableActions from "layouts/pages/users/components/UserTableActions";
import { SnackbarProvider, useSnackbar } from "notistack";
import useSort from "utils/useSort";
import moment from "moment";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import DeleteModal from "components/ConfirmDialog/ConfirmModal";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import fetchUsers from "../../actions/fetchUsers";
import UserActionModal from "../UserActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const UsersTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  page,
  setPage,
}) => {
  const { setCurrentUser, currentUser, company } = useAppContextController();

  const navigate = useNavigate();
  const classes = useStyle();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { action } = useParams();

  // Pagination state
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentUser });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const { data: users, isLoading } = useQuery(["users", allOptions], () => fetchUsers(allOptions), {
    refetchInterval: 15000,
  });

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const deleteModalHandler = () => setOpenDeleteUserModal(!openDeleteUserModal);

  const columns = [
    {
      title: "Avatar",
      field: "profileImg",
      customCell: (image, field, row) => (
        <PictureAvatar
          image={row?.profileImg}
          firstName={row?.firstName}
          lastName={row?.lastName}
          userId={row?._id}
          size="md"
        />
      ),
    },
    {
      title: "Mobile",
      field: "platform",
      customCell: (platform) => {
        return <SmartPhoneColumn platform={platform} />;
      },
    },
    {
      title: "Email/Name ID",
      field: "emailAddress",
      customCell: (email) => <div style={{ paddingLeft: 0 }}>{email}</div>,
    },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "User Type", field: "userType" },
    { title: "Logged In", field: "loginVerified" },
    {
      title: "Last Logged In",
      field: "lastLoginDate",
      customCell: (date) =>
        date ? <span>{moment(date).format("ddd YYYY-MM-DD hh:mm A")}</span> : "",
    },
    {
      title: "User Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <UsersTableActions
          id={id}
          row={row}
          setCurrentUser={setCurrentUser}
          setModalInfo={setModalInfo}
          setNavigateUrl={navigate}
          setGenericModalOpen={setGenericModalOpen}
          deleteModalHandler={deleteModalHandler}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/Name ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
    { title: "Logged In", field: "loginVerified" },
  ];

  const handleRowClick = (user, e) => {
    e.stopPropagation();
    setCurrentUser({ ...user });
    navigate(`/users/${user._id}`);
  };

  const updateUserMutation = useMutation(updateProfile, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      enqueueSnackbar("User has been deleted!", { variant: "success" });
    },
  });

  const deleteUser = async () => {
    await updateUserMutation.mutateAsync({
      userId: currentUser?.userId,
      data: { status: "Deleted" },
    });
    setOpenDeleteUserModal(false);
  };

  const addNew = (e) => {
    if (!currentUser) {
      setCurrentUser({});
    }
    navigate("/users/create");
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="info"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <AccountCircleIcon fontSize="large" />
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Users
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchUsers}
                fetchAll
                placeholder="Search Users"
                columns={columnsForSearch}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "emailAddress"]}
                filterBy="_id"
                onRowClick={(user, e) => {
                  setCurrentUser(user);
                  //                  alert(JSON.stringify(user))
                  navigate(`/users/${user._id}/action/userinfo`);
                  e.stopPropagation();
                }}
              />
            )}
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={users}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={fetchAll}
            defaultSort="lastLoginDate:desc"
          />
        </MDBox>
        <UserActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        />
      </SnackbarProvider>
      {openDeleteUserModal && (
        <DeleteModal
          title="Delete User"
          description="Please confirm you want to DELETE this user."
          isOpen={openDeleteUserModal}
          negativeBtn={{
            label: "Cancel",
            fn: deleteModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deleteUser,
            isShown: true,
          }}
        />
      )}
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
UsersTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Users",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
UsersTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default UsersTable;
