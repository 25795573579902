import { Alert, Autocomplete, Grid, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import isEmpty from "lodash/isEmpty";
import { useMutation } from "react-query";
// import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import moment from "moment";
import states from "assets/stateCodes.json";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import useDropdowns from "hooks/useDropdowns";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { applicantSchema } from "data/applicant";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { parseApplicantPhone } from "utils/helpers/applicant";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { baseAxios } from "config";
import FileDropzone from "components/Dropzone";
import DataListContainer from "components/DataListContainer";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import JobHistoryItem from "../JobHistoryItem";
import JobHistoryModal from "../JobHistoryModal";

const JobApplicationForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const { user } = useAppContextController();
  const resolver = useYupValidationResolver(applicantSchema);
  const { companyType, userType, currentLoggedUser, company } = useAppContextController();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const IMAGE_SERVER = company?.imageUrl;
  const [resumeExtension, setResumeExtension] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const isCompany = companyType === "Company";

  const defaultValues = isCompany
    ? {
        applicationDate: applicant?.applicationDate || new Date().toISOString().split('T')[0],
        firstName: applicant?.firstName || "",
        middleInitial: applicant?.middleInitial || "",
        lastName: applicant?.lastName || "",
        maidenName: applicant?.maidenName || "",
        socialSecurity: applicant?.socialSecurity || "",
        birthDate: applicant?.birthDate || null,
        driverLicense: applicant?.driverLicense || "",
        address1: applicant?.address1 || "",
        city: applicant?.city || "",
        state: applicant?.state || "",
        zip: applicant?.zip || "",
        phone: applicant?.phone || "",
        altPhone: applicant?.altPhone || "",
        emergencyContactNumber: applicant?.emergencyContactNumber || "",
        emergencyContactName: applicant?.emergencyContactName || "",
        criminalHistoryDisclosure: applicant?.criminalHistoryDisclosure || "",
        jobHistory: applicant?.jobHistory || [],
      }
    : {
        applicationDate: applicant?.applicationDate || new Date().toISOString().split('T')[0],
        firstName: applicant?.firstName || "",
        middleInitial: applicant?.middleInitial || "",
        lastName: applicant?.lastName || "",
        maidenName: applicant?.maidenName || "",
        socialSecurity: applicant?.socialSecurity || "",
        birthDate: applicant?.birthDate || null,
        driverLicense: applicant?.driverLicense || "",
        address1: applicant?.address1 || "",
        city: applicant?.city || "",
        state: applicant?.state || "",
        zip: applicant?.zip || "",
        phone: applicant?.phone ? parseApplicantPhone(applicant?.phone) : "",
        altPhone: applicant?.altPhone || "",
        emergencyContactNumber: applicant?.emergencyContactNumber || "",
        emergencyContactName: applicant?.emergencyContactName || "",
        criminalHistoryDisclosure: applicant?.criminalHistoryDisclosure || "",
      };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
  } = useForm({ resolver });

  const [isJobHistoryModalOpen, setJobHistoryModalOpen] = useState(false);
  const [currentJobHistory, setJobHistory] = useState({});
  const [jobHistoryIndex, setJobHistoryIndex] = useState(null);
  const { dropdowns: criminalRecords } = useDropdowns({
    entity: "criminalRecord",
    outsideMode: user ? "" : "protected",
    enabled: isCompany,
  });

  const onSubmit = async ({
    applicationDate,
    phone,
    socialSecurity,
    altPhone,
    emergencyContactNumber,
    zip,
    ...data
  }) => {
    const parsedData = {
      applicationDate: applicationDate.toISOString().split('T')[0],
      phone: phone || "",
      altPhone: altPhone || "",
      emergencyContactNumber: emergencyContactNumber || "",
      socialSecurity: socialSecurity ? socialSecurity.replace(/[^0-9]/g, "") : "",
      firstName: data.firstName,
      lastName: data.lastName,
      middleInitial: data.middleInitial,
      maidenName: data.maidenName,
      birthDate: moment(data.birthDate).format("YYYY-MM-DD"),
      driverLicense: data.driverLicense,
      address1: data.address1,
      city: data.city,
      state: data.state,
      zip: zip ? zip.replace(/\D/g, "") : "",
      emergencyContactName: data.emergencyContactName,
      criminalHistoryDisclosure: data.criminalHistoryDisclosure,
      jobHistory: data.jobHistory,
    };

    updateApplicantAction(applicant._id, parsedData);

    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
  });

  const uploadFileMutation = useMutation(
    async (resumeForm) => {
      await baseAxios.post(
        `/outside-protected/upload/applicants/${applicant?._id}/Resume`,
        resumeForm.form
      );

      const newAtt = {
        title: "Resume",
        type: "Resume",
        docType: resumeForm.extension,
        filename: resumeForm.name,
        uploadDate: new Date(),
      };
      // if we find this attachment type then overwrite the current
      const newAttachmentsArr = [newAtt];

      await updateApplicantMutation.mutateAsync(
        {
          applicantId: applicant?._id,
          data: {
            attachments: [...newAttachmentsArr],
          },
          outsideMode: "protected",
        },
        {
          onSuccess: (_, data) => {
            setToastAlert({
              isShow: true,
              message: "Resume Has Been Uploaded!",
              status: "success",
            });
          },
        }
      );
    },
    {
      onSuccess: (_, data) => {
        // setValue(
        //   "resumeUrl",
        //   `${IMAGE_SERVER}/resumes/${job.jobSlug}/${getValues().email}/${data?.name}`
        // );
        setResumeExtension(data?.extension);
        setFileName(data?.name);
        setToastAlert({ isShow: true, message: "Resume Has Been Uploaded!", status: "success" });
      },
    }
  );

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);
      uploadFileMutation.mutate({
        form,
        type: "Resume",
        name: file?.name,
        extension: file?.path?.split(".").pop(),
      });
    }
  };

  const handleJobHistory = (idx, row, isOpen) => {
    setJobHistoryIndex(idx);
    setJobHistory(row);
    setJobHistoryModalOpen(isOpen);
  };

  const handleJobHistoryChange = (jobHistory) => {
    setValue("jobHistory", jobHistory, { shouldDirty: true });
  };

  const handleRemoveJobHistory = (idx) => {
    const newJobHistoryList = watch("jobHistory");
    const updatedJobHistory = newJobHistoryList.filter((_, index) => index !== idx);
    setValue("jobHistory", updatedJobHistory);
    setJobHistoryModalOpen(false);
  };

  const renderJobHistory = (row, idx) => (
    <JobHistoryItem
      row={row}
      idx={idx}
      onEdit={handleJobHistory}
      onRemove={handleRemoveJobHistory}
    />
  );

  useEffect(() => {
    if (applicant) {
      const resume = applicant?.attachments?.find((attachment) => attachment.type === "Resume");
      if (resume) {
        setResumeExtension(resume?.docType);
      }

      reset(defaultValues, { keepErrors: true });
    }
  }, [applicant?._id]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: true,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    reset(defaultValues, { keepErrors: true });
  }, [applicant]);

  const avatar = (
    <MDBox>
      {!!company?.imageUrl &&
      !!company?.uploadUrl &&
      (resumeExtension?.toLowerCase() === "docx" || resumeExtension?.toLowerCase() === "rtf") ? (
        <img
          src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
          alt="Word Doc Preview"
          width={100}
          height={100}
        />
      ) : (
        resumeExtension?.toLowerCase() === "pdf" &&
        !!company?.imageUrl &&
        !!company?.uploadUrl && (
          <img
            src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
            alt="PDF Preview"
            width={100}
            height={100}
          />
        )
      )}
    </MDBox>
  );

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)} id="current-form">
        <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="body2">
                Please review your email address, first and last name for accuracy before
                proceeding.
              </MDTypography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="applicationDate"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Application Date"
                        disabled
                        {...field}
                        value={
                          applicant?.applicationDate
                            ? applicant?.applicationDate
                            : new Date().toISOString().split('T')[0]
                        }
                        inputProps={{ tabIndex: -1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <CustomTextField
                    disabled
                    inputProps={{ tabIndex: -1 }}
                    value={applicant?.email}
                    variant="outlined"
                    label="Email Address"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="First Name"
                        showError={!isEmpty(errors.firstName)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="middleInitial"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Middle Initial"
                        showError={!isEmpty(errors.middleInitial)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Last Name"
                        showError={!isEmpty(errors.lastName)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="maidenName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Maiden Name"
                        showError={!isEmpty(errors.maidenName)}
                        {...field}
                      />
                    )}
                  />
                </Grid>{" "}
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="socialSecurity"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="###-##-####"
                        allowEmptyFormatting
                        mask="_"
                        label="Social Security"
                        variant="outlined"
                        value={field.value}
                        onChange={field.onChange}
                        onValueChange={({ value }) => setValue("socialSecurity", value)}
                        customInput={CustomTextField}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disableFuture
                          value={!field.value ? null : field.value}
                          label="Date of Birth"
                          renderInput={(params) => (
                            <CustomTextField variant="outlined" {...params} />
                          )}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        variant="outlined"
                        label="Mobile Number"
                        value={field.value}
                        onChange={field.onChange}
                        onValueChange={({ value }) => setValue("phone", value)}
                        customInput={CustomTextField}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="altPhone"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        variant="outlined"
                        label="Alternate Number"
                        value={field.value}
                        onChange={field.onChange}
                        onValueChange={({ value }) => setValue("altPhone", value)}
                        customInput={CustomTextField}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="address1"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Address (include Unit #)"
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={4} xs={12} md={6}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="City"
                        showError={!isEmpty(errors.city)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} xs={12} md={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={states}
                        name="state"
                        autoSelect
                        freeSolo
                        defaultValue={null}
                        value={field.value || ""}
                        onChange={(e, v) => {
                          setValue("state", v?.toUpperCase());
                        }}
                        sx={{ maxHeight: "44px" }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="outlined"
                            type="text"
                            label="State"
                            sx={{
                              "& .MuiInputBase-root": {
                                py: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} xs={12} md={6}>
                  <Controller
                    name="zip"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField variant="outlined" label="Zip Code" {...field} />
                    )}
                  />
                </Grid>

                <Grid item lg={isCompany ? 6 : 12} xs={12}>
                  <Controller
                    name="driverLicense"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField variant="outlined" label="Driver License" {...field} />
                    )}
                  />
                </Grid>
                {isCompany && (
                  <Grid item lg={6} xs={12}>
                    <Controller
                      name="criminalHistoryDisclosure"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={criminalRecords || []}
                          autoSelect
                          freeSolo
                          value={field.value || ""}
                          onChange={(e, v) => {
                            setValue("criminalHistoryDisclosure", v, { shouldDirty: true });
                          }}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              variant="outlined"
                              type="text"
                              label="Criminal History Disclosure"
                              sx={{
                                "& .MuiInputBase-root": {
                                  py: "5px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item lg={6} xs={12}>
                  <Controller
                    name="emergencyContactNumber"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="(###) ### ####"
                        allowEmptyFormatting
                        mask="_"
                        variant="outlined"
                        label="Emergency Contact Number"
                        value={field.value}
                        onChange={field.onChange}
                        onValueChange={({ value }) => setValue("emergencyContactNumber", value)}
                        customInput={CustomTextField}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Controller
                    name="emergencyContactName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        variant="outlined"
                        label="Emergency Contact Name"
                        showError={!isEmpty(errors.emergencyContactName)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {!isCompany && (
              <Grid item lg={12} xs={12}>
                <Controller
                  name="criminalHistoryDisclosure"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      multiline={isCompany}
                      rows={8}
                      variant="outlined"
                      label="Criminal History Disclosure"
                      showError={!isEmpty(errors.criminalHistoryDisclosure)}
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
            {isCompany && (
              <>
                <Grid item lg={6} xs={12}>
                  <MDTypography variant="body2">Resume upload (optional) </MDTypography>
                  <FileDropzone
                    accept={{
                      "application/*": [".pdf", ".rtf", ".docx"],
                    }}
                    disabled={
                      watch("email") === undefined || watch("email") === "" || errors?.email
                    }
                    message="Drop files here to upload"
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      onDropHandler(acceptedFiles, rejectedFiles)
                    }
                    avatar={resumeExtension && avatar}
                  />
                </Grid>

                <Grid item lg={6} xs={12} mt={-1.25}>
                  <DataListContainer
                    onAdd={handleJobHistory}
                    data={watch("jobHistory")}
                    renderRow={renderJobHistory}
                    title="Job History"
                    subtext="(Please complete this section if you did not attach a resume)"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FlexWrapper>
        <FormErrors errors={errors} />
      </form>
      {isJobHistoryModalOpen && isCompany && (
        <JobHistoryModal
          jobHistoryList={watch("jobHistory")}
          currentJobHistory={currentJobHistory}
          setJobHistory={setJobHistory}
          jobHistoryIndex={jobHistoryIndex}
          setJobHistoryIndex={setJobHistoryIndex}
          onJobHistoryChange={handleJobHistoryChange}
          isModalOpen={isJobHistoryModalOpen}
          setModalOpen={setJobHistoryModalOpen}
        />
      )}
    </>
  );
};

export default JobApplicationForm;
