import fetchCompanyJob from "api/companies/fetchCompanyJob";
import * as yup from "yup";

export const isJobSlugUnique = async (value) => {
  const unique = await fetchCompanyJob({ filters: { jobSlug: value }, fetchAll: true });
  if (unique?.total === 0) return true;
  return false;
};

export const companyJobsInfoSchema = yup.object().shape({
  companyName: yup.string().required("Company is required"),
  companySlug: yup.string().default(""),
  jobSlug: yup.string().required("JobSlug is required"),
  title: yup
    .string()
    .required("Name is required")
    .matches(/^[a-zA-Z0-9*&#\-_+() ]+$/, "Invalid Job Name."),
  markupRate: yup.number().default(0),
  payRate: yup.number().default(0),
  billRate: yup.number().default(0),
  wcCode: yup.string().default(""),
  positionQuantity: yup.number().default(1).min(1),
  // approvingManager: yup.string().default(null),
  // .test({
  // name: "valid-approving-manager",
  // test(value, ctx) {
  //     if (!approvingManagers.includes(value)) {
  //       return ctx.createError({ message: "Insert a valid approving manager" });
  //     }
  //     return true;
  //   },
  // }),
  recruiter: yup.string().default(null),
  // .test({
  // name: "valid-recruiter",
  // test(value, ctx) {
  //     if (!recruiters.includes(value)) {
  //       return ctx.createError({ message: "Insert a valid recruiter" });
  //     }
  //     return true;
  //   },
  // }),
  jobId: yup.string().default(""),
  status: yup.string().required("Status is required"),
  // .test({
  // name: "valid-status",
  // test(value, ctx) {
  //     if (!statuses.includes(value)) {
  //       return ctx.createError({ message: "Insert a valid status" });
  //     }
  //     return true;
  //   },
  // }),
  lockMarkup: yup.string().default("yes"),
  jobLogo: yup.string().default("No"),
  hideThisJob: yup.string().default("No"),
  googleMap: yup.string().default("yes"),
  companyThumb: yup.string().default("yes"),
  jobLocation: yup.string().default(""),
  description: yup.string().default(""),
  tags: yup.array().default([]),
  notificationRecipients: yup.array().default([]),
  jobAndApplicantPermissions: yup.array().default([]),
});

export const jobApplicantPermissionSchema = yup.object().shape({
  permission: yup.string().required("Select Applicant Permission"),
  // markupRate: yup
  //   .number()
  //   .default(0)
  //   .test({
  //     name: "valid-markupRate",
  //     test(value, ctx) {
  //       if (value <= 0 || !value)
  //         return ctx.createError({ message: "Markup Rate must be greater than 0" });
  //       return true;
  //     },
  //   }),
  // payRate: yup
  //   .number()
  //   .default(0)
  //   .test({
  //     name: "valid-payRate",
  //     test(value, ctx) {
  //       if (value <= 0 || !value)
  //         return ctx.createError({ message: "Pay Rate must be greater than 0" });
  //       return true;
  //     },
  //   }),
  // billRate: yup
  //   .number()
  //   .default(0)
  //   .test({
  //     name: "valid-billRate",
  //     test(value, ctx) {
  //       if (value <= 0 || !value)
  //         return ctx.createError({ message: "Bill Rate must be greater than 0" });
  //       return true;
  //     },
  //   }),
});
