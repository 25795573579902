import {
  AccountBalance,
  AccountCircle,
  Badge,
  CheckCircle,
  PersonAdd,
  RequestPage,
  ThumbUp,
} from "@mui/icons-material";
import GppGoodIcon from '@mui/icons-material/GppGood';
import ContactsIcon from '@mui/icons-material/Contacts';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import applicantStatusField from "assets/applicantStatusField.json";
import WorkIcon from '@mui/icons-material/Work';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

export const CommonIconProps = {
  width: "18px",
  height: "18px",
  color: "white",
};


export const APPLICANT_OBJECTS_ENUM = {
  VERIFICATION: "verification",
  APPLICANT_INFO: "applicantInfo",
  RESUME_AND_JOB_HISTORY: "resumeAndJobHistory",
  // RECOMMENDED_JOBS: "recommendedJobs",
  JOB_APPLICANTS_AND_INTERVIEWS: "jobApplicantsAndInterviews",
  SUBSCRIPTIONS: "subscriptions",
  // ACCOUNT_PANEL: "accountPanel",
};

export const APPLICANT_STEPS = [
  {
    id: 1,
    label: "Applicant Contact Info",
    altLabel: "Personal",
    applicantObject: APPLICANT_OBJECTS_ENUM.APPLICANT_INFO,
    icon: <ContactsIcon {...CommonIconProps} />,
  },
  {
    id: 2,
    label: "Resume & Job History",
    applicantObject: APPLICANT_OBJECTS_ENUM.RESUME_AND_JOB_HISTORY,
    icon: <ManageSearchIcon {...CommonIconProps} />,
  },
  // {
  //   id: 3,
  //   label: `Recommended Jobs`,
  //   applicantObject: APPLICANT_OBJECTS_ENUM.RECOMMENDED_JOBS,
  //   icon: <ContentPasteSearchIcon {...CommonIconProps} />,
  // },
  {
    id: 3,
    label: `Job Applications`,
    applicantObject: APPLICANT_OBJECTS_ENUM.JOB_APPLICANTS_AND_INTERVIEWS,
    icon: <WorkIcon {...CommonIconProps} />,
  },
  {
    id: 4,
    label: "Subscriptions & Settings",
    applicantObject: APPLICANT_OBJECTS_ENUM.SUBSCRIPTIONS,
    icon: <NotificationsActiveIcon {...CommonIconProps} />,
  },
  // {
  //   id: 6,
  //   label: "Account Panel",
  //   applicantObject: APPLICANT_OBJECTS_ENUM.ACCOUNT_PANEL,
  //   icon: <AccountBalance {...CommonIconProps} />,
  // },

];


export const ONBOARDING_OBJECTS_ENUM = {
  VERIFICATION: "verification",
  JOB_APPLICATION: "jobApplication",
  I9_FORM: "i9Form",
  UPLOAD: "upload",
  W4_TAX: "w4Tax",
  DIRECT_DEPOSIT: "directDeposit",
  EMPLOYER_I_9: "employerI9",
  ACKNOWLEDGEMENT: "acknowledged",
  COMPLETE: "complete",
};

export const ONBOARDING_STEPS = [

  {
    id: 1,
    label: "Job Application",
    altLabel: "Personal",
    applicantObject: ONBOARDING_OBJECTS_ENUM.JOB_APPLICATION,
    icon: <PersonAdd {...CommonIconProps} />,
  },
  {
    id: 2,
    label: "U.S. I-9 Form",
    altLabel: "I-Nine",
    applicantObject: ONBOARDING_OBJECTS_ENUM.I9_FORM,
    icon: <AccountCircle {...CommonIconProps} />,
  },
  {
    id: 3,
    label: `Upload ID's`,
    altLabel: `Attachments`,
    applicantObject: ONBOARDING_OBJECTS_ENUM.UPLOAD,
    icon: <Badge {...CommonIconProps} />,
  },
  {
    id: 4,
    label: "W-4 Tax Form",
    altLabel: "Federal W4",
    applicantObject: ONBOARDING_OBJECTS_ENUM.W4_TAX,
    icon: <RequestPage {...CommonIconProps} />,
  },
  {
    id: 5,
    label: "Direct Deposit",
    altLabel: "Direct Deposit",
    applicantObject: ONBOARDING_OBJECTS_ENUM.DIRECT_DEPOSIT,
    icon: <AccountBalance {...CommonIconProps} />,
  },
  {
    id: 6,
    label: "Employer I-9",
    applicantObject: ONBOARDING_OBJECTS_ENUM.EMPLOYER_I_9,
    icon: <AccountCircle {...CommonIconProps} />,
    loggedIn: true,
  },
  {
    id: 7,
    label: "Acknowledgement",
    applicantObject: ONBOARDING_OBJECTS_ENUM.ACKNOWLEDGEMENT,
    icon: <ThumbUp {...CommonIconProps} />,
  },
  {
    id: 8,
    label: "Complete!",
    applicantObject: ONBOARDING_OBJECTS_ENUM.COMPLETE,
    icon: <CheckCircle {...CommonIconProps} />,
  },
];




export const APPLICANT_STATUS = applicantStatusField;

export const EMPLOYEE_APPLICANT_STATUS = ["Employee", "Inactive", "Terminated", "Partner"];

export const MEMBER_PARTNER_STATUS_ENUM = {
  MEMBER: "Add as Member",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  NOT_A_MEMBER: "Not a Member",
};
export const APPLICANT_EVENT_STATUS_ENUM = {
  NOT_ROSTER: "Not Roster",
  ROSTER: "Roster",
  WAITLIST: "Waitlist",
  REQUEST: "Request",
};

export const APPLICANT_VENUE_STATUS_ENUM = {
  NONE: "None",
  STAFFING_POOL: "StaffingPool",
  PENDING: "Pending",
  LOCKED: "Locked",
};
export const EMPLOYEE_STATUS_ENUM = {
  EVENT_STAFF: "Event Staff",
  EVENT_ADMIN: "Event Admin",
};
export const CLIENT_STATUS_ENUM = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const APPLICANT_MERGE_STATUS_ENUM = {
  PRIMARY: "primary",
  MERGE: "merge",
  IGNORE: "ignore",
  REMOVE: "remove",
};

export const APPLICANT_COMPANY_STATUS_ENUM = {
  NONE: "None",
  ON_ASSIGNMENT: "On Assignment",
  PENDING: "Pending",
  INACTIVE: "Inactive",
  // DNU: "Dnu",
  // TERMINATED: "Terminated",
};
export const JOB_STATUS_ENUM = {
  NONE: "None",
  ON_ASSIGNMENT: "On Assignment",
  PENDING: "Pending",
  DECLINE: "Declined",
  TERMINATED: "Terminated",
  COMPLETED: "Completed",
};

export const APPLICANT_JOB_STATUS_ENUM = {
  NONE: "None",
  SUBMITTED: "Submitted",
  INTERVIEW: "Interview",
  DECLINED: "Declined",
  FILLED: "Filled",
  WAITLIST: "Waitlist",
  COMPLETED: "Completed",
};
