import DataList from "components/DataList";
import { useEffect, useState } from "react";
// import AddAComment from "../AddAComment";
import NotesRow from "./NotesRow";

const Notes = ({ setValue, getValues, saveChanges, notes, currentLoggedUser, mode }) => {
  const [reverseNotes, setReverseNotes] = useState(null);
  const renderRow = (row, idx) => (
    <NotesRow saveChanges={saveChanges} notes={notes} row={row} idx={idx} />
  );

  useEffect(() => {
    if (notes) {
      setReverseNotes([...notes]?.reverse());
    }
  }, [notes, notes?.length]);

  return (
    <>
      {reverseNotes ? (
        <DataList
          renderRow={renderRow}
          data={reverseNotes}
          onRowClick={() => {}}
          title="Notes"
          greybar
          divider
          tableHeight={420}
          height={290}
          scrollY
        />
      ) : (
        <div>No Notes</div>
      )}
    </>
  );
};

export default Notes;
