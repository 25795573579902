import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Button,
  TextField,
  Card,
  Grid,
  Snackbar,
  useMediaQuery,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useDropdowns from "hooks/useDropdowns";

import GenericModal from "components/GenericModal";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import LoadingButton from "@mui/lab/LoadingButton";
import MDTypography from "components/MDTypography";
import { baseAxios } from "config";
import FormField from "layouts/applications/wizard/components/FormField";
import createApplication from "layouts/pages/web/actions/createApplication";
import { Controller, useForm } from "react-hook-form";

import { useAppContextController } from "context/AppContext";
import useCompanyCache, { useOutsideCompanyCache } from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import ThanksForApplyingModal from "layouts/pages/web/components/Apply/ThanksForApplyingModal";
import { createApplyPageSchema } from "data/applyPage";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { PatternFormat } from "react-number-format";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import TagList from "components/TagList";
import { getCommonBaseImageUrl } from "utils/helpers/upload";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 170,
  },
  dropzone: {
    height: 50,
  },
  previewButton: {
    padding: 0,
  },
  error: {
    fontSize: "0.75rem",
  },
});
const Apply = ({ job }) => {
  // const { loginWithRedirect } = useAuth0();.
  const { enqueueSnackbar } = useSnackbar();
  const { venues, setVenues, company, setCompany, setCompanyType, user, isMobile } =
    useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const [mailAlreadyUsed, setMailAlreadyUsed] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [keptOTp, setKeptOTp] = useState("")
  const [hash, setHash] = useState(null);
  const [resendCount, setResendCount] = useState(1);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const [clickCount, setClickCount] = useState(0);
  const [disableResendButton, setDisableResendButton] = useState(false);
  const applyPageSchema = createApplyPageSchema(
    verificationModalOpen,
    setVerificationModalOpen,
    emailVerified,
    setMailAlreadyUsed
  );

  const { dropdowns: howDidYouHear } = useDropdowns({
    entity: "howDidYouHear",
    outsideMode: "protected",
  });

  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "protected",
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({
    venues,
    setVenues,
    company,
    outsideMode: "protected",
  });
  const isCompany = company?.companyType === "Company";
  useEffect(() => {
    if (!venues || !Object.keys(venues)?.length) {
      refetchVenues();
    }
  }, []);

  useEffect(() => {
    let timer;
    if (clickCount > -1 && secondsLeft > 0) {
      timer = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setDisableResendButton(false);
      setSecondsLeft(null);
    }
    return () => clearTimeout(timer);
  }, [clickCount, secondsLeft]);

  const location = useLocation();

  let referrer;
  const params = new URLSearchParams(location.search);
  if (params.has("referrer")) {
    referrer = params.get("referrer");
  }

  const resolver = useYupValidationResolver(applyPageSchema);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [openThanksForApplyingModal, setOpenThanksForApplyingModal] = useState(false);
  const [resumeExtension, setResumeExtension] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [applicantId, setApplicantId] = useState(null);
  const [applicantCode, setApplicantCode] = useState(null);
  const classes = useStyle();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const {
    handleSubmit,
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      zip: "",
      howDidYouHearAbout: "",
      referralSource: "",
    },
  });

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
  });

  const uploadFileMutation = useMutation(
    async (resumeForm) => {
      await baseAxios.post(
        `/outside-protected/upload/applicants/${applicantId}/Resume`,
        resumeForm.form
      );

      const newAtt = {
        title: "Resume",
        type: "Resume",
        docType: resumeForm.extension,
        filename: resumeForm.name,
        uploadDate: new Date(),
      };
      // if we find this attachment type then overwrite the current
      const newAttachmentsArr = [newAtt];

      await updateApplicantMutation.mutateAsync(
        {
          applicantId,
          data: {
            attachments: [...newAttachmentsArr],
          },
          outsideMode: "protected",
        },
        {
          onSuccess: (_, data) => {
            setToastAlert({
              isShow: true,
              message: "Resume Has Been Uploaded!",
              status: "success",
            });
          },
        }
      );
    },
    {
      onSuccess: (_, data) => {
        setValue(
          "resumeUrl",
          `${IMAGE_SERVER}/resumes/${job.jobSlug}/${getValues().email}/${data?.name}`
        );
        setResumeExtension(data?.extension);
        setFileName(data?.name);
        setToastAlert({ isShow: true, message: "Resume Has Been Uploaded!", status: "success" });
      },
    }
  );

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    } else if (acceptedFiles.length !== 0) {
      setFileInfo({ acceptedFiles, rejectedFiles });
      const file = acceptedFiles[0];
      setResumeExtension(file?.path?.split(".").pop());
      setFileName(file?.name);
    }
  };

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);
      uploadFileMutation.mutate({
        form,
        type: "Resume",
        name: file?.name,
        extension: file?.path?.split(".").pop(),
      });
    }
  };

  const createApplicationMutation = useMutation(createApplication, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
    onSuccess: async (_, data) => {
      // await queryClient.invalidateQueries("applications");
      // setToastAlert({ isShow: true, message: "Application has been created!", status: "success" });
      // now we need to upload a resume if the user has specified.
      if (fileInfo) {
        const { acceptedFiles, rejectedFiles } = fileInfo;
        if (!mailAlreadyUsed) {
          setApplicantId(_.insertedId);
        }
        onDropHandler(acceptedFiles, rejectedFiles);
      }
      setApplicantCode(_.applicantCode);
      setOpenThanksForApplyingModal(true);
    },
  });

  const createApplicantHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    await createApplicationMutation.mutateAsync({
      data: {
        ...data,
        email: data.email?.toLowerCase(),
        otp: keptOTp,
        ...(hash ? { hash } : {}),
      },
      jobSlug: job.jobSlug,
    });
  };

  const avatar = (
    <MDBox
    // onClick={() => {
    //   const url = `${IMAGE_SERVER}/resumes/${job.jobSlug}/${encodeURIComponent(
    //     getValues().email
    //   )}/${encodeURIComponent(fileName)}`;
    //   // alert(url);
    //   window.open(url);
    // }}
    >
      {!!company?.imageUrl &&
        !!company?.uploadUrl &&
        (resumeExtension?.toLowerCase() === "docx" || resumeExtension?.toLowerCase() === "rtf") ? (
        <img
          src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
          alt="Word Doc Preview"
          width={100}
          height={100}
        />
      ) : (
        resumeExtension?.toLowerCase() === "pdf" &&
        !!company?.imageUrl &&
        !!company?.uploadUrl && (
          <img
            src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
            alt="PDF Preview"
            width={100}
            height={100}
          />
        )
      )}
    </MDBox>
  );

  const sendOTP = async () => {
    if (mailAlreadyUsed) {
      const result = await baseAxios.post(
        `/outside-protected/applicants/verify/email/${getValues().email}`,
        {
          device: "Web",
          resendCount,
        }
      );
      if (result?.data?.success) {
        setOpenOTPModal(true);
      }
    } else {
      const result = await baseAxios.post(
        `/outside-protected/applicants/verify/email/${getValues().email}/new`,
        {
          device: "Web",
          resendCount,
        }
      );
      if (result?.data?.success) {
        setHash(result?.data?.hash);
        setOpenOTPModal(true);
      }
    }
    setLoadingOTP(false);
  };

  const handleResendClick = () => {
    const delay = [30, 60, 90, 120];
    if (clickCount < delay.length) {
      setClickCount(clickCount + 1);
      setSecondsLeft(delay[clickCount]);
      setDisableResendButton(true);
    }
    sendOTP(); // Assuming sendOTP is a function that sends the OTP
  };

  const verifyOneTimePasscode = async () => {
    let result;
    try {
      result = await baseAxios.post(
        `/outside-protected/applicants/email/${getValues().email}/otp`,
        {
          device: "Web",
          otp,
          ...(hash ? { hash } : {}),
        }
      );
    } catch (error) {
      if (error.response.data.expired) {
        enqueueSnackbar("Expired one-time passcode", { variant: "error" });
      } else {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      }

      setSubmittingOTP(false);
      setOtp("")
    }
    if (result?.data?.success && mailAlreadyUsed) {
      const {
        applicant,
        applicantId: currentApplicantId,
      } = result.data;
      setValue("firstName", applicant?.firstName);
      setValue("lastName", applicant?.lastName);
      setValue("phone", applicant?.phone);
      setValue("zip", applicant?.zip);
      setApplicantId(currentApplicantId);
      if (applicant?.tags?.length) setValue("tags", [...applicant?.tags]);
      // setValue("howDidYouHearAbout", referralSource);
      setOpenOTPModal(false);
      setVerificationModalOpen(false);
      setSubmittingOTP(false);
      setOtp("")
      setEmailVerified(true);
      trigger("email");
      trigger("phone");
    } else if (result?.data?.success && !mailAlreadyUsed) {
      setOpenOTPModal(false);
      setVerificationModalOpen(false);
      setSubmittingOTP(false);
      setOtp("")
      setEmailVerified(true);
    } else {
      setEmailVerified(false);
    }
  };

  const mailAlreadyExistHeader = (
    <>
      <MDTypography variant="h3">Email Already Exists</MDTypography>
    </>
  );
  const newMailHeader = (
    <>
      <MDTypography variant="h3">Verify Your Email</MDTypography>
    </>
  );

  const mailAlreadyExistBody = (
    <Box textAlign="center">
      <MDTypography textAlign="center">
        Your email already exists.To apply for more jobs, simply verify your email address below and
        enter the One - Time Passcode sent to the address provided.
      </MDTypography>
      <Box mt={3} width="60%" sx={{ marginLeft: "20%" }}>
        <TextField label="Email" disabled value={getValues().email} fullWidth />
      </Box>
    </Box>
  );
  const newMailBody = (
    <Box textAlign="center">
      <MDTypography textAlign="center">
        Please verify your email address below and enter the One-Time Passcode (OTP) sent to the
        address provided.
      </MDTypography>
      <Box mt={3} width="60%" sx={{ marginLeft: "20%" }}>
        <TextField label="Email" disabled value={getValues().email} fullWidth />
      </Box>
    </Box>
  );
  const modalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        color="secondary"
        onClick={() => {
          setValue("email", "");
          setVerificationModalOpen(false);
          setEmailVerified(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="error"
        disabled={loadingOTP}
        style={{ color: "white" }}
        onClick={() => {
          setLoadingOTP(true);
          sendOTP();
        }}
      >
        Send OTP
      </Button>
    </>
  );

  const verifyModalHeader = (
    <>
      <MDTypography variant="h3">Verify Your Account</MDTypography>
    </>
  );
  const verifyModalBody = (
    <>
      <Box textAlign="center">
        <MDTypography textAlign="center">
          A One-Time Passcode has been sent to {getValues().email}.
        </MDTypography>
        <Box mt={3} width="60%" sx={{ marginLeft: "20%" }}>
          <PatternFormat
            value={otp}
            format="# # # # # #"
            mask="-"
            allowEmptyFormatting
            onValueChange={(e) => {
              setOtp(e.value)
              setKeptOTp(e.value)
            }}
            style={{
              fontSize: "4rem",
              textAlign: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          />
        </Box>
        {clickCount === 4 ? (
          <MDTypography textAlign="center">
            <span>Can&apos;t request to resend code</span>
          </MDTypography>
        ) : (
          <MDTypography textAlign="center">
            {secondsLeft !== null ? (
              <span>Resend available in {secondsLeft} seconds</span>
            ) : (
              <span>
                Didn&apos;t receive the code?{" "}
                <Button
                  disabled={disableResendButton}
                  sx={{ fontSize: "1.2rem", color: "red" }}
                  onClick={handleResendClick}
                >
                  RESEND CODE
                </Button>
              </span>
            )}
          </MDTypography>
        )}
      </Box>
    </>
  );
  const verifyModalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        color="secondary"
        onClick={() => {
          setEmailVerified(false);
          setVerificationModalOpen(false);
          setOpenOTPModal(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        style={{ color: "white" }}
        disabled={submittingOTP}
        color="error"
        onClick={() => {
          setSubmittingOTP(true);
          verifyOneTimePasscode();
        }}
      >
        Submit
      </Button>
    </>
  );

  return (
    <Card className={classes.box} p={3} shadow="md">
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <MDBox p={!isSmallScreen && 5}>
        <form onSubmit={handleSubmit(createApplicantHandler)}>
          <MDBox p={3}>
            <MDTypography variant="h4">Personal Information</MDTypography>
            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormField
                      {...field}
                      placeholder="Email Address (Required)"
                      type="text"
                      variant="outlined"
                      label="Email Address"
                      onChange={(e) => {
                        setEmailVerified(false);
                        setValue("email", e.target.value?.toLowerCase());
                        setValue("firstName", "");
                        setValue("lastName", "");
                        setValue("phone", "");
                        setValue("zip", "");
                        setValue("howDidYouHearAbout", null);
                        clearErrors();
                      }}
                    />
                  )}
                />
                {errors?.email && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.email.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PatternFormat
                      disabled={!emailVerified}
                      format="(###) ### ####"
                      allowEmptyFormatting
                      mask="_"
                      label="Mobile Number"
                      value={field.value}
                      onChange={field.onChange}
                      variant="outlined"
                      customInput={FormField}
                    />
                  )}
                />
                {errors?.phone && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.phone.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  disabled={!emailVerified}
                  render={({ field }) => (
                    <FormField
                      placeholder="First Name (Required)"
                      type="text"
                      variant="outlined"
                      label="First Name"
                      {...field}
                    />
                  )}
                />
                {errors?.firstName && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.firstName.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  disabled={!emailVerified}
                  render={({ field }) => (
                    <FormField
                      placeholder="Last Name (Required)"
                      type="text"
                      variant="outlined"
                      label="Last Name"
                      {...field}
                    />
                  )}
                />
                {errors?.lastName && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.lastName.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="zip"
                  control={control}
                  disabled={!emailVerified}
                  render={({ field }) => (
                    <FormField
                      placeholder="Zip Code (Required)"
                      type="text"
                      variant="outlined"
                      label="Zip Code"
                      {...field}
                    />
                  )}
                />
                {errors?.zip && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.zip.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="howDidYouHearAbout"
                  control={control}
                  defaultValue={referrer}
                  render={({ field }) => (
                    <Autocomplete
                      options={howDidYouHear?.length ? howDidYouHear : []}
                      name="howDidYouHearAbout"
                      disabled={mailAlreadyUsed || !emailVerified}
                      value={field.value || null}
                      onChange={(e, v) => {
                        setValue("howDidYouHearAbout", v);
                      }}
                      renderInput={(parameters) => (
                        <FormField
                          {...parameters}
                          type="text"
                          variant="outlined"
                          label="How did you hear about us"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {watch("howDidYouHearAbout") === "Referral" && (
                  <>
                    <Controller
                      name="referralSource"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          placeholder="Referring Person"
                          type="text"
                          variant="outlined"
                          label="Person who referred you"
                          {...field}
                        />
                      )}
                    />
                    {errors?.referralSource && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.referralSource.message}
                      </MDTypography>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {isCompany && (
                  <MDBox mt={3}>
                    <MDTypography variant="h5">
                      {" "}
                      Select skills that can be verified by your employment references
                    </MDTypography>
                    <Controller
                      name="tags"
                      control={control}
                      render={({ field }) => (
                        <TagList
                          {...field}
                          value={getValues().tags || []}
                          setValue={setValue}
                          setError={setError}
                          clearErrors={clearErrors}
                          outsideMode={user ? "" : "protected"}
                        />
                      )}
                    />
                    {errors?.invalidTag && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.invalidTag.message}
                      </MDTypography>
                    )}
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h4">Resume/CV</MDTypography>

                <MDBox mt={5}>
                  <MDTypography variant="body2">Resume upload (optional) </MDTypography>
                  <FileDropzone
                    accept={{
                      "application/docx": [".docx"],
                      "application/pdf": [".pdf"],
                      "application/rtf": [".rtf"],
                    }}
                    disabled={!emailVerified}
                    message="Drop files here to upload"
                    className={classes.dropzone}
                    onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}
                    avatar={resumeExtension && avatar}
                    multiple={false}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                {company?.companyType === "Venue" && (
                  <MDTypography variant="body2">
                    *Must be 16 years of age or older to apply. {company?.name} is an Equal
                    Opportunity Employer.
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={10} />
              <Grid item xs={12} sm={2}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="error"
                  disabled={isSubmitting || !emailVerified}
                  loading={isSubmitting}
                  fullWidth
                >
                  APPLY
                </LoadingButton>
              </Grid>
            </Grid>
          </MDBox>
        </form>
      </MDBox>
      <ThanksForApplyingModal
        applicantCode={applicantCode}
        open={openThanksForApplyingModal}
        setOpen={setOpenThanksForApplyingModal}
      />
      <GenericModal
        open={verificationModalOpen}
        setOpen={setVerificationModalOpen}
        header={mailAlreadyUsed ? mailAlreadyExistHeader : newMailHeader}
        body={mailAlreadyUsed ? mailAlreadyExistBody : newMailBody}
        buttons={modalButtons}
        width={isMobile ? "90%" : "25%"}
      />
      <GenericModal
        open={openOTPModal}
        setOpen={setOpenOTPModal}
        header={verifyModalHeader}
        body={verifyModalBody}
        buttons={verifyModalButtons}
        width={isMobile ? "90%" : "25%"}
      />
    </Card>
  );
};

export default Apply;
