import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import colors from "assets/theme/base/colors";
import CustomModal from "components/CustomizedMui/CustomModal";
import VenueIcon from "components/VenueIcon";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useRosterApplicants } from "hooks/useRosterApplicants";
import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";
import { useSnackbar } from "notistack";

const TimeInOutModal = ({ isOpen, toggleOpen, event, id }) => {
  const { eventDate, eventName, venueName, eventEndTime, venueSlug, eventUrl, _id, applicants } =
    event;
  let reportTime = null;
  let endTime = null;
  let clockInTime = new Date(eventDate);
  if (applicants?.length) {
    const ndx = applicants.findIndex((item) => item.id === id && item.status === "Roster");
    if (ndx > -1 && applicants[ndx]?.reportTime) {
      reportTime = new Date(applicants[ndx]?.reportTime);
      if (reportTime?.toISOString() < new Date(eventDate).toISOString()) clockInTime = reportTime;
    }
    if (ndx > -1 && applicants[ndx]?.endTime) endTime = applicants[ndx]?.endTime;
  }
  const { enqueueSnackbar } = useSnackbar();
  const {
    venues,
    currentLoggedUser: { applicantId },
  } = useAppContextController();
  const [isCloseActive, toggleCloseButton] = useState(false);

  const { data, refetch } = useRosterApplicants({
    eventUrl,
    options: {
      onSuccess: () => "",
    },
  });
  const updateRoster = useMutation(updateEventRoster, {
    onSuccess: async (_, { result }) => {
      refetch();
    },
    onError: (error) => console.log(error),
  });

  const applicant = data?.result?.find((item) => item.id === applicantId);

  const isTimeOut = useMemo(() => {
    if (applicant) {
      if (applicant.timeIn) {
        return true;
      }
      if (applicant.timeOut) {
        return false;
      }
    }
    return false;
  }, [applicant]);

  const isEventToday = useMemo(() => moment(eventDate).diff(moment(), "days") === 0, [eventDate]);

  const isClockInOkay = (cit) => {
    const now = new Date();

    // Get the time difference in minutes
    const differenceInMinutes = (cit - now) / 60000; // 60000 milliseconds in a minute

    // Check if the current time is more than 15 minutes before the clock-in time
    return now > clockInTime || differenceInMinutes < 15;
  };

  const dateTime = useMemo(() => {
    if (isTimeOut) return moment(endTime || eventEndTime);
    return moment(reportTime || eventDate);
  }, [eventEndTime, eventDate, isTimeOut, reportTime, endTime]);

  useEffect(() => {
    if (applicant?.timeOut && applicant?.timeIn) {
      toggleCloseButton(true);
    }
  }, [applicant]);

  const handleClick = useCallback(async () => {
    if (isCloseActive) {
      toggleCloseButton(false);
      toggleOpen(false);
      return;
    }
    const now = new Date();
    const updatedRoster = { ...applicant };
    const activeKey = isTimeOut ? "timeOut" : "timeIn";
    if (updatedRoster[activeKey] === "") {
      updatedRoster[activeKey] = null;
    } else if (isTimeOut) {
      updatedRoster[activeKey] = updatedRoster[activeKey] || now.toISOString();
    } else {
      updatedRoster[activeKey] =
        new Date() < clockInTime ? clockInTime.toISOString() : new Date().toISOString();
    }
    await updateRoster.mutateAsync({ id: _id, data: updatedRoster });
    toggleCloseButton(true);
    enqueueSnackbar(
      `Successful Time ${isTimeOut ? "Out" : "In"} at ${moment(updatedRoster[activeKey]).format(
        "hh:mm A"
      )}`,
      { variant: "success" }
    );
  }, [applicant, _id, enqueueSnackbar, isTimeOut, updateRoster, isCloseActive]);

  return (
    <CustomModal isOpen={isOpen} onClose={() => toggleOpen(false)} cardSx={{ maxWidth: "350px" }}>
      <FlexWrapper rowGap="20px">
        {applicant ? (
          <>
            {!isEventToday ? (
              <Typography variant="h2" textAlign="center">
                Cannot clock in. Not event day
              </Typography>
            ) : (
              <>
                {applicant?.status !== "Roster" ? (
                  <Typography variant="body" textAlign="center">
                    Cannot Clock-in. You are not on the Roster
                  </Typography>
                ) : (
                  <>
                    <FlexWrapper flexWrap="nowrap" width="auto" columnGap="15px">
                      <Box>
                        {venues && (
                          <VenueIcon
                            logoUrl={venues[venueSlug]?.logoUrl}
                            slug={venueSlug}
                            name={venueName}
                          />
                        )}
                      </Box>
                      <FlexWrapper flexDirection="column" alignItems="flex-start">
                        <Typography variant="body1" fontWeight="700">
                          {eventName}
                        </Typography>
                        <Typography variant="body2" fontWeight="500">
                          {venueName}
                        </Typography>
                      </FlexWrapper>
                    </FlexWrapper>
                    <FlexWrapper flexDirection="column">
                      <Typography variant="body1">Date: {dateTime.format("M/D/YYYY")}</Typography>
                      <Typography variant="body1">
                        {isTimeOut ? "Est End" : "Report"} Time: {dateTime.format("hh:mm A")}
                      </Typography>
                    </FlexWrapper>
                    <Button
                      sx={{
                        backgroundColor:
                          isTimeOut || isCloseActive ? colors.info.state : colors.success.main,
                      }}
                      disabled={!isClockInOkay(clockInTime)} // calling the function with clockInTime
                      variant="contained"
                      onClick={() => handleClick()}
                    >
                      {isCloseActive ? "Close" : `CLOCK ${isTimeOut ? "OUT" : "IN"}`}
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Typography variant="body" textAlign="center">
            You are not currently a member of this event roster. You need to select and add this
            event before you can be clocked in.
          </Typography>
        )}
      </FlexWrapper>
    </CustomModal>
  );
};

export default TimeInOutModal;
