import { ArrowBack, ArrowForward, Event, EventNote, ImportExport } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import exportEvents from "api/events/exportEvents";
import CustomModal from "components/CustomizedMui/CustomModal";
import CustomStepper from "components/CustomizedMui/CustomStepper";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import usePayrollExportStore from "stores/usePayrollExportStore";
import PayrollMainContent from "./MainContent";

const EXPORT_STEPS = [
  {
    id: 1,
    label: "Select Date Range",
    altLabel: "Personal",
    stepObject: "date",
    icon: <EventNote width="18px" height="18px" color="white" />,
  },
  {
    id: 2,
    label: "Select Events",
    altLabel: "Personal",
    stepObject: "events",
    icon: <Event width="18px" height="18px" color="white" />,
  },
  {
    id: 3,
    label: "Select Export Fields",
    altLabel: "Personal",
    stepObject: "exportFields",
    icon: <ImportExport width="18px" height="18px" color="white" />,
  },
];

const StyledButton = styled(Button)(() => ({
  maxWidth: "120px",
  width: "100%",
}));

const EVENT_THRESHOLD = 200;

const PayrollModal = ({ isOpen, toggleOpen }) => {
  const { currentLoggedUser } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [exportType, setExportType] = useState();

  const [activeStep, setActiveStep] = useState(1);
  const { eventIds, fields, setToDate, setFromDate } = usePayrollExportStore((state) => ({
    eventIds: state.eventIds,
    fields: state.fields,
    setToDate: state.setToDate,
    setFromDate: state.setFromDate,
  }));

  const { company } = useAppContextController();

  const { mutateAsync: exportMutation } = useMutation(exportEvents);

  const handleNextClick = async () => {
    if (activeStep >= EXPORT_STEPS.length) {
      const BODY = {
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
        exportType,
      };
      if (eventIds.length <= EVENT_THRESHOLD) {
        enqueueSnackbar("Please wait, we are preparing your CSV...", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("We will send you a notification when your CSV has been generated.", {
          variant: "success",
          autoHideDuration: 5000,
        });
      }
      exportMutation(
        { ...BODY, exportType, eventIds, fields },
        {
          onSuccess: (data) => {
            window.open(`${company?.imageUrl}${data.data.exportUrl}`, "_blank");
            toggleOpen(false);
          },
        }
      );
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const handlePreviousClick = () => {
    if (activeStep <= 1) {
      return;
    }
    setActiveStep((prev) => prev - 1);
  };

  useEffect(() => {
    setToDate(new Date());
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 30);
    setFromDate(currentDate);
  }, []);

  return (
    <CustomModal isOpen={isOpen} onClose={() => toggleOpen(false)} cardSx={{ maxWidth: "960px" }}>
      <FlexWrapper
        sx={{
          "& .MuiStepper-root": {
            py: 0,
          },
          gap: "15px",
        }}
      >
        <CustomStepper activeStepId={activeStep} steps={EXPORT_STEPS} />
        <FlexWrapper sx={{ gap: "10px" }} justifyContent="space-between">
          <StyledButton
            type="button"
            variant="contained"
            onClick={handlePreviousClick}
            color="error"
            startIcon={<ArrowBack />}
            disabled={activeStep === 1}
          >
            Previous
          </StyledButton>
          <StyledButton
            type="button"
            variant="contained"
            onClick={handleNextClick}
            color={activeStep < EXPORT_STEPS.length ? "success" : "primary"}
            endIcon={<ArrowForward />}
            disabled={
              activeStep >= EXPORT_STEPS.length || (activeStep === 2 && eventIds.length === 0)
            }
          >
            Next
          </StyledButton>
        </FlexWrapper>
        <PayrollMainContent
          activeStep={activeStep}
          exportType={exportType}
          setExportType={setExportType}
        />
        <FlexWrapper sx={{ gap: "10px" }} justifyContent="end">
          <StyledButton
            type="button"
            variant="contained"
            onClick={handleNextClick}
            color="primary"
            disabled={!(activeStep >= EXPORT_STEPS.length)}
          >
            Export
          </StyledButton>
        </FlexWrapper>
      </FlexWrapper>
    </CustomModal>
  );
};

export default PayrollModal;
