import axios from "axios";

import { baseAxios } from "config";

export const messageSystem = async (data) => {
  try {
    const res = await baseAxios.post(`/notifications`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const messageText = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/type/sms`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const messageEmail = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/type/email`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const messageEmailBulk = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/type/email/bulk`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const messageTextBulk = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/type/text/bulk`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const messageSystemBulk = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/type/system/bulk`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const messageTopicPushNotification = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/topic`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};

export const templateSubstitution = async (data) => {
  try {
    const res = await baseAxios.post(`/sendmessage/substitution`, data);
    return res;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw new Error(error.toString());
  }
};
