import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { interviewInfoSchema } from "data/interview";
import createEvent from "layouts/pages/events/actions/createEvent";
import fetchSingleEvent from "layouts/pages/events/actions/fetchSingleEvent";
import fetchCustomers from "api/companies/fetchCustomers";
import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { isEmpty, isEqual } from "lodash";
import { useSnackbar } from "notistack";
import uploadImage from "api/upload/uploadImage";
import { getDirtyFields } from "utils/helpers/formHelpers";
import { INTERVIEW_ENTITIES } from "utils/constants/entityOptions";
import {
  Autocomplete,
  Button,
  Card,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormField from "layouts/applications/wizard/components/FormField";
import MDBox from "components/MDBox";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import LandscapeIcon from "@mui/icons-material/Landscape";
import { styled } from "@mui/material/styles";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useDropdowns from "hooks/useDropdowns";
import { useAppContextController } from "context/AppContext";
import CustomTextField from "components/Form/CustomTextField";
import { makeStyles } from "@mui/styles";
import generateSlug from "utils/generateSlug";
import MDTypography from "components/MDTypography";
import DataList from "components/DataList";
import MDEditor from "components/MDEditor";
import Searchbar from "components/Searchbar";
import FileDropzone from "components/Dropzone";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import InviteAdditionalAttendeeModal from "layouts/pages/events/components/InterviewEditor/InviteAdditionalAttendeeModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchInterviewFeedback from "layouts/pages/events/actions/fetchInterviewFeedback";
import { formatInterviewFeedbacks } from "utils/helpers/events";
import InterviewFormActions from "layouts/pages/events/components/InterviewEditor/InterviewFormActions";
import NotesRow from "layouts/pages/applicants/components/ApplicantNotes/components/NotesRow";
import useSessionAuth from "hooks/useSessionAuth";
import Attachments from "../Attachments";
import ReminderToSaveChangesModal from "../../EventsInformation/ReminderToSaveChangesModal";
import InvitedAttendee from "./InvitedAttendee";
import SelectedApplicant from "./SelectedApplicant";
import UploadFileModal from "../../EventsInformation/UploadFileModal";
import SelectHiringManager from "../SelectHiringManager";
import SelectInterviewLocation from "../SelectInterviewLocation";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 175,
  },
  dropzone: {
    height: 170,
  },
  previewButton: {
    padding: 0,
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
  },
});
const { INTERVIEW_TYPES, STATES, INTERVIEW_STATUS } = INTERVIEW_ENTITIES;

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const InterviewForm = ({
  getCompanyField,
  open,
  setOpen,
  filters,
  setPreviewContent,
  setInterviewPreview,
  setInterviewLogo,
  setCopyInterviewName,
  setFilters = () => { },
}) => {
  const {
    currentLoggedUser,
    currentEvent,
    setCurrentEvent,
    company,
    currentCompany,
    setCurrentCompany,
  } = useAppContextController();

  const IMAGE_SERVER = company?.imageUrl;
  const eventExists = !isEmpty(currentEvent?.applicants);

  const resolver = useYupValidationResolver(interviewInfoSchema);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createVenueSlug } = useParams();
  const classes = useStyle();
  const isEdit = !!currentEvent?._id;
  const [searchParams] = useSearchParams();
  const copyInterviewId = searchParams.get("copyInterviewId");
  const { dropdowns: interviewTypes } = useDropdowns({ entity: INTERVIEW_TYPES });
  const { dropdowns: states } = useDropdowns({ entity: STATES });
  const { dropdowns: interviewStatuses } = useDropdowns({ entity: INTERVIEW_STATUS });
  const { enqueueSnackbar } = useSnackbar();
  const [editorValue, setEditorValue] = useState(null);
  const [interviewToCopy, setInterviewToCopy] = useState(null);
  const [inviteAttendeeModalOpen, setInviteAttendeeModalOpen] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  // Confirm Modal
  const [currentAttendee, setCurrentAttendee] = useState(null);
  const [attendeeIndex, setAttendeeIndex] = useState(null);
  const [companyNames, setCompanyNames] = useState();
  const [jobNames, setJobNames] = useState();
  const [usersNames, setUsersNames] = useState();
  const [interviewTypesList, setInterviewTypesList] = useState();
  const [notes, setNotes] = useState([]);
  const [allFeedbacks, setAllFeedbacks] = useState([]);
  const [displayNotes, setDisplayNotes] = useState(false);
  const options = { fetchAll: true };
  const allOptions = { ...options, filters: { primaryCompany: false } };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const allOptionsJobs = { ...options, filters: {} };

  const { data: companies, isLoading } = useQuery(["customer_interview_data", allOptions], () =>
    fetchCustomers(allOptions)
  );

  if (!companies?.data.includes(company)) {
    companies?.data.push(company);
  }

  const applicantOptions = {
    ...options,
    page: 1,
    limit: null,
    fetchAll: false,
    filters: {},
    // TODO:  Create filter includeEmployees that also fetches employees who are included in this event
  };

  // const {
  //   data: applicants,
  //   isLoading: applicantsLoading,

  //   refetch: requeryApplicants,
  // } = useQuery(
  //   ["applicantsOnly", applicantOptions],
  //   () =>
  //     fetchApplicantsOnly(applicantOptions)
  // );

  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );
  const {
    data: jobs,
    isLoading: isLoadingJobs,
    refetch,
  } = useQuery(["availableJobs", allOptionsJobs], () => fetchCompanyJob(allOptionsJobs));

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    setError,
    formState,
  } = useForm({ mode: "onBlur", defaultValues: currentEvent, resolver });

  const { errors, isSubmitting, dirtyFields, isDirty } = formState;
  const defaultValues = {
    eventName: "Sterling Interview",
    jobName: "",
    jobId: "",
    eventType: "Interview",
    interviewType: "",
    status: "Active",
    _id: createVenueSlug ? getCompanyField(createVenueSlug, "_id") : null,
    companyName: createVenueSlug ? getCompanyField(createVenueSlug, "name") : null,
    interviewLocation: "",
    address: createVenueSlug ? getCompanyField(createVenueSlug, "address") : "",
    venueCity: createVenueSlug ? getCompanyField(createVenueSlug, "city") : "",
    venueState: createVenueSlug ? getCompanyField(createVenueSlug, "state") : "",
    zip: createVenueSlug ? getCompanyField(createVenueSlug, "zip") : "",
    eventId: "",
    eventDate: null,
    eventEndTime: null,
    reportTimeTBD: "",
    eventLogo: "",
    eventLogos: [],
    attachments: [],
    applicants: [],
    recruiter: "",
    hiringManager: "",
    // jobLogo: "No",
    // googleMap: "No",
    enableFeedback: "No",
    enableNotes: "No",
    companySlug: isEdit ? createVenueSlug || "" : "",
    description: "",
    eventUrl: "",
    attendees: [],
    logoUrl: "",
  };

  const selectedCompany = watch("companyName");

  const { logoutAuth0User } = useSessionAuth();

  const { mutate: uploadMutation } = useMutation(
    async ({ type, data }) => {
      const form = new FormData();
      form.append("file", data.file);

      try {
        await uploadImage({
          uploadPath: `company/${currentEvent?.companySlug}/events/${currentEvent?.eventUrl}`,
          form,
        });

        if (type === "attachment") {
          await updateEvent({
            eventId: currentEvent._id,
            data: data && { attachments: [...currentEvent?.attachments, data.eventImage] },
          });
        } else {
          await updateEvent({
            eventId: currentEvent._id,
            data: data && {
              eventLogos: [...currentEvent?.eventLogos, data.eventImage],
              eventLogo: data.eventImage,
            },
          });
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
    {
      onError: (error) => enqueueSnackbar(`Error: ${error}`, { variant: "error" }),
      onSuccess: (_, data) => {
        if (data.type === "attachment") {
          setCurrentEvent({
            ...currentEvent,
            attachments: [...currentEvent?.attachments, data.data.eventImage],
          });
        } else {
          setCurrentEvent({
            ...currentEvent,
            eventLogos: [...currentEvent?.eventLogos, data.data.eventImage],
            eventLogo: data.data.eventImage,
          });
        }
        enqueueSnackbar(`Image been updated!`, { variant: "success" });
      },
    }
  );

  const createInterviewMutation = useMutation(createEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("events");
      enqueueSnackbar("Interview has been created!", { variant: "success" });
      setCurrentEvent({
        ...currentEvent,
        ...data,
        [_.id.name]: _.id?.[_.id.name],
        _id: _.result.insertedId,
      });
      navigate(`/events/${data.eventUrl}`);
    },
  });

  const createInterviewFormHandler = async (values) => {
    const updatedValues = { ...values };
    if (copyInterviewId) updatedValues.copyInterviewId = copyInterviewId;
    updatedValues.createAgent = currentLoggedUser._id;
    updatedValues.eventEndTime = new Date(updatedValues.eventEndTime).toISOString();
    updatedValues.eventDate = new Date(updatedValues.eventDate).toISOString();

    await createInterviewMutation.mutateAsync(updatedValues);
  };

  const updateInterviewMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      if (isEqual(data.status, "Deleted")) navigate("/events");
      enqueueSnackbar(
        `Interview has been ${isEqual(data.status, "Deleted") ? "archived" : "updated"}!`,
        { variant: "success" }
      );
      await queryClient.invalidateQueries("events");
    },
  });

  const editInterviewFormHandler = async (values) => {
    if (copyInterviewId) return;
    if (!currentEvent?.companySlug || !currentEvent?.eventId) {
      enqueueSnackbar("Cannot Update Interview -- No Interview ID!", { variant: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    if (modifiedFields?.eventEndTime) {
      modifiedFields.eventEndTime = new Date(modifiedFields.eventEndTime).toISOString();
    }
    if (modifiedFields?.eventDate) {
      modifiedFields.eventDate = new Date(modifiedFields.eventDate).toISOString();
    }
    await updateInterviewMutation.mutateAsync({
      companySlug: currentEvent?.companySlug,
      eventId: currentEvent?._id,
      data: {
        ...modifiedFields,
      },
    });
  };

  const onDropAttachmentHandler = (img) => {
    const file = img[0];
    uploadMutation({ type: "attachment", data: { eventImage: file?.name, file } });
  };
  const onDropLogoHandler = (img) => {
    const file = img[0];
    uploadMutation({ type: "logo", data: { eventImage: file?.name, file } });
  };

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setInterviewPreview(currentEvent);
    setOpen(!open);
  };

  const inviteAdditionalAttendee = () => {
    setInviteAttendeeModalOpen(true);
  };

  const attendeeHandler = (row, isOpenInvitedAttendee, posIdx) => {
    setCurrentAttendee(row);
    setInviteAttendeeModalOpen(isOpenInvitedAttendee);
    setAttendeeIndex(posIdx);
  };

  const removeAttendee = (idx) => {
    const temporaryAttendees = getValues()?.attendees;
    temporaryAttendees.splice(idx, 1);
    setValue("attendees", temporaryAttendees, { shouldDirty: true });
    if (isEdit) setReminderToSaveChangesModalOpen(true);
  };

  const hasSomeUpdateApplicantList = (updatedApplicants) => {
    if (updatedApplicants?.length !== currentEvent?.applicants?.length) return true;
    return updatedApplicants?.some(
      (updatedApplicant) =>
        !!currentEvent?.applicants?.some(
          (initialApplicant) => initialApplicant.id !== updatedApplicant.id
        )
    );
  };

  const handleRemoveApplicant = (idx) => {
    const temporaryApplicants = getValues("applicants");
    const updatedApplicants = temporaryApplicants.filter((_, index) => index !== idx);
    if (hasSomeUpdateApplicantList(updatedApplicants)) {
      setValue("applicants", updatedApplicants, { shouldDirty: true });
      if (isEdit) setReminderToSaveChangesModalOpen(true);
    } else {
      setValue("applicants", updatedApplicants, { shouldDirty: false });
    }
  };

  const avatar =
    IMAGE_SERVER && currentEvent?.interviewImage ? (
      <Card>
        <img
          src={`${IMAGE_SERVER}/${currentEvent?.companySlug}/interviews/${currentEvent?.eventUrl}/${currentEvent?.interviewImage}`}
          alt={
            currentEvent?.interviewImage
              ? `${currentEvent?.name} Interview Image`
              : "No Interview Image Set"
          }
          style={{ width: "100%" }}
        />
      </Card>
    ) : (
      <LandscapeIcon sx={{ width: "5em", height: "5em" }} />
    );

  const dateToSlugString = (date) =>
    getValues()?.eventDate?.toString()?.split(" ")?.splice(0, 4)?.join("-")?.toLowerCase();

  const onCompanyChange = () => {
    Object.keys(companies?.data).forEach((key) => {
      if (companies?.data[key]?.name.trim() === getValues("companyName").trim()) {
        setValue("logoUrl", companies?.data[key]?.logoUrl, { shouldDirty: true });
        setValue("companySlug", companies?.data[key]?.slug, { shouldDirty: true });
        setValue("address", companies?.data[key]?.address, { shouldDirty: true });
        setValue("companyName", companies?.data[key]?.name, { shouldDirty: true });
        setValue("venueCity", companies?.data[key]?.city, { shouldDirty: true });
        setValue("venueState", companies?.data[key]?.state, { shouldDirty: true });
        setValue("zip", companies?.data[key]?.zip, { shouldDirty: true });
      }
    });
  };

  const sortUsers = (a, b) => {
    if (a.firstName > b.firstName) return 1;
    return -1;
  };

  const formatSearchResults = (searchResult) => {
    const formatted = searchResult?.map((v, index) => {
      if (v.status === "Deleted") return v;
      if (v.doNotMerge === "Yes") return { ...v, mergeStatus: "ignore" };
      const result = searchResult.find(
        (val, idx) =>
          val.firstName === v.firstName &&
          val.lastName === v.lastName &&
          v._id !== val._id &&
          val.doNotMerge !== "Yes" &&
          val.status !== "Deleted"
      );
      if (result) {
        return { ...v, mergeStatus: "merge" };
      }
      return v;
    });
    return formatted;
  };

  const handleDateChange = (label, date) => {
    const newDate = new Date(date);
    const dateArr = date._a;

    if (dateArr) {
      const d = dateArr[2];
      const m = dateArr[1] + 1;
      const y = dateArr[0];
      const h = dateArr[3];
      const ms = dateArr[4];
      const lastDate = new Date(y, m, 0, h, ms);

      if (d > lastDate.getDate()) {
        setValue(label, lastDate, { shouldDirty: true });
      } else {
        setValue(label, newDate, { shouldDirty: true });
      }
    } else {
      setValue(label, newDate, { shouldDirty: true });
    }
  };

  const searchColumns = [
    {
      title: "Last Name",
      field: "lastName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email} \n ${row?.city} ${row?.state}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
      width: 150,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email} \n ${row?.city} ${row?.state}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
      width: 150,
    },
    { title: "Email", field: "email", width: 200 },
    { title: "Phone", field: "phone", width: 200 },
  ];

  const setCustomer = async (slug) => {
    try {
      const customer = await fetchSingleCustomer({ slug });
      if (customer?.data) setCurrentCompany(customer?.data);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const setApplicantDetails = (item) => {
    return {
      id: item?._id,
      status: "Roster",
      dateModified: new Date().toISOString(),
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
      // eventUrl: currentEvent.url,
      firstName: item?.firstName,
      lastName: item?.lastName,
      email: item?.email,
      phone: item?.phone,
      feedback: item?.feedback,
    };
  };

  const getFeedbacks = async () => {
    const fetchAllFeedbacks = currentEvent?.applicants?.map((applicant) => {
      return fetchInterviewFeedback({
        applicantId: applicant?.id || applicant?._id,
        eventUrl: currentEvent?.eventUrl,
      });
    });

    const results = await Promise.all(fetchAllFeedbacks);
    setAllFeedbacks(results);

    const feedbacks = formatInterviewFeedbacks(
      results,
      getValues("applicants"),
      currentLoggedUser?._id
    );
    feedbacks.sort((a, b) => new Date(b.date) - new Date(a.date));
    setNotes(feedbacks);
  };

  const handleCollapse = () => setDisplayNotes(!displayNotes);

  const header = (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="right" pr={3}>
        <Grid>
          <Button variant="text" color="primary" onClick={handleCollapse}>
            {displayNotes ? "Collapse All" : "Expand All"}
          </Button>
        </Grid>
      </Stack>
    </>
  );

  useEffect(() => {
    if (copyInterviewId) delete currentEvent?._id;
    clearErrors();
    if (!isEdit) {
      const data = copyInterviewId && interviewToCopy ? interviewToCopy : defaultValues;
      setCurrentEvent(data);
      reset(data);
    } else if (isEdit && currentEvent) {
      reset(currentEvent);
    }

    if (currentEvent) setInterviewLogo(getValues("logoUrl"));
    setEditorValue(
      !copyInterviewId && !isEdit ? defaultValues?.description : currentEvent?.description
    );
  }, [isEdit, watch, copyInterviewId, interviewToCopy]);

  useEffect(() => {
    setEditorValue(currentEvent?.description);
  }, [currentEvent]);

  useEffect(() => {
    if (companies?.data) {
      setCompanyNames(
        Object.keys(companies?.data)
          .map((key) => {
            return companies?.data[key]?.name;
          })
          .sort((companyA, companyB) => (companyA > companyB ? 1 : -1))
      );
    }
  }, [companies?.data]);

  useEffect(() => {
    setInterviewTypesList(
      interviewTypes?.map((interview) => {
        if (interview === "Onsite {Primary Company}") {
          return interview.replace("{Primary Company}", `${company?.name}`);
        }
        if (interview === "Onsite {Customer}" && selectedCompany) {
          return interview.replace("{Customer}", `${selectedCompany}`);
        }
        if (interview === "Onsite {Customer}") {
          return interview.replace("{Customer}", "Customer");
        }
        return interview;
      })
    );
  }, [selectedCompany, company, interviewTypes]);

  useEffect(() => {
    if (jobs?.data) {
      setJobNames(
        jobs?.data?.map((job) => ({
          ...job,
          label: `${job.title} - ${job.companyName}`,
        }))
      );
    }
  }, [jobs]);

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((user) => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
          }))
      );
    }
  }, [users]);

  useEffect(() => {
    if (eventExists) {
      getFeedbacks();
    }
  }, [eventExists]);

  useEffect(() => {
    if (isEdit) return;
    const hasEventUrl =
      watch("eventName") !== "" && watch("companySlug") !== "" && watch("eventDate") !== "";
    const variables = {
      venueSlug: watch("companySlug"),
      title: watch("eventName"),
      suffix: dateToSlugString(watch("eventDate")),
      slugField: "eventUrl",
      fetchFunction: fetchEvents,
    };

    if (hasEventUrl) {
      (async () => {
        const { slug } = await generateSlug(variables);
        setValue("eventUrl", slug, { shouldDirty: true });
      })();
    } else setValue("eventUrl", "", { shouldDirty: true });
  }, [watch("eventName"), watch("companySlug"), watch("eventDate")]);

  useEffect(() => {
    const fetchInterviewToCopy = async (eid) => {
      const interview = await fetchSingleEvent({ eventId: eid });
      if (interview) {
        setCopyInterviewName(interview.eventName);
        interview.reportTime = "";
        interview.eventDate = "";
        interview.eventEndTime = "";
        interview.eventUrl = "";
        interview.eventId = "";
        interview.applicants = [];
        setValue("description", interview.description, { shouldDirty: true });
        delete interview._id;
        if (!interview.attendees) interview.attendees = [];
        if (!interview.attachments) interview.attachments = [];
        setInterviewToCopy(interview);
        setEditorValue(interview.description);
      }
    };

    if (copyInterviewId) {
      try {
        fetchInterviewToCopy(copyInterviewId);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [copyInterviewId]);

  const onChangeFeedback = (idx, status) => {
    const eventApplicants = [...currentEvent.applicants];
    const selectedApplicant = eventApplicants[idx];
    const applicantFeedback = selectedApplicant.feedback ?? [];

    if (status === "None") {
      // New status is None. Remove the item that matches the current logged user
      eventApplicants[idx].feedback = applicantFeedback.filter(
        (applFeed) => applFeed.createAgent !== currentLoggedUser?._id
      );
    } else {
      // New status is not None
      const selectedFeedbackIndex = applicantFeedback.findIndex(
        (applFeed) => applFeed.createAgent === currentLoggedUser?._id
      );

      if (selectedFeedbackIndex >= 0) {
        // Feedback exists, change status
        eventApplicants[idx].feedback[selectedFeedbackIndex].rating = status;
      } else {
        // Feedback doesn't exist. Add it
        if (eventApplicants[idx].feedback == null) {
          eventApplicants[idx].feedback = [];
        }
        eventApplicants[idx].feedback.push({
          createAgent: currentLoggedUser?._id,
          agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
          firstName: currentLoggedUser?.firstName,
          lastName: currentLoggedUser?.lastName,
          eventUrl: currentEvent?.eventUrl,
          modifiedDate: new Date().toISOString(),
          rating: status,
        });
      }
    }

    setCurrentEvent({
      ...currentEvent,
      applicants: eventApplicants,
    });

    getFeedbacks();
  };

  useEffect(() => {
    if (currentEvent) {
      // initialize the description
      reset(currentEvent);
      setInterviewLogo(getValues("logoUrl"));
    }
  }, [currentEvent, isEdit]);

  return (
    <>
      <form
        onSubmit={
          isEdit ? handleSubmit(editInterviewFormHandler) : handleSubmit(createInterviewFormHandler)
        }
      >
        <FlexWrapper justifyContent="end">
          <InterviewFormActions
            submitted={isSubmitting}
            hasChanges={isDirty}
            resetChanges={() => reset(currentEvent)}
            setEditorValue={setEditorValue}
            copyInterviewId={copyInterviewId}
            updateInterviewMutation={updateInterviewMutation}
            onPreview={() => openPreviewModal("all")}
          />
        </FlexWrapper>

        <MDBox p={3} pt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                    Interview Information
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="jobName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={jobNames || []}
                        freeSolo
                        disabled={isEdit}
                        defaultValue=""
                        value={field.value || ""}
                        onChange={(e, v) => {
                          setValue("jobName", v.title, { shouldDirty: true });
                          setValue("jobId", v.jobId, { shouldDirty: true });
                          setValue("jobSlug", v.jobSlug, { shouldDirty: true });
                          setValue("companyName", v.companyName, { shouldDirty: true });
                          setValue("companySlug", v.companySlug, { shouldDirty: true });
                          setValue("hiringManager", "", { shouldDirty: true });
                          setValue("interviewType", "", { shouldDirty: true });
                          setValue("interviewLocation", "", { shouldDirty: true });
                          setCustomer(v.companySlug);
                        }}
                        renderOption={(props, option, index) => {
                          const key = `listItem-${index}-${option._id}`;
                          return (
                            <li {...props} key={key}>
                              {option.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            key={`jobName_${currentEvent?._id}`}
                            type="text"
                            label="Job Name"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eventId"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        disabled
                        InputLabelProps={{ tabIndex: -1 }}
                        value={currentEvent?.eventId || ""}
                        key={`_id${currentEvent?.eventId}`}
                        type="text"
                        label=" Interview ID"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="companyName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        autoSelect
                        disabled
                        options={/* companyNames || */[]}
                        name="companyName"
                        value={field.value || null}
                        onChange={(e) => {
                          field.onChange(e.target.textContent);
                          onCompanyChange();
                          trigger("address");
                        }}
                        renderInput={(params) => (
                          <FormField
                            key={`companyName_${currentEvent?._id}`}
                            {...params}
                            type="text"
                            label="Customer Name"
                            InputLabelProps={{ tabIndex: -1 }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.companyName && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.companyName.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="jobId"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        disabled
                        key={`jobId_${currentEvent?.companySlug}_${currentEvent?.jobId}`}
                        type="text"
                        label="Job ID"
                        InputLabelProps={{ shrink: !isEmpty(getValues("jobId")), tabIndex: -1 }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="interviewType"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_interviewType_${currentEvent?._id}`}
                        options={interviewTypesList || []}
                        autoSelect
                        freeSolo
                        disabled={isEmpty(watch("jobName"))}
                        name="Interview Type"
                        defaultValue={currentEvent?.interviewType || null}
                        value={field.value || ""}
                        onChange={(e, v) => {
                          field.onChange(v?.charAt(0).toUpperCase() + v?.slice(1));
                          setValue("interviewLocation", "", { shouldDirty: true });
                        }}
                        onBlur={() => trigger("interviewType")}
                        renderInput={(params) => (
                          <FormField
                            key={`interviewType_${currentEvent?._id}`}
                            {...params}
                            type="text"
                            label="Interview Type"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.interviewType && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.interviewType.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="interviewLocation"
                    control={control}
                    render={({ field }) => (
                      <SelectInterviewLocation
                        disabled={isEmpty(getValues("jobName"))}
                        inputProps={isEmpty(getValues("companyName")) ? { tabIndex: -1 } : {}}
                        value={field.value || ""}
                        onChange={(e, v) => {
                          field.onChange(v);
                        }}
                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                        currentEventId={currentEvent?._id}
                        company={company}
                        currentCompany={currentCompany}
                        selectedCompany={selectedCompany}
                        selectedInterviewType={watch("interviewType")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="recruiter"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        key={`autocomplete_recruiter_${currentEvent?.id}`}
                        options={
                          usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []
                        }
                        autoSelect
                        freeSolo
                        name="recruiter"
                        defaultValue={currentEvent?.recruiter}
                        value={field.value || ""}
                        onChange={(e, v) => {
                          setValue("recruiter", v, { shouldDirty: true });
                        }}
                        onBlur={() => trigger("recruiter")}
                        renderInput={(params) => (
                          <>
                            <FormField
                              key={`recruiter_${currentEvent?.id}`}
                              {...params}
                              type="text"
                              label="Recruiter"
                            // InputLabelProps={{ shrink: !isEmpty(getValues("recruiter")) }}
                            />
                            <FormHelperText error>{error?.message}</FormHelperText>
                          </>
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="hiringManager"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <SelectHiringManager
                        customer={currentCompany}
                        currentEvent={currentEvent}
                        value={field.value || ""}
                        onChange={(e, v) => {
                          field.onChange(v);
                        }}
                        onBlur={() => trigger("hiringManager")}
                        errorMessage={error?.message}
                        isShrink={!isEmpty(getValues("hiringManager"))}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={11}>
                  <Controller
                    name="eventUrl"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        key={`eventUrl_${currentEvent?._id}`}
                        disabled={
                          isEdit ||
                          !getValues()?.companySlug?.length ||
                          !getValues()?.interviewDate ||
                          !getValues()?.name?.length
                        }
                        type="text"
                        value={field.value || ""}
                        label=" Interview URL"
                        InputLabelProps={{
                          shrink: !isEmpty(getValues("eventUrl")),
                          tabIndex:
                            isEdit ||
                              !getValues()?.companySlug?.length ||
                              !getValues()?.interviewDate ||
                              !getValues()?.name?.length
                              ? -1
                              : undefined,
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Copy Url">
                    <IconButton
                      color="info"
                      onClick={() => {
                        navigator.clipboard.writeText(getValues().eventUrl);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Searchbar
                    fetch={fetchApplicants}
                    fetchAll={false}
                    placeholder="Select Applicants"
                    columns={searchColumns}
                    queryCharacterLimit={3}
                    resultsLimit={15}
                    setFilters={setFilters}
                    filters={filters}
                    searchBy={["firstName", "lastName", "email", "altEmail"]}
                    filterBy="_id"
                    scrollLimit={200}
                    onRowClick={(item) => {
                      if (
                        !getValues("applicants").find((applicant) => {
                          const applId = applicant._id || applicant.id;
                          return applId === item._id;
                        })
                      ) {
                        setValue(
                          "applicants",
                          [...getValues("applicants"), setApplicantDetails(item)],
                          { shouldDirty: true }
                        );
                        if (isEdit) setReminderToSaveChangesModalOpen(true);
                      } else
                        enqueueSnackbar("Applicant is already on the list", { variant: "error" });
                    }}
                    formatData={formatSearchResults}
                    dropRight
                  />

                  <DataList
                    renderRow={(row, idx) =>
                      SelectedApplicant(
                        row,
                        idx,
                        handleRemoveApplicant,
                        currentEvent?.applicants,
                        onChangeFeedback
                      )
                    }
                    data={watch("applicants")}
                    greybar
                    divider
                    scrollY
                    tableHeight={320}
                    height={350}
                    isLoading={false}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {isEdit && (
                  <MDBox mt={3}>
                    <MDTypography component="label" variant="body2">
                      Interview Notes
                    </MDTypography>

                    <DataList
                      renderRow={(row) => <NotesRow row={row} displayNotes={displayNotes} />}
                      data={notes}
                      onRowClick={() => { }}
                      icon="description"
                      iconColor="secondary"
                      title="Notes"
                      isLoading={false}
                      greybar
                      divider
                      header={header}
                      tableHeight={600}
                      height={850}
                      scrollY
                      computedHeight
                      noIcon
                    />
                  </MDBox>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                {isEdit && (
                  <MDBox mt={3}>
                    <MDTypography component="label" variant="body2">
                      Interview Logo
                    </MDTypography>

                    <Grid
                      item
                      xs={6}
                      mt={2}
                      style={{ maxWidth: "25%", marginLeft: "auto", marginRight: "auto" }}
                    >
                      <FileDropzone
                        className={classes.dropzone}
                        onDrop={onDropLogoHandler}
                        avatar={avatar}
                      />
                    </Grid>
                    <MDBox mt={5}>
                      <Attachments
                        setCurrentInterview={setCurrentEvent}
                        currentInterview={currentEvent}
                        setAttachmentOpen={setAttachmentOpen}
                        fileLocation="logos"
                      />
                    </MDBox>
                  </MDBox>
                )}
              </Grid> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                    Interview Configurations
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eventDate"
                    control={control}
                    defaultValue={currentEvent?.eventDate}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          disabled={isEmpty(watch("companyName"))}
                          inputProps={isEmpty(watch("companyName")) ? { tabIndex: -1 } : {}}
                          label="Start Time"
                          value={!field.value ? null : field.value}
                          onChange={(date) => {
                            clearErrors("eventEndTime");
                            handleDateChange("eventDate", date);
                            setValue(
                              "eventEndTime",
                              new Date(
                                new Date(getValues().eventDate).getTime() + 1 * 60 * 60 * 1000
                              ),
                              { shouldDirty: true }
                            );
                            clearErrors("eventDate");
                          }}
                          renderInput={(params) => <FormField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.eventDate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventDate.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eventEndTime"
                    control={control}
                    defaultValue={currentEvent?.eventEndTime}
                    render={({ field }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePicker
                            disabled={isEmpty(watch("companyName"))}
                            inputProps={isEmpty(watch("companyName")) ? { tabIndex: -1 } : {}}
                            label="End Time"
                            value={!field.value ? null : field.value}
                            onChange={(date) => {
                              handleDateChange("eventEndTime", date);
                            }}
                            renderInput={(params) => <FormField {...params} />}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                  {errors?.eventEndTime && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventEndTime.message}
                    </MDTypography>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Enable Notes</MDTypography>
                    <Controller
                      name="enableNotes"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          color="info"
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Enable Feedback</MDTypography>
                    <Controller
                      name="enableFeedback"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          color="info"
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid> */}
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography component="label" variant="body2">
                      Interview Description
                    </MDTypography>
                    <Button onClick={() => openPreviewModal("description")} sx={{ padding: 0 }}>
                      PREVIEW
                    </Button>
                  </Stack>
                  <MDBox>
                    <Controller
                      name="companyDescription"
                      control={control}
                      render={({ field }) => (
                        <MDEditor
                          name="description"
                          style={{ height: 100 }}
                          className={classes.editor}
                          value={editorValue || ""}
                          onChange={(e) => {
                            setEditorValue(e);
                            setValue("description", e, { shouldDirty: true });
                          }}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mt={6} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography component="label" variant="body2">
                      Add interview Members
                    </MDTypography>
                    <IconButton
                      className={classes.addButton}
                      color="info"
                      onClick={inviteAdditionalAttendee}
                    >
                      <AddCircleRoundedIcon />
                    </IconButton>
                  </MDBox>
                  <hr />
                  <Controller
                    name="attendees"
                    control={control}
                    render={({ field }) => (
                      <DataList
                        renderRow={(row, idx) =>
                          InvitedAttendee(row, idx, attendeeHandler, removeAttendee)
                        }
                        data={field?.value?.sort(sortUsers) || []}
                        greybar
                        divider
                        scrollY
                        tableHeight={320}
                        height={350}
                        isLoading={false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {isEdit && (
                    <MDBox mt={3}>
                      <MDTypography component="label" variant="body2">
                        Interview Attachments
                      </MDTypography>

                      <Grid
                        item
                        xs={6}
                        mt={2}
                        style={{ maxWidth: "25%", marginLeft: "auto", marginRight: "auto" }}
                      >
                        <FileDropzone
                          className={classes.dropzone}
                          onDrop={onDropAttachmentHandler}
                          avatar={avatar}
                        />
                      </Grid>
                      <MDBox mt={2}>
                        <Attachments
                          setCurrentInterview={setCurrentEvent}
                          currentInterview={currentEvent}
                          setAttachmentOpen={setAttachmentOpen}
                          fileLocation="attachments"
                        />
                      </MDBox>
                    </MDBox>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mb={-3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} />
          </Grid>
        </MDBox>
        {/* <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>



              <MDBox mt={1}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <FormField
                      disabled
                      inputProps={{ tabIndex: -1 }}
                      key={`address_${currentEvent?._id}`}
                      type="text"
                      label=" Address"
                      InputLabelProps={{ shrink: !isEmpty(getValues("address")) }}
                      {...field}
                    />
                  )}
                />
              </MDBox>
              <MDBox mt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="venueCity"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled
                          inputProps={{ tabIndex: -1 }}
                          key={`city_${currentEvent?._id}`}
                          type="text"
                          label="City"
                          InputLabelProps={{ shrink: !isEmpty(getValues("venueCity")) }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <MDBox mb={3}>
                      <Controller
                        name="venueState"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            freeSolo
                            options={states || []}
                            defaultValue={null}
                            name="venueState"
                            value={field.value || ""}
                            onChange={(e, v) => {
                              field.onChange(v.toUpperCase());
                            }}
                            onBlur={() => trigger("state")}
                            renderInput={(params) => (
                              <FormField
                                key={`state_${currentEvent?._id}`}
                                {...params}
                                type="text"
                                label="State"
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.venueState && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.venueState.message}
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="zip"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FormField
                            disabled
                            inputProps={{ tabIndex: -1 }}
                            key={`zipCode_${currentEvent?._id}`}
                            type="text"
                            label="Zip Code"
                            value={field.value}
                            InputLabelProps={{ shrink: !isEmpty(getValues("zip")?.toString()) }}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <MDBox>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={currentEvent?.status}
                      render={({ field }) => (
                        <Autocomplete
                          key={`autocomplete_status_${currentEvent?._id}`}
                          options={interviewStatuses || []}
                          autoSelect
                          freeSolo
                          name="status"
                          defaultValue={currentEvent?.status}
                          value={field.value}
                          onChange={(e, v) => {
                            field.onChange(v?.charAt(0).toUpperCase() + v?.slice(1));
                          }}
                          onBlur={() => trigger("status")}
                          renderInput={(params) => (
                            <FormField
                              key={`status_${currentEvent?._id}`}
                              {...params}
                              type="text"
                              label="Interview Status"
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.status && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.status.message}
                      </MDTypography>
                    )}
                  </MDBox>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        Google Map
                      </MDTypography>

                      <Controller
                        name="googleMap"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>



            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>



            </Grid>
          </Grid>
        </MDBox> */}
      </form>
      <UploadFileModal
        currentEvent={currentEvent}
        attachmentOpen={attachmentOpen}
        setAttachmentOpen={setAttachmentOpen}
      />

      <InviteAdditionalAttendeeModal
        open={inviteAttendeeModalOpen}
        setOpen={setInviteAttendeeModalOpen}
        selectedArray={getValues().attendees || []}
        setValue={setValue}
        objectField="attendees"
        setReminderToSaveChangesModalOpen={setReminderToSaveChangesModalOpen}
        isEdit={isEdit}
        filteredList={users?.data || []}
      />
      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}
    </>
  );
};

export default InterviewForm;
