import { useNewApplicantContext } from "context/NewApplicantContext";
import { ONBOARDING_OBJECTS_ENUM, APPLICANT_OBJECTS_ENUM } from "utils/constants/applicant";
import EmployerI9Form from "components/FormFields/EmployerI9";
import Acknowledgement from "./Acknowledgement";
import Congratulations from "./Congratulations";
import DirectDeposit from "./DirectDeposit";
import FederalW4Tax from "./FederalW4Tax";
import I9Form from "./I9Form";
import JobApplicationForm from "./JobApplicationForm";
import AlabamaForm from "./TaxForms/AlabamaForm";
import GeorgiaForm from "./TaxForms/GeorgiaForm";
import OklahomaForm from "./TaxForms/OklahomaForm";
import MichiganForm from "./TaxForms/MichiganForm";
import UploadID from "./UploadID";
import SouthCarolinaForm from "./TaxForms/SouthCarolinaForm";
import CaliforniaForm from "./TaxForms/CaliforniaForm";
import Verification from "./Verification";
import ApplicantContactForm from "./ApplicantContactForm";
import ApplicantResumeAndJobHistory from "./ApplicantResumeAndJobHistory";
// import RecommendedJobs from "./RecommendedJobs";
import JobApplicationAndInterview from "./JobApplicationsAndInterviews";
import Subscriptions from "./Subscriptions";

const NewApplicantForms = ({ parent }) => {
  const { activeStep } = useNewApplicantContext();
  if (activeStep === ONBOARDING_OBJECTS_ENUM.VERIFICATION) {
    return <Verification />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.JOB_APPLICATION) {
    return <JobApplicationForm />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.I9_FORM) {
    return <I9Form parent={parent} />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.UPLOAD) {
    return <UploadID />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.W4_TAX) {
    return <FederalW4Tax />;
  }
  if (activeStep === "georgiaStateTaxForm") {
    return <GeorgiaForm />;
  }
  if (activeStep === "alabamaStateTaxForm") {
    return <AlabamaForm />;
  }
  if (activeStep === "oklahomaStateTaxForm") {
    return <OklahomaForm />;
  }
  if (activeStep === "michiganStateTaxForm") {
    return <MichiganForm />;
  }
  if (activeStep === "southCarolinaStateTaxForm") {
    return <SouthCarolinaForm />;
  }
  if (activeStep === "californiaStateTaxForm") {
    return <CaliforniaForm />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.DIRECT_DEPOSIT) {
    return <DirectDeposit />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.EMPLOYER_I_9) {
    return <EmployerI9Form />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.ACKNOWLEDGEMENT) {
    return <Acknowledgement />;
  }
  if (activeStep === ONBOARDING_OBJECTS_ENUM.COMPLETE) {
    return <Congratulations />;
  }
  if (activeStep === APPLICANT_OBJECTS_ENUM.APPLICANT_INFO) {
    return <ApplicantContactForm />;
  }
  if (activeStep === APPLICANT_OBJECTS_ENUM.RESUME_AND_JOB_HISTORY) {
    return <ApplicantResumeAndJobHistory />;
  }
  // if (activeStep === APPLICANT_OBJECTS_ENUM.RECOMMENDED_JOBS) {
  //   return <RecommendedJobs />;
  // }
  if (activeStep === APPLICANT_OBJECTS_ENUM.JOB_APPLICANTS_AND_INTERVIEWS) {
    return <JobApplicationAndInterview />;
  }
  if (activeStep === APPLICANT_OBJECTS_ENUM.SUBSCRIPTIONS) {
    return <Subscriptions />;
  }
  return "";
};

export default NewApplicantForms;
