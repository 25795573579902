import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { isEqual } from "lodash";
import { useMutation, useQuery } from "react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import useCompanyCache from "hooks/useCompanyCache";
import CompanyJobsEventsTable from "layouts/pages/companyjobs/components/CompanyJobsEventsTable";
import CompanyJobsApplicantsTable from "layouts/pages/companyjobs/components/CompanyJobsApplicantsTable";
import CompanyJobsFilterCards from "layouts/pages/companyjobs/components/CompanyJobsFilterCards";
import CompanyJobInfoPanel from "layouts/pages/companyjobs/components/CompanyJobInfoPanel";
import CompanyJobsTable from "layouts/pages/companyjobs/components/CompanyJobsTable";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import useSessionAuth from "hooks/useSessionAuth";
import CompanyJobInterviews from "layouts/pages/companyjobs/components/CompanyJobInterviews";
import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import updateJob from "./actions/updateJob";
import AddANoteModal from "../applicants/components/AddANoteModal";
import CompanyJobsNotes from "../applicants/components/ApplicantNotes";
import CompanyJobPreview from "./components/CompanyJobPreview";
import CompanyJobsActivities from "./components/CompanyJobsActivities";
import CompanyJobsContent from "./components/CompanyJobsContent";
import CompanyJobsAttachments from "./components/CompanyJobsAttachments";
import CompanyJobsStatus from "./components/CompanyJobsStatus";
import CompanyJobsSettings from "./components/CompanyJobsSettings";
import CompanyJobsReport from "./components/CompanyJobsReport";

function CompanyJobs() {
  const { jobSlug, action = "" } = useParams();
  const [searchParams] = useSearchParams();
  const copyJobSlug = searchParams.get("copyJobSlug");
  const location = useLocation();
  const { currentJob, company, setCompany, setCompanyType, setCurrentJob, currentLoggedUser } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  // states
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({ status: "Active" });
  const [jobPreview, setJobPreview] = useState(null);
  const [refetchJobs, setRefetchJobs] = useState(false);
  const [applicantsFilters, setApplicantsFilters] = useState({});
  const [previewContent, setPreviewContent] = useState(null);
  const [selectedPanel, setSelectedPanel] = useState("activities");
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const isEdit = !!currentJob?._id;
  const accessPaths = ["/companyjobs/create"];
  const { logoutAuth0User } = useSessionAuth();

  // Get company of this job
  const { data: currentCustomerApi, isLoading } = useQuery(
    ["jobCustomer", currentJob?.companySlug],
    () => {
      return fetchSingleCustomer({ slug: currentJob?.companySlug });
    },
    {
      enabled: !!currentJob?.companySlug,
    }
  );

  const currentCustomer = currentCustomerApi?.data
    ? currentCustomerApi.data
    : {
      address: currentJob?.address,
      city: currentJob?.companyCity,
      state: currentJob?.companyState,
      zip: currentJob?.zip,
      name: currentJob?.companyName,
      slug: currentJob?.companySlug,
    };

  const getJob = async () => {
    try {
      const jobs = await fetchCompanyJob({
        filters: { jobSlug },
        company,
        useOr: true,
      });
      let job;
      if (jobs && jobs?.data?.length) {
        job = jobs?.data[0];
        setCurrentJob(job);
        if (action === "jobpreview" && job) {
          setJobPreview(job);
          setOpen(true);
        }
      }
    } catch (error) {
      // Check for error response and if it's a 401, perform the logout
      if (String(error).includes("401") || error?.response?.status === 401) {
        // Assuming logoutUser is a function that performs the logout logic
        logoutAuth0User();
      } else {
        // Handle other errors as needed, such as displaying an error message to the user
        console.error("An error occurred while fetching the job:", error);
      }
    }
  };

  const renderPanel = (selection) => {
    switch (selection) {
      case "activities":
        return <CompanyJobsActivities />;
      case "content":
        return <CompanyJobsContent />;
      case "interviews":
        return (
          <CompanyJobInterviews jobSlug={currentJob?.jobSlug} currentCustomer={currentCustomer} />
        );
      case "attachments":
        return <CompanyJobsAttachments />;
      case "status":
        return <CompanyJobsStatus currentCustomer={currentCustomer} />;
      case "settings":
        return <CompanyJobsSettings />;
      case "notes":
        return <CompanyJobsNotes setOpen={setNoteModalOpen} currentApplicant={currentJob} />;
      case "reportdata":
        return <CompanyJobsReport />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (jobSlug && !filters?.jobSlug && action) {
      setFilters({ jobSlug });
    }
    if (jobSlug) getJob();
    if (!jobSlug && currentJob) {
      setCurrentJob(null);
    }
    if (isEqual(action, "jobpreview") && currentJob) {
      setJobPreview(currentJob);
      setOpen(true);
    }
  }, [jobSlug, action, filters?.jobSlug]);

  // Default Filter Card
  // causes filter to default even if selecting different filter card
  // useEffect(() => {
  //   if (!action && !jobSlug) {
  //     const { filterField, filterValue } = initialCards.find((card) => card.default);
  //     setFilters(() => ({ [filterField]: filterValue }));
  //   }
  // }, [action, jobSlug]);
  const { mutateAsync: updateJobMutation } = useMutation(updateJob);

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };

    const allNotes = currentJob?.notes || [];
    const notes = [...allNotes, newNote];

    const updateNewNote = async () => {
      let res;
      updateJobMutation(
        { jobId: currentJob?._id, data: { notes } },
        {
          onSuccess: async (response) => {
            res = response;
            setCurrentJob({ ...currentJob, notes });
            setToastAlert({
              isShow: true,
              message: "Notes Have Been Successfully Updated",
              status: "success",
            });
          },
          onError: (err) => {
            res = err;
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        }
      );

      return res;
    };

    return updateNewNote();
  };

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <CompanyJobsFilterCards setFilters={setFilters} setPage={setPage} />
          <CompanyJobsTable
            jobSlug={jobSlug}
            fetchAll
            filters={filters}
            setFilters={setFilters}
            showFiltersList
            title="Customer Jobs"
            page={page}
            setPage={setPage}
            setOpen={setOpen}
            setJobPreview={setJobPreview}
            parent="jobs"
            refetchJobs={refetchJobs}
          />
        </Grid>
        {((["jobinfo", "joball"].includes(action) && jobSlug) ||
          copyJobSlug ||
          (!isEdit && window.location.pathname === "/companyjobs/create") ||
          (currentJob?._id && !action)) && (
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <CompanyJobInfoPanel
                    setRefetchJobs={setRefetchJobs}
                    setPreviewContent={setPreviewContent}
                    previewContent={previewContent}
                    selectedPanel={selectedPanel}
                    onPanelSelect={setSelectedPanel}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {renderPanel(selectedPanel)}
                </Grid>
              </Grid>
            </Grid>
          )}
        {["jobinterviews", "joball"].includes(action) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CompanyJobsEventsTable
                showFiltersList={false}
                showSearchBar
                filters={currentJob?.jobSlug ? { jobSlug: currentJob?.jobSlug } : {}}
                title="Interviews for Job"
                subtitle={`${currentJob?.title} at ${currentJob?.jobSlug
                  ?.substring(0, 1)
                  .toUpperCase()}${currentJob?.jobSlug?.substring(1)}`}
              />
            </Grid>
          </>
        )}
        {["jobapplicants", "joball"].includes(action) && (
          <Grid item xs={12} sm={12} md={12}>
            <CompanyJobsApplicantsTable
              type="Applicants"
              showFiltersList
              showSearchBar={false}
              jobSlug={currentJob?.jobSlug}
              filters={
                currentJob?.jobSlug
                  ? { "jobs.jobSlug": currentJob?.jobSlug, ...applicantsFilters }
                  : { ...applicantsFilters }
              }
              title={`Applicants for Job: ${currentJob?.title} at ${currentJob?.jobSlug
                ?.substring(0, 1)
                .toUpperCase()}${currentJob?.jobSlug?.substring(1)}`}
              staffingPoolName={currentJob?.title}
            />
          </Grid>
        )}
        {["jobstaffingpool", "joball"].includes(action) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CompanyJobsApplicantsTable
                type="Staffing"
                showFiltersList
                fetchAll
                showSearchBar={false}
                orderBy="createdDate"
                order="desc"
                filters={
                  currentJob?.jobSlug
                    ? { "jobs.jobSlug": currentJob?.jobSlug, ...applicantsFilters }
                    : {}
                }
                title={`Staffing Pool for Job: ${currentJob?.title} at ${currentJob?.jobSlug
                  ?.substring(0, 1)
                  .toUpperCase()}${currentJob?.jobSlug?.substring(1)}`}
                staffingPoolName={currentJob?.title}
              />
            </Grid>
          </>
        )}
      </Grid>
      {/* {jobPreview && <JobsModal open={open} setOpen={setOpen} jobPreview={jobPreview} />} */}
      {jobPreview && (
        <CompanyJobPreview
          content="all"
          open={open}
          handleOpen={setOpen}
          closeHandler={() => setOpen(!open)}
          customer={jobPreview}
        />
      )}
      <AddANoteModal
        currentApplicant={currentJob}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        saveNote={saveNote}
        types="jobNoteTypes"
      />
    </DashboardLayout>
  );
}

export default CompanyJobs;
