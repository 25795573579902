import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Card, Fade, Grid, Modal, Stack, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ExportFieldsModalFilterCards from "layouts/pages/events/components/EventsInformation/ExportFieldsModal/ExportFieldsModalFilterCards";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
  error: {
    fontSize: "0.75rem",
  },
});

const ExportFieldsModal = ({
  exportFieldModalOpen,
  setExportFieldModalOpen,
  applicantsData,
  rosterData,
  currentEvent,
  venues,
  rosterStatus,
}) => {
  const classes = useStyle();
  const handleClose = () => setExportFieldModalOpen(false);
  const [mode, setMode] = useState("timeSheet");
  // Time Sheet
  const [venueHelmId, setVenueHelmId] = useState(false);
  const [employeeID, setEmployeeID] = useState(false);
  const [payRate, setPayRate] = useState(false);
  const [billRate, setBillRate] = useState(false);
  const [position, setPosition] = useState(false);
  // Event Roster
  const [venueName, setVenueName] = useState(false);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [eventDate, setEventDate] = useState(false);
  const [rank, setRank] = useState(false);
  const [hireDate, setHireDate] = useState(false);
  const [birthDate, setBirthDate] = useState(false);
  // Both
  const [eventName, setEventName] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [eventId, setEventId] = useState(false);
  const [timeIn, setTimeIn] = useState(false);
  const [timeOut, setTimeOut] = useState(false);

  const [exportData, setExportData] = useState([]);
  const [exportFileName, setExportFileName] = useState("");
  const [mergedData, setMergedData] = useState([]);

  const handleSelectAll = (e) => {
    if (mode === "timeSheet") {
      setVenueHelmId(e);
      setEmployeeID(e);
      setEventDate(e);
      setPayRate(e);
      setBillRate(e);
      setPosition(e);
      setTimeIn(e);
      setTimeOut(e);
      setEventName(e);
      setFirstName(e);
      setLastName(e);
      setEventId(e);
    }
    if (mode === "eventRoster") {
      setVenueName(e);
      setPhone(e);
      setEmail(e);
      setEventDate(e);
      setRank(e);
      setPosition(e);
      setHireDate(e);
      setBirthDate(e);
      setEventName(e);
      setFirstName(e);
      setLastName(e);
      setEventId(e);
      setTimeIn(e);
      setTimeOut(e);
    }
  };

  useEffect(() => {
    setVenueHelmId(false);
    setEmployeeID(false);
    setPayRate(false);
    setBillRate(false);
    setPosition(false);
    setTimeIn(false);
    setTimeOut(false);
    setEventName(false);
    setFirstName(false);
    setLastName(false);
    setEventId(false);
    setVenueName(false);
    setPhone(false);
    setEmail(false);
    setEventDate(false);
    setRank(false);
    setHireDate(false);
    setBirthDate(false);
  }, [mode]);

  useEffect(() => {
    const mergeData = (rosters, apps) => {
      const merged = [];
      if ([""].includes(rosterStatus)) {
        apps.forEach((app) => {
          const ros = rosters.find((item) => app._id === item.id);
          if (app) {
            merged.push({
              billRate: currentEvent?.billRate,
              payRate: currentEvent?.payRate,
              ...app,
              primaryPosition: "Event Staff",
              ...ros,
            });
          }
        });
      } else if (["Not Roster"].includes(rosterStatus)) {
        apps.forEach((app) => {
          const ndx = rosters.findIndex((item) => app._id === item.id);
          if (ndx === -1) {
            merged.push({
              billRate: currentEvent?.billRate,
              payRate: currentEvent?.payRate,
              ...app,
              primaryPosition: "Event Staff",
            });
          }
        });
      } else {
        rosters.forEach((ros) => {
          const app = apps.find((item) => item._id === ros.id);
          if (app) {
            // TODO:  Implement this later
            app.primaryPosition = "Event Staff";
            merged.push({
              billRate: currentEvent?.billRate,
              payRate: currentEvent?.payRate,
              ...app,
              ...ros,
            });
          }
        });
      }
      setMergedData(merged);
    };
    if (applicantsData?.length) {
      mergeData(rosterData, applicantsData);
    }
  }, [rosterData, applicantsData]);

  const selectFields = () => {
    const fieldsSelected = {};
    if (venueHelmId) {
      fieldsSelected.venueHelmId = true;
    }
    if (employeeID) {
      fieldsSelected.employeeID = true;
    }
    if (payRate) {
      fieldsSelected.payRate = true;
    }
    if (billRate) {
      fieldsSelected.billRate = true;
    }
    if (position) {
      fieldsSelected.position = true;
    }
    if (timeIn) {
      fieldsSelected.timeIn = true;
    }
    if (timeOut) {
      fieldsSelected.timeOut = true;
    }
    if (venueName) {
      fieldsSelected.venueName = true;
    }
    if (phone) {
      fieldsSelected.phone = true;
    }
    if (email) {
      fieldsSelected.email = true;
    }
    if (eventDate) {
      fieldsSelected.eventDate = true;
    }
    if (rank) {
      fieldsSelected.rank = true;
    }
    if (hireDate) {
      fieldsSelected.hireDate = true;
    }
    if (birthDate) {
      fieldsSelected.birthDate = true;
    }
    if (eventName) {
      fieldsSelected.eventName = true;
    }
    if (firstName) {
      fieldsSelected.firstName = true;
    }
    if (lastName) {
      fieldsSelected.lastName = true;
    }
    if (eventId) {
      fieldsSelected.eventId = true;
    }

    return fieldsSelected;
  };

  const formatDate = (raw) => {
    if (!raw) return "";
    const fd = new Date(raw).toISOString().split("T")[0];
    return fd;
  };

  const formatTime = (raw) => {
    if (!raw) return "";
    return moment(raw).tz(currentEvent?.timeZone).format("hh:mm A");
  };
  const lookupVenueField = (slug, field) => {
    if (!venues || !slug) return "";
    const venue = venues[slug];
    return venue ? venue[field] : "";
  };

  const calculateTotalTime = (inTime, outTime, units) => {
    if (!inTime || !outTime) return 0;
    const dateIn = new Date(inTime).toISOString().split("T")[0];
    const minsIn = new Date(inTime).toISOString().split("T")[1];
    const minsOut = new Date(outTime).toISOString().split("T")[1];
    let totalMs;
    let first = true;
    let nextDay;

    if (new Date(`${dateIn}T${minsOut}`) > new Date(`${dateIn}T${minsIn}`))
      totalMs =
        new Date(`${dateIn}T${minsOut}`).getTime() - new Date(`${dateIn}T${minsIn}`).getTime();
    else {
      first = false;
      [nextDay] = new Date(new Date(dateIn).getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T");
      totalMs =
        new Date(`${nextDay}T${minsOut}`).getTime() - new Date(`${dateIn}T${minsIn}`).getTime();
    }

    return units === "mins"
      ? Math.round(totalMs / 1000 / 60)
      : Math.round((totalMs / 1000 / 60 / 60) * 100) / 100;
  };

  const sortData = (a, b) => {
    if (a.lastName < b.lastName) return -1;
    if (a.lastName > b.lastName) return 1;
    if (a.firstName < b.firstName) return -1;
    if (a.firstName > b.firstName) return 1;
    return 0;
  };

  const prepareData = (fields) => {
    setExportFileName(
      `${currentEvent?.eventUrl}-${mode === "timeSheet" ? "time-sheet" : "event-roster"}.csv`
    );
    const preppedData = [];
    mergedData.sort(sortData).forEach((item, i) => {
      const outputRec = {};
      if (fields.venueHelmId)
        outputRec.venueHelmId = lookupVenueField(currentEvent?.venueSlug, "venueHelmID");
      if (fields.employeeID) outputRec.employeeID = item.employeeID;
      if (item.timeIn && item.timeOut) {
        outputRec["Total Hrs"] = calculateTotalTime(item.timeIn, item.timeOut, "hrs");
      }
      if (fields.payRate) outputRec.payRate = item.payRate;
      if (fields.billRate) outputRec.billRate = item.billRate;
      if (fields.eventName) outputRec.eventName = currentEvent?.eventName;
      if (fields.eventDate) outputRec.eventDate = formatDate(currentEvent?.eventDate);
      if (fields.eventId) outputRec.eventId = currentEvent?.eventId;
      if (fields.lastName) outputRec.lastName = item.lastName;
      if (fields.firstName) outputRec.firstName = item.firstName;
      outputRec.Number = (i + 1).toString();
      if (fields.timeIn) outputRec.timeIn = formatTime(item.timeIn);
      if (fields.timeOut) outputRec.timeOut = formatTime(item.timeOut);
      if (item.timeIn && item.timeOut) {
        outputRec["Total Mins"] = calculateTotalTime(item.timeIn, item.timeOut, "mins");
      }
      if (fields.position) outputRec.position = item.primaryPosition;
      if (fields.venueName) outputRec.venueName = lookupVenueField(currentEvent?.venueSlug, "name");

      if (item.primaryPosition && item.primaryPosition !== "Event Staff") {
        // Overwrite the payrate and billrate
        const eventPositions = currentEvent?.positions ?? []
        const currentPosition = eventPositions.find((pos) => pos.positionName === item.primaryPosition)
        if (currentPosition) {
          if (fields.payRate && currentPosition.payRate) outputRec.payRate = currentPosition.payRate;
          if (fields.billRate && currentPosition.billRate) outputRec.billRate = currentPosition.billRate;
        }
      }

      Object.keys(item).forEach((key) => {
        if (fields[key] && outputRec[key] === undefined) {
          if (key.toLowerCase().indexOf("date") > -1 && item[key]) {
            outputRec[key] = formatDate(item[key]);
          } else outputRec[key] = item[key] ? item[key] : "";
        }
      });
      preppedData.push(outputRec);
    });

    return preppedData;
  };

  return (
    <Modal
      open={exportFieldModalOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={exportFieldModalOpen}>
        <Card className={classes.container}>
          <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
            <MDTypography variant="h3">Select Export Fields</MDTypography>
            <MDBox display="flex" alignItems="center">
              <Button
                variant="text"
                startIcon={<CancelIcon />}
                onClick={handleClose}
                sx={{ padding: "0.5rem 1rem" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                endIcon={<SaveIcon />}
                style={{ color: "white" }}
                sx={{ padding: "0.5rem 1rem" }}
              // onClick={selectFields}
              >
                <CSVLink
                  data={exportData}
                  style={{ color: "white" }}
                  asyncOnClick
                  filename={exportFileName}
                  onClick={(event, done) => {
                    const fields = selectFields();
                    const data = prepareData(fields);
                    setExportData(data);
                    done(); // REQUIRED to invoke the logic of component
                    handleClose();
                  }}
                >
                  Export
                </CSVLink>
              </Button>
            </MDBox>
          </MDBox>
          <MDBox p={2}>
            <ExportFieldsModalFilterCards setMode={setMode} mode={mode} />
          </MDBox>
          {mode === "timeSheet" && (
            <>
              <Grid container p={2}>
                <Grid item sm={7}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch
                      checked={venueHelmId}
                      onChange={(e) => setVenueHelmId(e.target.checked)}
                    />
                    <MDTypography style={{ fontSize: "1rem" }}> Venue ID</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={eventId} onChange={(e) => setEventId(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Event ID</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={eventName} onChange={(e) => setEventName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Event Name</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={eventDate} onChange={(e) => setEventDate(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Event Date</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch
                      checked={employeeID}
                      onChange={(e) => setEmployeeID(e.target.checked)}
                    />
                    <MDTypography style={{ fontSize: "1rem" }}> Employee ID</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={firstName} onChange={(e) => setFirstName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Employee First Name</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={lastName} onChange={(e) => setLastName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Employee Last Name</MDTypography>
                  </Stack>
                </Grid>
                <Grid item sm={5}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={payRate} onChange={(e) => setPayRate(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Pay Rate</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={billRate} onChange={(e) => setBillRate(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Bill Rate</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={position} onChange={(e) => setPosition(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Position</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={timeIn} onChange={(e) => setTimeIn(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Time In</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={timeOut} onChange={(e) => setTimeOut(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Time Out</MDTypography>
                  </Stack>
                </Grid>
              </Grid>
              <MDBox p={2}>
                <Stack display="flex" direction="row" alignItems="center" justifyContent="end">
                  <Switch onChange={(e, v) => handleSelectAll(v)} />
                  <MDTypography color="info"> Select All</MDTypography>
                </Stack>
              </MDBox>
            </>
          )}
          {mode === "eventRoster" && (
            <>
              <Grid container p={2}>
                <Grid item sm={5}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={venueName} onChange={(e) => setVenueName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Venue Name</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={eventName} onChange={(e) => setEventName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Event Name</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={firstName} onChange={(e) => setFirstName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Employee First Name</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={lastName} onChange={(e) => setLastName(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Employee Last Name</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={phone} onChange={(e) => setPhone(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Employee Phone Number</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={email} onChange={(e) => setEmail(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>
                      {" "}
                      Employee Email Address
                    </MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={position} onChange={(e) => setPosition(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Position</MDTypography>
                  </Stack>
                </Grid>

                <Grid item sm={5}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={eventId} onChange={(e) => setEventId(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Event Id</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={eventDate} onChange={(e) => setEventDate(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Event Date</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={rank} onChange={(e) => setRank(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}>Employee Rank</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={hireDate} onChange={(e) => setHireDate(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Hire Date</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={birthDate} onChange={(e) => setBirthDate(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Birth Date</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={timeIn} onChange={(e) => setTimeIn(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Time In</MDTypography>
                  </Stack>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Switch checked={timeOut} onChange={(e) => setTimeOut(e.target.checked)} />
                    <MDTypography style={{ fontSize: "1rem" }}> Time Out</MDTypography>
                  </Stack>
                </Grid>
              </Grid>
              <MDBox p={2}>
                <Stack display="flex" direction="row" alignItems="center" justifyContent="end">
                  <Switch onChange={(e, v) => handleSelectAll(v)} />
                  <MDTypography color="info"> Select All</MDTypography>
                </Stack>
              </MDBox>
            </>
          )}
        </Card>
      </Fade>
    </Modal>
  );
};

export default ExportFieldsModal;
