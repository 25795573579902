import * as yup from "yup";
import { isEmpty } from "lodash";

export const memberSchema = (dropdowns) =>
  yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    status: yup
      .string()
      .required("Status is required")
      .nullable()
      .test({
        name: "validate-status",
        test: (value, ctx) => {
          if (value) {
            if (!dropdowns?.memberStatusField?.includes(value) || isEmpty(value)) {
              return ctx.createError({ message: "Insert a valid Status" });
            }
          }

          return true;
        },
      }),
    tShirtSize: yup
      .string()
      .nullable()
      .test({
        name: "validate-shirt",
        test: (value, ctx) => {
          if (value) {
            if (!dropdowns?.tShirtSizes?.includes(value) || isEmpty(value)) {
              return ctx.createError({ message: "Insert a valid Shirt Size" });
            }
          }

          return true;
        },
      }),
    phone: yup.string().test({
      name: "validate-phone",
      test: (value, ctx) => {
        const trimValueLength = value.replace(/\D/g, "").length;

        if (trimValueLength === 10 || trimValueLength === 0) {
          return true;
        }
        return ctx.createError({ message: "Phone number must be 10 digits" });
      },
    }),
    email: yup.string().test({
      name: "validate-email",
      test: async (value, ctx) => {
        // if (!value) return ctx.createError({ message: "Email is required" });
        if (!value) return true;
        if (/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value) || !value) {
          return true;
        }
        return ctx.createError({ message: "Invalid Email Address" });
      },
    }),
  });
