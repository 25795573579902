import { Box, Button, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Save } from "@mui/icons-material";
import { PartnerMembersRosterList } from "./PartnerMembersRosterList";
import fakeMembers from "./fakeMembers.json";
import fakeRoster from "./fakeRoster.json";

export const PartnerMembersRosterModal = ({ isOpen, toggleModal, partner, rosterPartners }) => {
  const { companyType, currentEvent } = useAppContextController();

  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);

  useEffect(() => {
    if (currentEvent?._id && currentEvent?.eventDate) {
      // setVenueSlug(currentEvent.venueSlug);
      // setMongoId(currentEvent._id);
      const gmtDate = new Date(currentEvent.eventDate);
      const offset = gmtDate.getTimezoneOffset();
      const MS_PER_MINUTE = 60000;
      const localDate = new Date(gmtDate - offset * MS_PER_MINUTE);

      if (localDate) setEventDate(localDate?.toISOString().split("T")[0]);
      if (gmtDate) setEventTime(gmtDate?.toISOString().split("T")[1]);
    }
  }, [currentEvent]);

  return (
    <>
      <GenericModal
        open={isOpen}
        setOpen={toggleModal}
        overflow="auto"
        width="70vw"
        body={
          <Box height="calc(80vh - 10rem)" minHeight="24rem" display="flex" flexDirection="column">
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                // type="submit"
                variant="contained"
                onClick={() => {}}
                style={{ color: "white" }}
              >
                Add to Roster
              </Button>
              <Button
                // type="submit"
                variant="contained"
                onClick={() => {}}
                color="success"
                style={{ color: "white" }}
              >
                Clock In Group
              </Button>
              <Button
                // type="submit"
                variant="contained"
                onClick={() => {}}
                style={{ color: "white" }}
              >
                Clock Out Group
              </Button>
            </Stack>
            <Box pt={4} display="flex" flex={1}>
              <PartnerMembersRosterList
                applicants={fakeMembers}
                rosterApplicants={fakeRoster}
                isLoading={false}
                eventDate={eventDate}
                eventTime={eventTime}
              />
            </Box>
            <Box pt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={<Save />}
                style={{ color: "white" }}
                sx={{ padding: "0.5rem 1rem" }}
                onClick={() => {}}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
        header={
          <Box display="flex">
            <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
              <MDTypography variant="h5" color="dark">
                {`${partner?.name}: Member Attendance`}
              </MDTypography>
            </MDBox>
          </Box>
        }
      />
    </>
  );
};
