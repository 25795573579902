import * as yup from "yup";

const additionalContactsSchema = yup.object().shape({
  firstName: yup
    .string()
    .typeError("Invalid First Name Input")
    .nullable()
    .required("First Name is Required"),
  lastName: yup
    .string()
    .typeError("Invalid Last Name Input")
    .nullable()
    .required("Last Name is Required"),
  title: yup.string().typeError("Invalid Title Input").nullable(),
  departmentLocation: yup
    .string()
    .typeError("Invalid Department Location Input")
    .nullable(),
  email: yup
    .string()
    .email("Invalid Email")
    .matches(/^(?!.*@[^,]*,)/)
    .typeError("Invalid Email Input")
    .nullable(),
  phone: yup
    .string()
    .test({
      name: "validate-phone",
      test: (value, ctx) => {
        if (value) {
          const trimValueLength = value.replace(/\D/g, "").length;

          if (trimValueLength === 10 || trimValueLength === 0 || !value) {
            return true;
          }
          return ctx.createError({ message: "Phone number must be 10 digits" });
        }
        return true;
      },
    })
    .typeError("Invalid Phone Input")
    .nullable()
    .required("Phone is Required"),
});

export default additionalContactsSchema;
