import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Autocomplete, Box, Button, Grid, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { NumericFormat } from "react-number-format";
import isEmpty from "lodash/isEmpty";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import MDBox from "components/MDBox";
import { eventSettings } from "data/event";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { Cancel as CancelIcon, Save as SaveIcon, Settings } from "@mui/icons-material";
import CustomCard from "components/CustomCard";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { cardStyles } from "layouts/pages/events/styles";
import { getDirtyFields } from "utils/helpers/formHelpers";
import updateEvent from "layouts/pages/events/actions/updateEvent";

const useStyle = makeStyles({
  error: {
    fontSize: "0.75rem",
  },
  box: {
    overflow: "visible",
  },
});

const EventSettings = () => {
  const { currentEvent, setCurrentEvent } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const classes = useStyle();
  const resolver = useYupValidationResolver(eventSettings);

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({ mode: "onBlur", defaultValues: currentEvent, resolver });

  const handleWaitlistChange = (value) => {
    setValue("waitListPercentage", value, { shouldDirty: true });
  };

  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { spStatus: "Employee" } };
  const filteredUsers = [];
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );
  users?.data
    ?.filter((item) => !["Terminated", "Inactive"].includes(item.status))
    .map((user) => {
      // const obj = filteredUsers.push(`${user.firstName} ${user.lastName}`);
      filteredUsers.push({
        userId: user._id,
        applicantId: user.applicantId,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.emailAddress,
        label: `${user.firstName} ${user.lastName}`,
        profileImg: user.profileImg,
      });
      return filteredUsers;
    });
  const sortUsers = (a, b) => {
    if (a.fullName > b.fullName) return 1;
    return -1;
  };

  const cancelChangesHandler = () => {
    const url = currentEvent?.eventUrl;
    reset(currentEvent);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
  };

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar("Event has been updated!", { variant: "success" });
      await queryClient.invalidateQueries("events");
    },
  });

  const editEventFormHandler = async (values) => {
    if (!currentEvent.venueSlug || !currentEvent.eventId) {
      enqueueSnackbar("Cannot Update Event -- No Event ID!", { variant: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    if (modifiedFields?.positionsRequested) {
      modifiedFields.positionsRequested = modifiedFields.positionsRequested.toString();
    }
    if (modifiedFields?.eventEndTime) {
      modifiedFields.eventEndTime = new Date(modifiedFields.eventEndTime).toISOString();
    }
    if (modifiedFields?.eventDate) {
      modifiedFields.eventDate = new Date(modifiedFields.eventDate).toISOString();
    }
  
    await updateEventMutation.mutateAsync({
      venueSlug: currentEvent.venueSlug,
      eventId: currentEvent._id,
      data: {
        ...modifiedFields,
        billRate: parseFloat(values.billRate),
        payRate: parseFloat(values.payRate),
      },
    });
  };

  const panelActions = (
    <Box mt={2}>
      <Button
        variant="text"
        startIcon={<CancelIcon />}
        onClick={cancelChangesHandler}
        disabled={isSubmitting}
        color="error"
        sx={{ color: "#F44335", fontSize: 14 }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="text"
        endIcon={<SaveIcon />}
        sx={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Box>
  );

  return (
    currentEvent && (
      <form onSubmit={handleSubmit(editEventFormHandler)}>
        <CustomCard
          icon={<Settings color="white" />}
          cardTitle="Event Settings"
          cardActions={isDirty && panelActions}
          cardSx={cardStyles.card}
          titleSx={cardStyles.title}
          actionsSx={cardStyles.actions}
          iconContainerSx={{ backgroundColor: "info", mt: -3, ml: "1.5rem" }}
          isStacked
        >
          <Box sx={cardStyles.container}>
            <MDBox mb={3}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                    Event Configuration
                  </MDTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name="positionsRequested"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        type="number"
                        label="Positions requested"
                        placeholder="# Positions requested"
                        name="positionsRequested"
                        InputLabelProps={{ shrink: !isEmpty(getValues("positionsRequested")) }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="billRate"
                    control={control}
                    render={({ field: { ref, ...props } }) => (
                      <NumericFormat
                        InputProps={{
                          startAdornment: "$",
                          inputProps: {
                            inputMode: "numeric",
                          },
                        }}
                        label="Bill Rate"
                        decimalScale={2}
                        {...props}
                        customInput={FormField}
                      />
                    )}
                  />

                  {errors?.billRate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.billRate.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="payRate"
                    control={control}
                    render={({ field: { ref, ...props } }) => (
                      <NumericFormat
                        InputProps={{
                          startAdornment: "$",
                          inputProps: {
                            inputMode: "numeric",
                          },
                        }}
                        label="Pay Rate"
                        decimalScale={2}
                        {...props}
                        customInput={FormField}
                      />
                    )}
                  />
                  {errors?.payRate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.payRate.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eventManager"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={filteredUsers || []}
                        name="eventManager"
                        value={field.value}
                        onChange={(e, v) => {
                          field.onChange(v);
                        }}
                        renderInput={(params) => (
                          <FormField {...params} type="text" label="Event Manager" />
                        )}
                      />
                    )}
                  />
                  {errors?.payRate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.payRate.message}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <MDTypography variant="body2" textTransform="capitalize" fontWeight="bold">
                    Notifications
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Make Public and Send Notification
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="makePublicAndSendNotification"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Send Confirmation to Sign-Ups
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="sendConfirmationToSignUps"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={6}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Allow Partners
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="allowPartners"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <MDTypography variant="body2" textTransform="capitalize" fontWeight="bold">
                      Waitlist
                    </MDTypography>
                  </MDBox>

                  <Controller
                    name="waitListPercentage"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Grid container>
                          <Grid item xs={3} alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              10% over
                            </MDTypography>
                          </Grid>

                          <Grid item xs={3} alignItems="center">
                            <Switch
                              id="10"
                              checked={field.value === "10"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              Waitlist All
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <Switch
                              id="Infinity"
                              name="waitListPercentage"
                              checked={field.value === "Infinity"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              25% over
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <Switch
                              id="25"
                              checked={field.value === "25"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              No Waitlist
                            </MDTypography>
                          </Grid>

                          <Grid item xs={3} alignItems="center">
                            <Switch
                              id="0"
                              name="waitListPercentage"
                              checked={field.value === "0"}
                              onChange={(e, v) => {
                                handleWaitlistChange(e.target.id);
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              30% over
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <Switch
                              id="30"
                              checked={field.value === "30"}
                              onChange={(e, v) => handleWaitlistChange(e.target.id)}
                            />
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <MDTypography variant="body2" textTransform="capitalize">
                              Call-Off Notifications
                            </MDTypography>
                          </Grid>
                          <Grid item xs={3} alignItems="center">
                            <Controller
                              name="notifyCallOff"
                              control={control}
                              render={({ field: field24Hour }) => (
                                <Switch
                                  checked={field24Hour.value === "Yes"}
                                  onChange={(e) =>
                                    field24Hour.onChange(e.target.checked ? "Yes" : "No")
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                  <Grid container>
                    <Grid item xs={12} alignItems="center">
                      <MDBox mb={2}>
                        <MDTypography variant="body2" textTransform="capitalize" fontWeight="bold">
                          Event Reminders
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={3} alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        24-Hour
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <Controller
                        name="reminder24Hour"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3} alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        GeoFence
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <Controller
                        name="geoFence"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        48-Hour
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <Controller
                        name="reminder48Hour"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <MDTypography variant="body2" textTransform="capitalize">
                        Google Map
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <Controller
                        name="googleMap"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Box>
        </CustomCard>{" "}
      </form>
    )
  );
};

export default EventSettings;
