import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Icon, Grid, IconButton, Tooltip, Snackbar, Alert, Box } from "@mui/material";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import PictureAvatar from "components/PictureAvatar";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider } from "notistack";
import GetIcons from "components/GetIcons";
import TasksTableActions from "layouts/pages/tasks/components/TasksTableActions";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import fetchTasks from "layouts/pages/tasks/actions/fetchTasks";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import TaskDetailsModal from "../TaskDetailsModal";
// import TasksActionModal from "../TasksActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const TasksTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
}) => {
  const { setCurrentTask, currentTask, currentLoggedUser } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const [taskMode, setTaskMode] = useState("edit");
  const {
    data: issues,
    isLoading,
    refetch: refetchTasks,
  } = useQuery(["issues", allOptions], () => fetchTasks(allOptions));

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const columns = [
    {
      title: "Icon",
      field: "type",
      customCell: (image, field, row) => <GetIcons category={row?.category} />,
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Type",
      field: "taskType",
    },
    {
      title: "Date Opened",
      field: "createdDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Assigned By",
      field: "assignedByName",
      customCell: (assignedByName, field, row) => (
        <Grid container spacing={3} direction="row">
          <Grid item>
            <PictureAvatar
              image={assignedByName?.profileImg}
              firstName={assignedByName?.userFirstName}
              lastName={assignedByName?.userLastName}
              userId={assignedByName?.userId}
              size="md"
            />
          </Grid>
          <Grid item>
            <MDTypography variant="body">{`${assignedByName?.firstName} ${assignedByName?.lastName}`}</MDTypography>
          </Grid>
        </Grid>
      ),
    },
    {
      title: "Assigned To",
      field: "assignedToList",
      customCell: (assignedToList, field, row) => {
        // this needs to accommodate an array
        if (Array.isArray(assignedToList) && assignedToList?.length)
          return (
            <Grid container spacing={3} direction="row">
              <Grid item>
                <PictureAvatar
                  image={assignedToList[0]?.profileImg}
                  firstName={assignedToList[0]?.firstName}
                  lastName={assignedToList[0]?.lastName}
                  userId={assignedToList[0]?.userId}
                  size="md"
                />
              </Grid>
              <Grid item>
                <MDTypography variant="body">{assignedToList[0]?.fullName}</MDTypography>
              </Grid>
            </Grid>
          );
        return "";
      },
    },
    // {
    //   title: "Created By",
    //   field: "userFirstName",
    //   customCell: (id, field, row) => (
    //     <MDTypography variant="body">
    //       {row.userFirstName} {row.userLastName}
    //     </MDTypography>
    //   ),
    // },
    {
      title: "Task Title",
      field: "taskName",
      customCell: (subject) => (
        <Tooltip title={subject}>
          <MDTypography variant="body">
            {subject?.length > 20 ? `${subject.substring(0, 20)}...` : subject}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      field: "taskDescription",
      customCell: (desc) => {
        const text = desc?.length > 30 ? `${desc.substring(0, 30)}...` : desc;
        return (
          <Tooltip title={desc}>
            <Box sx={{ typography: "body1" }}>{text ? parse(text) : ""}</Box>
          </Tooltip>
        );
      },
    },
    {
      title: "Last Updated",
      field: "modifiedDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Tasks Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <TasksTableActions
          id={id}
          row={row}
          setCurrentTask={setCurrentTask}
          setNavigateUrl={setNavigateUrl}
          setTaskModalOpen={setTaskModalOpen}
          setTaskMode={setTaskMode}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/User ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
  ];

  const columnsForTasks = [
    { title: "First Name", field: "userFirstName" },
    { title: "Last Name", field: "userLastName" },
    { title: "Title", field: "title" },
    { title: "Status", field: "status" },
  ];

  const handleRowClick = (task, e) => {
    e.stopPropagation();
    setCurrentTask({ ...task });
    setTaskMode("edit");
    setTaskModalOpen(true);
  };

  const addNew = (e) => {
    if (!currentTask) {
      setCurrentTask({});
    }
    setTaskMode("create");
    setTaskModalOpen(true);
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">checklistIcon</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Tasks
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchTasks}
                placeholder="Search Tasks"
                columns={columnsForTasks}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                fetchUsers
                setPage={setPage}
                searchBy={["title", "description"]}
                // filterBy="applicantId"
                filterBy="_id"
                filterByColumn="_id"
              />
            )}
            {showSearchBar && (
              <Searchbar
                fetch={fetchUsers}
                placeholder="Search Users"
                columns={columnsForSearch}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "emailAddress"]}
                filterBy="userId"
                filterByColumn="_id"
              />
            )}
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={issues}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={false}
            defaultSort="issueDate:desc"
          />
        </MDBox>
        <TaskDetailsModal
          mode={taskMode}
          setTaskModalOpen={setTaskModalOpen}
          taskModalOpen={taskModalOpen}
          setCurrentTask={setCurrentTask}
          currentTask={currentTask}
          setToastAlert={setToastAlert}
          refetchTasks={refetchTasks}
          currentLoggedUser={currentLoggedUser}
        />
      </SnackbarProvider>
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
TasksTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Tasks",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
TasksTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default TasksTable;
