import { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PollIcon from "@mui/icons-material/Poll";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import SaveIcon from "@mui/icons-material/Save";
import MDDatePicker from "components/MDDatePicker";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
    height: "100%",
  },
  addButton: {
    fontSize: 40,
  },
  root: {
    height: "350px",
  },
  list: {
    width: "70%",
    height: "350px",
    overflowY: "scroll",
    border: "solid black 1px",
    padding: 2,
  },
  button: {
    margin: "8px",
  },
});

const ReportBuilder = () => {
  const classes = useStyle();
  const [rightList, setRightList] = useState([]);
  const [selectedLeftItem, setSelectedLeftItem] = useState(null);
  const [selectedRightItem, setSelectedRightItem] = useState(null);

  const hardcodedData = [
    { name: "Job Posted Date" },
    { name: "Job Status" },
    { name: "Applicant Last Name" },
    { name: "Applicant first Name" },
    { name: "Applicant Status New" },
    { name: "Applicant Status ATC" },
    { name: "Applicant Status Screened" },
    { name: "Applicant Status Pre-hire" },
    { name: "Venue Name" },
  ];
  const [leftList, setLeftList] = useState(hardcodedData);
  // const sortUsers = (a, b) => {
  //     if (a.firstName > b.firstName) return 1;
  //     return -1;
  // };
  // filteredList.sort(sortUsers);

  const moveItem = (index, sourceList, targetList, direction) => {
    const source = [...sourceList];
    const target = [...targetList];
    const item = source.splice(index, 1);
    target.push(...item);
    source.sort();
    target.sort();
    if (direction === "left") {
      setLeftList(source);
      setRightList(target);
    } else {
      setRightList(source);
      setLeftList(target);
    }
  };
  const handleLeftItemClick = (index) => {
    setSelectedLeftItem(index);
    setSelectedRightItem(null);
  };

  const handleRightItemClick = (index) => {
    setSelectedRightItem(index);
    setSelectedLeftItem(null);
  };

  const handleButtonClick = (direction) => {
    if (direction === "left") {
      if (selectedLeftItem !== null) {
        moveItem(selectedLeftItem, leftList, rightList, direction);
        setSelectedLeftItem(null);
      }
    } else if (direction === "right") {
      if (selectedRightItem !== null) {
        moveItem(selectedRightItem, rightList, leftList, direction);
        setSelectedRightItem(null);
      }
    }
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item sm={12}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="dark"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <PollIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Report Builder
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} px={3}>
        <Grid item sm={6}>
          <Grid item sm={11}>
            <Autocomplete
              options={["Jobs Stages Report 11.17.23", "option 2"]}
              autoSelect
              freeSolo
              name="page"
              defaultValue="Jobs Stages Report 11.17.23"
              renderInput={(params) => (
                <FormField
                  {...params}
                  defaultValue="Jobs Stages Report 11.17.23"
                  type="text"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item sm={6} />
        <Grid item sm={6}>
          <MDDatePicker
            // onChange={handleChange}
            // onBlur={handleBlur}
            value={new Date()}
            input={{
              label: "Start Date",
              variant: "standard",
              fullWidth: true,
              name: "startDate",
              // error: errors.startDate && touched.startDate,
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <MDDatePicker
            // onChange={handleChange}
            // onBlur={handleBlur}
            value={new Date()}
            input={{
              label: "End Date",
              variant: "standard",
              fullWidth: true,
              name: "endDate",
              // error: errors.startDate && touched.startDate,
            }}
          />
        </Grid>
      </Grid>
      <Box className={classes.root} px={3}>
        <Grid container>
          <Grid item sm={5}>
            <List className={classes.list}>
              {leftList?.map((item, index) => (
                <ListItemButton
                  key={item?.userId}
                  selected={index === selectedLeftItem}
                  onClick={() => handleLeftItemClick(index)}
                >
                  <ListItemText primary={item?.name} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item sm={2} display="flex" p={3}>
            <Grid container direction="column">
              <Button
                className={classes.button}
                variant="contained"
                color="success"
                onClick={() => handleButtonClick("left")}
              >
                &gt;
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="error"
                onClick={() => handleButtonClick("right")}
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item sm={5}>
            <List className={classes.list}>
              {rightList?.map((item, index) => (
                <ListItemButton
                  key={item?.userId}
                  selected={index === selectedRightItem}
                  onClick={() => handleRightItemClick(index)}
                >
                  <ListItemText primary={item?.name} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
      <MDBox textAlign="end" p={3}>
        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          style={{ color: "white" }}
        >
          Save
        </Button>
      </MDBox>
    </Card>
  );
};

export default ReportBuilder;
