import { useState } from "react";

import { Grid, Icon, Stack, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import { CheckCircle, CheckCircleOutline, Cancel, Email } from "@mui/icons-material";

import { dataStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ContactsItem = ({
  row,
  idx,
  noContact,
  onPanelChange,
  setSelectedContact,
  onEdit,
  onRemove,
  onPrimaryChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { contact, firstName, lastName, email, phone, primaryContact } = row;
  const isPrimary = primaryContact === "Yes";
  const isIconMenuOpen = Boolean(anchorEl);

  const isDisabled = (!email && !phone) || noContact;

  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  const handleChange = (e) => {
    e.stopPropagation();
    onPrimaryChange(idx);
    handleIconClose();
  };

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          onEdit(idx, row, true);
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={2.5}>
            <MDTypography sx={styles.font}>{`${firstName} ${lastName}` || contact}</MDTypography>
          </Grid>
          <Grid item sm={4}>
            <MDTypography sx={styles.font}>{email}</MDTypography>
          </Grid>
          <Grid item sm={2.5}>
            <MDTypography sx={styles.font}>{phone}</MDTypography>
          </Grid>
          <Grid item sm={1} textAlign="right">
            {isPrimary ? (
              <Tooltip title="Primary" placement="top">
                <CheckCircle color="success" fontSize="medium" sx={{ mb: -0.5, mt: 0.5 }} />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Set Primary" placement="top">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleIconClick(e);
                    }}
                  >
                    <CheckCircleOutline color="" fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Menu anchorEl={anchorEl} open={isIconMenuOpen} onClose={handleIconClose}>
                  <MenuItem onClick={handleChange} disableRipple sx={{ p: 0 }}>
                    <MDTypography variant="h6">Set as Primary</MDTypography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Grid>

          <Grid item sm={1}>
            <MDBox textAlign="right">
              <Tooltip title="Send Message" placement="top">
                <IconButton
                  sx={{ p: 0 }}
                  disabled={isDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedContact(idx);
                    onPanelChange("message");
                  }}
                >
                  <Email color={isDisabled ? "" : "warning"} fontSize="medium" />
                </IconButton>
              </Tooltip>
            </MDBox>
          </Grid>
          <Grid item sm={1}>
            <MDBox textAlign="right">
              {!isPrimary && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(idx);
                  }}
                >
                  <Cancel color="error" fontSize="medium" />
                </IconButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ContactsItem;
