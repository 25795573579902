import { useMemo } from 'react'
import { Card, Grid } from '@mui/material';
import PollIcon from '@mui/icons-material/Poll';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import { makeStyles } from "@mui/styles";
import VenueIcon from 'components/VenueIcon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'components/DataTable';
import moment from 'moment';
import ReportsTableActions from './ReportsTableActions';

const useStyle = makeStyles({
  box: {
    marginTop: 35,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});
const ReportsTable = () => {
  const classes = useStyle();
  const columns = useMemo(
    () => [
      {
        title: "",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => (
          <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />
        ),
      },
      {
        title: "Venue Name",
        field: "venueName",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Job Title", field: "title" },
      {
        title: "Job Posted Date", field: "postedDate", customCell: (date, field, row) => (
          <MDTypography fontSize="1rem" >
            {moment(date).format("MM/DD/YYYY")}
          </MDTypography>)
      },
      { title: "New", field: "new", },
      { title: "atc", field: "atc", },
      { title: "screened", field: "screened", },
      { title: "preHire", field: "preHire", },
      {
        title: "Job Actions",
        field: "_id",
        customCell: (id, field, row) => (
          <ReportsTableActions
          // id={id}
          // row={row}
          // setCurrentTemplate={setCurrentTemplate}
          // setModalInfo={setModalInfo}
          // setNavigateUrl={setNavigateUrl}
          // toggleEditModal={toggleEmailTemplateModal}
          // toggleConfirmationModal={toggleConfirmationModal}
          // setContext={setContext}
          />
        ),
      }
    ],
    []
  );
  const hardcodedData = [
    {
      logoUrl: "https://images.stadiumpeople.com/sp/allenisd/venues/logo/allenisd-venuelogo.png",
      venueSlug: "allenisd",
      venueName: "Allen ISD",
      title: "Event Staff",
      postedDate: "2023-10-27T21:38:16.312Z",
      new: 34,
      atc: 82,
      screened: 30,
      preHire: 12,
    },
    {
      logoUrl: "https://images.stadiumpeople.com/sp/auburn/venues/logo/auburn-venuelogo.png",
      venueSlug: "auburn",
      venueName: "Auburn University",
      title: "Event Staff",
      postedDate: "2023-10-27T21:38:16.312Z",
      new: 34,
      atc: 82,
      screened: 30,
      preHire: 12,
    },
    {
      logoUrl: "https://images.stadiumpeople.com/sp/cutx/venues/logo/cutx-venuelogo.png",
      venueSlug: "cutx",
      venueName: "Credit Union of Texas Event Center",
      title: "Event Staff",
      postedDate: "2023-10-27T21:38:16.312Z",
      new: 34,
      atc: 82,
      screened: 30,
      preHire: 12,
    },
    {
      logoUrl: "https://images.stadiumpeople.com/sp/dcm/venues/logo/dcm-venuelogo.png",
      venueSlug: "dcm",
      venueName: "Dallas Cowboys Merchandising",
      title: "Event Staff",
      postedDate: "2023-10-27T21:38:16.312Z",
      new: 34,
      atc: 82,
      screened: 30,
      preHire: 12,
    },
    {
      logoUrl: "https://images.stadiumpeople.com/sp/emory/venues/logo/Emory-venuelogo.png",
      venueSlug: "emory",
      venueName: "Emory",
      title: "Event Staff",
      postedDate: "2023-10-27T21:38:16.312Z",
      new: 34,
      atc: 82,
      screened: 30,
      preHire: 12,
    },
  ]
  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} >
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="dark"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <PollIcon fontSize='large' />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Jobs Stages Report 11.17.23
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox justifyContent="end" display="flex">
        <MDBox mr={2}>
          <DownloadIcon color="info" fontSize='large' />
        </MDBox>
        <MDBox mr={2}>
          <PictureAsPdfIcon color="info" fontSize='large' />
        </MDBox>
        <MDBox mr={2}>
          <DonutSmallIcon color="info" fontSize='large' />
        </MDBox>
      </MDBox>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={{ data: hardcodedData, count: hardcodedData.length }}
        // page={page}
        // limit={limit}
        // setPage={setPage}
        // setLimit={setLimit}
        // onRowClick={(row, e) => handleRowClick(row, e)}
        // order={order}
        // orderBy={orderBy}
        // toggleSort={toggleSort}
        // fetchAll={fetchAll}
        // isLoading={isLoading}
        // defaultSort="jobSlug"
        // idField="jobSlug"
        />
      </MDBox>
    </Card>
  );
}
export default ReportsTable