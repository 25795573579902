import { useMemo, useEffect, useState } from "react";

import { Box, Typography, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { useEventContext } from "context/EventContext";
import "./PartnerMembersRosterList.css";
import { useAppContextController } from "context/AppContext";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import { Check } from "@mui/icons-material";
import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import { useMutation } from "react-query";
import EventActionModal from "../../EventActionModal";
import TimeInOut from "../../TimeInOut";

export const PartnerMembersRosterList = ({
  applicants = [],
  rosterApplicants = [],
  isLoading,
  eventDate,
  eventTime,
}) => {
  const [modalInfo, setModalInfo] = useState({ data: {} });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const { company, currentEvent, currentVenue } = useAppContextController();
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const isOnRoster = (applId, i) => {
    if (rosterApplicants) {
      const returnValue = rosterApplicants.findIndex(
        (item) => item.id === applId && item.status === "Roster"
      );
      return returnValue > -1;
    }
    return false;
  };

  // const clockInRosterMutation = useMutation(rosterClockIn, {
  //   // eslint-disable-next-line
  //   onSuccess: async (_, { data }) => {
  //     // await queryClient.invalidateQueries("rosterApplicants")
  //     const newApps = currentEvent?.applicants;
  //     if (newApps?.length) {
  //       const ndx = newApps.findIndex((item) => item.id === data.id);
  //       if (ndx > -1) {
  //         newApps[ndx] = data;
  //         setCurrentEvent({ ...currentEvent, applicants: newApps });
  //       }
  //     }
  //     toggleReadRoster();
  //   },
  //   onError: (error) => alert(error.toString()),
  // });

  // const insertRosterMutation = useMutation(updateEventRoster, {
  //   // eslint-disable-next-line
  //   onSuccess: async (_, { data }) => {
  //     // await queryClient.invalidateQueries("rosterApplicants")
  //     const newApps = currentEvent?.applicants;
  //     if (newApps?.length) {
  //       const ndx = newApps.findIndex((item) => item.id === data.id);
  //       if (ndx > -1) {
  //         newApps[ndx] = data;
  //         setCurrentEvent({ ...currentEvent, applicants: newApps });
  //       }
  //     }
  //     toggleReadRoster();
  //   },
  //   onError: (error) => alert(error.toString()),
  // });

  const clockInOut = async (e, id, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    } else newRoster[field] = newRoster[field] || new Date();
    // await insertRosterMutation.mutateAsync({ id: currentEvent?._id, data: newRoster });
  };

  const clockInOutAtEventTime = async (e, id, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    } else newRoster[field] = newRoster[field] || new Date();
    // await clockInRosterMutation.mutateAsync({
    //   id: currentEvent?._id,
    //   applicantId: rosterObj.id,
    //   data: newRoster,
    // });
  };

  const columns = useMemo(
    () => [
      {
        headerName: "First Name",
        field: "firstName",
        flex: 1,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "Last Name",
        field: "lastName",
        flex: 1,
        customCell: ({ value, row }) => (
          <Link to={`/applicants/${row._id}/action/applicantinfo`}>
            <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
              {value}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "Phone",
        field: "phone",
        flex: 1,
        // renderCell: ({ value }) =>
        //   value && value !== "" ? (
        //     <Tooltip title={value}>
        //       <CheckCircle fontSize="medium" style={{ color: "green" }} />
        //     </Tooltip>
        //   ) : null,
      },
      {
        headerName: "T-Shirt Size",
        field: "tShirtSize",
        flex: 1,
        align: "center",
        renderCell: ({ value }) => (
          <Typography variant="subtitle" color="#000000de" sx={{ textDecoration: "none" }}>
            {value}
          </Typography>
        ),
      },
      {
        headerName: "Clock-In",
        field: "timeIn",
        flex: 1,
        align: "center",
        renderCell: ({ value, row }) => (
          <>
            <MDBox width="7rem">
              <TimeInOut
                id={row?._id}
                field="timeIn"
                currentEvent={currentEvent}
                eventDate={eventDate}
                eventTime={eventTime}
                rosterObj={
                  // !rosterLoading &&
                  rosterApplicants.find((item) => item.id === row._id)
                }
                disabled={!isOnRoster(row._id)}
                timeZone={currentEvent?.timeZone}
                clockInOut={(e, rosterObj) => clockInOut(e, row._id, "timeIn", rosterObj)}
                clockInOutAtEventTime={(e, rosterObj) =>
                  clockInOutAtEventTime(e, row._id, "timeIn", rosterObj)
                }
              />
            </MDBox>
          </>
        ),
      },
      {
        headerName: "Clock-Out",
        field: "timeOut",
        flex: 1,
        align: "center",
        renderCell: ({ value, row }) => (
          <>
            <MDBox width="7rem">
              {/* <Tooltip title="Clock Out"> */}
              <TimeInOut
                id={row?._id}
                field="timeOut"
                currentEvent={currentEvent}
                eventDate={eventDate}
                eventTime={eventTime}
                rosterObj={
                  // !rosterLoading &&
                  rosterApplicants.find((item) => item.id === row._id)
                }
                // checks if there is a time in value before enabling
                disabled={
                  !(
                    isOnRoster(row._id) &&
                    rosterApplicants.find((item) => item.id === row._id)?.timeIn
                  )
                }
                timeZone={currentEvent?.timeZone}
                eventEndTime={currentEvent?.eventEndTime}
                clockInOut={(e, rosterObj) => clockInOut(e, row._id, "timeOut", rosterObj)}
              />
              {/* </Tooltip> */}
            </MDBox>
          </>
        ),
      },
      {
        headerName: "Roster",
        field: "roster",
        flex: 1,
        align: "center",
        renderCell: ({ value, row }) => isOnRoster(row._id) ? <Check fontSize="medium" style={{ color: "green" }} /> : null,
      },
      {
        headerName: "Notes",
        field: "notes",
        flex: 1,
        align: "center",
        renderCell: ({ value, row }) => (
          <TableAction
            color="info"
            icon="description"
            showBadge
            badgeNumber={0}
            tooltip="Add a Note"
            clickAction={(e) => {
              setModalInfo({ type: "applicantComment", data: row });
              setGenericModalOpen(true);
            }}
          />
        ),
      },
    ],
    []
  );

  const parsedRows = useMemo(
    () =>
      applicants.map((item) => {
        const venueSlug = currentEvent?.venueSlug || currentVenue?.slug;
        const venueStatus = getApplicantVenueStatus(item, venueSlug);

        return {
          ...item,
          id: item._id,
          venueStatus,
          enabled: true,
        };
      }),
    [applicants, currentEvent?.venueSlug, currentVenue?.slug]
  );

  // useEffect(() => {
  //   setRows(eventRoster);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [eventRoster]);

  return (
    <Box
      sx={{
        minHeight: "100px",
        display: "flex",
        flexGrow: 1,
        overflow: "scroll",
        width: "100%",
      }}
    >
      <DataGrid
        rows={parsedRows}
        columns={columns}
        checkboxSelection
        selectionModel={selectedRecipients}
        onSelectionModelChange={setSelectedRecipients}
        disableSelectionOnClick
        isRowSelectable={(params) => {
          return params?.row?.enabled;
        }} // Check if params.data is defined
        getRowClassName={(params) => {
          if (selectedRecipients.includes(params?.row?._id)) {
            return "row-selected";
          }
          // if (
          //   (params?.row?.recordLocked && params?.row?.recordLocked === "Yes") ||
          //   (params?.row?.venueStatus && params?.row?.venueStatus === "Locked")
          // )
          //   return "row-locked";
          // if (params?.row?.email && params?.row?.phone) return "";
          // // if (params?.row?.email && emailChecked) return "row-emailonly";
          // // if (params?.row?.phone && smsChecked) return "row-phoneonly";
          return "";
        }}
        loading={isLoading}
      />
      <EventActionModal
        open={genericModalOpen}
        setOpen={setGenericModalOpen}
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        setToastAlert={setToastAlert}
        toggleReadApps={() => {}}
      />
    </Box>
  );
};
