// import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
// import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
// import fetchEvents from "layouts/pages/events/actions/fetchEvents";
// import fetchVenues from "layouts/pages/venues/actions/fetchVenues";

const EntityName = ({
  activity,
  action,
  activityApplicant,
  activityJob,
  activityEvent,
  activityVenue,
  activityTemplate,
}) => {
  if (activity?.integration === "Indeed") {
    return (
      <>
        <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
          Internet
        </MDTypography>
      </>
    );
  }
  if (activityApplicant) {
    return (
      <>
        <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
          {action}
        </MDTypography>
        <MDTypography variant="h5" color="dark" sx={{ mt: 1, ml: 2, mr: -6 }}>
          {`${activityApplicant?.firstName} ${activityApplicant?.lastName}`}
        </MDTypography>
      </>
    );
  }
  if (activityJob) {
    return (
      <>
        <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
          {action}
        </MDTypography>
        <MDTypography variant="h5" color="dark" sx={{ mt: 1, ml: 2, mr: -6 }}>
          {activityJob?.title}
        </MDTypography>
      </>
    );
  }
  if (activityEvent) {
    return (
      <>
        <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
          {action}
        </MDTypography>
        <MDTypography variant="h5" color="dark" sx={{ mt: 1, ml: 2, mr: -6 }}>
          {activityEvent?.eventName}
        </MDTypography>
      </>
    );
  }
  if (activityVenue) {
    return (
      <>
        <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
          {action}
        </MDTypography>
        <MDTypography variant="h5" color="dark" sx={{ mt: 1, ml: 2, mr: -6 }}>
          {activityVenue?.name}
        </MDTypography>
      </>
    );
  }
  if (activityTemplate) {
    return (
      <>
        <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
          {action}
        </MDTypography>
        <MDTypography variant="h5" color="dark" sx={{ mt: 1, ml: 2, mr: -6 }}>
          {activityTemplate}
        </MDTypography>
      </>
    );
  }

  return (
    <>
      <MDTypography variant="h5" color="dark" sx={{ ml: 2, mr: -6 }}>
        {activity?.userId}
      </MDTypography>
    </>
  );
};

export default EntityName;
