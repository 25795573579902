import { useAuth0 } from "@auth0/auth0-react";
import storeToken from "api/auth/storeToken";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMountedReferenced from "hooks/useIsMountedReferenced";
import useSessionAuth from "../../hooks/useSessionAuth";

const StoreTokenAndRedirect = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const { handleStoreToken } = useSessionAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedReferenced();

  useEffect(() => {
    const storeTokenAndProceed = async () => {
      const tokenStored = await handleStoreToken();

      if (tokenStored) {
        navigate("/dashboards/analytics");
      }

      if (isMountedRef.current) {
        setIsLoading(false);
      }
    };

    storeTokenAndProceed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, navigate]);

  if (isLoading) {
    return null;
  }

  return children;
};

export default StoreTokenAndRedirect;
