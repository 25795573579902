import axios from "axios";

import { baseAxios } from "config";
// import createApiParams from "utils/createApiParams";
import createApiParams from "utils/createApiParamsTemp";

const fetchPartners = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useElemMatch = false,
  useOr = false,
}) => {
  try {
    const { paginationQuery, sortQuery, filterQuery, queryString } = createApiParams({
      page,
      limit,
      order,
      orderBy,
      fetchAll,
      filters,
    });

    // TODO:  fix this with new api params functions
    // const queryString = `${paginationQuery}${sortQuery}${filterQuery}${
    //   useOr ? "&useOr=true" : ""
    // }`.replace(/\?/g, "");
    const res = await baseAxios.get(
      `/partners${queryString}${useOr ? "&useOr=true" : ""}${
        useElemMatch ? `&useElemMatch=true` : ""
      }`
    );
    if (!res) return { data: [] };
    if (res?.data?.data?.length) {
      res.data = { ...res.data };
    }

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchPartners;
