import { useMemo, useState } from "react";
import { Button, Grid, Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataList from "components/DataList";
import useSort from "utils/useSort";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import fetchTasks from "layouts/pages/tasks/actions/fetchTasks";
import TaskDetailsModal from "layouts/pages/tasks/components/TaskDetailsModal";
import { SnackbarProvider } from "notistack";
import UserActionModal from "layouts/pages/users/components/UserActionModal";
import TaskRow from "layouts/pages/dashboard/components/MyTasks/TaskRow";
import Searchbar from "components/Searchbar";
import moment from "moment";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  button: {
    fontSize: "40px!important",
  },
});

const MyTasks = ({ fetchAll, currentLoggedUser, filters }) => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [taskMode, setTaskMode] = useState("edit");
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageModalInfo, setMessageModalInfo] = useState(null);


  const columns = useMemo(() => [

    {
      title: "Name",
      field: "taskName",
    },

    {
      title: "Type",
      field: "taskType",
    },
    {
      title: "Created Date",
      field: "createdDate",
      customCell: (date) => moment(date).format("MM/DD/YYYY"),
    }
  ]);

  const allOptions = {
    ...options,
    filters: { "assignedToList.userId": currentLoggedUser?._id },
  };



  const {
    data: issues,
    isLoading,
    refetch: refetchTasks,
  } = useQuery(["issues", allOptions], () => fetchTasks(allOptions));
  const renderRow = (row) => (
    <TaskRow
      row={row}
      setCurrentTask={setCurrentTask}
      setTaskModalOpen={setTaskModalOpen}
      setTaskMode={setTaskMode}
      setMessageModalOpen={setMessageModalOpen}
      setMessageModalInfo={setMessageModalInfo}
    />
  );

  const addNew = (e) => {
    if (!currentTask) {
      setCurrentTask({});
    }
    setTaskMode("create");
    setTaskModalOpen(true);
    e.stopPropagation();
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2} ml={5}>
        <Grid item xs={12}>
          <Searchbar
            fetch={fetchTasks}
            fetchAll={fetchAll}
            placeholder="Search Tasks"
            columns={columns.slice(1, columns.length - 1)}
            queryCharacterLimit={1}
            resultsLimit={10}
            // setFilters={setFilters}
            setPage={setPage}
            searchBy="taskName"
            // onRowClick={(item) => searchBarRowClick(item)}
            dropRight
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              navigate(`/tasks`);
            }}
          >
            View All
          </Button>
          |
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              addNew();
            }}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <DataList
          renderRow={renderRow}
          data={issues?.data}
          onRowClick={() => { }}
          icon="checklistIcon"
          iconColor="success"
          title="My Tasks"
          isLoading={isLoading}
          greybar
          divider
          header={header}
          tableHeight={420}
          height={isLaptop ? 550 : null}
          cardVariant="outlined"
          scrollY
        />
        <TaskDetailsModal
          mode={taskMode}
          setTaskModalOpen={setTaskModalOpen}
          taskModalOpen={taskModalOpen}
          setCurrentTask={setCurrentTask}
          currentTask={currentTask}
          setToastAlert={setToastAlert}
          refetchTasks={refetchTasks}
          currentLoggedUser={currentLoggedUser}
        />
        <UserActionModal
          open={messageModalOpen}
          setOpen={setMessageModalOpen}
          modalInfo={messageModalInfo}
          setToastAlert={setToastAlert}
        />
      </SnackbarProvider>
    </>
  );
};

export default MyTasks;
