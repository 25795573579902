import { Box } from "@mui/material";
import { forwardRef } from "react";

const FlexWrapper = forwardRef((props, ref) => (
  <Box
    display="flex"
    alignItems="center"
    flexWrap="wrap"
    width="100%"
    justifyContent="center"
    {...props}
    ref={ref}
  />
));

export default FlexWrapper;
