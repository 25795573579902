import { Save } from "@mui/icons-material";
import { Button, Icon, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";
import CustomStepper from "components/CustomizedMui/CustomStepper";
import ButtonTabs from "./ButtonTabs";
import NewApplicantForms from "../../new/components/NewApplicantForms";

const ApplicantOnboardingPanel = ({ refetchApplicant }) => {
  const { currentApplicant } = useAppContextController();
  const { setApplicant, registrationSteps, onboardingProgressId, setApplicantSteps } =
    useNewApplicantContext();
  const isEmployee = currentApplicant?.status === "Employee";

  useEffect(() => {
    if (currentApplicant) {
      setApplicant(currentApplicant);
      setApplicantSteps(
        currentApplicant.status,
        currentApplicant.applicantStatus,
        currentApplicant.acknowledged
      );
    }
  }, [currentApplicant]);

  const handleSubmit = useCallback(async () => {
    const form = await document.getElementById("current-form");

    const submitHandler = function (event) {
      // refetchApplicant();
      form.removeEventListener("submit", submitHandler);
    };

    form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));

    form.addEventListener("submit", submitHandler);
  }, [refetchApplicant]);

  return (
    <Card sx={{ overflow: "visible !important", mt: "30px" }}>
      <MDBox display="flex">
        <MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="error"
            color="white"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="large">folder_shared</Icon>
          </MDBox>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={5} xl={5}>
            <Grid container>
              <Grid item xs={12} sm={10} lg={11} xl={11}>
                {currentApplicant && (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      pt={4}
                      pb={isEmployee ? 1 : 3}
                      px={2}
                    >
                      <MDTypography variant="h5" color="dark" sx={{ whiteSpace: "nowrap" }}>
                        {`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                      </MDTypography>
                      <Button>{currentApplicant?.status}</Button>
                      <MDTypography variant="h6" color="dark" sx={{ whiteSpace: "nowrap" }}>
                        {currentApplicant.modifiedDate &&
                          `Last Update: ${new Date(
                            currentApplicant?.modifiedDate
                          ).toLocaleDateString()} ${new Date(
                            currentApplicant?.modifiedDate
                          ).toLocaleTimeString()}`}
                      </MDTypography>
                    </Stack>
                    {!isEmployee && (
                      <CustomStepper
                        activeStepId={onboardingProgressId + 1}
                        steps={registrationSteps.filter(
                          (step) => step.applicantObject !== "complete"
                        )}
                      />
                    )}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      <Grid item xs={12} sm={4} lg={5} xl={5} />
      <Grid item xs={12} sm={4} lg={12} xl={12}>
        <MDBox m={3} mt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} lg={12} xl={12}>
              <FlexWrapper sx={{ padding: 3, flexWrap: "nowrap" }} justifyContent="space-between">
                <ButtonTabs />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  endIcon={<Save />}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </FlexWrapper>
            </Grid>
            <Grid item xs={12} sm={4} lg={12} xl={12}>
              <NewApplicantForms parent="onboarding" />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Card>
  );
};

export default ApplicantOnboardingPanel;
