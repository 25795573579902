import { useMemo } from "react";
import moment from "moment";
import { Autocomplete, Button, Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PollIcon from "@mui/icons-material/Poll";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import FormField from "layouts/applications/wizard/components/FormField";
import MySavedReportsTableActions from "./MySavedReportsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
    height: "100%",
  },
  addButton: {
    fontSize: 40,
  },
});

const MySavedReportsTable = () => {
  const classes = useStyle();
  const columns = useMemo(
    () => [
      {
        title: "Report Name",
        field: "name",
        customCell: (name, field, row) => (
          <MDTypography fontSize="1rem" color="info">
            {name}
          </MDTypography>
        ),
      },
      {
        title: "Date",
        field: "date",
        customCell: (date, field, row) => (
          <MDTypography fontSize="1rem">{moment(date).format("MM/DD/YYYY")}</MDTypography>
        ),
      },
      { title: "User", field: "user" },
      {
        title: "",
        field: "_id",
        customCell: (id, field, row) => (
          <MySavedReportsTableActions
          // id={id}
          // row={row}
          // setCurrentTemplate={setCurrentTemplate}
          // setModalInfo={setModalInfo}
          // setNavigateUrl={setNavigateUrl}
          // toggleEditModal={toggleEmailTemplateModal}
          // toggleConfirmationModal={toggleConfirmationModal}
          // setContext={setContext}
          />
        ),
      },
    ],
    []
  );
  const hardcodedData = [
    {
      name: "Job Stages Report 11.17.23",
      date: "2023-10-27T21:38:16.312Z",
      user: "Andrew",
    },
    {
      name: "Payroll Report 3.5.2023",
      date: "2023-10-27T21:38:16.312Z",
      user: "Andrew",
    },
    {
      name: "Jobs Applicant Report 2.22.2023",
      date: "2023-10-27T21:38:16.312Z",
      user: "Andrew",
    },
    {
      name: "Event Report - CUTX 3.16.2023",
      date: "2023-10-27T21:38:16.312Z",
      user: "Andrew",
    },
    {
      name: "Job Stages Report 11.17.23",
      date: "2023-10-27T21:38:16.312Z",
      user: "Andrew",
    },
    {
      name: "Payroll Report 3.5.2023",
      date: "2023-10-27T21:38:16.312Z",
      user: "Andrew",
    },
  ];

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item sm={12}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="dark"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <PollIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              My Saved Reports
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item sm={6} />
        <Grid item sm={6} pr={5}>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={11}>
              <Autocomplete
                options={["option1", "option 2"]}
                autoSelect
                freeSolo
                name="page"
                defaultValue="All Saved Reports"
                renderInput={(params) => (
                  <FormField
                    {...params}
                    defaultValue="All Saved Reports"
                    type="text"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item sm={1}>
              <DownloadIcon fontSize="large" color="info" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2} px={3}>
        <DataTable
          columns={columns}
          data={{ data: hardcodedData, count: hardcodedData.length }}
          // page={page}
          // limit={limit}
          // setPage={setPage}
          // setLimit={setLimit}
          // onRowClick={(row, e) => handleRowClick(row, e)}
          // order={order}
          // orderBy={orderBy}
          // toggleSort={toggleSort}
          // fetchAll={fetchAll}
          // isLoading={isLoading}
          // defaultSort="jobSlug"
          // idField="jobSlug"
        />
      </MDBox>
      <hr />
      <MDBox textAlign="end">
        <Button startIcon={<RemoveRedEyeIcon />}>Show All Venue Report</Button>
      </MDBox>
    </Card>
  );
};

export default MySavedReportsTable;
