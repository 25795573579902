import { useState, useEffect } from "react";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { Controller } from "react-hook-form";
import CustomTextField from "components/Form/CustomTextField";
import {
  Autocomplete,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import Language from "@mui/icons-material/Language";
import { PatternFormat } from "react-number-format";
import FormField from "layouts/applications/wizard/components/FormField";
import useDropdowns from "hooks/useDropdowns";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";
import fetchCustomers from "api/companies/fetchCustomers";
import { useQuery } from "react-query";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import MDTypography from "components/MDTypography";

const InputFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const { STATUSES_ENTITY_NAME, STATES_ENTITY_NAME } = COMPANY_ENTITIES;

const CompanyInformationSection = ({
  control,
  setValue,
  isEdit,
  selectedStatus,
  isCompany = false,
  onBlur,
}) => {
  const { dropdowns: statuses } = useDropdowns({ entity: STATUSES_ENTITY_NAME });
  const { dropdowns: stateCodes } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const [usersNames, setUsersNames] = useState();
  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );
  const statusColor = () => {
    switch (selectedStatus) {
      case "Active":
        return "#4caf50";
      case "ACTIVE":
        return "#4CAF50";
      case "Inactive":
        return "#fc9d26";
      case "INACTIVE":
        return "#fc9d26";
      case "Prospect":
        return "#1A73E8";
      case "PROSPECT":
        return "#1A73E8";
      default:
        return "#000";
    }
  };

  const allOptions = {
    ...options,
    filters: { status: "Active" },
  };

  const { data: customers } = useQuery(["customers_info", allOptions], () =>
    fetchCustomers(allOptions)
  );

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((user) => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
          }))
      );
    }
  }, [users]);
  return (
    <>
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                label="Name"
                showError
                errorMessage={error?.message}
                {...field}
                onChange={(e) => {
                  setValue("name", e.target.value, { shouldDirty: true });
                  if (!isEdit) {
                    setValue(
                      "slug",
                      e.target.value
                        .toLowerCase()
                        .replace(/\s+/g, "-")
                        // eslint-disable-next-line no-useless-escape
                        .replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, ""),
                      { shouldDirty: true }
                    );
                  }
                }}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={statuses || []}
                name="status"
                autoSelect
                freeSolo
                // defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("status", v, { shouldDirty: true });
                }}
                sx={{ maxHeight: "44px", width: "100%" }}
                color="red"
                renderInput={(params) => {
                  return (
                    <FormField
                      type="text"
                      label="Status"
                      sx={{ input: { color: statusColor(), fontWeight: "bold" } }}
                      showError
                      errorMessage={error?.message}
                      {...params}
                    />
                  );
                }}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="masterCustomer"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                key="autocomplete_masterOrganization"
                options={
                  customers?.data
                    ? customers?.data?.map((customer) => ({
                        label: customer.name,
                        value: customer.slug,
                      }))
                    : []
                }
                autoSelect
                freeSolo
                value={
                  customers?.data?.find((customer) => customer.slug === field.value)?.name || ""
                }
                defaultValue=""
                onChange={(e, v) => {
                  setValue("masterCustomer", v.value, { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <>
                    <FormField
                      key="autocomplete_masterOrganization_form"
                      {...params}
                      type="text"
                      label="Master Organization"
                      // InputLabelProps={{ shrink: !isEmpty(getValues("recruiter")) }}
                    />
                    <FormHelperText error>{error?.message}</FormHelperText>
                  </>
                )}
              />
            )}
          />
        </InputFlexWrapper>

        <InputFlexWrapper>
          <Controller
            name="clientId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField label="Client ID" {...field} flex="1 0 50%" />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      {!isCompany && (
        <FlexWrapper justifyContent="space-between">
          <InputFlexWrapper>
            <Controller
              name="accountManager"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  key={`autocomplete_recruiter_${isCompany}`}
                  options={usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []}
                  autoSelect
                  freeSolo
                  name="accountManager"
                  defaultValue={null}
                  value={field.value || ""}
                  onChange={(e, v) => {
                    setValue("accountManager", v?.label ?? null, { shouldDirty: true });
                  }}
                  sx={{ width: "100%" }}
                  onBlur={onBlur}
                  renderInput={(params) => (
                    <>
                      <FormField
                        key={`accountManager_${isCompany}`}
                        {...params}
                        type="text"
                        label="Account Manager"
                        // InputLabelProps={{ shrink: !isEmpty(getValues("recruiter")) }}
                      />
                      <FormHelperText error>{error?.message}</FormHelperText>
                    </>
                  )}
                />
              )}
            />
          </InputFlexWrapper>
          <InputFlexWrapper>
            <Controller
              name="companyUrl"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  label="URL"
                  placeholder="URL"
                  showError
                  errorMessage={error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip title={`Visit ${field?.value} in another tab`} placement="top">
                          <IconButton
                            aria-label="open link"
                            onClick={() =>
                              window.open(
                                `${field.value?.includes("http") ? "" : "https://"}${field?.value}`
                              )
                            }
                            edge="end"
                          >
                            <Language />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </InputFlexWrapper>
        </FlexWrapper>
      )}
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="slug"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                {...field}
                label="Slug"
                disabled={isEdit}
                inputProps={{ tabIndex: -1 }}
                showError
                errorMessage={error?.message}
                onChange={(e) => field.onChange(e.target.value.toLowerCase().replace(/\s+/g, ""))}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      <Box mb={2}>
        <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
          Contact Information
        </MDTypography>
      </Box>
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="contact.firstName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                showError
                label="First Name"
                errorMessage={error?.message}
                {...field}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="contact.lastName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                showError
                label="Last Name"
                errorMessage={error?.message}
                {...field}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper>
          <Controller
            name="contact.email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                label="Email Address"
                placeholder="Email Address"
                showError
                errorMessage={error?.message}
                {...field}
              />
            )}
          />
        </InputFlexWrapper>
        {isCompany && (
          <InputFlexWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="contact.phone"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <PatternFormat
                      format="(###) ### ####"
                      allowEmptyFormatting
                      mask="_"
                      label="Contact Phone"
                      {...field}
                      onValueChange={({ value }) =>
                        setValue("contact.phone", value, { shouldDirty: true })
                      }
                      showError
                      errorMessage={error?.message}
                      customInput={CustomTextField}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="contact.phoneExt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      onChange={(e) => setValue("contact.phoneExt", e.target.value)}
                      label="Phone Extension"
                      type="number"
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      showError
                      errorMessage={error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </InputFlexWrapper>
        )}
        {!isCompany && (
          <InputFlexWrapper>
            <Controller
              name="contact.title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField label="Job Title" {...field} />
              )}
            />
          </InputFlexWrapper>
        )}
      </FlexWrapper>
      {isCompany && (
        <InputFlexWrapper maxWidth="100%">
          <Controller
            name="address"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField label="Address" {...field} />
            )}
          />
        </InputFlexWrapper>
      )}
      {!isCompany && (
        <FlexWrapper justifyContent="space-between">
          <InputFlexWrapper>
            <Controller
              name="address"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField label="Address" {...field} />
              )}
            />
          </InputFlexWrapper>
          <InputFlexWrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="contact.phone"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <PatternFormat
                      format="(###) ### ####"
                      allowEmptyFormatting
                      mask="_"
                      label="Contact Phone"
                      {...field}
                      onValueChange={({ value }) =>
                        setValue("contact.phone", value, { shouldDirty: true })
                      }
                      showError
                      errorMessage={error?.message}
                      customInput={CustomTextField}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="contact.phoneExt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      onChange={(e) => setValue("contact.phoneExt", e.target.value)}
                      label="Phone Extension"
                      type="number"
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      showError
                      errorMessage={error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </InputFlexWrapper>
        </FlexWrapper>
      )}
      <FlexWrapper justifyContent="space-between">
        <InputFlexWrapper maxWidth={{ xs: "32%", lg: "38%" }}>
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField label="City" showError errorMessage={error?.message} {...field} />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper maxWidth={{ xs: "32%", lg: "28%" }}>
          <Controller
            name="state"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={stateCodes}
                name="state"
                autoSelect
                freeSolo
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("state", v?.toUpperCase() || "", { shouldDirty: true });
                }}
                sx={{ maxHeight: "44px", width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    type="text"
                    label="State"
                    showError
                    errorMessage={error?.message}
                    {...params}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper maxWidth={{ xs: "32%", lg: "28%" }}>
          <Controller
            name="zip"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                label="Zip Code"
                showError
                errorMessage={error?.message}
                {...field}
              />
            )}
          />
        </InputFlexWrapper>
      </FlexWrapper>
    </>
  );
};

export default CompanyInformationSection;
