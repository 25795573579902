import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/applications/wizard/components/FormField";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { Autocomplete, Icon, Button, Switch, Alert, Snackbar } from "@mui/material";
import SendMessage from "components/SendMessage";
import { useAppContextController } from "context/AppContext";
import { Controller } from "react-hook-form";

const useStyle = makeStyles({
    editor: {
        height: 100,
    },
    box: {
        overflow: "visible",
        height: 850,
    },
    switchBase: {
        "&.Mui-checked": {
            "& .MuiSwitch-thumb": {
                border: "1px solid #1A73E8 !important",
            },
            "& + .MuiSwitch-track": {
                opacity: "1 !important",
                backgroundColor: "#1A73E8 !important",
                borderColor: "#1A73E8 !important",
            },
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: "0.3 !important",
            backgroundColor: "grey",
            borderColor: "grey",
        },
    },
    thumb: {
        color: "white !important",
        border: "1px solid grey !important",
    },
});

const PartnerSendMessagePanel = ({
    profilePanel,
    loginVerified,
    control,
    emailVerified = "No",
}) => {
    const { currentPartner, setStateVar } = useAppContextController();
    const classes = useStyle();
    const [text, setText] = useState(false);
    const [email, setEmail] = useState(true);
    const [system, setSystem] = useState(false);
    const [editorValue, setEditorValue] = useState(`<p></p>`);
    const [toastAlert, setToastAlert] = useState(false);

    return (
        <SnackbarProvider
            maxSnack={3}
            open={toastAlert}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setToastAlert(false)}
            key="top-center"
        >
            <Card className={classes.box} key={`card_sendMessage_${currentPartner?.id}`}>
                <MDBox display="flex" key={`box_sendMessage_${currentPartner?.id}`}>
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        variant="gradient"
                        bgColor="error"
                        color="white"
                        borderRadius="xl"
                        ml={3}
                        mt={-2}
                    >
                        <Icon fontSize="large">email</Icon>
                    </MDBox>
                    {!profilePanel ? (
                        <MDBox sx={{ mt: 2, ml: 2 }}>
                            <MDTypography variant="h5" color="dark">
                                Send Message to {currentPartner?.lastName} {currentPartner?.firstName}
                            </MDTypography>
                            {email && (
                                <MDTypography variant="body2" color="info" fontWeight="bold">
                                    {`E-mail: ${currentPartner?.email}`}
                                </MDTypography>
                            )}
                            {text && (
                                <MDTypography variant="body2" color="info" fontWeight="bold">
                                    {`Phone: ${currentPartner?.phone}`}
                                </MDTypography>
                            )}
                        </MDBox>
                    ) : (
                        <MDBox sx={{ mt: 2, ml: 2 }}>
                            <MDTypography variant="h5" color="dark">
                                Email Verification
                            </MDTypography>
                        </MDBox>
                    )}
                </MDBox>
                {!profilePanel ? (
                    <SendMessage
                        recipient={currentPartner}
                        setToastAlert={setToastAlert}
                        text={text}
                        setText={setText}
                        email={email}
                        setEmail={setEmail}
                        system={system}
                        setSystem={setSystem}
                        applicant={currentPartner}
                    />
                ) : (
                    <Grid container style={{ width: "100%", padding: "30px" }}>
                        <Grid
                            item
                            sm={4}
                            display="flex"
                            justifyContent="space-between"
                            direction="row"
                            alignItems="center"
                            marginRight={5}
                        >
                            <MDTypography variant="body2">Email Verified</MDTypography>
                            <Controller
                                {...emailVerified}
                                control={control}
                                render={({ field }) => (
                                    <Switch
                                        disabled
                                        checked={field.value === "Yes"}
                                        onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                        inputProps={{ tabIndex: -1, "aria-label": "" }}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            thumb: classes.thumb,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={4}
                            display="flex"
                            justifyContent="space-between"
                            direction="row"
                            alignItems="center"
                        >
                            <MDTypography variant="body2">Login Verified</MDTypography>
                            <Controller
                                {...loginVerified}
                                control={control}
                                render={({ field }) => (
                                    <Switch
                                        disabled
                                        checked={field.value === "Yes"}
                                        onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                        inputProps={{ tabIndex: -1, "aria-label": "" }}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            thumb: classes.thumb,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
            </Card>
        </SnackbarProvider>
    );
};

export default PartnerSendMessagePanel;
