import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import PreviewIcon from "@mui/icons-material/Preview";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import JobStatusButton from "components/JobStatusButton";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import { useEffect, useState } from "react";

const JobsTableActions = ({
  jobSlug,
  setNavigateUrl,
  row,
  setFilters,
  setOpen,
  setJobPreview,
  parent = "jobs",
  currentApplicant,
  isCompany,
}) => {
  const navigate = useNavigate();

  const [localJobStatus, setLocalJobStatus] = useState(
    getApplicantJobStatus(currentApplicant, jobSlug)
  );

  useEffect(() => {
    setLocalJobStatus(getApplicantJobStatus(currentApplicant, jobSlug));
  }, [currentApplicant, jobSlug]);

  const refresh = (slug, status) => {
    if (status) {
      setLocalJobStatus(status);
    }
  };

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    navigate(`/jobs/${jobSlug}/action/${action}`);
  };

  const setPreviewOpen = (job, e) => {
    e.stopPropagation();
    setJobPreview(job);
    setOpen(true);
  };
  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("jobinfo", e)}
        tooltip="Job Info"
      />
      {!!currentApplicant && (
        <JobStatusButton status={localJobStatus} job={row} refresh={refresh} />
      )}
      {/* <TableAction
        color="success"
        icon="event-seat"
        showBadge
        tooltip="Job Interviews"
        clickAction={(e) => onClickHandler("jobevents", e)}
      /> */}
      <TableAction
        icon={<PersonAddIcon color="warning" fontSize="large" />}
        showBadge
        color="warning"
        tooltip="Job Applicants"
        clickAction={(e) => onClickHandler("jobapplicants", e)}
      />
      <TableAction
        icon="people"
        showBadge
        color="error"
        tooltip={isCompany ? "Job Employees" : "Venue Staffing Pool"}
        clickAction={(e) => onClickHandler("jobstaffingpool", e)}
      />
      <TableAction
        color="info"
        icon={<PreviewIcon />}
        clickAction={(e) =>
          parent === "jobs"
            ? setPreviewOpen(row, e)
            : navigate(`/jobs/${jobSlug}/action/jobpreview`)
        }
        tooltip="Job Preview"
      />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("joball", e)}
        tooltip="All Job Information"
      /> */}
    </span>
  );
};

JobsTableActions.defaultProps = {
  jobSlug: "",
  setNavigateUrl: () => { },
};

// Typechecking props for the TableAction
JobsTableActions.propTypes = {
  jobSlug: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};

export default JobsTableActions;
