import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import fetchApplicantActivities from "components/Activities/actions/fetchApplicantActivities";
import ActivitiesDataList from "./ActivitiesDataList";
import fetchActivities from "./actions/fetchActivities";
import fetchPartnerActivites from "./actions/fetchPartnerActivities";

const Activities = ({ activitiesParent, filters, height, tableHeight, parent = "applicant" }) => {
  const [page, setPage] = useState(1);

  const options = useMemo(() => {
    return {
      fetchAll: false,
      orderBy: "activityDate",
      order: "desc",
      limit: 20,
      filters: { ...filters },
      activitiesParent,
    };
  }, [filters, activitiesParent]);


  const allOptions = useMemo(() => {
    if (parent === "partners") {
      return { ...options, partnerId: activitiesParent?._id, page };
    }
    return { ...options, applicantId: activitiesParent?._id, page };
  }, [page, activitiesParent?._id, options]);

  const {
    data: activities,
    isLoading,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    [parent === "partners" ? "activitiespartner" : "activitiesapplicant", allOptions],
    ({ queryKey, pageParam }) => {
      if (parent === "partners")
        return fetchPartnerActivites({ ...allOptions, page: pageParam ?? 1 })

      return fetchApplicantActivities({ ...allOptions, page: pageParam ?? 1 })

    }
    ,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!activitiesParent?._id,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
          return undefined;
        }

        const nextPage = lastPage?.pagination?.next?.page;
        return nextPage ?? false;
      },
    }
  );
  const fullData = useMemo(
    () =>
      activities?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [activities?.pages])


  return (
    <ActivitiesDataList
      isLoading={isLoading}
      activities={activities?.pages?.[activities?.pages?.length - 1]}
      height={height}
      tableHeight={tableHeight}
      computedHeight
      activitiesParent={activitiesParent}
      page={page}
      setPage={setPage}
      fetchNextPage={fetchNextPage}
      fullData={fullData}
      isFetching={isFetching}
    />
  );
};

export default Activities;
