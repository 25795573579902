import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Icon, Grid, IconButton, Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import PictureAvatar from "components/PictureAvatar";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BugReportIcon from "@mui/icons-material/BugReport";
import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider, useSnackbar } from "notistack";
import GetIcons from "components/GetIcons";
import PartnersTableActions from "layouts/pages/partners/components/PartnersTableActions";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import fetchPartners from "layouts/pages/partners/actions/fetchPartners";
import { Handshake } from "@mui/icons-material";
import useVenueCache from "hooks/useVenueCache";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import updatePartner from "../../actions/updatePartner";
// import PartnersActionModal from "../PartnersActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const PartnersTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
}) => {
  const { setCurrentPartner, currentPartner, currentLoggedUser, venues, setVenues, company } =
    useAppContextController();
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const navigate = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentPartner });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [toastAlert, setToastAlert] = useState(false);
  const { data: partners, isLoading } = useQuery(["partners", allOptions], () =>
    fetchPartners(allOptions)
  );

  // DELETE BUTTON
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [partnerToDelete, setPartnerToDelete] = useState()
  const updatePartnerMutation = useMutation(updatePartner, {
    onError: (err) =>
      enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("partners");
      if (data.status === "Deleted") {
        setCurrentPartner({});
        enqueueSnackbar("Partner was archived", { variant: "success" });
        navigate(`/partners`);
      } else {
        setCurrentPartner({ ...currentPartner, ...data, modifiedDate: new Date() });
        enqueueSnackbar("Partner has been updated!", { variant: "success" });
      }
    },
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (row) => {
    setPartnerToDelete(row)
    setOpenDeleteModal(!openDeleteModal);
  }
  
  const deletePartnerHandler = async (values) => {
    await updatePartnerMutation.mutateAsync({
      id: partnerToDelete._id,
      data: { status: "Deleted" },
    });
    handleOpenDeleteModal();
  };
  // ------------------

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const columns = [
    // {
    //   title: "Icon",
    //   field: "type",
    //   customCell: (image, field, row) => <GetIcons category={row?.category} />,
    // },
    {
      title: "Slug",
      field: "slug",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "City",
      field: "city",
    },
    {
      title: "State",
      field: "state",
    },
    {
      title: "Modified",
      field: "modifiedDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    // {
    //   title: "Avatar",
    //   field: "createAgent",
    //   customCell: (createAgent, field, row) => (
    //     <>
    //       {createAgent}
    //       <PictureAvatar
    //         image={null}
    //         firstName={row?.userFirstName}
    //         lastName={row?.userLastName}
    //         userId={createAgent}
    //         size="md"
    //       />
    //     </>
    //   ),
    // },
    // {
    //   title: "Created By",
    //   field: "userFirstName",
    //   customCell: (id, field, row) => (
    //     <MDTypography variant="body">
    //       {row.userFirstName} {row.userLastName}
    //     </MDTypography>
    //   ),
    // },
    {
      title: "Partners Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <PartnersTableActions
          id={id}
          slug={row.slug}
          row={row}
          setCurrentPartner={setCurrentPartner}
          setModalInfo={setModalInfo}
          setNavigateUrl={navigate}
          setGenericModalOpen={setGenericModalOpen}
          onOpenDeleteModal={handleOpenDeleteModal}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "Slug", field: "slug" },
    { title: "Name", field: "name" },
    { title: "City", field: "city" },
    { title: "Leader First", field: "leader.firstName" },
    { title: "Leader Last", field: "leader.lastName" },
  ];

  // const columnsForPartners = [
  //   { title: "First Name", field: "userFirstName" },
  //   { title: "Last Name", field: "userLastName" },
  //   { title: "Title", field: "title" },
  //   { title: "Status", field: "status" },
  // ];

  const handleRowClick = (partner, e) => {
    e.stopPropagation();
    setCurrentPartner({ ...partner });
    navigate(`/partners/${partner.slug}`);
  };

  const addNew = (e) => {
    setCurrentPartner({});

    navigate("/partners/create");
    e.stopPropagation();
  };

  return (
    <Card className={classes.box}>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="secondary"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Handshake fontSize="large" />
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Partners
              </MDTypography>
              <MDBox ml={3}>
                {showFiltersList && (
                  <Grid item xs={6}>
                    <FiltersList filters={filters} setFilters={setFilters} />
                  </Grid>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {showSearchBar && (
              <Searchbar
                fetch={fetchPartners}
                fetchAll={false}
                placeholder="Search Partners"
                columns={columnsForSearch}
                queryCharacterLimit={2}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                onRowClick={(item) => navigate(`/partners/${item.slug}`)}
                searchBy={["name", "slug", "city"]}
                filterBy="slug"
                idField="slug"
              />
            )}
            <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={partners}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={false}
            defaultSort="slug:asc"
          />
        </MDBox>
        {/* <PartnersActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
        /> */}
      </SnackbarProvider>
      {openDeleteModal && (
        <ConfirmModal
          title="Delete Partner"
          description="Please confirm you want to DELETE this partner."
          isOpen={openDeleteModal}
          negativeBtn={{
            label: "Cancel",
            fn: handleOpenDeleteModal,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deletePartnerHandler,
            isShown: true,
          }}
        />
      )}
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
PartnersTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Partners",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
PartnersTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default PartnersTable;
