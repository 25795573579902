import { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Typography, Grid, IconButton, styled } from "@mui/material";
import GenericModal from "components/GenericModal";
import parse from "html-react-parser";
import CancelIcon from "@mui/icons-material/Cancel";
import DataList from "components/DataList";
import moment from "moment";
import { useAppContextController } from "context/AppContext";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import { useMutation, useQueryClient } from "react-query";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import CreateEditNoteModal from "../CreateEditNoteModal";

const MembersNotesList = ({
  open,
  setOpen,
  currentApplicant,
  setValue,
  setToastAlert,
  dirtyFields,
  getValues,
  watch,
  reset,
}) => {
  const queryClient = useQueryClient();
  const [currentNote, setCurrentNote] = useState(null);

  const [notesArray, setNotesArray] = useState([]);
  const [noteIndex, setNoteIndex] = useState(null);
  const { currentLoggedUser } = useAppContextController();
  const [editorValue, setEditorValue] = useState("<p></p>");
  const [noteTitle, setNoteTitle] = useState(null);
  const [validationError, setValidationError] = useState([]);

  const resetNotes = async () => {
    setNoteTitle(null);
    setEditorValue("<p></p>");
    setCurrentNote(null);
    setNoteIndex(null);
    setValidationError([]);
  };

  const onCancel = () => {
    resetNotes();
    setOpen(false);
  };

  const validation = () => {
    const errorArr = [];
    let newError;
    if (noteTitle === null) {
      newError = "noteTitle";
      errorArr.push(newError);
    }
    if (editorValue === "<p></p>" || editorValue === "<div><br></div>") {
      newError = "editorValue";
      errorArr.push(newError);
    }
    setValidationError(errorArr);
    return errorArr;
  };
  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: (err) => {
      console.log("error", err);
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
    },
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");
      if (data.status === "Deleted") {
        setToastAlert({ isShow: true, message: "Member was archived", status: "success" });
      } else {
        setToastAlert({ isShow: true, message: "Member has been updated!", status: "success" });
      }
    },
  });

  const saveChanges = async (data) => {
    if (!currentApplicant?._id) {
      setToastAlert({ isShow: true, message: "Something went wrong! No ID", status: "error" });
      return;
    }
    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant?._id,
      data: {
        notes: watch("notes"),
      },
    });
  };

  useEffect(() => {
    setNotesArray(watch("notes"));
  }, [currentApplicant?.notes, watch("notes")]);

  const saveNote = async (e) => {
    e.preventDefault();
    validation();
    if (validation().length === 0) {
      const newNote = {
        type: noteTitle,
        text: editorValue,
        userId: currentLoggedUser?._id,
        firstName: currentLoggedUser?.firstName,
        lastName: currentLoggedUser?.lastName,
        date: new Date().toISOString(),
      };

      if (noteIndex !== null) {
        const newNotesArray = watch("notes") || [];
        newNotesArray[noteIndex] = newNote;
        setValue("notes", newNotesArray, { shouldDirty: true });
      } else {
        const newNotesArray = getValues("notes") || [];
        newNotesArray.push(newNote);
        setValue("notes", newNotesArray, { shouldDirty: true });
      }
      resetNotes();
    }
  };

  const handleRowClick = (note, index) => {
    setCurrentNote(note);
    setNoteIndex(index);
  };

  //   const updateApplicantMutation = useMutation(updateApplicant, {
  //     onError: (err) => {
  //       console.log("error", err);
  //       setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
  //     },
  //     onSuccess: async (_, { data }) => {
  //       await queryClient.invalidateQueries("applicants");
  //       if (data.status === "Deleted") {
  //         setToastAlert({ isShow: true, message: "Member was archived", status: "success" });
  //       } else {
  //         setToastAlert({ isShow: true, message: "Member has been updated!", status: "success" });
  //       }
  //     },
  //   });

  //   const saveChanges = async (data) => {
  //     if (!currentApplicant?._id) {
  //       setToastAlert({ isShow: true, message: "Something went wrong! No ID", status: "error" });
  //       return;
  //     }
  //     await updateApplicantMutation.mutateAsync({
  //       applicantId: currentApplicant?._id,
  //       data: {
  //         notes: watch("notes"),
  //       },
  //     });
  //   };

  useEffect(() => {
    setNotesArray(watch("notes"));
  }, [currentApplicant?.notes, watch("notes")]);

  useEffect(() => {
    if (currentNote) {
      setNoteTitle(currentNote.type);
      setEditorValue(currentNote.text);
    }
  }, [currentNote]);

  useEffect(() => {
    if (validationError.length > 0) {
      validation();
    }
  }, [editorValue, noteTitle]);

  const renderRow = (row, index) => (
    <>
      <Grid
        container
        onClick={() => {
          handleRowClick(row, index);
        }}
      >
        <Grid item xs={12} sm={2}>
          <MDTypography variant="body2">{row?.type}</MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDTypography variant="body2">
            {moment(row?.activityDate).format("ddd YYYY-MM-DD hh:mm A")}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box sx={{ typography: 'body2' }} key={`position_${row?.action}`} >
            {parse(row?.text)}
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const header = (
    <>
      {/* <Typography variant="h5">
                {noteIndex === null ? "Add a Note" : "Edit Note"}
            </Typography> */}
      <Box>
        <Typography variant="h5">Members Notes</Typography>
      </Box>

      {/* <Box textAlign="end">
                <Button
                    variant="text"
                    startIcon={<AddCircleRoundedIcon />}
                    onClick={() => {
                        setCurrentNote(null)
                        setNoteIndex(null)
                    }}
                    sx={{ padding: "0.5rem 1rem" }}
                >
                    Add Note
                </Button>

            </Box> */}
    </>
  );

  const modalBody = (
    <>
      <Box mb={3}>
        <FormField
          value={noteTitle || ""}
          onChange={(e) => {
            setNoteTitle(e.target.value);
          }}
          label="Note Title"
        />
        <MDTypography color="error">
          {validationError.includes("noteTitle") && "You must enter a title for the note"}
        </MDTypography>
      </Box>
      <MDEditor value={editorValue} onChange={(e) => setEditorValue(e)} />
      <MDTypography color="error">
        {validationError.includes("editorValue") && "Please enter a note"}
      </MDTypography>
      <Box textAlign="end" my={5}>
        <Button
          variant="text"
          startIcon={<CancelIcon />}
          style={{ color: "red" }}
          onClick={() => {
            resetNotes();
          }}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          style={{ color: "white" }}
          onClick={(e) => {
            saveNote(e);
          }}
        >
          {noteIndex === null ? "Create Note" : "Save Changes"}
        </Button>
      </Box>

      <DataList
        renderRow={renderRow}
        data={notesArray}
        greybar
        divider
        cardVariant="outlined"
        scrollY
      />

      {/* <CreateEditNoteModal
            open={createEditNoteModalOpen}
            setOpen={setCreateEditNoteModalOpen}
            notesArray={notesArray}
            setNotesArray={setNotesArray}
            currentNote={currentNote}
            setCurrentNote={setCurrentNote}
            setNoteIndex={setNoteIndex}
            noteIndex={noteIndex}
            setValue={setValue}
            getValues={getValues}
        /> */}
    </>
  );

  const buttons = (
    <Box display="flex">
      <Box mr={2}>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          onClick={() => {
            resetNotes();
            reset(currentApplicant);
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </Box>
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        style={{ color: "white" }}
        onClick={() => {
          saveChanges();
          setOpen(false);
        }}
      >
        Save
      </Button>
    </Box>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "1100px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  right: "0%",
  m: 1,
  p: 0,
}));

export default MembersNotesList;
